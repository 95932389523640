import React from 'react';
import {
  AnnualReportVersion,
  CashFlowStatementGeneral,
  Settings,
} from '@agoy/annual-report-document';
import { booleanValue } from '@agoy/document';
import { CashFlowStatementPage } from '@agoy/annual-report-view';
import PreviewSection from '../../UI/PreviewSection';
import Page from '../../UI/Print/Page';

interface CashFlowStatementPreviewProps {
  cashFlowStatement: CashFlowStatementGeneral | undefined;
  settings: Settings;
  version: AnnualReportVersion;
}

const CashFlowStatementPreview = ({
  cashFlowStatement,
  settings,
  version,
}: CashFlowStatementPreviewProps): JSX.Element | null => {
  if (cashFlowStatement == null) {
    return null;
  }

  return (
    <Page>
      <PreviewSection id="cashFlowStatement">
        <CashFlowStatementPage
          cashFlowStatement={cashFlowStatement}
          settings={settings}
        />
      </PreviewSection>
    </Page>
  );
};

export default CashFlowStatementPreview;
