/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2029K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2029K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Exceptionella intäkter och kostnader'),
  data: {
    active: true,
    ExceptionellaIntakterTuple: {
      type: 'group',
      active: true,
      title: field('Exceptionella intäkter'),
      table: table<IxbrlCell>(
        'notes.note29.data.ExceptionellaIntakterTuple.table',
        {
          id: 'ExceptionellaIntakterPost',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'ExceptionellaIntakterTuple',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Benämning på exceptionell intäkt'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:ExceptionellaIntakterPost',
                  contextRef: 'period0',
                  standardRubrik: 'Benämning på exceptionell intäkt',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:ExceptionellaIntakterTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: true,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ExceptionellaIntakter',
                  contextRef: 'period0',
                  standardRubrik: 'Exceptionell intäkt per intäktsslag',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple_period0`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ExceptionellaIntakter',
                  contextRef: 'period1',
                  standardRubrik: 'Exceptionell intäkt per intäktsslag',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_period1`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              ExceptionellaIntakterPost: ixbrlCell(
                value('Benämning på exceptionell intäkt'),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:ExceptionellaIntakterPost',
                  contextRef: 'period0',
                  standardRubrik: 'Benämning på exceptionell intäkt',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:ExceptionellaIntakterTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: true,
                }
              ),
              year: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ExceptionellaIntakter',
                contextRef: 'period0',
                standardRubrik: 'Exceptionell intäkt per intäktsslag',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_period0`),
                previousYearTuple: false,
              }),
              previousYear: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ExceptionellaIntakter',
                contextRef: 'period1',
                standardRubrik: 'Exceptionell intäkt per intäktsslag',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_period1`),
                previousYearTuple: false,
              }),
            },
          }));
          return rows.build();
        })
        .build(),
    },
    ExceptionellaKostnaderTuple: {
      type: 'group',
      active: true,
      title: field('Exceptionella kostnader'),
      table: table<IxbrlCell>(
        'notes.note29.data.ExceptionellaKostnaderTuple.table',
        {
          id: 'ExceptionellaKostnaderPost',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'ExceptionellaKostnaderTuple',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Benämning på exceptionell kostnad'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:ExceptionellaKostnaderPost',
                  contextRef: 'period0',
                  standardRubrik: 'Benämning på exceptionell kostnad',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:ExceptionellaKostnaderTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: true,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ExceptionellaKostnader',
                  contextRef: 'period0',
                  standardRubrik: 'Exceptionell kostnad per kostnadsslag',
                  saldo: 'debit',
                  negateValue: true,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple_period0`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(undefined), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ExceptionellaKostnader',
                  contextRef: 'period1',
                  standardRubrik: 'Exceptionell kostnad per kostnadsslag',
                  saldo: 'debit',
                  negateValue: true,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple_period1`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              ExceptionellaKostnaderPost: ixbrlCell(
                value('Benämning på exceptionell kostnad'),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:ExceptionellaKostnaderPost',
                  contextRef: 'period0',
                  standardRubrik: 'Benämning på exceptionell kostnad',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:ExceptionellaKostnaderTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: true,
                }
              ),
              year: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ExceptionellaKostnader',
                contextRef: 'period0',
                standardRubrik: 'Exceptionell kostnad per kostnadsslag',
                saldo: 'debit',
                negateValue: true,
                tupleRef: tupleRef(2, `${baseId}.${_id}.tuple_period0`),
                previousYearTuple: false,
              }),
              previousYear: ixbrlCell(value(undefined), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ExceptionellaKostnader',
                contextRef: 'period1',
                standardRubrik: 'Exceptionell kostnad per kostnadsslag',
                saldo: 'debit',
                negateValue: true,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple_period1`),
                previousYearTuple: false,
              }),
            },
          }));
          return rows.build();
        })
        .build(),
      NotExceptionellaIntakterKostnaderKommentar: {
        type: 'group',
        active: true,
        title: field(
          'Kommentar till specifikation av exceptionella intäkter och kostnader'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotExceptionellaIntakterKostnaderKommentar',
          standardRubrik:
            'Kommentar till specifikation av exceptionella intäkter och kostnader',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2029K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        ExceptionellaIntakterTuple: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        ExceptionellaKostnaderTuple: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotExceptionellaIntakterKostnaderKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
