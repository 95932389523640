import React from 'react';
import {
  AnnualReportType,
  AnnualReport,
  AnnualReportVersion,
} from '@agoy/annual-report-document';
import { NotesPage } from '@agoy/annual-report-view';
import Page from '../../UI/Print/Page';

interface NotesPreviewProps {
  annualReport: AnnualReport;
  reportType: AnnualReportType;
  isDigitalSubmission: boolean;
  documentTypeVersion: AnnualReportVersion;
}

const NotesPreview = ({
  annualReport,
  reportType,
  isDigitalSubmission,
  documentTypeVersion,
}: NotesPreviewProps): JSX.Element => {
  return (
    <Page>
      <NotesPage
        annualReport={annualReport}
        reportType={reportType}
        isDigitalSubmission={isDigitalSubmission}
        annualReportVersion={documentTypeVersion}
      />
    </Page>
  );
};

export default NotesPreview;
