/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6841
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2011K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2011K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Aktierelaterade ersättningar till anställda'),
  data: {
    active: true,
    AktierelateradErsattningAnstalldaAbstract: {
      type: 'group',
      active: true,
      title: field('Aktierelaterade ersättningar till anställda'),
      AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract: {
        type: 'group',
        active: true,
        title: field('Antalet aktieoptioner till anställda'),
        table: table<IxbrlCell>(
          'notes.note11.data.AktierelateradErsattningAnstalldaAbstract.AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract.table',
          {
            id: 'AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract',
            label: 'Antalet optioner',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'AktierelateradErsattningAnstalldaAntalPersonaloptionerIngaende',
                  'row',
                  noteLabel('Utestående vid årets början'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'decimalItemType',
                    unitRef: 'andelar',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:AktierelateradErsattningAnstalldaAntalPersonaloptioner',
                    standardRubrik:
                      'Antalet utestående aktieoptioner till anställda',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans2',
                    type: 'decimalItemType',
                    unitRef: 'andelar',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:AktierelateradErsattningAnstalldaAntalPersonaloptioner',
                    standardRubrik:
                      'Antalet utestående aktieoptioner till anställda',
                    negateValue: false,
                  })
                );
                rows
                  .addRowWithType(
                    'AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerAbstract',
                    'header',
                    noteLabel('Förändring av antalet optioner'),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows.addRowWithType(
                      'AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerTilldelade',
                      'row',
                      noteLabel('Tilldelade under året'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'decimalItemType',
                        unitRef: 'andelar',
                        format: 'ixt:numspacecomma',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerTilldelade',
                        standardRubrik:
                          'Antalet tilldelade aktieoptioner till anställda under perioden',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'decimalItemType',
                        unitRef: 'andelar',
                        format: 'ixt:numspacecomma',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerTilldelade',
                        standardRubrik:
                          'Antalet tilldelade aktieoptioner till anställda under perioden',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerForverkade',
                      'row',
                      noteLabel('Förverkade under året'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'decimalItemType',
                        unitRef: 'andelar',
                        format: 'ixt:numspacecomma',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerForverkade',
                        standardRubrik:
                          'Antalet förverkade aktieoptioner till anställda under perioden',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'decimalItemType',
                        unitRef: 'andelar',
                        format: 'ixt:numspacecomma',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerForverkade',
                        standardRubrik:
                          'Antalet förverkade aktieoptioner till anställda under perioden',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerInlosta',
                      'row',
                      noteLabel('Inlösta under året'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'decimalItemType',
                        unitRef: 'andelar',
                        format: 'ixt:numspacecomma',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerInlosta',
                        standardRubrik:
                          'Antalet inlösta aktieoptioner till anställda under perioden',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'decimalItemType',
                        unitRef: 'andelar',
                        format: 'ixt:numspacecomma',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerInlosta',
                        standardRubrik:
                          'Antalet inlösta aktieoptioner till anställda under perioden',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerForfallna',
                      'row',
                      noteLabel('Förfallna under året'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'decimalItemType',
                        unitRef: 'andelar',
                        format: 'ixt:numspacecomma',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerForfallna',
                        standardRubrik:
                          'Antalet förfallna aktieoptioner till anställda under perioden',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'decimalItemType',
                        unitRef: 'andelar',
                        format: 'ixt:numspacecomma',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerForfallna',
                        standardRubrik:
                          'Antalet förfallna aktieoptioner till anställda under perioden',
                        negateValue: false,
                      })
                    );
                    return rows.build();
                  });
                rows.addRowWithType(
                  'AktierelateradErsattningAnstalldaAntalPersonaloptionerUtgaende',
                  'sum',
                  noteLabel('Utestående vid årets slut'),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'notes.note11.data.AktierelateradErsattningAnstalldaAbstract.AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract.table.AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract.AktierelateradErsattningAnstalldaAntalPersonaloptionerIngaende.year'
                        ),
                        id(
                          'notes.note11.data.AktierelateradErsattningAnstalldaAbstract.AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract.table.AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract.AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerAbstract.*.year'
                        )
                      )
                    ),
                    {
                      contextRef: 'balans0',
                      type: 'decimalItemType',
                      unitRef: 'andelar',
                      format: 'ixt:numspacecomma',
                      name: 'se-gen-base:AktierelateradErsattningAnstalldaAntalPersonaloptioner',
                      standardRubrik:
                        'Antalet utestående aktieoptioner till anställda',
                      negateValue: false,
                    }
                  ),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'notes.note11.data.AktierelateradErsattningAnstalldaAbstract.AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract.table.AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract.AktierelateradErsattningAnstalldaAntalPersonaloptionerIngaende.previousYear'
                        ),
                        id(
                          'notes.note11.data.AktierelateradErsattningAnstalldaAbstract.AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract.table.AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract.AktierelateradErsattningAnstalldaForandringAntalPersonaloptionerAbstract.*.previousYear'
                        )
                      )
                    ),
                    {
                      contextRef: 'balans1',
                      type: 'decimalItemType',
                      unitRef: 'andelar',
                      format: 'ixt:numspacecomma',
                      name: 'se-gen-base:AktierelateradErsattningAnstalldaAntalPersonaloptioner',
                      standardRubrik:
                        'Antalet utestående aktieoptioner till anställda',
                      negateValue: false,
                    }
                  )
                );
                rows.addRowWithType(
                  'AktierelateradErsattningAnstalldaAntalPersonaloptionerInlosningsbara',
                  'row',
                  noteLabel('Inlösningsbara vid årets slut'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'decimalItemType',
                    unitRef: 'andelar',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:AktierelateradErsattningAnstalldaAntalPersonaloptionerInlosningsbara',
                    standardRubrik:
                      'Antalet inlösningsbara aktieoptioner till anställda vid periodens slut',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'decimalItemType',
                    unitRef: 'andelar',
                    format: 'ixt:numspacecomma',
                    name: 'se-gen-base:AktierelateradErsattningAnstalldaAntalPersonaloptionerInlosningsbara',
                    standardRubrik:
                      'Antalet inlösningsbara aktieoptioner till anställda vid periodens slut',
                    negateValue: false,
                  })
                );
                return rows.build();
              });
            return rows.build();
          })
          .build(),
        AktierelateradErsattningAnstalldaAntalPersonaloptionerKommentar: {
          type: 'group',
          active: true,
          title: field('Kommentar till antalet aktieoptioner till anställda'),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:AktierelateradErsattningAnstalldaAntalPersonaloptionerKommentar',
            standardRubrik:
              'Kommentar till antalet aktieoptioner till anställda',
          }),
        },
      },
      AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract: {
        type: 'group',
        active: true,
        title: field(
          'Genomsnittligt lösenpris per aktieoptioner till anställda'
        ),
        table: table<IxbrlCell>(
          'notes.note11.data.AktierelateradErsattningAnstalldaAbstract.AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract.table',
          {
            id: 'AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract',
            label: 'Genomsnittligt lösenpris per option',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'AktierelateradErsattningAnstalldaLosenprisPersonaloptionerIngaende',
                  'row',
                  noteLabel('Utestående vid årets början'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptioner',
                    standardRubrik:
                      'Genomsnittligt lösenpris på aktieoptioner till anställda',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptioner',
                    standardRubrik:
                      'Genomsnittligt lösenpris på aktieoptioner till anställda',
                    negateValue: false,
                  })
                );
                rows
                  .addRowWithType(
                    'AktierelateradErsattningAnstalldaForandringLosenprisPersonaloptionerAbstract',
                    'header',
                    noteLabel(
                      'Förändring av genomsnittligt lösenpris i kr per option'
                    ),
                    undefined,
                    undefined
                  )
                  .addSubRows((rows) => {
                    rows.addRowWithType(
                      'AktierelateradErsattningAnstalldaLosenprisPersonaloptionerTilldelade',
                      'row',
                      noteLabel('Tilldelade under året'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptionerTilldelade',
                        standardRubrik:
                          'Lösenpris för tilldelade aktieoptioner till anställda under perioden',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptionerTilldelade',
                        standardRubrik:
                          'Lösenpris för tilldelade aktieoptioner till anställda under perioden',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'AktierelateradErsattningAnstalldaLosenprisPersonaloptionerForverkade',
                      'row',
                      noteLabel('Förverkade under året'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptionerForverkade',
                        standardRubrik:
                          'Lösenpris för förverkade aktieoptioner till anställda under perioden',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptionerForverkade',
                        standardRubrik:
                          'Lösenpris för förverkade aktieoptioner till anställda under perioden',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'AktierelateradErsattningAnstalldaLosenprisPersonaloptionerInlosta',
                      'row',
                      noteLabel('Inlösta under året'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptionerInlosta',
                        standardRubrik:
                          'Lösenpris för inlösta aktieoptioner till anställda under perioden',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptionerInlosta',
                        standardRubrik:
                          'Lösenpris för inlösta aktieoptioner till anställda under perioden',
                        negateValue: false,
                      })
                    );
                    rows.addRowWithType(
                      'AktierelateradErsattningAnstalldaLosenprisPersonaloptionerForfallna',
                      'row',
                      noteLabel('Förfallna under året'),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptionerForfallna',
                        standardRubrik:
                          'Lösenpris för förfallna aktieoptioner till anställda under perioden',
                        negateValue: false,
                      }),
                      ixbrlCell(value(undefined), {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptionerForfallna',
                        standardRubrik:
                          'Lösenpris för förfallna aktieoptioner till anställda under perioden',
                        negateValue: false,
                      })
                    );
                    return rows.build();
                  });
                rows.addRowWithType(
                  'AktierelateradErsattningAnstalldaLosenprisPersonaloptionerUtgaende',
                  'sum',
                  noteLabel('Utestående vid årets slut'),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'notes.note11.data.AktierelateradErsattningAnstalldaAbstract.AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract.table.AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract.AktierelateradErsattningAnstalldaLosenprisPersonaloptionerIngaende.year'
                        ),
                        id(
                          'notes.note11.data.AktierelateradErsattningAnstalldaAbstract.AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract.table.AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract.AktierelateradErsattningAnstalldaForandringLosenprisPersonaloptionerAbstract.*.year'
                        )
                      )
                    ),
                    {
                      contextRef: 'balans0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptioner',
                      standardRubrik:
                        'Genomsnittligt lösenpris på aktieoptioner till anställda',
                      negateValue: false,
                    }
                  ),
                  ixbrlCell(
                    ref(
                      sumAllowNull(
                        id(
                          'notes.note11.data.AktierelateradErsattningAnstalldaAbstract.AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract.table.AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract.AktierelateradErsattningAnstalldaLosenprisPersonaloptionerIngaende.previousYear'
                        ),
                        id(
                          'notes.note11.data.AktierelateradErsattningAnstalldaAbstract.AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract.table.AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract.AktierelateradErsattningAnstalldaForandringLosenprisPersonaloptionerAbstract.*.previousYear'
                        )
                      )
                    ),
                    {
                      contextRef: 'balans1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptioner',
                      standardRubrik:
                        'Genomsnittligt lösenpris på aktieoptioner till anställda',
                      negateValue: false,
                    }
                  )
                );
                rows.addRowWithType(
                  'AktierelateradErsattningAnstalldaLosenprisPersonaloptionerInlosningsbara',
                  'row',
                  noteLabel('Inlösningsbara vid årets slut'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptionerInlosningsbara',
                    standardRubrik:
                      'Genomsnittligt lösenpris för inlösningsbara aktieoptioner till anställda vid periodens slut',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptionerInlosningsbara',
                    standardRubrik:
                      'Genomsnittligt lösenpris för inlösningsbara aktieoptioner till anställda vid periodens slut',
                    negateValue: false,
                  })
                );
                return rows.build();
              });
            return rows.build();
          })
          .build(),
        AktierelateradErsattningAnstalldaLosenprisPersonaloptionKommentar: {
          type: 'group',
          active: true,
          title: field(
            'Kommentar till genomsnittligt lösenpris per aktieoptioner till anställda'
          ),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:AktierelateradErsattningAnstalldaLosenprisPersonaloptionKommentar',
            standardRubrik:
              'Kommentar till genomsnittligt lösenpris per aktieoptioner till anställda',
          }),
        },
      },
      AktierelateradErsattningAnstalldaRedovisadKostnadSkuldAbstract: {
        type: 'group',
        active: true,
        title: field(
          'Aktierelaterade ersättningar redovisade i resultat- och balansräkningen'
        ),
        table: table<IxbrlCell>(
          'notes.note11.data.AktierelateradErsattningAnstalldaAbstract.AktierelateradErsattningAnstalldaRedovisadKostnadSkuldAbstract.table',
          {
            id: 'AktierelateradErsattningAnstalldaRedovisadKostnadSkuldAbstract',
            label: 'Belopp redovisade i resultat- och balansräkningen',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'AktierelateradErsattningAnstalldaRedovisadKostnadSkuldAbstract',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'AktierelateradErsattningAnstalldaRedovisadKostnad',
                  'row',
                  noteLabel('Redovisad kostnad'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AktierelateradErsattningAnstalldaRedovisadKostnad',
                    standardRubrik:
                      'Redovisad kostnad för aktierelaterade ersättningar',
                    saldo: 'debit',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AktierelateradErsattningAnstalldaRedovisadKostnad',
                    standardRubrik:
                      'Redovisad kostnad för aktierelaterade ersättningar',
                    saldo: 'debit',
                    negateValue: false,
                  })
                );
                rows.addRowWithType(
                  'AktierelateradErsattningAnstalldaRedovisadSkuld',
                  'row',
                  noteLabel('Redovisad skuld'),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AktierelateradErsattningAnstalldaRedovisadSkuld',
                    standardRubrik:
                      'Redovisad skuld till följd av aktierelaterade ersättningar',
                    saldo: 'credit',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AktierelateradErsattningAnstalldaRedovisadSkuld',
                    standardRubrik:
                      'Redovisad skuld till följd av aktierelaterade ersättningar',
                    saldo: 'credit',
                    negateValue: false,
                  })
                );
                return rows.build();
              });
            return rows.build();
          })
          .build(),
        AktierelateradErsattningAnstalldaRedovisadKostnadSkuldKommentar: {
          type: 'group',
          active: true,
          title: field(
            'Kommentar till aktierelaterade ersättningar redovisade i resultat- och balansräkningen'
          ),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:AktierelateradErsattningAnstalldaRedovisadKostnadSkuldKommentar',
            standardRubrik:
              'Kommentar till aktierelaterade ersättningar redovisade i resultat- och balansräkningen',
          }),
        },
      },
    },
    NotAktierelateradErsattningAnstalldaKommentar: {
      type: 'group',
      active: true,
      title: field(
        'Kommentar till aktierelaterade ersättningar till anställda'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotAktierelateradErsattningAnstalldaKommentar',
        standardRubrik:
          'Kommentar till aktierelaterade ersättningar till anställda',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2011K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        AktierelateradErsattningAnstalldaAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            AktierelateradErsattningAnstalldaAntalPersonaloptionerAbstract: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },

                AktierelateradErsattningAnstalldaAntalPersonaloptionerKommentar:
                  {
                    type: 'part' as const,
                    children: {
                      type: type,
                      active: boolean,

                      title: cell,

                      value: cell,
                    },
                  },
              },
            },

            AktierelateradErsattningAnstalldaLosenprisPersonaloptionAbstract: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },

                AktierelateradErsattningAnstalldaLosenprisPersonaloptionKommentar:
                  {
                    type: 'part' as const,
                    children: {
                      type: type,
                      active: boolean,

                      title: cell,

                      value: cell,
                    },
                  },
              },
            },

            AktierelateradErsattningAnstalldaRedovisadKostnadSkuldAbstract: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },

                AktierelateradErsattningAnstalldaRedovisadKostnadSkuldKommentar:
                  {
                    type: 'part' as const,
                    children: {
                      type: type,
                      active: boolean,

                      title: cell,

                      value: cell,
                    },
                  },
              },
            },
          },
        },

        NotAktierelateradErsattningAnstalldaKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
