/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6782
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2058K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2058K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Finansiella leasingavtal - leasegivare'),
  data: {
    active: true,
    FinansiellaLeasingavtalLeasegivareAbstract: {
      type: 'group',
      active: true,
      title: field('Finansiella leasingavtal för leasegivare'),
      table: table<IxbrlCell>(
        'notes.note58.data.FinansiellaLeasingavtalLeasegivareAbstract.table',
        {
          id: 'FinansiellaLeasingavtalLeasegivareAbstract',
          label: 'Finansiella leasingavtal - leasegivare',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'FinansiellaLeasingavtalLeasegivareAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'FinansiellaLeasingavtalLeasegivareAvgarEjIntjanadeFinansiellaIntakter',
                'row',
                noteLabel('Ej intjänade finansiella intäkter'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaLeasingavtalLeasegivareAvgarEjIntjanadeFinansiellaIntakter',
                  standardRubrik:
                    'Ej intjänade finansiella intäkter avseende finansiella leasingavtal, leasegivare',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaLeasingavtalLeasegivareAvgarEjIntjanadeFinansiellaIntakter',
                  standardRubrik:
                    'Ej intjänade finansiella intäkter avseende finansiella leasingavtal, leasegivare',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'FinansiellaLeasingavtalLeasegivareEjGaranteradeRestvardenTillfallerLeasegivaren',
                'row',
                noteLabel(
                  'Ej garanterade restvärden som tillfaller leasegivaren'
                ),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaLeasingavtalLeasegivareEjGaranteradeRestvardenTillfallerLeasegivaren',
                  standardRubrik:
                    'Ej garanterade restvärden som tillfaller leasegivaren avseende finansiella leasingavtal',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaLeasingavtalLeasegivareEjGaranteradeRestvardenTillfallerLeasegivaren',
                  standardRubrik:
                    'Ej garanterade restvärden som tillfaller leasegivaren avseende finansiella leasingavtal',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'FinansiellaLeasingavtalLeasegivareReservOsakraFordringarMinimileaseavgifter',
                'row',
                noteLabel(
                  'Reservering för osäkra fordringar avseende minimileaseavgifter'
                ),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaLeasingavtalLeasegivareReservOsakraFordringarMinimileaseavgifter',
                  standardRubrik:
                    'Reservering för osäkra fordringar avseende minimileaseavgifter för finansiella leasingavtal, leasegivare',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaLeasingavtalLeasegivareReservOsakraFordringarMinimileaseavgifter',
                  standardRubrik:
                    'Reservering för osäkra fordringar avseende minimileaseavgifter för finansiella leasingavtal, leasegivare',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'FinansiellaLeasingavtalLeasegivareVariablaAvgifterIngarRakenskapsaretsResultat',
                'row',
                noteLabel(
                  'Variabla avgifter som ingår i räkenskapsårets resultat'
                ),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaLeasingavtalLeasegivareVariablaAvgifterIngarRakenskapsaretsResultat',
                  standardRubrik:
                    'Variabla leaseavgifter avseende finansiella leasingavtal som ingår i periodens resultat, leasegivare',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FinansiellaLeasingavtalLeasegivareVariablaAvgifterIngarRakenskapsaretsResultat',
                  standardRubrik:
                    'Variabla leaseavgifter avseende finansiella leasingavtal som ingår i periodens resultat, leasegivare',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          return rows.build();
        })
        .build(),
      NotFinansiellaLeasingavtalLeasegivareKommentar: {
        type: 'group',
        active: true,
        title: field(
          'Kommentar till specifikation av finansiella leasingavtal för leasegivare'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotFinansiellaLeasingavtalLeasegivareKommentar',
          standardRubrik:
            'Kommentar till specifikation av finansiella leasingavtal för leasegivare',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2058K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        FinansiellaLeasingavtalLeasegivareAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotFinansiellaLeasingavtalLeasegivareKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
