/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6799
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2049K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2049K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel(
    'Fordringar hos intresseföretag och gemensamt styrda företag'
  ),
  data: {
    active: true,
    FordringarIntresseforetagGemensamtStyrdaForetagAbstract: {
      type: 'group',
      active: true,
      title: field(
        'Fordringar hos intresseföretag och gemensamt styrda företag'
      ),
      table: table<IxbrlCell>(
        'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table',
        {
          id: 'FordringarIntresseforetagGemensamtStyrdaForetagAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'FordringarIntresseforetagGemensamtStyrdaForetagAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'FordringarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1341+1343', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1341+1343', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'FordringarIntresseforetagGemensamtStyrdaForetagaForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagForandringTillkommandeFordringar',
                    'row',
                    noteLabel('Tillkommande fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringTillkommandeFordringar',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens tillkommande långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringTillkommandeFordringar',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens tillkommande långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för långfristiga fordringar hos intresseföretag och gemensamt styrda företag genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för långfristiga fordringar hos intresseföretag och gemensamt styrda företag genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenRegleradeFordringar',
                    'row',
                    noteLabel('Reglerade fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenRegleradeFordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens reglering av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenRegleradeFordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens reglering av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenBortskrivnafordringar',
                    'row',
                    noteLabel('Bortskrivna fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens bortskrivning av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens bortskrivning av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'FordringarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.FordringarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.FordringarIntresseforetagGemensamtStyrdaForetagaForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.FordringarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.FordringarIntresseforetagGemensamtStyrdaForetagaForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'FordringarIntresseforetagGemensamtStyrdaForetagNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1342+1344', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1342+1344', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              rows
                .addRowWithType(
                  'FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarRegleradeFordringar',
                    'row',
                    noteLabel('Reglerade fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarRegleradeFordringar',
                      standardRubrik:
                        'Återförda nedskrivningar avseende reglerade långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarRegleradeFordringar',
                      standardRubrik:
                        'Återförda nedskrivningar avseende reglerade långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för långfristiga fordringar hos intresseföretag och gemensamt styrda företag genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för långfristiga fordringar hos intresseföretag och gemensamt styrda företag genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagaForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagaForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagaForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarBortskrivningfordringar',
                    'row',
                    noteLabel('Bortskrivna fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarBortskrivningfordringar',
                      standardRubrik:
                        'Minskning av nedskrivningar i samband med bortskrivning av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarBortskrivningfordringar',
                      standardRubrik:
                        'Minskning av nedskrivningar i samband med bortskrivning av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'FordringarIntresseforetagGemensamtStyrdaForetagNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.FordringarIntresseforetagGemensamtStyrdaForetagNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.FordringarIntresseforetagGemensamtStyrdaForetagNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.FordringarIntresseforetagGemensamtStyrdaForetagForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.FordringarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.FordringarIntresseforetagGemensamtStyrdaForetagNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga',
                standardRubrik:
                  'Långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.FordringarIntresseforetagGemensamtStyrdaForetagAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note49.data.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.table.FordringarIntresseforetagGemensamtStyrdaForetagAbstract.FordringarIntresseforetagGemensamtStyrdaForetagNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:FordringarIntresseforetagGemensamtStyrdaForetagLangfristiga',
                standardRubrik:
                  'Långfristiga fordringar hos intresseföretag och gemensamt styrda företag',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotFordringarIntresseforetagGemensamtStyrdaForetagKommentar: {
        type: 'group',
        active: true,
        title: field(
          'Kommentar till specifikation av fordringar hos intresseföretag och gemensamt styrda företag'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotFordringarIntresseforetagGemensamtStyrdaForetagKommentar',
          standardRubrik:
            'Kommentar till specifikation av fordringar hos intresseföretag och gemensamt styrda företag',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2049K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        FordringarIntresseforetagGemensamtStyrdaForetagAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotFordringarIntresseforetagGemensamtStyrdaForetagKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
