import React from 'react';
import styled from '@emotion/styled';

import { SignaturePerson } from '_reconciliation/types';
import CommonPreviewModal from '_shared/components/CommonPreviewModal';
import CashViewPreview from './CashViewPreview';

const Container = styled.section`
  @media print {
    page-break-inside: avoid;
  }
`;

const Content = styled.div`
  width: 100%;
`;

type CashViewPreviewModalProps = {
  accountNumber: string;
  signatures: SignaturePerson[];
  richText: string;
  location: string;
  open: boolean;
  handlePreview: () => void;
};

const CashViewPreviewModal = ({
  accountNumber,
  signatures,
  richText,
  location,
  open,
  handlePreview,
}: CashViewPreviewModalProps): JSX.Element | null => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <CommonPreviewModal
      handleShow={handlePreview}
      onPrint={handlePrint}
      isOpen={open}
    >
      <Container>
        <Content>
          <CashViewPreview
            accountNumber={accountNumber}
            signatures={signatures}
            richText={richText}
            location={location}
          />
        </Content>
      </Container>
    </CommonPreviewModal>
  );
};

export default CashViewPreviewModal;
