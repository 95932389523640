/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2022K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2022K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel(
    'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar inkl. nedskrivningar'
  ),
  data: {
    active: true,
    ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract: {
      type: 'group',
      active: true,
      title: field(
        'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar'
      ),
      table: table<IxbrlCell>(
        'notes.note22.data.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract.table',
        {
          id: 'ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'ResultatOvrigaFinansiellaAnlaggningstillgangarUtdelning',
                'row',
                noteLabel('Utdelning'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarUtdelning',
                  standardRubrik:
                    'Utdelning avseende övriga värdepapper som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarUtdelning',
                  standardRubrik:
                    'Utdelning avseende övriga värdepapper som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ResultatOvrigaFinansiellaAnlaggningstillgangarEmissionsinsats',
                'row',
                noteLabel('Emissionsinsats'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarEmissionsinsats',
                  standardRubrik:
                    'Emissionsinsats avseende övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarEmissionsinsats',
                  standardRubrik:
                    'Emissionsinsats avseende övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows
                .addRowWithType(
                  'ResultatOvrigaFinansiellaAnlaggningstillgangarRanteintakterAbstract',
                  'header',
                  noteLabel('Ränteintäkter'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'ResultatOvrigaFinansiellaAnlaggningstillgangarRanteintakterLiknandeResultatposterLangfristigaFordringarPaKoncernforetag',
                    'row',
                    noteLabel('Koncernföretag'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarRanteintakterLiknandeResultatposterLangfristigaFordringarPaKoncernforetag',
                      standardRubrik:
                        'Ränteintäkter och liknande resultatposter från övriga värdepapper och fordringar som är anläggningstillgångar avseende koncernföretag',
                      saldo: 'credit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarRanteintakterLiknandeResultatposterLangfristigaFordringarPaKoncernforetag',
                      standardRubrik:
                        'Ränteintäkter och liknande resultatposter från övriga värdepapper och fordringar som är anläggningstillgångar avseende koncernföretag',
                      saldo: 'credit',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ResultatOvrigaFinansiellaAnlaggningstillgangarRanteintakterLiknandeResultatposterLangfristigaFordringarAndraForetagKoncernForetag',
                    'row',
                    noteLabel('Övriga företag'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarRanteintakterLiknandeResultatposterLangfristigaFordringarAndraForetagKoncernForetag',
                      standardRubrik:
                        'Ränteintäkter från övriga värdepapper och fordringar som är anläggningstillgångar avseende andra företag än koncernföretag',
                      saldo: 'credit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarRanteintakterLiknandeResultatposterLangfristigaFordringarAndraForetagKoncernForetag',
                      standardRubrik:
                        'Ränteintäkter från övriga värdepapper och fordringar som är anläggningstillgångar avseende andra företag än koncernföretag',
                      saldo: 'credit',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'ResultatOvrigaFinansiellaAnlaggningstillgangarRanteintakt',
                'sum',
                noteLabel('Summa'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note22.data.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract.table.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangarRanteintakterAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarRanteintakt',
                    standardRubrik:
                      'Ränteintäkter från övriga värdepapper och fordringar som är anläggningstillgångar',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note22.data.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract.table.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract.ResultatOvrigaFinansiellaAnlaggningstillgangarRanteintakterAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarRanteintakt',
                    standardRubrik:
                      'Ränteintäkter från övriga värdepapper och fordringar som är anläggningstillgångar',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'ResultatOvrigaFinansiellaAnlaggningstillgangarKursdifferens',
                'row',
                noteLabel('Kursdifferenser'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarKursdifferens',
                  standardRubrik:
                    'Kursdifferenser avseende övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarKursdifferens',
                  standardRubrik:
                    'Kursdifferenser avseende övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ResultatOvrigaFinansiellaAnlaggningstillgangarOrealiseradVardeforandringLangfristigaDerivatinstrument',
                'row',
                noteLabel(
                  'Orealiserad värdeförändring på anläggningstillgångar'
                ),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarOrealiseradVardeforandringLangfristigaDerivatinstrument',
                  standardRubrik:
                    'Orealiserad värdeförändring på anläggningstillgångar inom övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarOrealiseradVardeforandringLangfristigaDerivatinstrument',
                  standardRubrik:
                    'Orealiserad värdeförändring på anläggningstillgångar inom övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ResultatOvrigaFinansiellaAnlaggningstillgangarResultatForsaljning',
                'row',
                noteLabel('Realisationsresultat vid försäljning'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarResultatForsaljning',
                  standardRubrik:
                    'Realisationsresultat vid försäljning av övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarResultatForsaljning',
                  standardRubrik:
                    'Realisationsresultat vid försäljning av övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ResultatOvrigaFinansiellaAnlaggningstillgangarResultatandelar',
                'row',
                noteLabel('Resultatandelar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarResultatandelar',
                  standardRubrik:
                    'Resultatandelar avseende övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarResultatandelar',
                  standardRubrik:
                    'Resultatandelar avseende övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ResultatOvrigaFinansiellaAnlaggningstillgangarNedskrivning',
                'row',
                noteLabel('Nedskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarNedskrivning',
                  standardRubrik:
                    'Nedskrivningar av övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'debit',
                  negateValue: true,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarNedskrivning',
                  standardRubrik:
                    'Nedskrivningar av övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'debit',
                  negateValue: true,
                })
              );
              rows.addRowWithType(
                'ResultatOvrigaFinansiellaAnlaggningstillgangarAterforingNedskrivning',
                'row',
                noteLabel('Återföring av nedskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarAterforingNedskrivning',
                  standardRubrik:
                    'Återföring av nedskrivningar av övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarAterforingNedskrivning',
                  standardRubrik:
                    'Återföring av nedskrivningar av övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ResultatOvrigaFinansiellaAnlaggningstillgangarOvrigtResultat',
                'row',
                noteLabel('Övrigt'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarOvrigtResultat',
                  standardRubrik:
                    'Övrigt resultat från övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarOvrigtResultat',
                  standardRubrik:
                    'Övrigt resultat från övriga värdepapper och fordringar som är anläggningstillgångar',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note22.data.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract.table.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning',
                standardRubrik:
                  'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar inklusive nedskrivningar',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note22.data.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract.table.ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivning',
                standardRubrik:
                  'Resultat från övriga värdepapper och fordringar som är anläggningstillgångar inklusive nedskrivningar',
                saldo: 'credit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningKommentar:
        {
          type: 'group',
          active: true,
          title: field(
            'Kommentar till specifikation av resultat från övriga värdepapper och fordringar som är anläggningstillgångar inklusive nedskrivningar'
          ),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:NotResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningKommentar',
            standardRubrik:
              'Kommentar till specifikation av resultat från övriga värdepapper och fordringar som är anläggningstillgångar inklusive nedskrivningar',
          }),
        },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2022K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        ResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningAbstract:
          {
            type: 'part' as const,
            children: {
              type: type,
              active: boolean,

              title: cell,

              table: { type: 'table' as const },

              NotResultatOvrigaFinansiellaAnlaggningstillgangarInklNedskrivningKommentar:
                {
                  type: 'part' as const,
                  children: {
                    type: type,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },
            },
          },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
