import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { ccyFormat } from '@agoy/common';
import { ClientsRouteParams } from 'routes/types';
import { InputData } from '_reconciliation/types';
import { AccountingAccount } from 'types/Accounting';

import { PeriodDocument } from 'utils/usePeriodDocuments/Provider';
import SpecificationInfo from '_shared/components/SpecificationInfo';
import { useApiSdk } from 'api-sdk';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import DocumentView from './DocumentView';
import { BalanceAndInput } from '../types';
import {
  isImageDocument,
  isPdfDocument,
} from '../HiddenRow/HiddenGroupRow/utils/documentTypes';

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-column-gap: ${(props) => props.theme.spacing(1)}px;
  grid-row-gap: ${(props) => props.theme.spacing(1)}px;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const ContentContainer = styled.div`
  width: 800px;
  background-color: ${(props) => props.theme.palette.grey[50]};
  padding: ${(props) => props.theme.spacing(1)}px;
  margin-top: ${(props) => props.theme.spacing(2)}px;
  @media print {
    background-color: ${(props) => props.theme.palette.common.white};
  }
`;

const Records = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

const getFileExtension = (file) => {
  const dotIndex = file.name.lastIndexOf('.');
  let extension = '';

  if (dotIndex !== -1) {
    extension = file.name.substr(dotIndex);
  }

  return extension;
};

type ContentProps = {
  userData?: InputData;
  period: Period;
  accountInformation?: AccountingAccount;
  specification?: Client.Specification[];
  financialYear: FinancialYear;
  balanceAndInput?: BalanceAndInput;
  loaded: () => void;
  handleDocumentsToLoad: (documents: number) => void;
  handleDocumentsToPrint: (documents: number) => void;
};

const Content = ({
  userData,
  period,
  accountInformation,
  specification,
  financialYear,
  loaded,
  balanceAndInput,
  handleDocumentsToLoad,
  handleDocumentsToPrint,
}: ContentProps): JSX.Element => {
  const intl = useIntl();
  const { clientId } = useParams<ClientsRouteParams>();
  const sdk = useApiSdk();

  const documentsToLoad = useRef(0);
  const [images, setImages] = useState<PeriodDocument[]>([]);

  const { comment, printComment } = balanceAndInput ?? {};
  const account = accountInformation?.number ?? String(balanceAndInput?.number);

  React.useEffect(() => {
    if (!account) return;

    sdk
      .getPeriodDocuments({
        clientid: clientId,
        account: [account],
        periodId: [period.id],
      })
      .then((docs) => {
        const documentsToPrint = docs
          // show only pdf and pictures
          .filter((item) => {
            const extension = getFileExtension(item);
            return isImageDocument(extension) || isPdfDocument(extension);
          })
          .map((item) => ({
            ...item,
            period,
          }));
        setImages(documentsToPrint);
        handleDocumentsToPrint(documentsToPrint.length);

        documentsToLoad.current = documentsToPrint.length;
        handleDocumentsToLoad(documentsToLoad.current);
        if (documentsToLoad.current === 0) {
          loaded();
        }
      });
  }, []);

  return (
    <ContentContainer>
      {userData && accountInformation && (
        <SpecificationInfo
          period={period}
          financialYear={financialYear}
          inputData={userData}
          accountName={accountInformation.name}
          accountNumber={accountInformation.number}
          yearIB={accountInformation.balances[financialYear.id].in ?? 0}
        />
      )}

      {specification && specification.length > 0 && (
        <Table>
          <b>{intl.formatMessage({ id: 'description' })}</b>
          <b>{intl.formatMessage({ id: 'reference' })}</b>
          <b>{intl.formatMessage({ id: 'account' })}</b>
          <b>{intl.formatMessage({ id: 'amount' })}</b>
          {specification &&
            specification.map(
              ({ description, reference, account, amount }, index) => (
                <React.Fragment key={index}>
                  <div>{description}</div>
                  <div>{reference}</div>
                  <div>{account}</div>
                  <div>{ccyFormat(amount)}</div>
                </React.Fragment>
              )
            )}
        </Table>
      )}

      {comment && printComment && (
        <Box paddingTop={2}>
          <Typography component="div">
            {intl.formatMessage({
              id: 'comment',
            })}
            :
            <Box component="span" paddingLeft={1}>
              {comment}
            </Box>
          </Typography>
        </Box>
      )}

      {images && images.length > 0 && (
        <Records>
          <Box fontWeight="fontWeightBold">
            {intl.formatMessage({ id: 'hidden.event.view' })}
          </Box>
          {images.map((document) => (
            <React.Fragment key={document.id}>
              <DocumentView
                document={document}
                loaded={loaded}
                documentsToLoad={documentsToLoad}
              />
            </React.Fragment>
          ))}
        </Records>
      )}
    </ContentContainer>
  );
};

export default Content;
