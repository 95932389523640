import React from 'react';
import { useSelector } from 'redux/reducers';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

import InventoryStockTable from './InventoryStockTable';

const Wrapper = styled.div`
  width: 100%;
`;

const TableTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.palette.accountingView.headers.main};
  margin-bottom: 10px;
`;

const InventoryStockViewPrint = (): JSX.Element | null => {
  const { formatMessage } = useIntl();

  const { stockInventoryTable } = useSelector((state) => state.accountingView);

  return (
    <Wrapper>
      {stockInventoryTable && (
        <InventoryStockTable
          isEditing={false}
          renderHeader={() => (
            <TableTitle>
              {formatMessage({
                id: `hidden.inventoryStock.title`,
              })}
            </TableTitle>
          )}
          tableData={stockInventoryTable}
        />
      )}
    </Wrapper>
  );
};

export default InventoryStockViewPrint;
