import { InputData, Comment, SignaturePerson } from '_reconciliation/types';
import { cleanSpecification } from 'utils';
import { Specification } from '_clients/types/types';

type InputRoutineData = {
  routinesForAccount?: string;
};

export const createInputData = ({
  visited,
  saldo,
  comment,
  specification,
  numImages = 0,
  printComment = true,
  checked,
  cashView = {},
  inventoryView = {},
}: Partial<InputData>): InputData => {
  const inputData: {
    visited: boolean;
    saldo: string;
    comment: string;
    specification: Specification[];
    numImages: number;
    printComment: boolean;
    internalComments: boolean;
    checked: boolean;
    cashView: {
      richText?: string;
      location?: string;
      signatures?: Array<SignaturePerson>;
    };
    inventoryView: {
      richText?: string;
      location?: string;
      signatures?: Array<SignaturePerson>;
    };
  } = {
    visited: false,
    saldo: '',
    comment: '',
    specification: [],
    numImages: 0,
    printComment,
    internalComments: false,
    checked: false,
    cashView,
    inventoryView,
  };
  if (visited) inputData.visited = visited;
  if (saldo) inputData.saldo = saldo;
  if (comment) inputData.comment = comment;
  if (specification)
    inputData.specification = cleanSpecification(specification);
  if (numImages >= 0) {
    inputData.numImages = numImages;
  } else {
    inputData.numImages = 0;
  }
  if (checked) inputData.checked = checked;
  return inputData;
};

export const createInputRoutineData = (
  accountRoutines: Record<string, unknown>
): InputRoutineData => {
  return accountRoutines;
};
