import React from 'react';
import styled from '@emotion/styled';

import { SignaturePerson } from '_reconciliation/types';
import { Period } from '@agoy/api-sdk-core';
import CommonPreviewModal from '_shared/components/CommonPreviewModal';
import InventoryViewPreview from './InventoryViewPreview';

const Container = styled.section`
  @media print {
    page-break-inside: avoid;
  }
`;

const Content = styled.div`
  width: 100%;
`;

type InventoryViewPreviewModalProps = {
  period: Period;
  accountNumber: string;
  signatures: SignaturePerson[];
  richText: string;
  location: string;
  open: boolean;
  onPreviewShow: () => void;
};

const InventoryViewPreviewModal = ({
  period,
  accountNumber,
  signatures,
  richText,
  location,
  open,
  onPreviewShow,
}: InventoryViewPreviewModalProps): JSX.Element | null => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <CommonPreviewModal
      handleShow={onPreviewShow}
      onPrint={handlePrint}
      isOpen={open}
    >
      <Container>
        <Content>
          <InventoryViewPreview
            period={period}
            accountNumber={accountNumber}
            signatures={signatures}
            richText={richText}
            location={location}
          />
        </Content>
      </Container>
    </CommonPreviewModal>
  );
};

export default InventoryViewPreviewModal;
