import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  CompanyMetaData,
} from '../../types';
import {
  account,
  accountName,
  id,
  ifOrElse,
  max,
  multiply,
  not,
  or,
  ref,
  sum,
  sumAllowNull,
  table,
  value,
} from '@agoy/document';
import {
  absolute,
  mapFormId,
  toConfig,
  calculatePercent,
  toInitial,
  toStructure,
  onlyPositive,
} from '../forms-util';
import helpStructureNE from '../../types/help/NE';
import { getFinalCalculationsMetadata } from '../shared';

const finalTaxCalculations = {
  type: 'part' as const,
  partType: 'additionalPages' as const,
  children: {
    sumLabelSurplus: { type: 'field' as const },
    sumLabelDeficit: { type: 'field' as const },
    totalLabelSurplus: { type: 'field' as const },
    totalLabelDeficit: { type: 'field' as const },
    remainingLabelSurplus: { type: 'field' as const },
    remainingLabelDeficit: { type: 'field' as const },
    companyType: { type: 'field' as const },
    financialYearStart: { type: 'field' as const },
    financialYearEnd: { type: 'field' as const },
    orgNumber: { type: 'field' as const },

    finalTax: { type: 'table' as const },
    deductionsCalculations: { type: 'table' as const },
    deficitCalculations: { type: 'table' as const },
  },
};

const configNE = (
  form: TaxDeclarationFormDefinition,
  customer: CompanyMetaData,
  financialYearStart: string,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const NE = mapFormId(form, 'NE');
  const percent = calculatePercent(customer.orgNumber);
  const initial = {
    NE: {
      partType: 'form' as const,
      id: NE,
      fields: {
        date: value(''),
        name: value(customer.name),
        socSecNr: value(customer.orgNumber),
        scopeOfBusiness: value(''),
        passivNaringsverksamhet: value(false),
        sjalvstandingNaringsverksamhet: value(false),
        inteForenklatBokslut: value(false),
        socSecNrAccountant: value(''),
        B1: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_1')),
        B2: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_2')),
        B3: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_3')),
        B4: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_4')),
        B5: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_5')),
        B6: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_6')),
        B7: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_7')),
        B8: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_8')),
        B9: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_9')),
        B10: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_10')),
        B11: value(
          undefined,
          'Ska inte fyllas i av den som upprättar förenklat årsbokslut'
        ),
        B12: value(
          undefined,
          'Ska inte fyllas i av den som upprättar förenklat årsbokslut'
        ),
        B13: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_13')),
        B14: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_14')),
        B15: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_15')),
        B16: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.B_16')),
        R1_pos: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.R_1')),
        R2_pos: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.R_2')),
        R3_pos: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.R_3')),
        R4_pos: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.R_4')),
        R5_neg: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.R_5')),
        R6_neg: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.R_6')),
        R7_neg: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.R_7')),
        R8_neg: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.R_8')),
        R9_neg: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.R_9')),
        R10_neg: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.R_10')),
        R11: ref(id('ForenklatArsbokslut.ForenklatArsbokslut.fields.R_11')),
        uppdragstagareHarBitratt_yes: value(false),
        uppdragstagareHarBitratt_no: value(false),
        R12: ref(id('NE.fields.R11')),
        R13_pos: ref(sumAllowNull(id('NE.help.improvements.*.amount'))),
        R14_neg: ref(absolute(sumAllowNull(id('NE.help.renovation.*.amount')))),
        R15_pos: value(undefined),
        R16_neg: value(undefined),
        R17: ref(
          sumAllowNull(
            id('NE.fields.R12'),
            id('NE.fields.R13_pos'),
            multiply(-1, id('NE.fields.R14_neg')),
            id('NE.fields.R15_pos'),
            multiply(-1, id('NE.fields.R16_neg'))
          )
        ),
        R18_neg: ref(
          sumAllowNull(id('NEA-*.NEA.fields.R_23_underskott')),
          'Värdet i R18 hämtas från fält R23 i blankett NEA'
        ),
        R19_pos: ref(
          sumAllowNull(id('NEA-*.NEA.fields.R_22_overskott')),
          'Värdet i R19 hämtas från fält R22 i blankett NEA'
        ),
        R20_pos: value(undefined),
        R20_neg: value(undefined),
        R21: ref(
          sumAllowNull(
            id('NE.fields.R17'),
            multiply(-1, id('NE.fields.R18_neg')),
            id('NE.fields.R19_pos'),
            id('NE.fields.R20_pos'),
            multiply(-1, id('NE.fields.R20_neg'))
          )
        ),
        R22_neg: value(undefined),
        R23_pos: value(undefined),
        R24_neg: value(undefined),
        R25_neg: ref(
          sumAllowNull(
            id('N8-*.N8.fields.A_1_summa'),
            id('N8-*.N8.fields.B_1_belopp')
          )
        ),
        R26_pos: ref(
          sumAllowNull(
            id('N8-*.N8.fields.A_2_summa'),
            id('N8-*.N8.fields.B_2_belopp')
          )
        ),
        R27_pos: value(undefined),
        R28_neg: value(undefined),
        R29: ref(
          sumAllowNull(
            id('NE.fields.R21'),
            multiply(-1, id('NE.fields.R22_neg')),
            id('NE.fields.R23_pos'),
            multiply(-1, id('NE.fields.R24_neg')),
            multiply(-1, id('NE.fields.R25_neg')),
            id('NE.fields.R26_pos'),
            id('NE.fields.R27_pos'),
            multiply(-1, id('NE.fields.R28_neg'))
          )
        ),
        R30_neg: ref(id('NE_PERSON.NE.fields.R30_neg')),
        R31_pos: ref(id('NE_PERSON.NE.fields.R31_pos')),
        R32_pos: ref(id('NE_PERSON.NE.fields.R32_pos')),
        R33: ref(id('NE_PERSON.NE.fields.R33')),
        R34_neg: ref(id('NE_PERSON.NE.fields.R34_neg')),
        R35: ref(id('NE_PERSON.NE.fields.R35')),
        R36_neg: ref(id('NE_PERSON.NE.fields.R36_neg')),
        R37_pos: ref(id('NE_PERSON.NE.fields.R37_pos')),
        R38_neg: ref(id('NE_PERSON.NE.fields.R38_neg')),
        R39_neg: ref(id('NE_PERSON.NE.fields.R39_neg')),
        R40_pos: ref(id('NE_PERSON.NE.fields.R40_pos')),
        R41_neg: ref(id('NE_PERSON.NE.fields.R41_neg')),
        R42: ref(id('NE_PERSON.NE.fields.R42')),
        R43_neg: ref(
          onlyPositive(multiply(id('NE.fields.R42'), percent)),
          String(percent)
        ),
        R44_pos: ref(id('NE_PERSON.NE.fields.R44_pos')),
        R45_pos: ref(id('NE_PERSON.NE.fields.R45_pos')),
        R46_pos: ref(id('NE_PERSON.NE.fields.R46_pos')),
        R47: ref(id('NE_PERSON.NE.fields.R47')),
        R48: ref(id('NE_PERSON.NE.fields.R48')),
        // if no reference is present, then the default value for checkbox is false
        otherInfo_1: ref(or(id('NE_PERSON.NE.fields.otherInfo_1'), 'false')),
        otherInfo_2: ref(id('NE_PERSON.NE.fields.otherInfo_2')),
        otherInfo_3: ref(id('NE_PERSON.NE.fields.otherInfo_3')),
        otherInfo_4: ref(id('NE_PERSON.NE.fields.otherInfo_4')),
        otherInfo_5: ref(id('NE_PERSON.NE.fields.otherInfo_5')),
        otherInfo_6: ref(id('NE_PERSON.NE.fields.otherInfo_6')),
        otherInfo_7: ref(id('NE_PERSON.NE.fields.otherInfo_7')),
        otherInfo_8: ref(id('NE_PERSON.NE.fields.otherInfo_8')),
        otherInfo_9: ref(id('NE_PERSON.NE.fields.otherInfo_9')),
        otherInfo_10: ref(id('NE_PERSON.NE.fields.otherInfo_10')),
        otherInfo_11: ref(id('NE_PERSON.NE.fields.otherInfo_11')),
      },
      derivedFields: {
        R47_notPassive: ref(
          ifOrElse(
            not(id('NE.fields.passivNaringsverksamhet')),
            id('NE.fields.R47'),
            undefined
          )
        ),
        R47_isPassive: ref(
          ifOrElse(
            id('NE.fields.passivNaringsverksamhet'),
            id('NE.fields.R47'),
            undefined
          )
        ),
        R48_notPassive: ref(
          ifOrElse(
            not(id('NE.fields.passivNaringsverksamhet')),
            id('NE.fields.R48'),
            undefined
          )
        ),
        R48_isPassive: ref(
          ifOrElse(
            id('NE.fields.passivNaringsverksamhet'),
            id('NE.fields.R48'),
            undefined
          )
        ),
      },
      sru: {
        '7011': value(financialYearStart),
        '7012': value(financialYearEnd),
        '7020': ref(id('NE.fields.scopeOfBusiness')),
        '7021': ref(id('NE.fields.passivNaringsverksamhet')),
        '7024': ref(id('NE.fields.sjalvstandingNaringsverksamhet')),
        '7023': ref(id('NE.fields.inteForenklatBokslut')),
        '7025': ref(id('NE.fields.socSecNrAccountant')),
        '7200': ref(id('NE.fields.B1')),
        '7210': ref(id('NE.fields.B2')),
        '7211': ref(id('NE.fields.B3')),
        '7212': ref(id('NE.fields.B4')),
        '7213': ref(id('NE.fields.B5')),
        '7240': ref(id('NE.fields.B6')),
        '7250': ref(id('NE.fields.B7')),
        '7260': ref(id('NE.fields.B8')),
        '7280': ref(id('NE.fields.B9')),
        '7300': ref(id('NE.fields.B10')),
        '7320': ref(id('NE.fields.B11')),
        '7330': ref(id('NE.fields.B12')),
        '7380': ref(id('NE.fields.B13')),
        '7381': ref(id('NE.fields.B14')),
        '7382': ref(id('NE.fields.B15')),
        '7383': ref(id('NE.fields.B16')),
        '7400': ref(id('NE.fields.R1_pos')),
        '7401': ref(id('NE.fields.R2_pos')),
        '7402': ref(id('NE.fields.R3_pos')),
        '7403': ref(id('NE.fields.R4_pos')),
        '7500': ref(id('NE.fields.R5_neg')),
        '7501': ref(id('NE.fields.R6_neg')),
        '7502': ref(id('NE.fields.R7_neg')),
        '7503': ref(id('NE.fields.R8_neg')),
        '7504': ref(id('NE.fields.R9_neg')),
        '7505': ref(id('NE.fields.R10_neg')),
        '7440': ref(id('NE.fields.R11')),
        '8046': ref(id('NE.fields.uppdragstagareHarBitratt_yes')),
        '8047': ref(id('NE.fields.uppdragstagareHarBitratt_no')),
        '7600': ref(id('NE.fields.R12')),
        '7601': ref(id('NE.fields.R13_pos')),
        '7700': ref(id('NE.fields.R14_neg')),
        '7602': ref(id('NE.fields.R15_pos')),
        '7701': ref(id('NE.fields.R16_neg')),
        '7702': ref(id('NE.fields.R18_neg')),
        '7603': ref(id('NE.fields.R19_pos')),
        '7614': ref(id('NE.fields.R20_pos')),
        '7703': ref(id('NE.fields.R20_neg')),
        '7704': ref(id('NE.fields.R22_neg')),
        '7604': ref(id('NE.fields.R23_pos')),
        '7705': ref(id('NE.fields.R24_neg')),
        '7706': ref(id('NE.fields.R25_neg')),
        '7605': ref(id('NE.fields.R26_pos')),
        '7606': ref(id('NE.fields.R27_pos')),
        '7707': ref(id('NE.fields.R28_neg')),
        '7708': ref(id('NE.fields.R30_neg')),
        '7607': ref(id('NE.fields.R31_pos')),
        '7608': ref(id('NE.fields.R32_pos')),
        '7709': ref(id('NE.fields.R34_neg')),
        '7710': ref(id('NE.fields.R36_neg')),
        '7609': ref(id('NE.fields.R37_pos')),
        '7711': ref(id('NE.fields.R38_neg')),
        '7712': ref(id('NE.fields.R39_neg')),
        '7610': ref(id('NE.fields.R40_pos')),
        '7713': ref(id('NE.fields.R41_neg')),
        '7714': ref(id('NE.fields.R43_neg')),
        '7611': ref(id('NE.fields.R44_pos')),
        '7612': ref(id('NE.fields.R45_pos')),
        '7613': ref(id('NE.fields.R46_pos')),
        '7630': ref(id('NE.fields.R47')),
        '7730': ref(id('NE.fields.R48')),
        '8000': ref(id('NE.fields.otherInfo_1')),
        '8002': ref(id('NE.fields.otherInfo_2')),
        '8003': ref(id('NE.fields.otherInfo_3')),
        '8004': ref(id('NE.fields.otherInfo_4')),
        '8006': ref(id('NE.fields.otherInfo_5')),
        '8007': ref(id('NE.fields.otherInfo_6')),
        '8008': ref(id('NE.fields.otherInfo_7')),
        '8009': ref(id('NE.fields.otherInfo_8')),
        '8010': ref(id('NE.fields.otherInfo_9')),
        '8011': ref(id('NE.fields.otherInfo_10')),
        '8012': ref(id('NE.fields.otherInfo_11')),
      },
      help: {
        _type: 'NE' as const,
        improvements: table(
          'NE.help.improvements',
          'account',
          'accountName',
          'amount'
        )
          .addRows((rows) =>
            rows
              .addRow(
                '1',
                value('5099'),
                ref(accountName('5099')),
                ref(account('5099'))
              )
              .addRow(
                '2',
                value('5199'),
                ref(accountName('5199')),
                ref(account('5199'))
              )
              .addRow(
                '3',
                value('6072'),
                ref(accountName('6072')),
                ref(account('6072'))
              )
              .addRow(
                '4',
                value('6342'),
                ref(accountName('6342')),
                ref(account('6342'))
              )
              .addRow(
                '5',
                value('6352'),
                ref(accountName('6352')),
                ref(account('6352'))
              )
              .addRow(
                '6',
                value('6982'),
                ref(accountName('6982')),
                ref(account('6982'))
              )
              .addRow(
                '7',
                value('6992'),
                ref(accountName('6992')),
                ref(account('6992'))
              )
              .addRow(
                '8',
                value('6998'),
                ref(accountName('6998')),
                ref(account('6998'))
              )
              .addRow(
                '9',
                value('7622'),
                ref(accountName('7622')),
                ref(account('7622'))
              )
              .addRow(
                '10',
                value('7632'),
                ref(accountName('7632')),
                ref(account('7632'))
              )
              .addRow(
                '11',
                value('8423'),
                ref(accountName('8423')),
                ref(account('8423'))
              )
              .build()
          )
          .newRowTemplate(
            value(0),
            ref(accountName(id('$id.account'))),
            ref(account(id('$id.account')))
          )
          .build(),
        renovation: table(
          'NE.help.renovation',
          'account',
          'accountName',
          'amount'
        )
          .addRows((rows) =>
            rows
              .addRow(
                '1',
                value('8254'),
                ref(accountName('8254')),
                ref(account('8254'))
              )
              .addRow(
                '2',
                value('8314'),
                ref(accountName('8314')),
                ref(account('8314'))
              )
              .build()
          )
          .newRowTemplate(
            value(0),
            ref(accountName(id('$id.account'))),
            ref(account(id('$id.account')))
          )
          .build(),
      },
    },
  };

  const additionalPages = {
    finalTaxCalculations: {
      ...getFinalCalculationsMetadata({
        customer,
        financialYearStart,
        financialYearEnd,
      }),
      sumLabelSurplus: value('Överskott före årets schablonavdrag'),
      sumLabelDeficit: value('Underskott före årets schablonavdrag'),
      totalLabelSurplus: value('Överskott total'),
      totalLabelDeficit: value('Underskott total'),
      remainingLabelSurplus: value('Kvarvarande överskott'),
      remainingLabelDeficit: value('Kvarvarande underskott'),
      finalTax: table(
        'finalTaxCalculations.finalTax',
        'name',
        'value',
        'default'
      )
        .addRows((rows) =>
          rows
            .addRow('main')
            .addSubRows((subRows) =>
              subRows
                .addRow(
                  '1',
                  value('Resultat av verksamheten'),
                  ref(id('NE.fields.R17')),
                  value(true)
                )
                .addRow(
                  '2',
                  value('Positiv räntefördelning'),
                  ref(multiply(-1, id('NE.fields.R30_neg'))),
                  value(true)
                )
                .addRow(
                  '3',
                  value('Negativ räntefördelning'),
                  ref(id('NE.fields.R31_pos')),
                  value(true)
                )
                .addRow(
                  '4',
                  value('Periodiseringsfond (+/-)'),
                  ref(
                    sumAllowNull(
                      id('NE.fields.R32_pos'),
                      multiply(-1, id('NE.fields.R34_neg'))
                    )
                  ),
                  value(true)
                )
                .addRow(
                  '5',
                  value('Expansionsfond (+/-)'),
                  ref(
                    sumAllowNull(
                      multiply(-1, id('NE.fields.R36_neg')),
                      id('NE.fields.R37_pos')
                    )
                  ),
                  value(true)
                )
                .addRow(
                  '6',
                  value('Pensionssparande, löneskatt'),
                  ref(
                    sum(
                      multiply(-1, id('NE.fields.R38_neg')),
                      multiply(-1, id('NE.fields.R39_neg'))
                    )
                  ),
                  value(true)
                )
                .addRow(
                  '7',
                  value('Medgivna avdrag för egenavgifter från föregående år'),
                  ref(id('NE.fields.R40_pos')),
                  value(true)
                )
                .addRow(
                  '8',
                  value('Påförda egenavgifter från föregående år'),
                  ref(multiply(-1, id('NE.fields.R41_neg'))),
                  value(true)
                )
                .build()
            )
            .newRowTemplate(value(''), value(0), value(false))
            .addRow(
              'sum',
              ref(
                ifOrElse(
                  max(id(`${rows.getBaseId()}.sum.value`), 0),
                  id('finalTaxCalculations.sumLabelSurplus'),
                  id('finalTaxCalculations.sumLabelDeficit')
                )
              ),
              ref(sum(id(`${rows.getBaseId()}.main.*.value`)))
            )
            .addRow('companyType', value('Company Type'), value(customer.type))
            .build()
        )
        .build(),

      deductionsCalculations: table(
        'finalTaxCalculations.deductionsCalculations',
        'name',
        'value',
        'default'
      )
        .addRows((rows) =>
          rows
            .addRow('main')
            .addSubRows((subRows) =>
              subRows
                .addRow(
                  '1',
                  ref(
                    ifOrElse(
                      max(id(`${rows.getBaseId()}.main.1.value`), 0),
                      id('finalTaxCalculations.sumLabelSurplus'),
                      id('finalTaxCalculations.sumLabelDeficit')
                    )
                  ),
                  ref(sum(id('finalTaxCalculations.finalTax.main.*.value'))),
                  value(true)
                )
                .addRow(
                  '2',
                  value('Årets schablonavdrag'),
                  ref(multiply(-1, id('NE.fields.R43_neg'))),
                  value(true)
                )
                .addRow(
                  '3',
                  value('Sjukpenning'),
                  ref(id('NE.fields.R44_pos')),
                  value(true)
                )
                .build()
            )
            .newRowTemplate(value(''), value(0), value(false))
            .addRow(
              'sum',
              ref(
                ifOrElse(
                  max(id(`${rows.getBaseId()}.sum.value`), 0),
                  id('finalTaxCalculations.totalLabelSurplus'),
                  id('finalTaxCalculations.totalLabelDeficit')
                )
              ),
              ref(sum(id(`${rows.getBaseId()}.main.*.value`)))
            )
            .addRow('companyType', value('Company Type'), value(customer.type))
            .build()
        )
        .build(),
      deficitCalculations: table(
        'finalTaxCalculations.deficitCalculations',
        'name',
        'value',
        'default'
      )
        .addRows((rows) =>
          rows
            .addRow('main')
            .addSubRows((subRows) =>
              subRows
                .addRow(
                  '1',
                  ref(
                    ifOrElse(
                      max(id(`${rows.getBaseId()}.main.1.value`), 0),
                      id('finalTaxCalculations.totalLabelSurplus'),
                      id('finalTaxCalculations.totalLabelDeficit')
                    )
                  ),
                  ref(
                    sum(
                      id(
                        'finalTaxCalculations.deductionsCalculations.main.*.value'
                      )
                    )
                  ),
                  value(true)
                )
                .addRow(
                  '2',
                  value('Utnyttjat underskott som allmänt avdrag'),
                  ref(id('NE.fields.R45_pos')),
                  value(true)
                )
                .addRow(
                  '3',
                  value('Utnyttjat underskott i inkomstslaget kapital'),
                  ref(id('NE.fields.R46_pos')),
                  value(true)
                )
                .build()
            )
            .newRowTemplate(value(''), value(0), value(false))
            .addRow(
              'sum',
              ref(
                ifOrElse(
                  max(id(`${rows.getBaseId()}.sum.value`), 0),
                  id('finalTaxCalculations.remainingLabelSurplus'),
                  id('finalTaxCalculations.remainingLabelDeficit')
                )
              ),
              ref(sum(id(`${rows.getBaseId()}.main.*.value`)))
            )
            .addRow('companyType', value('Company Type'), value(customer.type))
            .build()
        )
        .build(),
    },
  };

  return customer.type === 'individual'
    ? toConfig(
        initial,
        form,
        { NE: helpStructureNE },
        { finalTaxCalculations },
        additionalPages
      )
    : {
        initial: toInitial(initial, form),
        definition: toStructure(initial, form, { NE: helpStructureNE }),
      };
};

export default configNE;
