/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6798 (fomer 401)
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2050K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2050K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Ägarintressen i övriga företag'),
  data: {
    active: true,
    AgarintressenOvrigaForetagAbstract: {
      type: 'group',
      active: true,
      title: field('Ägarintressen i övriga företag'),
      table: table<IxbrlCell>(
        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table',
        {
          id: 'AgarintressenOvrigaForetagAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AgarintressenOvrigaForetagAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'AgarintressenOvrigaForetagAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1336', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden ägarintressen i övriga företag',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(ref(sumAccountsIBs('1336', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden ägarintressen i övriga företag',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows
                .addRowWithType(
                  'AgarintressenOvrigaForetagAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagAnskaffningsvardenInkop',
                    'row',
                    noteLabel('Inköp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av ägarintressen i övriga företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av ägarintressen i övriga företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för ägarintressen i övriga företag genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för ägarintressen i övriga företag genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar',
                    'row',
                    noteLabel('Försäljningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar av ägarintressen i övriga företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenForsaljningar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar av ägarintressen i övriga företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott',
                    'row',
                    noteLabel('Lämnade aktieägartillskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med lämnande aktieägartillskott till ägarintressen i övriga företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenLamnadeAktieagartillskott',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med lämnande aktieägartillskott till ägarintressen i övriga företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
                    'row',
                    noteLabel('Återbetalning av lämnat aktieägartillskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med återbetalning av lämnat aktieägartillskott till ägarintressen i övriga företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenAterbetalningLamnatAktieagartillskott',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med återbetalning av lämnat aktieägartillskott till ägarintressen i övriga företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel',
                    'row',
                    noteLabel('Årets resultatandel'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med periodens resultatandel i handelsbolag som är ägarintressen i övriga företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenAretsResultatAndel',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med periodens resultatandel i handelsbolag som är ägarintressen i övriga företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av ägarintressen i övriga företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av ägarintressen i övriga företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagAnskaffningsvardenUtdelning',
                    'row',
                    noteLabel('Utdelning'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenUtdelning',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med utdelning, ägarintressen i övriga företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenUtdelning',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med utdelning, ägarintressen i övriga företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av ägarintressen i övriga företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av ägarintressen i övriga företag',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AgarintressenOvrigaForetagAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden ägarintressen i övriga företag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AgarintressenOvrigaForetagAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden ägarintressen i övriga företag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'AgarintressenOvrigaForetagUppskrivningarIngaende',
                'row',
                noteLabel('Ingående uppskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningar',
                  standardRubrik:
                    'Ackumulerade uppskrivningar ägarintressen i övriga företag',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningar',
                  standardRubrik:
                    'Ackumulerade uppskrivningar ägarintressen i övriga företag',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              rows
                .addRowWithType(
                  'AgarintressenOvrigaForetagUppskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av uppskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagUppskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar för ägarintressen i övriga företag genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar för ägarintressen i övriga företag genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar',
                    'row',
                    noteLabel('Försäljningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar',
                      standardRubrik:
                        'Återförda uppskrivningar i samband med försäljningar av ägarintressen i övriga företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningarAterfordaUppskrivningarForsaljningar',
                      standardRubrik:
                        'Återförda uppskrivningar i samband med försäljningar av ägarintressen i övriga företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagUppskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av ägarintressen i övriga företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningarOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omklassificeringar av ägarintressen i övriga företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar',
                    'row',
                    noteLabel('Årets uppskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar',
                      standardRubrik:
                        'Periodens uppskrivningar av ägarintressen i övriga företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningarAretsUppskrivningar',
                      standardRubrik:
                        'Periodens uppskrivningar av ägarintressen i övriga företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                    'row',
                    noteLabel('Årets nedskrivningar på uppskrivet belopp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Nedskrivningar av uppskrivet belopp på ägarintressen i övriga företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningarAretsNedskrivningarUppskrivetBelopp',
                      standardRubrik:
                        'Nedskrivningar av uppskrivet belopp på ägarintressen i övriga företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av ägarintressen i övriga företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av uppskrivningar i samband med omräkningsdifferenser av ägarintressen i övriga företag',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AgarintressenOvrigaForetagUppskrivningarUtgaende',
                'sum',
                noteLabel('Utgående uppskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagUppskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagUppskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningar',
                    standardRubrik:
                      'Ackumulerade uppskrivningar ägarintressen i övriga företag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagUppskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagUppskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AgarintressenOvrigaForetagUppskrivningar',
                    standardRubrik:
                      'Ackumulerade uppskrivningar ägarintressen i övriga företag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'AgarintressenOvrigaForetagNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1337', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AgarintressenOvrigaForetagNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar ägarintressen i övriga företag',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1337', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AgarintressenOvrigaForetagNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar ägarintressen i övriga företag',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'AgarintressenOvrigaForetagNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för ägarintressen i övriga företag genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för ägarintressen i övriga företag genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                    'row',
                    noteLabel('Försäljningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar av ägarintressen i övriga företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar av ägarintressen i övriga företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av ägarintressen i övriga företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av ägarintressen i övriga företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av ägarintressen i övriga företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av ägarintressen i övriga företag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av ägarintressen i övriga företag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av ägarintressen i övriga företag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av ägarintressen i övriga företag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AgarintressenOvrigaForetagForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av ägarintressen i övriga företag',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AgarintressenOvrigaForetagNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AgarintressenOvrigaForetagNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar ägarintressen i övriga företag',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AgarintressenOvrigaForetagNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar ägarintressen i övriga företag',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'AgarintressenOvrigaForetag',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagUppskrivningarUtgaende.year'
                  ),
                  id(
                    'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AgarintressenOvrigaForetag',
                standardRubrik: 'Ägarintressen i övriga företag',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagUppskrivningarUtgaende.previousYear'
                  ),
                  id(
                    'notes.note50.data.AgarintressenOvrigaForetagAbstract.table.AgarintressenOvrigaForetagAbstract.AgarintressenOvrigaForetagNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AgarintressenOvrigaForetag',
                standardRubrik: 'Ägarintressen i övriga företag',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      AgarintressenOvrigaForetagKommentar: {
        type: 'group',
        active: true,
        title: field('Kommentar till ägarintressen i övriga företag'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:AgarintressenOvrigaForetagKommentar',
          standardRubrik: 'Kommentar till ägarintressen i övriga företag',
        }),
      },
    },
    InnehavAgarintressenOvrigaForetag: {
      type: 'group',
      active: true,
      title: field(
        'Innehav av andelar övriga företag, som det finns ägarintresse i'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:InnehavAgarintressenOvrigaForetag',
        standardRubrik:
          'Innehav av andelar övriga företag, som det finns ägarintresse i',
      }),
      InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple: {
        type: 'group',
        active: true,
        title: field(
          'Specifikation innehav av andelar i övriga företag som det finns ägarintressen i inklusive uppgifter om företag'
        ),
        table: table<IxbrlCell>(
          'notes.note50.data.InnehavAgarintressenOvrigaForetag.InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple.table',
          {
            id: 'ForetagetsNamn',
            label: 'Företagets namn',
            dataType: 'text',
            active: true,
          },
          {
            id: 'Organisationsnummer',
            label: 'Organisationsnummer',
            dataType: 'text',
            active: true,
          },
          {
            id: 'ForetagetsSate',
            label: 'Säte',
            dataType: 'text',
            active: true,
          },
          {
            id: 'InnehavAndelarAgarintressenOvrigaForetagAntalAndelar',
            label: 'Antal andelar',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'InnehavAgarintressenOvrigaForetagKapital',
            label: 'Eget kapital',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'InnehavAndelarAgarintressenOvrigaForetagAretsResultat',
            label: 'Årets resultat',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'InnehavAndelarAgarintressenOvrigaForetagKapitalandel',
            label: 'Kapitalandel',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'InnehavAndelarAgarintressenOvrigaForetagRostrattsandel',
            label: 'Rösträttsandel',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'InnehavAgarintressenOvrigaForetagRedovisatVarde',
            label: 'Redovisat värde',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple',
                'hidden',
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  '1',
                  'row',
                  ixbrlCell(value('Företagets namn'), {
                    type: 'stringItemType',
                    name: 'se-cd-base:ForetagetsNamn',
                    contextRef: 'period0',
                    standardRubrik: 'Företagets nuvarande namn',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple',
                      id: `${rows.getBaseId()}.1.tuple`,
                      tupleID: `${rows.getBaseId()}.1.tuple`,
                    },
                    tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value('Organisationsnummer'), {
                    type: 'stringItemType',
                    name: 'se-cd-base:Organisationsnummer',
                    contextRef: 'period0',
                    standardRubrik: 'Organisationsnummer',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value('Säte'), {
                    type: 'stringItemType',
                    name: 'se-cd-base:ForetagetsSate',
                    contextRef: 'period0',
                    standardRubrik: 'Företagets säte',
                    negateValue: false,
                    tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'sharesItemType',
                    unitRef: 'andelar',
                    name: 'se-gen-base:InnehavAndelarAgarintressenOvrigaForetagAntalAndelar',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Antal andelar per övrigt företag som det finns ägarintresse i',
                    negateValue: false,
                    tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InnehavAgarintressenOvrigaForetagKapital',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Positivt (negativt) eget kapital per övrigt företag som det finns ägarintresse i',
                    negateValue: false,
                    tupleRef: tupleRef(5, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InnehavAndelarAgarintressenOvrigaForetagAretsResultat',
                    contextRef: 'period0',
                    standardRubrik:
                      'Periodens vinst (förlust) per övrigt företag som det finns ägarintresse i',
                    negateValue: false,
                    tupleRef: tupleRef(6, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:InnehavAndelarAgarintressenOvrigaForetagKapitalandel',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Kapitalandel per övrigt företag som det finns ägarintresse i',
                    negateValue: false,
                    tupleRef: tupleRef(7, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:InnehavAndelarAgarintressenOvrigaForetagRostrattsandel',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Rösträttsandel per övrigt företag som det finns ägarintresse i',
                    negateValue: false,
                    tupleRef: tupleRef(8, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InnehavAgarintressenOvrigaForetagRedovisatVarde',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Redovisat värde per övrigt företag som det finns ägarintresse i',
                    saldo: 'debit',
                    negateValue: false,
                    tupleRef: tupleRef(9, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  })
                );
                return rows.build();
              });
            rows.newRowTemplateGenerator((_id, baseId) => ({
              id: _id,
              active: true,
              type: 'row',
              cells: {
                ForetagetsNamn: ixbrlCell(value('Företagets namn'), {
                  type: 'stringItemType',
                  name: 'se-cd-base:ForetagetsNamn',
                  contextRef: 'period0',
                  standardRubrik: 'Företagets nuvarande namn',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                Organisationsnummer: ixbrlCell(value('Organisationsnummer'), {
                  type: 'stringItemType',
                  name: 'se-cd-base:Organisationsnummer',
                  contextRef: 'period0',
                  standardRubrik: 'Organisationsnummer',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                ForetagetsSate: ixbrlCell(value('Säte'), {
                  type: 'stringItemType',
                  name: 'se-cd-base:ForetagetsSate',
                  contextRef: 'period0',
                  standardRubrik: 'Företagets säte',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                InnehavAndelarAgarintressenOvrigaForetagAntalAndelar: ixbrlCell(
                  value(),
                  {
                    type: 'sharesItemType',
                    unitRef: 'andelar',
                    name: 'se-gen-base:InnehavAndelarAgarintressenOvrigaForetagAntalAndelar',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Antal andelar per övrigt företag som det finns ägarintresse i',
                    negateValue: false,
                    tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
                InnehavAgarintressenOvrigaForetagKapital: ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:InnehavAgarintressenOvrigaForetagKapital',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Positivt (negativt) eget kapital per övrigt företag som det finns ägarintresse i',
                  negateValue: false,
                  tupleRef: tupleRef(5, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                InnehavAndelarAgarintressenOvrigaForetagAretsResultat:
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InnehavAndelarAgarintressenOvrigaForetagAretsResultat',
                    contextRef: 'period0',
                    standardRubrik:
                      'Periodens vinst (förlust) per övrigt företag som det finns ägarintresse i',
                    negateValue: false,
                    tupleRef: tupleRef(6, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
                InnehavAndelarAgarintressenOvrigaForetagKapitalandel: ixbrlCell(
                  value(),
                  {
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:InnehavAndelarAgarintressenOvrigaForetagKapitalandel',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Kapitalandel per övrigt företag som det finns ägarintresse i',
                    negateValue: false,
                    tupleRef: tupleRef(7, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
                InnehavAndelarAgarintressenOvrigaForetagRostrattsandel:
                  ixbrlCell(value(), {
                    type: 'pureItemType',
                    unitRef: 'procent',
                    name: 'se-gen-base:InnehavAndelarAgarintressenOvrigaForetagRostrattsandel',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Rösträttsandel per övrigt företag som det finns ägarintresse i',
                    negateValue: false,
                    tupleRef: tupleRef(8, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }),
                InnehavAgarintressenOvrigaForetagRedovisatVarde: ixbrlCell(
                  value(),
                  {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:InnehavAgarintressenOvrigaForetagRedovisatVarde',
                    contextRef: 'balans0',
                    standardRubrik:
                      'Redovisat värde per övrigt företag som det finns ägarintresse i',
                    saldo: 'debit',
                    negateValue: false,
                    tupleRef: tupleRef(9, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
              },
            }));
            return rows.build();
          })
          .build(),
      },
      InnehavAndelarIAgarintressenOvrigaForetagKommentar: {
        type: 'group',
        active: true,
        title: field(
          'Kommentar till innehav i övriga företag som det finns ägarintresse i'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:InnehavAndelarIAgarintressenOvrigaForetagKommentar',
          standardRubrik:
            'Kommentar till innehav i övriga företag som det finns ägarintresse i',
        }),
      },
    },
    NotAgarintressenOvrigaForetagKommentar: {
      type: 'group',
      active: true,
      title: field(
        'Kommentar till specifikation av ägarintressen i övriga företag'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotAgarintressenOvrigaForetagKommentar',
        standardRubrik:
          'Kommentar till specifikation av ägarintressen i övriga företag',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2050K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        AgarintressenOvrigaForetagAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            AgarintressenOvrigaForetagKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },

        InnehavAgarintressenOvrigaForetag: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,

            InnehavAndelarAgarintressenOvrigaForetagInklusiveUppgiftForetagTuple:
              {
                type: 'part' as const,
                children: {
                  type: type,
                  active: boolean,

                  title: cell,

                  table: { type: 'table' as const },
                },
              },

            InnehavAndelarIAgarintressenOvrigaForetagKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },

        NotAgarintressenOvrigaForetagKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
