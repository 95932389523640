import {
  combineLatest,
  Observable,
  ReplaySubject,
  Subscription,
  map,
  BehaviorSubject,
  distinctUntilChanged,
} from 'rxjs';
import {
  AgoyDocument,
  AgoyDocumentChanges,
  AgoyDocumentStructure,
  applyChanges,
  traverseDocument,
  updateValues,
} from '../AgoyDocument';
import { collectReferences, collectValues } from '../AgoyDocument/collect';
import {
  AccountResolver,
  ResolveReferenceContext,
  ResolveReferenceInput,
  TimePeriod,
  Values,
} from '../References';
import AnnualConfigContext from '../References/context/AnnualConfigContext';
import { CachingResolveReferenceContext } from '../References/context/CachingResolveReferenceContext';
import { ValuesContext } from '../References/context/ValuesContext';
import { DocumentDataService } from '../types/DocumentDataService';
import { DocumentValuesResolver } from '../types/DocumentValuesResolver';
import { RelatedClientResolver } from '../types/RelatedClientResolver';

export class GenericDocumentDataService<T extends AgoyDocumentStructure>
  implements DocumentDataService<T>
{
  clientId: string;

  documentId: string;

  structure: T;

  /**
   * report, calculated and ready to be rendered
   */
  report: Observable<AgoyDocument<T>>;

  /**
   * all changes done in the report
   */
  changes: ReplaySubject<AgoyDocumentChanges<T>> = new ReplaySubject(1);

  /**
   * latestReport, the latest report state with possible dirty values waiting for recalculation
   */
  latestReport: ReplaySubject<AgoyDocument<T>> = new ReplaySubject(1);

  locked: ReplaySubject<boolean> = new ReplaySubject(1);

  latestLocked = false;

  accountResolver: AccountResolver;

  externalDocumentValuesResolver: DocumentValuesResolver;

  externalDocumentValues: Observable<Record<string, Values>>;

  relatedClientResolver: RelatedClientResolver;

  /**
   * The original document
   */
  config: AgoyDocument<T>;

  subscriptions: Subscription[] = [];

  constructor(
    structure: T,
    config: AgoyDocument<T>,
    clientId: string,
    documentId: string,
    defaultPeriod: TimePeriod,
    accountResolver: AccountResolver,
    externalDocumentValuesResolver: DocumentValuesResolver,
    relatedClientResolver: RelatedClientResolver,
    changes: Observable<{ changes: AgoyDocumentChanges<T>; locked: boolean }>
  ) {
    this.clientId = clientId;
    this.documentId = documentId;
    this.structure = structure;
    this.accountResolver = accountResolver;
    this.externalDocumentValuesResolver = externalDocumentValuesResolver;
    this.relatedClientResolver = relatedClientResolver;
    this.config = config;

    this.externalDocumentValues = this.initValues();

    // Subscribe to the external changes
    this.subscriptions.push(
      changes
        .pipe(
          map((value) => value.changes),
          distinctUntilChanged()
        )
        .subscribe((ch) => this.changes.next(ch)),
      changes
        .pipe(
          map((value) => value.locked),
          distinctUntilChanged()
        )
        .subscribe((value) => this.locked.next(value)),
      this.locked.subscribe((locked) => {
        this.latestLocked = locked;
      })
    );

    this.report = this.initCalculations(defaultPeriod);
  }

  /**
   * Checks the document structure for external document/documents
   * definitions and requests values for those from the
   * DocumentValuesResolver
   *
   * @returns An observable for the external values
   */
  initValues(): Observable<Record<string, Values>> {
    const multipleValues: Observable<Record<string, Values>>[] = [];

    traverseDocument(null, this.structure, this.config, undefined, {
      externalDocument: (key, id, props) => {
        const values = this.externalDocumentValuesResolver.resolveValues(
          id,
          props.structure.documentType,
          props.structure.financialYear,
          props.structure.relationType
        );
        multipleValues.push(values.pipe(map((v) => ({ [id]: v }))));
        return props;
      },
      externalDocuments: (key, id, props) => {
        const docs = this.externalDocumentValuesResolver.resolveMultipleValues(
          id,
          props.structure.documentType,
          props.structure.financialYear,
          props.structure.relationType
        );
        multipleValues.push(docs);

        return props;
      },
      relatedClient: (key, id, props) => {
        const data = props.structure.information.map((informationType) =>
          this.relatedClientResolver
            .resolve(props.structure.name, informationType)
            .pipe(map((v) => ({ [`${id}.${informationType}`]: v })))
        );
        multipleValues.push(...data);

        return props;
      },
    });

    if (multipleValues.length === 0) {
      return new BehaviorSubject({});
    }

    // Make an observable from the combination of all observables
    return combineLatest(multipleValues).pipe(
      map((values) => values.reduce((result, v) => ({ ...result, ...v }), {}))
    );
  }

  initCalculations(defaultPeriod: TimePeriod): Observable<AgoyDocument<T>> {
    // For every changes, apply them to the config
    this.subscriptions.push(
      this.changes.subscribe((changes) =>
        this.latestReport.next(
          applyChanges(this.structure)(this.config, changes)
        )
      )
    );

    const input: ResolveReferenceInput = {
      accountResolver: this.accountResolver,
      defaultPeriod,
      periods: {},
    };

    const context = this.externalDocumentValues.pipe(
      map((values): ResolveReferenceContext | undefined => {
        return Object.entries(values).reduce(
          (
            context: ResolveReferenceContext | undefined,
            [name, values]
          ): ResolveReferenceContext => {
            return new ValuesContext(name, values, input, context);
          },
          new AnnualConfigContext(undefined, input, defaultPeriod)
        );
      })
    );

    return combineLatest({
      config: this.latestReport,
      context,
    }).pipe(
      map(({ config, context }) => {
        // Collect all references that exist in this document
        const references = collectReferences(this.structure)(config, {});

        // Collect all values in the document, this
        // does not include the external document's values
        const values = collectValues(this.structure)(config);

        const resolveContext = new CachingResolveReferenceContext(
          references,
          input,
          new ValuesContext(null, values, input, context)
        );

        // Resolve all ids that are references in this document
        Object.keys(references).forEach((id) => {
          resolveContext.resolveById(id, resolveContext);
        });

        // Update the document with the values stored in
        // resolve context.
        const updated = updateValues(this.structure)(
          config,
          resolveContext.calculatedReferences
        );
        return updated;
      })
    );
  }

  update(
    report: AgoyDocument<T> | undefined,
    changes: AgoyDocumentChanges<T>
  ): void {
    if (this.latestLocked) {
      console.warn('Document is locked');
      return;
    }
    if (report) {
      this.latestReport.next(report);
    } else {
      this.latestReport.next(
        applyChanges(this.structure)(this.config, changes)
      );
    }
    this.changes.next(changes);
  }

  updateLocked(locked: boolean): void {
    this.locked.next(locked);
  }

  dispose(): void {
    this.changes.complete();
    this.latestReport.complete();
    // timeout for waiting for throttle (2s) of saving of changes
    setTimeout(() => {
      this.subscriptions.forEach((sub) => sub.unsubscribe());
    }, 2100);
  }
}
