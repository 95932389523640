import { ResolvedReference } from '../References';
import { Cell } from './types';

export * from './types';

export const stringValue = (cell: Cell | undefined): string | undefined => {
  switch (cell?.type) {
    case 'string':
    case 'label':
      return cell.value;
    case 'msg':
    case 'ref':
      return typeof cell.value === 'string' ? cell.value : undefined;
    default:
      return undefined;
  }
};

export const labelValue = (cell: Cell | undefined): string | undefined =>
  cell?.type === 'label' ? cell.value : undefined;

export const numberValue = (cell: Cell | undefined): number | undefined => {
  switch (cell?.type) {
    case 'number':
      return cell.value;
    case 'ref':
      return typeof cell.value === 'number' ? cell.value : undefined;
    default:
      return undefined;
  }
};

export const referenceValue = (cell: Cell | undefined): ResolvedReference =>
  cell?.type === 'ref' ? cell.value : undefined;

export const referenceNumberValue = (
  cell: Cell | undefined
): number | undefined =>
  cell?.type === 'ref' && typeof cell.value === 'number'
    ? cell.value
    : undefined;

export const referenceStringValue = (
  cell: Cell | undefined
): string | undefined =>
  cell?.type === 'ref' && typeof cell.value === 'string'
    ? cell.value
    : undefined;

export const booleanValue = (
  cell: Cell | boolean | undefined | null,
  defaultValue = false
): boolean => {
  if (typeof cell === 'boolean') {
    return cell;
  }
  if (
    cell !== null &&
    typeof cell === 'object' &&
    (cell.type === 'boolean' || cell.type === 'ref') &&
    typeof cell.value === 'boolean'
  ) {
    return cell.value;
  }

  return defaultValue;
};

export * from './config';
