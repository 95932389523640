import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Print as PrintIcon, Close as CloseIcon } from '@material-ui/icons';
import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
} from '@material-ui/core';

import { Period } from '@agoy/api-sdk-core';
import { ReportSupplierData } from '_reconciliation/types';
import InvoicesPreview from './InvoicesPreview';

const Container = styled.section`
  @media print {
    page-break-inside: avoid;
  }
`;

const Dialog = styled(MuiDialog)`
  @media print {
    .MuiDialog-paper {
      margin: 0;
      border: none;
      border-radius: 0;
    }
  }
`;

const TopBarActions = styled.div`
  display: flex;
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const PrintButton = styled(IconButton)`
  @media print {
    display: none;
  }
  background-color: white;
`;

const CloseButton = styled(IconButton)`
  @media print {
    display: none;
  }
  background-color: white;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
`;

type InvoicesPreviewProps = {
  data: ReportSupplierData;
  period: Period;
  open: boolean;
  handleShow: (value: boolean) => void;
};

const InvoicesPreviewModal = ({
  data,
  period,
  open,
  handleShow,
}: InvoicesPreviewProps): JSX.Element => {
  const [isPrintPreviewMode, setIsPrintPreviewMode] = useState(false);

  const handlePrint = () => {
    // before we prit we have to remove the sticky header and the footer
    // so that the print functionality will be able to print all the pages correctly
    setTimeout(() => {
      setIsPrintPreviewMode(true);
      window.print();
    });

    // after the print we restore the view with the sticky header and footer
    window.onafterprint = () => setIsPrintPreviewMode(false);
  };

  return (
    <Container>
      <Dialog open={open} onClose={() => handleShow(false)} maxWidth="lg">
        <DialogContent>
          <TopBarActions>
            <LoadingContainer>
              <PrintButton aria-label="print" onClick={handlePrint}>
                <PrintIcon />
              </PrintButton>
            </LoadingContainer>
            <CloseButton aria-label="close" onClick={() => handleShow(false)}>
              <CloseIcon />
            </CloseButton>
          </TopBarActions>
          <Content>
            <InvoicesPreview
              data={data}
              period={period}
              isPrintPreviewMode={isPrintPreviewMode}
            />
          </Content>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default InvoicesPreviewModal;
