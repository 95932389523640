import { group } from '@agoy/common';
import { WSNotification } from '@agoy/messages';
import { asResultClass, getApiSdk } from 'api-sdk';
import { ApiErrorType, isApiErrorType } from 'api-sdk/api-errors';
import {
  FinancialYear,
  Period,
  ReconciliationBalanceAccountRow,
  ReconciliationBalances,
} from '@agoy/api-sdk-core';
import { max, min, orderBy } from 'lodash';
import { flushSync } from 'react-dom';
import { useDispatch } from 'react-redux';

import {
  buffer,
  distinctUntilChanged,
  firstValueFrom,
  map,
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  throttleTime,
} from 'rxjs';
import { Err, Ok, Result } from 'ts-results';
import { areIntervalsOverlapping } from 'date-fns';
import { TimePeriod } from '@agoy/document';
import { getContext } from 'utils/AgoyAppClient/contextHolder';
import {
  NotificationErrors,
  NotificationService,
} from '_shared/services/Notifications/types';
import { ReconciliationDataLayer } from './types';
import { findAccountRow } from './utils';
import { getClient } from '../../../_clients/redux/customers/actions';

type Request = {
  start: string;
  end: string;
  type: 'month' | 'quarter' | 'financialYear';
  includeYearEnd: boolean;
};

type Dispatch = ReturnType<typeof useDispatch>;

class ReconciliationDataLayerService implements ReconciliationDataLayer {
  clientId: string;

  financialYears: Observable<Array<FinancialYear & { periods?: Period[] }>>;

  /**
   * Use getter sdk
   */
  private _sdk: ReturnType<typeof getApiSdk> | undefined;

  private dispatch: Dispatch;

  private request: Subject<Request>;

  /**
   * Stored subjects providing balances, key is provided by getKey
   */
  private balances: Record<
    string,
    ReplaySubject<Result<ReconciliationBalances | null, ApiErrorType>>
  > = {};

  private notificationService: NotificationService | null;

  private subscriptions: Subscription[] = [];

  constructor(
    dispatch: Dispatch,
    clientId: string,
    financialYears: Observable<Array<FinancialYear & { periods?: Period[] }>>,
    notificationService: NotificationService | null
  ) {
    this.dispatch = dispatch;
    this.clientId = clientId;
    this.financialYears = financialYears;
    this.notificationService = notificationService;
    if (notificationService) {
      this.subscriptions.push(
        notificationService.subscribe(
          {
            topic: 'user-input-changed',
            clientId,
          },
          (result) => {
            this.onUserInputChanged(result);
          }
        )
      );
      this.subscriptions.push(
        notificationService.subscribe(
          {
            topic: 'accounting-balances-changed',
            clientId,
          },
          (result) => {
            this.onAccountBalancesChanged(result);
          }
        )
      );
    }

    this.request = new Subject<Request>();

    this.request
      .pipe(
        // Buffer all requests, and emit 100ms after the first
        buffer(
          this.request.pipe(
            throttleTime(100, undefined, { leading: false, trailing: true })
          )
        )
      )
      .subscribe({
        next: (val) => {
          this.sendRequests(val);
        },
      });
  }

  private async onUserInputChanged(
    result: Result<WSNotification, NotificationErrors>
  ): Promise<void> {
    if (result.ok) {
      const msg = result.val;
      if (
        msg.topic === 'user-input-changed' &&
        msg.information.includes('overview')
      ) {
        const financialYears = await firstValueFrom(this.financialYears);
        const period = financialYears
          .flatMap((fy) => fy.periods ?? [])
          .find((p) => p.id === msg.period.id);
        if (!period) {
          // eslint-disable-next-line no-console
          console.warn(
            'No matching period found',
            msg.period.id,
            financialYears
          );
          return;
        }
        Object.keys(this.balances)
          .map((key) => this.parseKey(key))
          .filter((key) => {
            return (
              key.includeYearEnd === (period.type === 'year_end') &&
              areIntervalsOverlapping(
                TimePeriod.fromISODates(period).toInterval(),
                TimePeriod.fromISODates(key).toInterval(),
                { inclusive: true }
              )
            );
          })
          .forEach((request) => {
            this.request.next(request);
          });
      }
    }
  }

  private async onAccountBalancesChanged(
    result: Result<WSNotification, NotificationErrors>
  ): Promise<void> {
    if (result.ok) {
      const msg = result.val;
      if (msg.topic === 'accounting-balances-changed') {
        const financialYears = await firstValueFrom(this.financialYears);
        const financialYear = financialYears.find(
          (y) => y.id === msg.financialYearId
        );
        if (!financialYear) {
          // Missing financial year, probably a new one imported.
          this.dispatch(getClient(this.clientId) as any);
          return;
        }
        Object.keys(this.balances)
          .map((key) => this.parseKey(key))
          .filter(
            // Is the period included in the key?
            (key) =>
              areIntervalsOverlapping(
                TimePeriod.fromISODates(financialYear).toInterval(),
                TimePeriod.fromISODates(key).toInterval(),
                { inclusive: true }
              )
          )
          .forEach((request) => {
            this.request.next(request);
          });
      }
    }
  }

  private sendRequests(requests: Request[]) {
    const requestsByKey = group(
      orderBy(requests, 'start'),
      (req) => this.getKey('start', 'end', req.type, req.includeYearEnd),
      (req) => req
    );

    requestsByKey.forEach(async (requestsOfType, groupKey) => {
      const { type, includeYearEnd } = this.parseKey(groupKey);

      const start = min(requestsOfType.map((r) => r.start));
      const end = max(requestsOfType.map((r) => r.end));
      if (!start || !end) {
        return;
      }

      const balancesResult = await asResultClass(
        this.sdk.getReconciliationOverview({
          clientid: this.clientId,
          start,
          end,
          groupBy: type,
          includeYearEnd,
        })
      );

      // Using flushSync to make React render all periods at the same time
      flushSync(() => {
        requestsOfType.forEach((request) => {
          const key = this.getKey(
            request.start,
            request.end,
            request.type,
            request.includeYearEnd
          );
          if (this.balances[key]) {
            if (balancesResult.err) {
              if (isApiErrorType(balancesResult.val)) {
                if (!balancesResult.val.handled) {
                  this.balances[key].next(Err(balancesResult.val));
                }
              } else {
                this.balances[key].error(balancesResult.val);
              }
            } else {
              // Get the group for the specific period
              const period = balancesResult.val.groups.find(
                (aGroup) =>
                  aGroup.end === request.end && aGroup.start === request.start
              );
              if (period) {
                this.balances[key].next(Ok(period));
              } else {
                // eslint-disable-next-line no-console
                console.warn(`The group ${key} was not found in the response`);
                this.balances[key].next(Ok(null));
              }
            }
          } else {
            // eslint-disable-next-line no-console
            console.error(`Missing subject for ${key}`);
          }
        });
      });
    });
  }

  private getKey(
    start: string,
    end: string,
    type: 'month' | 'quarter' | 'financialYear',
    includeYearEnd: boolean
  ) {
    return `${type}_${start}_${end}${includeYearEnd ? '_yearEnd' : ''}`;
  }

  private parseKey(key: string): {
    type: 'month' | 'quarter' | 'financialYear';
    start: string;
    end: string;
    includeYearEnd: boolean;
  } {
    const [type, start, end, includeYearEnd] = key.split('_');
    if (type !== 'month' && type !== 'quarter' && type !== 'financialYear') {
      throw new Error(`Illegal key type ${type}`);
    }
    return {
      type,
      start,
      end,
      includeYearEnd: !!includeYearEnd,
    };
  }

  public get sdk(): ReturnType<typeof getApiSdk> {
    if (!this._sdk) {
      this._sdk = getApiSdk(getContext());
    }
    return this._sdk;
  }

  getAccounts(
    start: string,
    end: string,
    type: 'month' | 'quarter' | 'financialYear',
    includeYearEnd: boolean
  ): Observable<Result<ReconciliationBalances | null, ApiErrorType>> {
    const key = this.getKey(start, end, type, includeYearEnd);
    if (this.balances[key]) {
      return this.balances[key];
    }

    this.request.next({
      start,
      end,
      type,
      includeYearEnd,
    });

    this.balances[key] = new ReplaySubject<
      Result<ReconciliationBalances | null, ApiErrorType>
    >();

    return this.balances[key];
  }

  getAccount(
    start: string,
    end: string,
    type: 'month' | 'quarter' | 'financialYear',
    account: string,
    includeYearEnd: boolean
  ): Observable<Result<ReconciliationBalanceAccountRow | null, ApiErrorType>> {
    return this.getAccounts(start, end, type, includeYearEnd).pipe(
      map((result) =>
        result.andThen((value) =>
          Ok(value && findAccountRow(value.rows, +account))
        )
      ),
      distinctUntilChanged()
    );
  }

  release() {
    // Do clean up
    this.request.complete();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
    this.balances = {};
  }
}

export default ReconciliationDataLayerService;
