import React, { useContext } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import { clientYear } from '_reconciliation/redux/accounting-view/selectors';

import PropertyTaxPreviewPrint from '_tax/components/Taxes/PropertyTax/PropertyTaxPreviewPrint';
import PrintStateContext from '_annual-report/components/AnnualReportView/PrintStateContext';
import PeriodDataContext from './HiddenRow/Rows/PeriodDataContext';
import { useSelector } from '../../../redux/reducers';
import { InvoicesPreview as CustomerInvoices } from './HiddenRow/Rows/CustomerInvoicesView/InvoicesPreview';
import { InvoicesPreview as SupplierInvoices } from './HiddenRow/Rows/SupplierInvoicesView/InvoicesPreview';
import SkatteverketPrintView from './HiddenRow/Rows/SkatteverketIntegrationView/SkatteverketView';
import SemesterskuldlistaPreviewPrint from './HiddenRow/Rows/SemesterskuldView/SemesterskuldlistaView/SemesterskuldlistaPreviewPrint';
import { LagerjusteringPreviewPrint } from './HiddenRow/Rows/LagerjusteringView';
import InventoryStockViewPrint from './HiddenRow/Rows/InventoryStockView/InventoryStockViewPrint';

const Page = styled(({ children, ...props }) =>
  React.Children.count(children) > 0 ? <div {...props}>{children}</div> : null
)`
  page-break-before: always;
  width: 842px;

  @media screen {
    border-bottom: 1px dashed ${(props) => props.theme.palette.grey['500']};
    padding: 32px 32px;
    width: 864px;
  }
`;

interface ReconciliationPrintProps {
  pageNumbers: Record<string, number>;
}

const ReconciliationPrint = ({
  pageNumbers,
}: ReconciliationPrintProps): JSX.Element | null => {
  const { path } = useRouteMatch();

  const { clientId, period, financialYear } = useContext(PeriodDataContext);
  const {
    state: { parameters },
  } = useContext(PrintStateContext);

  const currentClient = useSelector(
    clientYear(clientId, financialYear, (state) => state)
  );

  const transactions = currentClient?.skatteverketTransactions || [];

  return (
    <Switch>
      <Route path={`${path}/customerInvoices`}>
        {period && currentClient && (
          <Page>
            <CustomerInvoices
              data={currentClient.customerInvoices?.reportData || {}}
              period={period}
              isPrintPreviewMode
            />
          </Page>
        )}
      </Route>
      <Route path={`${path}/supplierInvoices`}>
        {period && currentClient && (
          <Page>
            <SupplierInvoices
              data={currentClient.supplierInvoices?.reportData || {}}
              period={period}
              isPrintPreviewMode
            />
          </Page>
        )}
      </Route>
      <Route path={`${path}/skatteverket`}>
        {parameters && (
          <Page>
            <SkatteverketPrintView
              accountNumber={`${parameters.accountNumber}`}
              transactions={transactions}
              isPrintPreviewMode
            />
          </Page>
        )}
      </Route>
      <Route path={`${path}/semesterskuld`}>
        {period && (
          <Page>
            <SemesterskuldlistaPreviewPrint />
          </Page>
        )}
      </Route>
      <Route path={`${path}/lagerjustering`}>
        {period && (
          <Page>
            <LagerjusteringPreviewPrint />
          </Page>
        )}
      </Route>
      <Route path={`${path}/lagerinventeringslista`}>
        {period && (
          <Page>
            <InventoryStockViewPrint />
          </Page>
        )}
      </Route>
      <Route path={`${path}/fastighetsskatt`}>
        {period && (
          <Page>
            <PropertyTaxPreviewPrint />
          </Page>
        )}
      </Route>
      <Route path={path} exact>
        {'customerInvoices' in pageNumbers && period && currentClient && (
          <Page>
            <CustomerInvoices
              data={currentClient.customerInvoices?.reportData || {}}
              period={period}
              isPrintPreviewMode
            />
          </Page>
        )}
        {'supplierInvoices' in pageNumbers && period && currentClient && (
          <Page>
            <SupplierInvoices
              data={currentClient.supplierInvoices?.reportData || {}}
              period={period}
              isPrintPreviewMode
            />
          </Page>
        )}
        {'skatteverket' in pageNumbers && parameters && (
          <Page>
            <SkatteverketPrintView
              accountNumber={`${parameters.accountNumber}`}
              transactions={transactions}
              isPrintPreviewMode
            />
          </Page>
        )}
        {'semesterskuld' in pageNumbers && (
          <Page>
            <SemesterskuldlistaPreviewPrint />
          </Page>
        )}
        {'lagerjustering' in pageNumbers && (
          <Page>
            <LagerjusteringPreviewPrint />
          </Page>
        )}
        {'lagerinventeringslista' in pageNumbers && (
          <Page>
            <InventoryStockViewPrint />
          </Page>
        )}
        {'fastighetsskatt' in pageNumbers && (
          <Page>
            <PropertyTaxPreviewPrint />
          </Page>
        )}
      </Route>
    </Switch>
  );
};

export default ReconciliationPrint;
