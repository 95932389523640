import React, { useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import styled from '@emotion/styled';

import { TimePeriod } from '@agoy/document';
import { SkatteverketTransaction } from '_clients/redux/customer-view/types';
import { AccountingViewClientYear } from '_reconciliation/redux/accounting-view/reducer';
import { PrintState } from '_annual-report/components/AnnualReportView/PrintStateContext';
import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';
import { useSelector } from 'redux/reducers';
import { useAccountDocuments } from 'utils/usePeriodDocuments';
import { Period } from '@agoy/api-sdk-core';
import { parseFormat } from '@agoy/dates';
import Button from '_shared/components/Buttons/Button';
import PeriodDataContext from '../PeriodDataContext';
import { SaldoHistoryContext } from '../../HiddenRowHistoryProvider';

const ActionHeaders = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const DateWrapper = styled.div`
  margin-left: auto;
  margin-right: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  gap: ${(props) => props.theme.spacing(1)}px;
`;

type SKVPrintState = Pick<
  Required<PrintState>,
  | 'accountingView'
  | 'customerView'
  | 'organisation'
  | 'customers'
  | 'periodData'
  | 'parameters'
>;

type FetchHeaderProps = {
  date: string | undefined;
  period: Period;
  account: string;
  transactions: SkatteverketTransaction[];
  hasSpecifications: boolean;
  isLoading: boolean;
  onFetchClick: () => void;
  onPreviewClick: () => void;
};

const FetchHeader = ({
  date,
  period,
  account,
  transactions,
  hasSpecifications,
  isLoading = false,
  onFetchClick,
  onPreviewClick,
}: FetchHeaderProps): JSX.Element => {
  const { createAndAddDocument } = useAccountDocuments();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { onSaldoChange } = useContext(SaldoHistoryContext);
  const periodData = useContext(PeriodDataContext);
  const { clientId } = periodData;

  const [isSaving, setIsSaving] = useState(false);

  const customer = useSelector((state) => state.customers[clientId]);
  const accountingView = useSelector((state) => state.accountingView);
  const customerView = useSelector((state) => state.customerView);
  const organisation = useSelector((state) => state.organisation);

  const getPrintState = (): SKVPrintState | null => {
    if (!customer) {
      return null;
    }

    const { start } = period;
    const currentFinancialYear = TimePeriod.fromISODates(
      periodData.financialYear
    ).value;

    return {
      customerView: {
        ...customerView,
        currentPeriod: parseFormat(start, 'yyyyMMdd'),
      },
      customers: {
        [customer.id]: customer,
      },
      organisation,
      accountingView: {
        ...accountingView,
        clients: {
          [customer.id]: {
            years: {
              [currentFinancialYear]: {
                skatteverketTransactions: transactions,
              } as AccountingViewClientYear,
            },
          },
        },
      },
      periodData,
      parameters: {
        accountNumber: account,
      },
    };
  };

  const handleSaveSkatteverketPdf = async () => {
    const printState = getPrintState();

    if (!customer || !printState) {
      return;
    }

    try {
      const docName = `Skatteverket ${customer.name} ${period.start}.pdf`;

      setIsSaving(true);
      await createAndAddDocument({
        name: docName,
        docType: 'accounting',
        footer: '',
        header: '',
        parts: ['skatteverket'],
        printState,
      });

      if (!hasSpecifications) {
        const lastTransaction = transactions[transactions.length - 1];
        const updatedSaldo = lastTransaction?.saldo || 0;

        onSaldoChange(account, period, updatedSaldo);
      }

      const messageKey = 'hidden.skatteverket.saveToPeriodDocuments.success';
      dispatch(addGlobalMessage('success', messageKey));
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ActionHeaders>
      {customer?.name && customer?.orgNumber && (
        <Typography variant="body1" color="textSecondary">
          Bokförda transaktioner för {customer.name} {customer.orgNumber}
        </Typography>
      )}
      <DateWrapper>
        {date && (
          <>
            <AccessTimeIcon />
            <Typography variant="body1" color="textSecondary">
              {date}
            </Typography>
          </>
        )}
      </DateWrapper>
      <Button
        label={formatMessage({ id: 'hidden.skatteverket.view' })}
        onClick={onPreviewClick}
        variant="text"
      />

      <Button
        label={formatMessage({ id: 'hidden.skatteverket.save' })}
        onClick={handleSaveSkatteverketPdf}
        loading={isSaving}
        variant="text"
      />

      <Button
        label={formatMessage({ id: 'hidden.skatteverket.fetch' })}
        onClick={onFetchClick}
        disabled={isLoading}
      />
    </ActionHeaders>
  );
};

export default FetchHeader;
