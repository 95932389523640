import React, { useCallback, useState, useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Period } from '@agoy/api-sdk-core';
import { useApiSdk } from 'api-sdk';
import { Typography } from '@material-ui/core';
import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';
import EditingIconButton from '_shared/components/Buttons/EditingIconButton';
import ResetContentButton from '_shared/components/ResetContent/ResetContentButton';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CommonPreviewModal from '_shared/components/CommonPreviewModal';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PrintButton from '_shared/components/Buttons/PrintButton';
import { useAccountDocuments } from 'utils/usePeriodDocuments';
import { PrintState } from '_annual-report/components/AnnualReportView/PrintStateContext';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import { Alert } from '@material-ui/lab';
import Button from '_shared/components/Buttons/Button';
import LagerjusteringTable from './LagerjusteringTable';
import LagerjusteringTablePreview from './LagerjusteringTablePreview';
import { InventoryObsolescenceDataType } from './types';
import PeriodDataContext from '../PeriodDataContext';

type Props = {
  defaultData: InventoryObsolescenceDataType;
  data: InventoryObsolescenceDataType;
  onChangeData: (data: InventoryObsolescenceDataType) => void;
  ub: number | undefined;
  period: Period;
  handleStockValueChange: (value: number) => void;
};

type PagePrintState = Pick<
  Required<PrintState>,
  'accountingView' | 'organisation' | 'customers' | 'periodData'
>;

const Wrapper = styled.div`
  display: flex;
  min-width: 1000px;
  width: 100%;
  flex-direction: column;
  border-right: 1px solid ${(props) => props.theme.palette.grey[400]};
  flex: 1;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 30px;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 2px solid #eeeeee;
  background-color: ${(props) => props.theme.palette.grey[100]};
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  padding: 10px;
  border-radius: 4px;
`;

const ErrorAlert = styled(Alert)`
  margin-bottom: 20px;
`;

const Message = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[600]};
`;

const TableTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.palette.accountingView.headers.main};
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;

const InfoIcon = styled(InfoOutlinedIcon)`
  height: 20px;
  margin-right: 10px;
  color: ${(props) => props.theme.palette.grey[600]};
`;

const LagerjusteringTableView = ({
  defaultData,
  data,
  onChangeData,
  ub,
  period,
  handleStockValueChange,
}: Props) => {
  const { formatMessage } = useIntl();
  const { createAndAddDocument } = useAccountDocuments();

  const [isError, setError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [isPrintPreviewMode, setIsPrintPreviewMode] = useState(false);

  const sdk = useApiSdk();
  const dispatch = useDispatch();

  const periodData = useContext(PeriodDataContext);
  const { clientId } = periodData;

  const customer = useSelector((state) => state.customers[clientId]);
  const organisation = useSelector((state) => state.organisation);
  const accountingView = useSelector((state) => state.accountingView);

  const handleUpdateLagerjusteringData = useCallback(
    async (rowId, cell, value) => {
      if (rowId && cell) {
        if (
          cell === 'reference' &&
          rowId === '2' &&
          (value > 0.03 || value < 0)
        ) {
          setIsDataUpdated(false);
          onChangeData({
            ...data,
            rate: data.rate,
          });
          if (data.rate !== value) {
            setIsDataUpdated(true);
          } else {
            setIsDataUpdated(false);
          }
          setError(true);
          return;
        }
        if (cell === 'reference' && rowId === '2') {
          onChangeData({
            ...data,
            rate: value,
          });
          if (data.rate !== value) {
            setIsDataUpdated(true);
          } else {
            setIsDataUpdated(false);
          }
          setError(false);
        } else {
          onChangeData({
            ...data,
            [cell]: value,
          });
          if (data[cell] !== value) {
            setIsDataUpdated(true);
          } else {
            setIsDataUpdated(false);
          }
        }
      }
    },
    [data, onChangeData]
  );

  useEffect(() => {
    setError(false);
  }, [isEditing]);

  useEffect(() => {
    if (!isEditing) {
      setIsDataUpdated(false);
    }
    if (!isEditing && isDataUpdated && !isError) {
      sdk.updateInventoryObsolescence({
        clientId,
        requestBody: data,
      });
    }
  }, [isEditing, isDataUpdated]);

  const handleReset = useCallback(async () => {
    onChangeData(defaultData);
    setError(false);
    setIsDataUpdated(true);
    await sdk.updateInventoryObsolescence({
      clientId,
      requestBody: defaultData,
    });
  }, []);

  const handleShowPreview = (value: boolean) => {
    setOpenPreview(value);
  };

  const handlePrint = () => {
    setTimeout(() => {
      setIsPrintPreviewMode(true);
      window.print();
    });

    window.onafterprint = () => setIsPrintPreviewMode(false);
  };

  const getPrintState = (): PagePrintState | null => {
    if (!customer) {
      return null;
    }

    return {
      customers: {
        [customer.id]: customer,
      },
      organisation,
      accountingView: {
        ...accountingView,
        inventoryObsolescence: {
          ...data,
          ub,
        },
      },
      periodData,
    };
  };

  const handleSavePdf = async () => {
    const printState = getPrintState();

    if (!customer || !printState) {
      return;
    }

    try {
      setIsSaving(true);
      const docName = `Lagerjustering ${data.account} ${customer.name} ${period.start}.pdf`;

      await createAndAddDocument({
        name: docName,
        docType: 'accounting',
        footer: '',
        header: '',
        parts: ['lagerjustering'],
        printState,
      });

      const messageKey =
        'inventoryObsolescence.table.saveToPeriodDocuments.success';
      dispatch(addGlobalMessage('success', messageKey));
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      {openPreview && (
        <CommonPreviewModal
          isOpen={openPreview}
          handleShow={handleShowPreview}
          onPrint={handlePrint}
        >
          <LagerjusteringTablePreview
            data={data}
            ub={ub}
            isPrint={isPrintPreviewMode}
          />
        </CommonPreviewModal>
      )}
      <Wrapper>
        <TableWrapper>
          <TableHeaderWrapper>
            <TableTitle>
              {formatMessage({
                id: `hidden.lagerjustering.view`,
              })}
            </TableTitle>
            <Buttons>
              <EditingIconButton
                value={isEditing}
                onClick={() => setEditing(!isEditing)}
              />
              <PrintButton
                onClick={() => setOpenPreview(true)}
                tooltip="hidden.lagerjustering.print"
              />
              <ResetContentButton what="Lagerjustering" onReset={handleReset} />
            </Buttons>
          </TableHeaderWrapper>
          <MessageWrapper>
            <InfoIcon />
            <Message>
              {formatMessage({
                id: `hidden.lagerjustering.message`,
              })}
            </Message>
          </MessageWrapper>
          {isError && (
            <ErrorAlert severity="error">
              {formatMessage({
                id: 'hidden.lagerjustering.rate.error',
              })}
            </ErrorAlert>
          )}
          <LagerjusteringTable
            data={data}
            handleUpdateData={handleUpdateLagerjusteringData}
            ub={ub}
            isEditing={isEditing}
            handleStockValueChange={handleStockValueChange}
          />
          <Button
            label={formatMessage({
              id: 'inventoryObsolescence.table.saveToPeriodDocuments',
            })}
            onClick={() => handleSavePdf()}
            disabled={isError}
            loading={isSaving}
            startIcon={<AttachFileIcon />}
          />
        </TableWrapper>
      </Wrapper>
    </>
  );
};

export default LagerjusteringTableView;
