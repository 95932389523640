/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6861
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../../common/utils/util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2075K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2075K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Långfristiga skulder'),
  data: {
    active: true,
    LangfristigaSkulderForfallerTuple: {
      type: 'group',
      active: true,
      title: field('Långfristiga skulder som förfaller till betalning'),
      table: table<IxbrlCell>(
        'notes.note75.data.LangfristigaSkulderForfallerTuple.table',
        {
          id: 'LangfristigaSkulderForfallerSkuldpost',
          label: 'Skuldpost',
          dataType: 'text',
          active: true,
        },
        {
          id: 'LangfristigaSkulderForfallerSenare5Ar',
          label: 'Senare än 5 år efter balansdagen',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'LangfristigaSkulderForfallerTuple',
              'hidden',
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Skuldpost'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:LangfristigaSkulderForfallerSkuldpost',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Skuldpost som förfaller till betalning senare än fem år efter balansdagen',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:LangfristigaSkulderForfallerTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:LangfristigaSkulderForfallerSenare5Ar',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Belopp på skuldpost som förfaller till betalning senare än fem år efter balansdagen',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              LangfristigaSkulderForfallerSkuldpost: ixbrlCell(
                value('Skuldpost'),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:LangfristigaSkulderForfallerSkuldpost',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Skuldpost som förfaller till betalning senare än fem år efter balansdagen',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:LangfristigaSkulderForfallerTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              LangfristigaSkulderForfallerSenare5Ar: ixbrlCell(value(), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:LangfristigaSkulderForfallerSenare5Ar',
                contextRef: 'balans0',
                standardRubrik:
                  'Belopp på skuldpost som förfaller till betalning senare än fem år efter balansdagen',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
            },
          }));
          return rows.build();
        })
        .build(),
      NotLangfristigaSkulderKommentar: {
        type: 'group',
        active: true,
        title: field('Kommentar till specifikation av långfristiga skulder'),
        value: ixbrlField(field(''), {
          contextRef: 'balans0',
          type: 'stringItemType',
          name: 'se-gen-base:NotLangfristigaSkulderKommentar',
          standardRubrik:
            'Kommentar till specifikation av långfristiga skulder',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2075K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        LangfristigaSkulderForfallerTuple: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotLangfristigaSkulderKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
