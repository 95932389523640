import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Typography } from '@material-ui/core';
import { numberValue, stringValue } from '@agoy/document';
import { asResultClass, useApiSdk } from 'api-sdk';
import {
  addGlobalErrorMessage,
  addGlobalMessage,
  setStockInventoryTable,
} from 'redux/actions';
import PeriodSummary from '_shared/components/PeriodSummary';
import LoadingLogo from '_shared/components/LoadingLogo';
import { Period, StockInventoryTable } from '@agoy/api-sdk-core';
import { formatPeriodToMonthAndYear } from 'utils';
import { useAccountDocuments } from 'utils/usePeriodDocuments';
import { InputData } from '_reconciliation/types';
import EditingIconButton from '_shared/components/Buttons/EditingIconButton';
import ResetContentButton from '_shared/components/ResetContent/ResetContentButton';
import CommonPreviewModal from '_shared/components/CommonPreviewModal';
import PrintButton from '_shared/components/Buttons/PrintButton';
import { PrintState } from '_annual-report/components/AnnualReportView/PrintStateContext';
import { parseFormat } from '@agoy/dates';
import Button from '_shared/components/Buttons/Button';
import InventoryStockTable from './InventoryStockTable';
import {
  defaultColumnKeys,
  StockInventoryTableRow,
  TableService,
} from './types';
import {
  calculateTotalCellValue,
  generateDefaultDataWithNEmptyRows,
} from './utils';
import PeriodDataContext from '../PeriodDataContext';

type InventoryStockViewProps = {
  userData: InputData;
  accountNumber: string;
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 30px;
  overflow: auto;
  gap: ${({ theme }) => theme.spacing(1)}px;
`;

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  gap: ${(props) => props.theme.spacing(3)}px;
`;

const TableTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.palette.accountingView.headers.main};
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
`;

const ActionButton = styled(Button)`
  display: flex;
  padding-left: 15px;
  padding-right: 15px;
  width: fit-content;
  margin-top: ${(props) => props.theme.spacing(3)}px;
`;

const AttachIcon = styled(AttachFileIcon)`
  color: ${(props) => props.theme.palette.common.white};
`;

const TableWithCopyButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow: auto;
`;

const CopyButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 58px;

  & > * {
    width: max-content;
  }
`;

const LoadingContainer = styled.div`
  width: 300px;
  margin-top: 50px;
`;

const InventoryStockView = ({
  userData,
  accountNumber,
}: InventoryStockViewProps) => {
  const { formatMessage } = useIntl();

  const {
    ub,
    ib,
    psaldo: userDataPsaldo,
    saldo: userDataSaldo,
  } = userData || {};
  const saldo = userDataSaldo ? parseInt(userDataSaldo, 10) : 0;
  const psaldo = userDataPsaldo ? parseInt(userDataPsaldo.toString(), 10) : 0;

  const periodData = useContext(PeriodDataContext);
  const { nextPeriod, previousPeriod, clientId, period, lastPeriod } =
    periodData;

  const customer = useSelector((state) => state.customers[clientId]);

  const translate = (id) => formatMessage({ id });
  const sdk = useApiSdk();
  const dispatch = useDispatch();
  const { createAndAddDocument } = useAccountDocuments();
  const accountingView = useSelector((state) => state.accountingView);
  const customerView = useSelector((state) => state.customerView);
  const organisation = useSelector((state) => state.organisation);

  const [isEditing, setEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isCopying, setIsCopying] = useState(false);
  const [tableData, setTableDataState] = useState<StockInventoryTable>({
    headers: [],
    rows: [],
  });
  const [openPreview, setOpenPreview] = useState(false);

  const printState = useMemo(
    (): PrintState | null => ({
      customerView: {
        ...customerView,
        currentPeriod: parseFormat(period.start, 'yyyyMMdd'),
      },
      customers: {
        [customer.id]: customer,
      },
      organisation,
      accountingView: {
        ...accountingView,
      },
      periodData,
    }),
    [
      accountingView,
      customer,
      customerView,
      organisation,
      period.start,
      periodData,
    ]
  );

  const setTableData = useCallback(
    (data: StockInventoryTable) => {
      dispatch(setStockInventoryTable(data));

      setTableDataState(data);
    },
    [dispatch]
  );

  const saveData = useCallback(
    async (data: StockInventoryTable, periodId = period.id) => {
      const result = await asResultClass(
        sdk.addStockInventoryTable({
          periodId,
          clientCompanyId: clientId,
          account: +accountNumber,
          requestBody: data,
        })
      );
      if (result.err) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [accountNumber, clientId, dispatch, period.id, sdk]
  );

  const updateData = useCallback(
    async (data: StockInventoryTable, periodId = period.id) => {
      const result = await asResultClass(
        sdk.updateStockInventoryTable({
          periodId,
          clientCompanyId: clientId,
          account: +accountNumber,
          requestBody: data,
        })
      );
      if (result.err) {
        dispatch(addGlobalErrorMessage('error'));
      }
    },
    [accountNumber, clientId, dispatch, period.id, sdk]
  );

  const clearData = useCallback(() => {
    const defaultData = generateDefaultDataWithNEmptyRows(5);

    setIsLoading(true);
    setTableData(defaultData);
    updateData(defaultData).finally(() => {
      setIsLoading(false);
    });
  }, [setTableData, updateData]);

  useEffect(() => {
    setIsLoading(true);
    const defaultData = generateDefaultDataWithNEmptyRows(5);
    sdk
      .getStockInventoryTable({
        periodId: period.id,
        clientCompanyId: clientId,
        account: +accountNumber,
      })
      .then(
        (data) => {
          setTableData(data);
        },
        async (err) => {
          setTableData(defaultData);

          if (err.status === 404) {
            await saveData(defaultData);
          } else {
            await updateData(defaultData);
          }
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [
    accountNumber,
    clientId,
    period,
    saveData,
    sdk,
    setTableData,
    updateData,
  ]);

  const handleSavePdf = async () => {
    if (!customer || !printState) {
      return;
    }

    try {
      setIsSaving(true);
      const docName = `Lagerinventeringslista ${customer.name} ${period.start}.pdf`;

      await createAndAddDocument({
        name: docName,
        docType: 'accounting',
        footer: '',
        header: '',
        parts: ['lagerinventeringslista'],
        printState,
      });

      const messageKey =
        'vacationDebt.inventory.view.table.saveToPeriodDocuments.success';
      dispatch(addGlobalMessage('success', messageKey));
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setIsSaving(false);
    }
  };

  const handlePrint = () => {
    setTimeout(() => {
      window.print();
    });
  };

  const headerElement = useCallback(() => {
    return (
      <TableHeaderWrapper>
        <TableTitle>
          {formatMessage({
            id: `hidden.inventoryStock.title`,
          })}
        </TableTitle>
        {!openPreview && (
          <Buttons>
            <EditingIconButton
              value={isEditing}
              onClick={() => setEditing(!isEditing)}
            />
            <PrintButton
              onClick={() => setOpenPreview(true)}
              tooltip={formatMessage({
                id: `hidden.inventoryStock.print`,
              })}
            />
            <ResetContentButton onReset={() => clearData()} what="table" />
          </Buttons>
        )}
      </TableHeaderWrapper>
    );
  }, [clearData, formatMessage, isEditing, openPreview]);

  const copyFromPrevPeriod = () => {
    setIsLoading(true);
    if (previousPeriod) {
      sdk
        .getStockInventoryTable({
          periodId: previousPeriod.id,
          clientCompanyId: clientId,
          account: +accountNumber,
        })
        .then(async (data) => {
          setTableData(data);
          await updateData(data, period.id);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const copyToPeriod = (period: Period | null) => {
    setIsCopying(true);
    if (period) {
      sdk
        .getStockInventoryTable({
          periodId: period.id,
          clientCompanyId: clientId,
          account: +accountNumber,
        })
        .then(
          () => updateData(tableData, period.id),
          async (err) => {
            if (err.status === 404) {
              await saveData(tableData, period.id);
            } else {
              await updateData(tableData, period.id);
            }
          }
        )
        .finally(() => setIsCopying(false));
    }
  };

  const tableService: TableService = {
    onTableDataUpdate(data) {
      const newRows: StockInventoryTableRow[] = calculateTotalCellValue(
        data.rows
      ).map((row) => {
        const cells = Object.keys(row.cells ?? {}).reduce(
          (result, c) => {
            const value =
              stringValue(row.cells?.[c]) ?? numberValue(row.cells?.[c]);
            if (defaultColumnKeys.includes(c)) {
              result[c] = value;
            } else {
              result.additionalColumns[c] = value;
            }

            return result;
          },
          {
            additionalColumns: {},
          }
        );

        return {
          ...cells,
        } as StockInventoryTableRow;
      });

      const newTableData = {
        headers: data.columns?.map((column) => {
          if (defaultColumnKeys.includes(column.id)) {
            return {
              id: column.id,
              label: column.label ?? null,
            };
          }

          return {
            id: column.id,
            label: column.id,
          };
        }),
        rows: newRows,
      };

      setTableData(newTableData);
      updateData(newTableData);
    },
    onDefaultColumnLabelUpdate(oldColumn: string, newColumn: string) {
      const newTableData = {
        rows: tableData.rows,
        headers: tableData.headers.map((header) => {
          if (header.id === oldColumn) {
            return {
              id: header.id,
              label: newColumn,
            };
          }
          return header;
        }),
      };

      setTableData(newTableData);
      updateData(newTableData);
    },
  };

  return (
    <Wrapper>
      {openPreview && (
        <CommonPreviewModal
          handleShow={setOpenPreview}
          onPrint={handlePrint}
          isOpen={openPreview}
        >
          <InventoryStockTable
            isEditing={false}
            renderHeader={headerElement}
            tableData={tableData}
          />
        </CommonPreviewModal>
      )}
      <PeriodSummary
        date={formatPeriodToMonthAndYear(period)}
        periodIb={ib}
        periodSaldo={psaldo}
        periodUb={ub}
        saldo={saldo}
      />
      <TableWithCopyButtons>
        {isLoading ? (
          <LoadingContainer>
            <LoadingLogo size="medium" />
          </LoadingContainer>
        ) : (
          <TableWrapper>
            <InventoryStockTable
              isEditing={isEditing}
              renderHeader={headerElement}
              tableService={tableService}
              tableData={tableData}
            />
            <Button
              label={translate('vacationDebt.table.saveToPeriodDocuments')}
              onClick={() => handleSavePdf()}
              loading={isSaving}
              startIcon={<AttachIcon />}
            />
          </TableWrapper>
        )}
        <CopyButtons>
          <Button
            label={translate('inventoryStock.table.copyToNextPeriod')}
            variant="text"
            size="medium"
            onClick={() => copyToPeriod(nextPeriod)}
            disabled={isCopying || !nextPeriod || isLoading}
            loading={isCopying}
          />
          <Button
            label={translate('inventoryStock.table.copyFromPrevPeriod')}
            variant="text"
            size="medium"
            onClick={copyFromPrevPeriod}
            disabled={isCopying || !previousPeriod}
            loading={isLoading}
          />
          <Button
            label={translate('inventoryStock.table.copyToFinancialPeriod')}
            variant="text"
            size="medium"
            onClick={() => copyToPeriod(lastPeriod)}
            disabled={!lastPeriod || isLoading}
            loading={isCopying}
          />
        </CopyButtons>
      </TableWithCopyButtons>
    </Wrapper>
  );
};

export default InventoryStockView;
