import React, { useCallback } from 'react';
import { AgoyTableColumn, AgoyTableRow, Cell } from '@agoy/document';
import styled from '@emotion/styled';
import { StockInventoryTable } from '@agoy/api-sdk-core';
import ExpandableTable from '_shared/components/Table/ExpandableTable';
import {
  defaultColumnKeys,
  defaultColumnTypes,
  TableService,
  emptyTableService,
} from './types';
import { calculateTotalCellValue } from './utils';

type Props = {
  isEditing: boolean;
  renderHeader?: () => React.ReactElement;
  tableData: StockInventoryTable;
  tableService?: TableService;
};

const FooterRow = styled.div`
  font-weight: 600;
`;

const TableWrapper = styled.div`
  min-width: fit-content;
  width: 100%;

  & > * {
    min-width: 100%;
    width: 100%;
  }
`;

const InventoryStockTable = ({
  isEditing,
  renderHeader,
  tableData,
  tableService = emptyTableService,
}: Props) => {
  const mapColumns = useCallback(
    (rawData: StockInventoryTable): AgoyTableColumn[] => {
      return rawData.headers.map(({ id, label }) => {
        return {
          id,
          label: label ?? undefined,
        };
      });
    },
    []
  );

  const mapRows = useCallback(
    (rawData: StockInventoryTable): AgoyTableRow[] => {
      const recordObjectToCells = (record, keyToIgnore?) => {
        return Object.keys(record).reduce((acc: Record<string, Cell>, c) => {
          if (c !== keyToIgnore) {
            acc[c] = {
              type: defaultColumnTypes[c] ?? ('string' as const),
              value: record[c],
            };
          }

          return acc;
        }, {});
      };

      return calculateTotalCellValue(
        rawData.rows.map((row, index): AgoyTableRow => {
          const additionalColumns = recordObjectToCells(row.additionalColumns);
          const cells = recordObjectToCells(row, 'additionalColumns');

          return {
            id: `${index + 1}`,
            active: true,
            cells: {
              ...cells,
              ...additionalColumns,
            },
          };
        })
      );
    },
    []
  );

  const renderFooterRow = useCallback(() => {
    const summary = tableData.rows.reduce((sum, row) => {
      return sum + row.total;
    }, 0);

    return <FooterRow>Summa varulager: {summary}</FooterRow>;
  }, [tableData]);

  return (
    <TableWrapper>
      {renderHeader && renderHeader()}
      <ExpandableTable
        tableId="inventoryStock"
        initialData={mapRows(tableData)}
        isEditing={isEditing}
        initialColumns={mapColumns(tableData)}
        defaultColumnKeys={defaultColumnKeys}
        defaultColumnTypes={defaultColumnTypes}
        onDataUpdate={tableService.onTableDataUpdate}
        nonEditableRowColumns={['total']}
        initialColumnKeys={['total']}
        renderFooterRow={renderFooterRow}
        onDefaultColumnLabelUpdate={tableService.onDefaultColumnLabelUpdate}
      />
    </TableWrapper>
  );
};

export default InventoryStockTable;
