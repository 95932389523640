import React, { useContext } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';

import PropertyTaxPreviewPrint from '_tax/components/Taxes/PropertyTax/PropertyTaxPreviewPrint';
import PeriodDataContext from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/PeriodDataContext';

const Page = styled(({ children, ...props }) =>
  React.Children.count(children) > 0 ? <div {...props}>{children}</div> : null
)`
  page-break-before: always;
  width: 842px;

  @media screen {
    border-bottom: 1px dashed ${(props) => props.theme.palette.grey['500']};
    padding: 32px 32px;
    width: 864px;
  }
`;

interface TaxesPrintProps {
  pageNumbers: Record<string, number>;
}

const TaxesPrint = ({ pageNumbers }: TaxesPrintProps): JSX.Element | null => {
  const { path } = useRouteMatch();

  const { period } = useContext(PeriodDataContext);
  return (
    <Switch>
      <Route path={`${path}/fastighetsskatt`}>
        {period && (
          <Page>
            <PropertyTaxPreviewPrint />
          </Page>
        )}
      </Route>
    </Switch>
  );
};

export default TaxesPrint;
