import React, { useCallback, useState, useRef, useEffect } from 'react';

interface DownloadLinkProps {
  href: string;
  title: string;
  children?: React.ReactElement;
  autoDownload?: boolean;
  onDownloading?: (downloading: boolean, error: string | null) => void;
}

/**
 * Workaround for downloading content from another origin.
 *
 * @param Component an a element
 */
const Downloadlink: React.FC<DownloadLinkProps> = ({
  href,
  title,
  children = undefined,
  autoDownload = false,
  onDownloading = () => {},
  ...rest
}: DownloadLinkProps) => {
  const [data, setData] = useState<string | null>(null);
  const link = useRef<HTMLAnchorElement>(null);
  const download = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        onDownloading(true, null);
        const response = await fetch(href);
        if (response.ok) {
          const blob = await response.blob();
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            setData(reader.result as string);
            onDownloading(false, null);
          });
          reader.readAsDataURL(blob);
        } else {
          onDownloading(false, `${response.status}`);
        }
      } catch (error) {
        onDownloading(false, error.message);
      }
    },
    [href, onDownloading]
  );

  useEffect(() => {
    if (data !== null) {
      link.current?.click();
    }
  }, [data]);

  useEffect(() => {
    if (autoDownload) {
      link.current?.click();
    }
  }, [autoDownload]);

  useEffect(() => {
    setData(null);
  }, [href]);

  return (
    <a
      href={data || '#'}
      download={title}
      ref={link}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      onClick={data === null ? download : undefined}
    >
      {children}
    </a>
  );
};

export default Downloadlink;
