/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6901
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  multiply,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2101K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2101K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Resultatdisposition'),
  data: {
    active: true,
    MedelDisponeraAbstract: {
      type: 'group',
      active: true,
      title: field('Till årsstämmans förfogande står följande medel'),
      table: table<IxbrlCell>(
        'notes.note101.data.MedelDisponeraAbstract.table',
        {
          id: 'MedelDisponeraAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'MedelDisponeraAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'Overkursfond',
                'row',
                noteLabel('Fri överkursfond'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:Overkursfond',
                  standardRubrik: 'Fri överkursfond',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:Overkursfond',
                  standardRubrik: 'Fri överkursfond',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'FondVerkligtVarde',
                'row',
                noteLabel('Fond för verkligt värde'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FondVerkligtVarde',
                  standardRubrik: 'Fond för verkligt värde',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FondVerkligtVarde',
                  standardRubrik: 'Fond för verkligt värde',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'BalanseratResultat',
                'row',
                noteLabel('Balanserat resultat'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:BalanseratResultat',
                  standardRubrik: 'Balanserat resultat',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:BalanseratResultat',
                  standardRubrik: 'Balanserat resultat',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'AretsResultatEgetKapital',
                'row',
                noteLabel('Årets resultat'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AretsResultatEgetKapital',
                  standardRubrik: 'Periodens resultat i eget kapital',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AretsResultatEgetKapital',
                  standardRubrik: 'Periodens resultat i eget kapital',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'FrittEgetKapital',
            'sum',
            noteLabel('Medel att disponera'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note101.data.MedelDisponeraAbstract.table.MedelDisponeraAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:FrittEgetKapital',
                standardRubrik: 'Fritt eget kapital',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note101.data.MedelDisponeraAbstract.table.MedelDisponeraAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:FrittEgetKapital',
                standardRubrik: 'Fritt eget kapital',
                saldo: 'credit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
    },
    UtdelningBeslutadExtraBolagsstammaAbstract: {
      type: 'group',
      active: true,
      title: field('Utdelning beslutad på extra bolagsstämma'),
      table: table<IxbrlCell>(
        'notes.note101.data.UtdelningBeslutadExtraBolagsstammaAbstract.table',
        {
          id: 'UtdelningBeslutadExtraBolagsstammaAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'UtdelningBeslutadExtraBolagsstammaAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'UtdelningBeslutadExtraBolagsstamma',
                'row',
                noteLabel('Efterutdelning'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UtdelningBeslutadExtraBolagsstamma',
                  standardRubrik: 'Utdelning beslutad på extra bolagsstämma',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UtdelningBeslutadExtraBolagsstamma',
                  standardRubrik: 'Utdelning beslutad på extra bolagsstämma',
                  saldo: 'debit',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'FrittEgetKapitalEfterUtdelningBeslutadExtraBolagsstamma',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note101.data.MedelDisponeraAbstract.table.FrittEgetKapital.year'
                  ),
                  multiply(
                    -1,
                    id(
                      'notes.note101.data.UtdelningBeslutadExtraBolagsstammaAbstract.table.UtdelningBeslutadExtraBolagsstammaAbstract.UtdelningBeslutadExtraBolagsstamma.year'
                    )
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:FrittEgetKapitalEfterUtdelningBeslutadExtraBolagsstamma',
                standardRubrik:
                  'Fritt eget kapital efter beslutad utdelning på extra bolagsstämma',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note101.data.MedelDisponeraAbstract.table.FrittEgetKapital.previousYear'
                  ),
                  multiply(
                    -1,
                    id(
                      'notes.note101.data.UtdelningBeslutadExtraBolagsstammaAbstract.table.UtdelningBeslutadExtraBolagsstammaAbstract.UtdelningBeslutadExtraBolagsstamma.previousYear'
                    )
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:FrittEgetKapitalEfterUtdelningBeslutadExtraBolagsstamma',
                standardRubrik:
                  'Fritt eget kapital efter beslutad utdelning på extra bolagsstämma',
                saldo: 'credit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
    },
    ForslagDispositionAbstract: {
      type: 'group',
      active: true,
      title: field('Styrelsen föreslår att medlen disponeras enligt följande'),
      table: table<IxbrlCell>(
        'notes.note101.data.ForslagDispositionAbstract.table',
        {
          id: 'ForslagDispositionAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'ForslagDispositionAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows
                .addRowWithType(
                  'ForslagDispositionUtdelningSpecificationAbstract',
                  'header',
                  noteLabel('Förslag till utdelning'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'ForslagDispositionUtdelningAterbetalningVillkoratAktieagartillskott',
                    'row',
                    noteLabel('Återbetalning av villkorat aktieägartillskott'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'balans0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForslagDispositionUtdelningAterbetalningVillkoratAktieagartillskott',
                      standardRubrik:
                        'Förslag till återbetalning av villkorat aktieägartillskott',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'balans1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForslagDispositionUtdelningAterbetalningVillkoratAktieagartillskott',
                      standardRubrik:
                        'Förslag till återbetalning av villkorat aktieägartillskott',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'ForslagDispositionUtdelningAnnanUtdelning',
                    'row',
                    noteLabel('Annan utdelning'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'balans0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForslagDispositionUtdelningAnnanUtdelning',
                      standardRubrik:
                        'Förlag till annan utdelning än återbetalning av villkorat aktieägartillskottt',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'balans1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForslagDispositionUtdelningAnnanUtdelning',
                      standardRubrik:
                        'Förlag till annan utdelning än återbetalning av villkorat aktieägartillskottt',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'ForslagDispositionUtdelning',
                'row',
                noteLabel('Utdelning'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionUtdelning',
                  standardRubrik: 'Förslag till utdelning',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionUtdelning',
                  standardRubrik: 'Förslag till utdelning',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ForslagDispositionFondemission',
                'row',
                noteLabel('Fondemission'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionFondemission',
                  standardRubrik: 'Förslag till fondemission',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionFondemission',
                  standardRubrik: 'Förslag till fondemission',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ForslagDispositionAvsattningReservfond',
                'row',
                noteLabel('Avsättning till reservfond'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionAvsattningReservfond',
                  standardRubrik: 'Förslag till avsättning till reservfond',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionAvsattningReservfond',
                  standardRubrik: 'Förslag till avsättning till reservfond',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ForslagDispositionIanspraktagandeReservfond',
                'row',
                noteLabel('Ianspråktagande av reservfond'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionIanspraktagandeReservfond',
                  standardRubrik: 'Förslag till ianspråktagande av reservfond',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionIanspraktagandeReservfond',
                  standardRubrik: 'Förslag till ianspråktagande av reservfond',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ForslagDispositionIanspraktagandeBundenOverkursfond',
                'row',
                noteLabel('Ianspråktagande av bunden överkursfond'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionIanspraktagandeBundenOverkursfond',
                  standardRubrik:
                    'Förslag till ianspråktagande av bunden överkursfond',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionIanspraktagandeBundenOverkursfond',
                  standardRubrik:
                    'Förslag till ianspråktagande av bunden överkursfond',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ForslagDispositionIanspraktagandeUppskrivningsfond',
                'row',
                noteLabel('Ianspråktagande av uppskrivningsfond'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionIanspraktagandeUppskrivningsfond',
                  standardRubrik:
                    'Förslag till ianspråktagande av uppskrivningsfond',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionIanspraktagandeUppskrivningsfond',
                  standardRubrik:
                    'Förslag till ianspråktagande av uppskrivningsfond',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ForslagDispositionIanspraktagandeFondUtvecklingsutgifter',
                'row',
                noteLabel('Ianspråktagande av fond för utvecklingsutgifter'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionIanspraktagandeFondUtvecklingsutgifter',
                  standardRubrik:
                    'Förslag till ianspråktagande av fond för utvecklingsutgifter',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionIanspraktagandeFondUtvecklingsutgifter',
                  standardRubrik:
                    'Förslag till ianspråktagande av fond för utvecklingsutgifter',
                  negateValue: false,
                })
              );
              rows.addRowWithType(
                'ForslagDispositionBalanserasINyRakning',
                'row',
                noteLabel('Balanseras i ny räkning'),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionBalanserasINyRakning',
                  standardRubrik:
                    'Förslag till hantering av positivt (negativt) resultat som balanseras i ny räkning',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagDispositionBalanserasINyRakning',
                  standardRubrik:
                    'Förslag till hantering av positivt (negativt) resultat som balanseras i ny räkning',
                  negateValue: false,
                })
              );
              return rows.build();
            });
          rows.addRowWithType(
            'ForslagDisposition',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note101.data.ForslagDispositionAbstract.table.ForslagDispositionAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ForslagDisposition',
                standardRubrik: 'Medel som föreslås att disponeras',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note101.data.ForslagDispositionAbstract.table.ForslagDispositionAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:ForslagDisposition',
                standardRubrik: 'Medel som föreslås att disponeras',
                saldo: 'credit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
    },
    SpecifikationForslagUtdelningAktieAbstract: {
      type: 'group',
      active: true,
      title: field('Specifikation av förslag om utdelning per aktie'),
      SpecifikationForslagUtdelningAktieslagTuple: {
        type: 'group',
        active: true,
        title: field('Specifikation av förslag om utdelning per aktieslag'),
        table: table<IxbrlCell>(
          'notes.note101.data.SpecifikationForslagUtdelningAktieAbstract.SpecifikationForslagUtdelningAktieslagTuple.table',
          {
            id: 'ForslagUtdelningAktieslag',
            label: 'Aktieslag',
            dataType: 'text',
            active: true,
          },
          {
            id: 'ForslagUtdelningBeloppAktie',
            label: 'Utdelningsbelopp per aktie',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'ForslagUtdelningAvstamningsdag',
            label: 'Avstämningsdag',
            dataType: 'text',
            active: true,
          },
          {
            id: 'ForslagUtdelningTidpunktBetalning',
            label: 'Tidpunkt för betalning av utdelning',
            dataType: 'text',
            active: true,
          },
          {
            id: 'ForslagUtdelningTillgang',
            label: 'Typ av tillgång vid utdelning av annat än pengar',
            dataType: 'text',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'SpecifikationForslagUtdelningAktieslagTuple',
                'hidden',
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  '1',
                  'row',
                  ixbrlCell(value('Aktieslag'), {
                    type: 'stringItemType',
                    name: 'se-gen-base:ForslagUtdelningAktieslag',
                    contextRef: 'period0',
                    standardRubrik:
                      'Aktieslag som ingår i förslag till utdelning',
                    negateValue: false,
                    tuple: {
                      name: 'se-gaap-ext:SpecifikationForslagUtdelningAktieslagTuple',
                      id: `${rows.getBaseId()}.1.tuple`,
                      tupleID: `${rows.getBaseId()}.1.tuple`,
                    },
                    tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(), {
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForslagUtdelningBeloppAktie',
                    contextRef: 'period0',
                    standardRubrik:
                      'Utdelningsbelopp per aktie för förslag till utdelning',
                    negateValue: false,
                    tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(''), {
                    type: 'dateItemType',
                    name: 'se-gen-base:ForslagUtdelningAvstamningsdag',
                    contextRef: 'period0',
                    standardRubrik: 'Avstämningsdag för förslag till utdelning',
                    negateValue: false,
                    tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(value(''), {
                    type: 'dateItemType',
                    name: 'se-gen-base:ForslagUtdelningTidpunktBetalning',
                    contextRef: 'period0',
                    standardRubrik:
                      'Tidpunkt för betalning av utdelning för förslag till utdelning',
                    negateValue: false,
                    tupleRef: tupleRef(4, `${rows.getBaseId()}.1.tuple`),
                    previousYearTuple: false,
                  }),
                  ixbrlCell(
                    value('Typ av tillgång vid utdelning av annat än pengar'),
                    {
                      type: 'stringItemType',
                      name: 'se-gen-base:ForslagUtdelningTillgang',
                      contextRef: 'period0',
                      standardRubrik:
                        'Typ av tillgång vid utdelning av annat än pengar för förslag till utdelning',
                      negateValue: false,
                      tupleRef: tupleRef(5, `${rows.getBaseId()}.1.tuple`),
                      previousYearTuple: false,
                    }
                  )
                );
                return rows.build();
              });
            rows.newRowTemplateGenerator((_id, baseId) => ({
              id: _id,
              active: true,
              type: 'row',
              cells: {
                ForslagUtdelningAktieslag: ixbrlCell(value('Aktieslag'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:ForslagUtdelningAktieslag',
                  contextRef: 'period0',
                  standardRubrik:
                    'Aktieslag som ingår i förslag till utdelning',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:SpecifikationForslagUtdelningAktieslagTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                ForslagUtdelningBeloppAktie: ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForslagUtdelningBeloppAktie',
                  contextRef: 'period0',
                  standardRubrik:
                    'Utdelningsbelopp per aktie för förslag till utdelning',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                ForslagUtdelningAvstamningsdag: ixbrlCell(value(''), {
                  type: 'dateItemType',
                  name: 'se-gen-base:ForslagUtdelningAvstamningsdag',
                  contextRef: 'period0',
                  standardRubrik: 'Avstämningsdag för förslag till utdelning',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                ForslagUtdelningTidpunktBetalning: ixbrlCell(value(''), {
                  type: 'dateItemType',
                  name: 'se-gen-base:ForslagUtdelningTidpunktBetalning',
                  contextRef: 'period0',
                  standardRubrik:
                    'Tidpunkt för betalning av utdelning för förslag till utdelning',
                  negateValue: false,
                  tupleRef: tupleRef(4, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }),
                ForslagUtdelningTillgang: ixbrlCell(
                  value('Typ av tillgång vid utdelning av annat än pengar'),
                  {
                    type: 'stringItemType',
                    name: 'se-gen-base:ForslagUtdelningTillgang',
                    contextRef: 'period0',
                    standardRubrik:
                      'Typ av tillgång vid utdelning av annat än pengar för förslag till utdelning',
                    negateValue: false,
                    tupleRef: tupleRef(5, `${baseId}.${_id}.tuple`),
                    previousYearTuple: false,
                  }
                ),
              },
            }));
            return rows.build();
          })
          .build(),
      },
    },
    DispositionerVinstForlustKommentar: {
      type: 'group',
      active: true,
      title: field('Kommentar till resultatdisposition'),
      value: ixbrlField(field(''), {
        contextRef: 'balans0',
        type: 'stringItemType',
        name: 'se-gen-base:DispositionerVinstForlustKommentar',
        standardRubrik: 'Kommentar till resultatdisposition',
      }),
    },
    StyrelsensYttrandeVinstutdelning: {
      type: 'group',
      active: true,
      title: field('Styrelsens yttrande om vinstutdelning'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:StyrelsensYttrandeVinstutdelning',
        standardRubrik: 'Styrelsens yttrande om vinstutdelning',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2101K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        MedelDisponeraAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        UtdelningBeslutadExtraBolagsstammaAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        ForslagDispositionAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        SpecifikationForslagUtdelningAktieAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            SpecifikationForslagUtdelningAktieslagTuple: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                table: { type: 'table' as const },
              },
            },
          },
        },

        DispositionerVinstForlustKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        StyrelsensYttrandeVinstutdelning: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
