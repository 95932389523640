import React, {
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';

import { FinancialYear, Period } from '@agoy/api-sdk-core';
import { UserInput } from '_reconciliation/types';
import { PrintContext } from '_shared/HOC/withPrintContext';
import { AccountingBalances } from 'types/Accounting';
import SharpPDFToggler from '_shared/components/SharpPDFToggler';
import CommonPreviewModal from '_shared/components/CommonPreviewModal';
import Content from './Content';
import Header from './Header';
import { BalanceAndInput } from '../types';
import {
  createRowData,
  createUserInputData,
} from '../HiddenRow/HiddenGroupRow/utils';

const TopbarActions = styled.div`
  display: flex;
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  @media print {
    display: none;
  }
`;

type Props = {
  open: boolean;
  handleToggleModal: () => void;
  reload: () => void;
  checked: boolean;
  period: Period;
  financialYear: FinancialYear;
  accountingBalances: AccountingBalances;
  userInputData: UserInput;
  actualFinancialYear: string;
  balanceAndInput: BalanceAndInput[];
};

const PrintSpecifications = ({
  open,
  reload,
  checked,
  handleToggleModal,
  period,
  actualFinancialYear,
  financialYear,
  accountingBalances,
  userInputData,
  balanceAndInput,
}: Props): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const { setHideMainContent } = useContext(PrintContext);
  const [loading, setLoading] = useState(1);
  const [documentsToLoad, setDocumentsToLoad] = useState(0);
  const [documentsToPrint, setDocumentsToPrint] = useState(0);

  const handleDocumentsToLoad = (documents: number) => {
    setDocumentsToLoad((prev) => prev + documents);
  };

  const handleDocumentsToPrint = (documents: number) => {
    setDocumentsToPrint((prev) => prev + documents);
  };

  const accounts = useMemo(
    () =>
      Object.keys(userInputData)
        .filter((key) => {
          if (key.startsWith('account')) {
            const accountData = accountingBalances.accounts.find(
              (a) => a.number === key.substring(7)
            );

            return (
              userInputData[key][period.id] &&
              Object.keys(userInputData[key][period.id]).length > 0 &&
              accountData
            );
          }
          return false;
        })
        .map((key) => key.substring(7))
        .sort(),
    [accountingBalances.accounts, period.id, userInputData]
  );

  const loaded = useCallback(() => {
    setLoading((currentLoading) => currentLoading - 1);
  }, []);

  useEffect(() => {
    setHideMainContent(open);
  }, [open, setHideMainContent]);

  useEffect(() => {
    if (open) {
      setLoading(documentsToLoad);
    } else {
      setLoading(0);
      setDocumentsToLoad(0);
      setDocumentsToPrint(0);
    }
  }, [open, documentsToLoad]);

  const handleChange = () => {
    reload();
  };

  return (
    <CommonPreviewModal
      handleShow={handleToggleModal}
      onPrint={() => window.print()}
      isOpen={open}
      loading={loading > 0}
    >
      <section>
        <TopbarActions>
          <LoadingContainer>
            {loading > 0 &&
              formatMessage(
                {
                  id: 'print.periodSpecification.loading',
                },
                {
                  value: documentsToPrint - documentsToLoad,
                  valueOf: documentsToPrint,
                }
              )}
          </LoadingContainer>
          <ButtonWrapper>
            <SharpPDFToggler checked={checked} onChange={handleChange} />
          </ButtonWrapper>
        </TopbarActions>
        {open && (
          <>
            <Header actualFinancialYear={actualFinancialYear} period={period} />
            {accounts.map((account) => {
              const userData = userInputData[`account${account}`];
              const accountInformation = accountingBalances?.accounts.find(
                (a) => a.number === account
              );
              if (!accountInformation) {
                return null;
              }

              const yearBalance = accountInformation.balances[financialYear.id];

              const rowData = createRowData(
                accountingBalances.periods,
                yearBalance,
                accountInformation.periods
              );

              const data = createUserInputData(rowData, userData);

              return (
                <Content
                  userData={data[period.id]}
                  period={period}
                  financialYear={financialYear}
                  specification={data[period.id].specification}
                  accountInformation={accountInformation}
                  loaded={loaded}
                  key={`print_${account}`}
                  balanceAndInput={balanceAndInput.find(
                    (bi) => bi.number === Number(account)
                  )}
                  handleDocumentsToLoad={handleDocumentsToLoad}
                  handleDocumentsToPrint={handleDocumentsToPrint}
                />
              );
            })}
          </>
        )}
      </section>
    </CommonPreviewModal>
  );
};

export default PrintSpecifications;
