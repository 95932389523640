import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid, Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { referenceNumberValue, stringValue } from '@agoy/document';
import { ccyFormat } from '@agoy/common';
import {
  AnnualGeneralMeeting,
  Signatures,
  Settings,
} from '@agoy/annual-report-document';
import { BOARD_LABELS } from '@agoy/annual-report-view/src/types/enums';
import PreviewSection from '../../UI/PreviewSection';
import Paragraph from './Paragraph';
import VotingList from './VotingList';
import Fee from './Fee';
import BoardMembersParagraph from './BoardMembersParagraph';
import AuditorsParagraph from './AuditorsParagraph';
import CustomParagraph from './CustomParagraph';
import ResultDisposition from './ResultDisposition/ResultDisposition';
import { mapAdjusterNames, hasAdjuster } from './utils';
import Page from '../../UI/Print/Page';

const TypographyPreview = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
`;

const GridItem = styled(Grid)`
  margin-top: ${(props) => props.theme.spacing(8)}px;
`;

const SignatureBox = styled(Box)`
  page-break-inside: avoid;
`;

type AnnualGeneralMeetingPreviewProps = {
  annualGeneralMeeting: AnnualGeneralMeeting | undefined;
  settings: Settings;
  signatures: Signatures | undefined;
};

const AnnualGeneralMeetingPreview = ({
  annualGeneralMeeting,
  settings,
  signatures,
}: AnnualGeneralMeetingPreviewProps): JSX.Element | null => {
  const { formatMessage } = useIntl();

  // STATE
  const currentFinancialYear = stringValue(settings.section.period);

  if (annualGeneralMeeting == null || signatures == null) {
    return null;
  }

  const { isAuditReportRequired } = settings.section;

  const ceo = signatures.section.people.rows?.find((row) =>
    stringValue(row.cells?.role)?.includes(BOARD_LABELS.CEO)
  );

  let paragraph = 1;

  const chairman = annualGeneralMeeting.main.votingList.rows.find(
    (row) =>
      stringValue(row.cells?.role) === 'chairman' ||
      stringValue(row.cells?.role) === 'chairman_protocol' ||
      stringValue(row.cells?.role) === 'chairmanAndRecordKeeper'
  );
  const recordKeeper = annualGeneralMeeting.main.votingList.rows.find(
    (row) =>
      stringValue(row.cells?.role) === 'recordKeeper' ||
      stringValue(row.cells?.role) === 'chairmanAndRecordKeeper'
  );
  const adjuster = annualGeneralMeeting.main.votingList.rows.filter(
    (row) => stringValue(row.cells?.role) === 'adjuster'
  );

  if (!currentFinancialYear) {
    return null;
  }

  const result =
    annualGeneralMeeting.main.result.type === 'ref' &&
    ccyFormat(referenceNumberValue(annualGeneralMeeting.main.result));

  const defaultHeadingText = formatMessage(
    {
      id: `annualReport.annualGeneralMeeting.main.header`,
    },
    {
      clientName: stringValue(settings.section.clientName),
      organisationNumber: stringValue(settings.section.organisationNumber),
    }
  );

  const HeadingLocation =
    annualGeneralMeeting.main.location.value !== ''
      ? `, ${stringValue(annualGeneralMeeting.main.location)}`
      : '';

  const HeadingDate =
    annualGeneralMeeting.main.date.value !== ''
      ? `, ${stringValue(annualGeneralMeeting.main.date)}`
      : '';

  const headingText =
    stringValue(annualGeneralMeeting.main.headingText) ||
    `${defaultHeadingText}${HeadingLocation}${HeadingDate}.`;

  const votingListText =
    stringValue(annualGeneralMeeting.main.votingListText) ||
    formatMessage({
      id: 'annualReport.annualGeneralMeeting.main.votingList.text2',
    });

  const { periodStart, periodEnd } = settings.section;
  const finYearStart = stringValue(periodStart);
  const finYearEnd = stringValue(periodEnd);

  const shortFinancialYear =
    finYearStart?.endsWith('-01-01') && finYearEnd?.endsWith('-12-31')
      ? finYearEnd?.substring(0, 4)
      : `${finYearStart?.substring(0, 4)}/${finYearEnd?.substring(0, 4)}`;

  return (
    <Page>
      <PreviewSection id="annualGeneralMeeting">
        <Typography variant="h1">
          {stringValue(annualGeneralMeeting.main.heading) ||
            formatMessage({ id: 'annualReport.menu.annualGeneralMeeting' })}
        </Typography>

        <TypographyPreview>{headingText}</TypographyPreview>

        {/* Voting list */}
        <Paragraph
          no={paragraph++}
          id="votingList"
          headingId="annualGeneralMeeting.main.votingListHeading"
          heading={
            stringValue(annualGeneralMeeting.main.votingListHeading) ||
            formatMessage({
              id: `annualReport.annualGeneralMeeting.main.votingList`,
            })
          }
          optionalComment
          defaultText={formatMessage({
            id: `comment`,
          })}
          textId="annualGeneralMeeting.main.votingListHeadingText"
          text={stringValue(annualGeneralMeeting.main.votingListHeadingText)}
          isPreview
        />
        <VotingList
          votingList={annualGeneralMeeting.main.votingList}
          fullId="annualGeneralMeeting.main.votingList"
          print
        />
        <TypographyPreview>{votingListText}</TypographyPreview>

        {/* Chairman */}
        <Paragraph
          no={paragraph++}
          id="2"
          headingId="annualGeneralMeeting.main.chairmanHeading"
          heading={
            stringValue(annualGeneralMeeting.main.chairmanHeading) ||
            formatMessage({
              id: `annualReport.annualGeneralMeeting.main.2`,
            })
          }
          textId="annualGeneralMeeting.main.chairmanHeadingText"
          text={stringValue(annualGeneralMeeting.main.chairmanHeadingText)}
          defaultText={formatMessage(
            {
              id: `annualReport.annualGeneralMeeting.main.2.text`,
            },
            { name: stringValue(chairman?.cells?.name) }
          )}
          isPreview
        />

        {/* Record Keeper */}
        <Paragraph
          no={paragraph++}
          id="3"
          headingId="annualGeneralMeeting.main.recordKeeperHeading"
          heading={
            stringValue(annualGeneralMeeting.main.recordKeeperHeading) ||
            formatMessage({
              id: `annualReport.annualGeneralMeeting.main.3`,
            })
          }
          textId="annualGeneralMeeting.main.recordKeeperHeadingText"
          text={stringValue(annualGeneralMeeting.main.recordKeeperHeadingText)}
          defaultText={formatMessage(
            {
              id: `annualReport.annualGeneralMeeting.main.3.text`,
            },
            {
              name: stringValue(recordKeeper?.cells?.name),
              role: stringValue(recordKeeper?.cells?.role),
            }
          )}
          isPreview
        />

        {/* Adjuster */}
        {hasAdjuster(adjuster) && (
          <Paragraph
            no={paragraph++}
            id="4"
            headingId="annualGeneralMeeting.main.adjusterHeading"
            heading={
              stringValue(annualGeneralMeeting.main.adjusterHeading) ||
              formatMessage({
                id: `annualReport.annualGeneralMeeting.main.4`,
              })
            }
            textId="annualGeneralMeeting.main.adjusterHeadingText"
            text={stringValue(annualGeneralMeeting.main.adjusterHeadingText)}
            defaultText={formatMessage(
              {
                id: `annualReport.annualGeneralMeeting.main.4.text`,
              },
              { name: mapAdjusterNames(adjuster) }
            )}
            isPreview
          />
        )}

        {/* Notice and Agenda */}
        <Paragraph
          no={paragraph++}
          id="5"
          headingId="annualGeneralMeeting.main.noticeAndAgendaHeading"
          heading={
            stringValue(annualGeneralMeeting.main.noticeAndAgendaHeading) ||
            formatMessage({
              id: `annualReport.annualGeneralMeeting.main.5`,
            })
          }
          textId="annualGeneralMeeting.main.noticeAndAgendaHeadingText"
          text={stringValue(
            annualGeneralMeeting.main.noticeAndAgendaHeadingText
          )}
          defaultText={formatMessage({
            id: `annualReport.annualGeneralMeeting.main.5.text`,
          })}
          isPreview
        />

        {/* Auditor report */}
        <Paragraph
          no={paragraph++}
          id="6"
          headingId="annualGeneralMeeting.main.auditorReportHeading"
          heading={
            stringValue(annualGeneralMeeting.main.auditorReportHeading) ||
            formatMessage(
              {
                id: `annualReport.annualGeneralMeeting.main.6`,
              },
              { auditor: !!isAuditReportRequired.value }
            )
          }
          textId="annualGeneralMeeting.main.auditorReportHeadingText"
          text={stringValue(annualGeneralMeeting.main.auditorReportHeadingText)}
          defaultText={formatMessage(
            {
              id: `annualReport.annualGeneralMeeting.main.6.text`,
            },
            {
              auditor: !!isAuditReportRequired.value,
              year: shortFinancialYear,
            }
          )}
          isPreview
        />

        {/* Results and balance sheet */}
        <Paragraph
          no={paragraph++}
          id="7"
          headingId="annualGeneralMeeting.main.resultAndBalanceHeading"
          heading={
            stringValue(annualGeneralMeeting.main.resultAndBalanceHeading) ||
            formatMessage({
              id: `annualReport.annualGeneralMeeting.main.7`,
            })
          }
          textId="annualGeneralMeeting.main.resultAndBalanceHeadingText"
          text={stringValue(
            annualGeneralMeeting.main.resultAndBalanceHeadingText
          )}
          defaultText={formatMessage({
            id: `annualReport.annualGeneralMeeting.main.7.text`,
          })}
          isPreview
        />

        {/* Result Disposition */}
        <ResultDisposition
          no={paragraph++}
          annualGeneralMeeting={annualGeneralMeeting}
          result={result}
          print
          isPreview
        />

        {/* Discharge */}
        <Paragraph
          no={paragraph++}
          id="9"
          headingId="annualGeneralMeeting.main.dischargeHeading"
          heading={
            stringValue(annualGeneralMeeting.main.dischargeHeading) ||
            formatMessage({
              id: `annualReport.annualGeneralMeeting.main.9`,
            })
          }
          textId="annualGeneralMeeting.main.dischargeHeadingText"
          text={stringValue(annualGeneralMeeting.main.dischargeHeadingText)}
          defaultText={formatMessage(
            {
              id: `annualReport.annualGeneralMeeting.main.9.text`,
            },
            {
              ceo: ceo ? 'true' : 'false',
              year:
                currentFinancialYear
                  .replace(/[\r\n]+/gm, '')
                  .replace(/ /g, '') || '',
            }
          )}
          isPreview
        />

        {/* Fee */}
        <Fee
          fee={stringValue(annualGeneralMeeting.main.fee) || ''}
          heading={stringValue(annualGeneralMeeting?.main.feeHeading)}
          text={stringValue(annualGeneralMeeting?.main.feeHeadingText)}
          print
          no={paragraph++}
          isPreview
        />

        {/* Board members */}
        <BoardMembersParagraph
          no={paragraph++}
          table={annualGeneralMeeting.main.boardMembers}
          heading={stringValue(annualGeneralMeeting.main.boardMembersHeading)}
          text={stringValue(annualGeneralMeeting.main.boardMembersHeadingText)}
          print
          isPreview
        />

        {/* Auditors */}
        <AuditorsParagraph
          no={paragraph++}
          annualGeneralMeeting={annualGeneralMeeting}
          print
          isPreview
        />

        {/* Custom paragraphs */}
        {annualGeneralMeeting.main.customParagraphs.rows.map((p) => (
          <CustomParagraph
            key={p.id}
            no={paragraph++}
            id={`annualGeneralMeeting.main.customParagraphs.${p.id}`}
            heading={stringValue(p.cells?.heading) || ''}
            text={stringValue(p.cells?.text) || ''}
            print
            isPreview
          />
        ))}

        {/* Termination */}
        <Paragraph
          no={paragraph++}
          id="13"
          headingId="annualGeneralMeeting.main.terminationHeading"
          heading={
            stringValue(annualGeneralMeeting.main.terminationHeading) ||
            formatMessage({
              id: `annualReport.annualGeneralMeeting.main.13`,
            })
          }
          // now the default comment always appears for this section
          defaultText={formatMessage({
            id: `annualReport.annualGeneralMeeting.main.13.defaultComment`,
          })}
          textId="annualGeneralMeeting.main.terminationHeadingText"
          text={stringValue(annualGeneralMeeting.main.terminationHeadingText)}
          isPreview
        />
        <SignatureBox>
          <Grid container>
            <GridItem item xs={4}>
              {chairman?.cells?.name && (
                <>
                  <Typography>{stringValue(chairman?.cells?.name)}</Typography>
                  <TypographyPreview variant="caption">
                    {formatMessage({
                      id: 'annualReport.annualGeneralMeeting.role.chairman',
                    })}
                  </TypographyPreview>
                </>
              )}
            </GridItem>
            {chairman !== recordKeeper && (
              <GridItem item xs={4}>
                <Typography>
                  {stringValue(recordKeeper?.cells?.name)}
                </Typography>
                <TypographyPreview variant="caption">
                  {formatMessage({
                    id: 'annualReport.annualGeneralMeeting.role.recordKeeper',
                  })}
                </TypographyPreview>
              </GridItem>
            )}
            {adjuster &&
              adjuster.map((person) => (
                <GridItem item xs={4} key={person.id}>
                  <Typography>{stringValue(person.cells?.name)}</Typography>
                  <TypographyPreview variant="caption">
                    {formatMessage({
                      id: 'annualReport.annualGeneralMeeting.role.adjuster',
                    })}
                  </TypographyPreview>
                </GridItem>
              ))}
          </Grid>
        </SignatureBox>
      </PreviewSection>
    </Page>
  );
};

export default AnnualGeneralMeetingPreview;
