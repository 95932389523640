import React from 'react';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import LagerjusteringTable from './LagerjusteringTable';
import { InventoryObsolescenceDataType } from './types';

type Props = {
  data: InventoryObsolescenceDataType | undefined;
  ub: number | undefined;
  isPrint: boolean;
};

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableTitle = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 500;
  color: ${(props) => props.theme.palette.accountingView.headers.main};
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
`;

const LagerjusteringTablePreview = ({ ub, data, isPrint }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <TableHeaderWrapper>
        <TableTitle>
          {formatMessage({
            id: `hidden.lagerjustering.view`,
          })}
        </TableTitle>
      </TableHeaderWrapper>
      <TableWrapper>
        <LagerjusteringTable
          ub={ub}
          data={data}
          isEditing={false}
          isPrint={isPrint}
        />
      </TableWrapper>
    </Wrapper>
  );
};

export default LagerjusteringTablePreview;
