import React from 'react';
import {
  AnnualReportVersion,
  IncomeStatement,
  Settings,
  StatementSection,
} from '@agoy/annual-report-document';
import { IncomeStatementPage } from '@agoy/annual-report-view';
import PreviewSection from '../../UI/PreviewSection';
import Page from '../../UI/Print/Page';

interface IncomeStatementPreviewProps {
  incomeStatement: IncomeStatement | StatementSection;
  settings: Settings;
  version: AnnualReportVersion;
}

const IncomeStatementPreview = ({
  incomeStatement,
  settings,
  version,
}: IncomeStatementPreviewProps): JSX.Element => {
  return (
    <Page>
      <PreviewSection id="incomeStatement">
        <IncomeStatementPage
          incomeStatement={incomeStatement}
          settings={settings}
          version={version}
        />
      </PreviewSection>
    </Page>
  );
};

export default IncomeStatementPreview;
