import React, { useContext } from 'react';
import useObservable from 'utils/useObservable';
import AnnualReportDataServiceContext from '_annual-report/service/AnnualReportDataServiceContext';
import ConfirmationCertificatePreview from './Parts/ConfirmationCertificate/ConfirmationCertificatePreview';
import Page from './UI/Print/Page';
import useAnnualReport from './useAnnualReport';
import useDocumentConfiguration from './useDocumentConfiguration';

const ConfirmationCertificatePrint = (): JSX.Element | null => {
  const annualReport = useAnnualReport();
  const documentConfiguration = useDocumentConfiguration();
  const service = useContext(AnnualReportDataServiceContext);

  const confirmationCertificateDocument = useObservable(
    service.confirmationCertificate?.report
  );
  const annualGeneralMeetingDocument = useObservable(
    service.annualGeneralMeeting?.report
  );

  if (!annualReport || !documentConfiguration) {
    return null;
  }

  const confirmationCertificate =
    'confirmationCertificate' in annualReport
      ? annualReport.confirmationCertificate
      : confirmationCertificateDocument?.confirmationCertificate;

  const annualGeneralMeeting =
    'annualGeneralMeeting' in annualReport &&
    documentConfiguration.version === '1'
      ? annualReport.annualGeneralMeeting
      : annualGeneralMeetingDocument?.annualGeneralMeeting;

  if (!confirmationCertificate || !annualGeneralMeeting) {
    return null;
  }

  return (
    <Page>
      <ConfirmationCertificatePreview
        documentConfiguration={documentConfiguration}
        confirmationCertificate={confirmationCertificate}
        annualGeneralMeeting={annualGeneralMeeting}
        settings={annualReport.settings}
      />
    </Page>
  );
};

export default ConfirmationCertificatePrint;
