import React, { useContext } from 'react';
import PrintStateContext from '_annual-report/components/AnnualReportView/PrintStateContext';
import PeriodDataContext from '_reconciliation/components/ReconciliationView/HiddenRow/Rows/PeriodDataContext';

type TaxesPrintProviderProps = React.PropsWithChildren<{}>;

const TaxesPrintProvider = ({ children }: TaxesPrintProviderProps) => {
  const { state } = useContext(PrintStateContext);

  if (!state.periodData) {
    // eslint-disable-next-line no-console
    console.log('State is missing periodData');
    return null;
  }

  const {
    nextPeriodFinancialYear = null,
    nextPeriod = null,
    nextPeriodLocked = null,
    nextGroupedPeriods = null,
    previousPeriodFinancialYear = null,
    previousPeriod = null,
    previousPeriodLocked = null,
    previousGroupedPeriods = null,
    periodLocked = false,
    lastPeriodLocked = false,
    groupedPeriods = [],
    clientId = '',
    financialYear,
    period,
    lastPeriod,
    periodType,
    parentPeriodType,
    yearEndPeriod,
  } = state.periodData;

  const periodDataContext = {
    getPeriodUserInput: () => Promise.resolve(null),
    nextPeriodFinancialYear,
    nextPeriod,
    nextPeriodLocked,
    nextGroupedPeriods,
    previousPeriodFinancialYear,
    previousPeriod,
    previousPeriodLocked,
    previousGroupedPeriods,
    periodLocked,
    lastPeriodLocked,
    groupedPeriods,
    clientId,
    financialYear,
    period,
    lastPeriod,
    periodType,
    parentPeriodType,
    yearEndPeriod,
  };

  return (
    <PeriodDataContext.Provider value={periodDataContext}>
      {children}
    </PeriodDataContext.Provider>
  );
};

export default TaxesPrintProvider;
