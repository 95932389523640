/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6767 (Former 414)
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2047K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2047K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Fordringar hos koncernföretag'),
  data: {
    active: true,
    FordringarKoncernforetagLangfristigaAbstract: {
      type: 'group',
      active: true,
      title: field('Fordringar hos koncernföretag'),
      table: table<IxbrlCell>(
        'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table',
        {
          id: 'FordringarKoncernforetagLangfristigaAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'FordringarKoncernforetagLangfristigaAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'FordringarKoncernforetagLangfristigaAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1320:1327+1329', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FordringarKoncernforetagLangfristigaAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden långfristiga fordringar hos koncernföretag',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1320:1327+1329', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FordringarKoncernforetagLangfristigaAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden långfristiga fordringar hos koncernföretag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenTillkommandeFordringar',
                    'row',
                    noteLabel('Tillkommande fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenTillkommandeFordringar',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens tillkommande långfristiga fordringar på koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenTillkommandeFordringar',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med periodens tillkommande långfristiga fordringar på koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenRorelseforvarv',
                    'row',
                    noteLabel('Rörelseförvärv'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för långfristiga fordringar hos koncernföretag genom rörelseförvärv',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenRorelseforvarv',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för långfristiga fordringar hos koncernföretag genom rörelseförvärv',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för långfristiga fordringar hos koncernföretag genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för långfristiga fordringar hos koncernföretag genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenRegleradeFordringar',
                    'row',
                    noteLabel('Reglerade fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenRegleradeFordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens reglerade långfristiga fordringar hos koncernföretag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenRegleradeFordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med periodens reglerade långfristiga fordringar hos koncernföretag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenBortskrivnafordringar',
                    'row',
                    noteLabel('Bortskrivna fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med bortskrivning av långfristiga fordringar hos koncernföretag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med bortskrivning av långfristiga fordringar hos koncernföretag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenAretsOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenAretsOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenAretsOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'FordringarKoncernforetagLangfristigaAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table.FordringarKoncernforetagLangfristigaAbstract.FordringarKoncernforetagLangfristigaAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table.FordringarKoncernforetagLangfristigaAbstract.FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FordringarKoncernforetagLangfristigaAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden långfristiga fordringar hos koncernföretag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table.FordringarKoncernforetagLangfristigaAbstract.FordringarKoncernforetagLangfristigaAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table.FordringarKoncernforetagLangfristigaAbstract.FordringarKoncernforetagLangfristigaForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FordringarKoncernforetagLangfristigaAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden långfristiga fordringar hos koncernföretag',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'FordringarKoncernforetagLangfristigaNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1328', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FordringarKoncernforetagLangfristigaNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar långfristiga fordringar hos koncernföretag',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1328', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:FordringarKoncernforetagLangfristigaNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar långfristiga fordringar hos koncernföretag',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'FordringarKoncernforetagLangfristigaForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändringar av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringNedskrivningarRegleradeFordringar',
                    'row',
                    noteLabel('Reglerade fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarRegleradeFordringar',
                      standardRubrik:
                        'Återförda nedskrivningar avseende reglerade långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarRegleradeFordringar',
                      standardRubrik:
                        'Återförda nedskrivningar avseende reglerade långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för långfristiga fordringar hos koncernföretag genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för långfristiga fordringar hos koncernföretag genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringNedskrivningarBortskrivnafordringar',
                    'row',
                    noteLabel('Bortskrivna fordringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av nedskrivningar i samband med bortskrivning av långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarBortskrivnafordringar',
                      standardRubrik:
                        'Minskning av nedskrivningar i samband med bortskrivning av långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringNedskrivningarAretsOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarAretsOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarAretsOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av långfristiga fordringar hos koncernföretag',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av långfristiga fordringar hos koncernföretag',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'FordringarKoncernforetagLangfristigaForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:FordringarKoncernforetagLangfristigaForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av långfristiga fordringar hos koncernföretag',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'FordringarKoncernforetagLangfristigaNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table.FordringarKoncernforetagLangfristigaAbstract.FordringarKoncernforetagLangfristigaNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table.FordringarKoncernforetagLangfristigaAbstract.FordringarKoncernforetagLangfristigaForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FordringarKoncernforetagLangfristigaNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar långfristiga fordringar hos koncernföretag',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table.FordringarKoncernforetagLangfristigaAbstract.FordringarKoncernforetagLangfristigaNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table.FordringarKoncernforetagLangfristigaAbstract.FordringarKoncernforetagLangfristigaForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:FordringarKoncernforetagLangfristigaNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar långfristiga fordringar hos koncernföretag',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'FordringarKoncernforetagLangfristiga',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table.FordringarKoncernforetagLangfristigaAbstract.FordringarKoncernforetagLangfristigaAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table.FordringarKoncernforetagLangfristigaAbstract.FordringarKoncernforetagLangfristigaNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:FordringarKoncernforetagLangfristiga',
                standardRubrik: 'Långfristiga fordringar hos koncernföretag',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table.FordringarKoncernforetagLangfristigaAbstract.FordringarKoncernforetagLangfristigaAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note47.data.FordringarKoncernforetagLangfristigaAbstract.table.FordringarKoncernforetagLangfristigaAbstract.FordringarKoncernforetagLangfristigaNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:FordringarKoncernforetagLangfristiga',
                standardRubrik: 'Långfristiga fordringar hos koncernföretag',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotFordringarKoncernforetagLangfristigaKommentar: {
        type: 'group',
        active: true,
        title: field(
          'Kommentar till specifikation av fordringar hos koncernföretag'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotFordringarKoncernforetagLangfristigaKommentar',
          standardRubrik:
            'Kommentar till specifikation av fordringar hos koncernföretag',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2047K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        FordringarKoncernforetagLangfristigaAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotFordringarKoncernforetagLangfristigaKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
