import {
  Subject,
  ReplaySubject,
  BehaviorSubject,
  skip,
  throttleTime,
  asyncScheduler,
} from 'rxjs';
import {
  AccountResolver,
  AgoyDocument,
  AgoyDocumentStructure,
  GenericDocumentDataService,
  NoAccountResolver,
  TimePeriod,
} from '@agoy/document';
import { ApiSdk, asResultClass } from 'api-sdk';
import ExternalDocumentValuesResolver from '_shared/services/document/ExternalDocumentValuesResolver';
import WebRelatedClientResolver from './WebRelatedClientResolver';
import { NotificationService } from '../Notifications/types';

type CreateParameters<T extends AgoyDocumentStructure> = {
  structure: T;

  initialDocument: AgoyDocument<T>;

  defaultPeriod: TimePeriod;

  clientId: string;

  accountResolver?: AccountResolver;

  /**
   * Function that returns the documentId
   * @returns
   */
  findDocument: () => Promise<string>;

  /**
   * Readonly is for the support users.
   */
  readonly: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const createGenericDocumentDataService = <
  T extends AgoyDocumentStructure
>(
  sdk: ApiSdk,
  notificationsService: NotificationService | null,
  {
    structure,
    initialDocument,
    clientId,
    defaultPeriod,
    findDocument,
    accountResolver,
    readonly,
  }: CreateParameters<T>
): Subject<GenericDocumentDataService<T>> => {
  const dataService = new ReplaySubject<GenericDocumentDataService<T>>(1);

  findDocument().then(async (documentId) => {
    if (dataService.closed) {
      // Ignore this response, the creation was interrupted before
      // the response was received.
      return;
    }

    const changes = await asResultClass(
      sdk.getAgoyDocumentChanges({ documentId, clientId })
    );

    if (changes.err) {
      dataService.error(changes.err);
      return;
    }

    if (dataService.closed) {
      // Ignore this response
      return;
    }

    const externalDocumentValuesResolver = new ExternalDocumentValuesResolver(
      sdk,
      clientId,
      documentId,
      defaultPeriod,
      readonly
    );

    const relatedClientResolver = new WebRelatedClientResolver(
      sdk,
      clientId,
      {},
      defaultPeriod.value
    );

    const changesSubject = new BehaviorSubject(changes.val);

    const service = new GenericDocumentDataService(
      structure,
      initialDocument,
      clientId,
      documentId,
      defaultPeriod,
      accountResolver ?? NoAccountResolver,
      externalDocumentValuesResolver,
      relatedClientResolver,
      changesSubject
    );

    // Add a subscription to store the new changes.
    const changesSubscription = readonly
      ? undefined
      : service.changes
          .pipe(
            // Skip the first changes as this is the first loaded changes
            skip(1),
            // Store changes after 2 seconds
            throttleTime(2000, asyncScheduler, { trailing: true })
          )
          .subscribe((newChanges) =>
            sdk
              .addAgoyDocumentChanges({
                documentId,
                clientId,
                requestBody: newChanges,
              })
              .catch((err) => {
                dataService.error(err);
              })
          );

    const notificationsSubscription = notificationsService?.subscribe(
      {
        clientId,
        topic: 'document-values-updated',
      },
      (result) => {
        if (result.ok && result.val.topic === 'document-values-updated') {
          // update document values
          externalDocumentValuesResolver.refreshValues(result.val.documentId);
        }
      }
    );

    // Add the subscriptions to the service so we unsubscribe to them
    // when the service is disposed.
    if (changesSubscription) {
      service.subscriptions.push(changesSubscription);
    }
    if (notificationsSubscription) {
      service.subscriptions.push(notificationsSubscription);
    }

    // Service ready for usage.
    dataService.next(service);

    // No more services will come here.
    dataService.complete();
  });

  return dataService;
};
