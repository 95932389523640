export const STARTED_SAVING_USER_DATA =
  'accounting-view/STARTED_SAVING_USER_DATA';
export const SAVING_USER_DATA_FAILED =
  'accounting-view/SAVING_USER_DATA_FAILED';
export const SAVING_USER_DATA_SUCCESS =
  'accounting-view/SAVING_USER_DATA_SUCCESS';

export const SET_USER_INPUT_DATA = 'accounting-view/SET_USER_INPUT_DATA';
export const SET_ROUTINES_DATA = 'accounting-view/SET_ROUTINES_DATA';
export const PATCH_USER_INPUT_DATA = 'accounting-view/PATCH_USER_INPUT_DATA';
export const PATCH_USER_INPUT_ROUTINE_DATA =
  'accounting-view/PATCH_USER_INPUT_ROUTINE_DATA';
export const SET_NUM_DOCUMENTS = 'accounting-view/SET_NUM_DOCUMENTS';
export const SET_SINGLE_PERIOD = 'accounting-view/SET_SINGLE_PERIOD';
export const SET_PERIOD_CHANGE_VISIBILITY = 'SET_PERIOD_CHANGE_VISIBILITY';
export const SET_PERIOD_UB_VISIBILITY =
  'accounting-view/SET_PERIOD_UB_VISIBILITY';
export const SET_MOVING_ACCOUNTS_MODE =
  'accounting-view/SET_MOVING_ACCOUNTS_MODE';
export const SET_CUSTOMER_INVOICES = 'accounting-view/SET_CUSTOMER_INVOICES';
export const SET_SUPPLIER_INVOICES = 'accounting-view/SET_SUPPLIER_INVOICES';
export const SET_USER_INPUT_DOCUMENTS =
  'accounting-view/SET_USER_INPUT_DOCUMENTS';
export const SET_ACCOUNTING_BALANCES =
  'accounting-view/SET_ACCOUNTING_BALANCES';
export const TOGGLE_PERIOD_DONE_VISIBILITY =
  'accounting-view/TOGGLE_PERIOD_DONE_VISIBILITY';
export const TOGGLE_PERIOD_LOCKED_VISIBILITY =
  'accounting-view/TOGGLE_PERIOD_LOCKED_VISIBILITY';
export const SET_VACATION_DEBT = 'accounting-view/SET_VACATION_DEBT';
export const ADD_PROGRAM_PERIODS_STATUS =
  'accounting-view/ADD_PROGRAM_PERIODS_STATUS';
export const SET_INVENTORY_OBSOLESCENCE =
  'accounting-view/SET_INVENTORY_OBSOLESCENCE';
export const SET_STOCK_INVENTORY_TABLE =
  'accounting-view/set-stock-inventory-table';
