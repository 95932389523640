import * as t from 'io-ts';
import { AccountingBalancesChangedCodec } from './accounting-balances-changed';
import { ClientLampsChangedMessageCodec } from './client-lamps-changed';
import { parseMessage } from './common';
import { DocumentValuesUpdatedMessageCodec } from './document-values-updated';
import { FortnoxInvoicesStateCodec } from './fortnox-invoices';
import { UserInputChangedCodec } from './user-input-changed';
import { ProgramStatusChangedCodec } from './program-status-changed';

const SubscribeActionCodec = t.type({
  action: t.literal('subscribe'),
  clientId: t.string,
  topic: t.string,
  token: t.string,
});

/**
 * SubscribeAction
 *
 * Add subscription to events in frontend ws-notifications.
 * Sent by frontend to API Gateway.
 */
export type SubscribeAction = t.TypeOf<typeof SubscribeActionCodec>;

const SieImportStateMessageCodec = t.intersection([
  t.type({
    jobId: t.string,
    clientId: t.string,
    topic: t.literal('sie-import'),
    status: t.union([
      t.literal('started'),
      t.literal('ignored'),
      t.literal('completed'),
      t.literal('failed'),
    ]),
  }),
  t.union([
    t.type({
      status: t.literal('started'),
      financialYearId: t.number,
    }),
    t.type({
      status: t.literal('ignored'),
      financialYearId: t.number,
      reason: t.union([
        t.literal('already_imported'),
        t.literal('other_import_in_progress'),
      ]),
    }),
    t.type({
      status: t.literal('completed'),
      financialYearId: t.number,
      warnings: t.array(
        t.type({
          type: t.string,
          details: t.record(t.string, t.union([t.string, t.number])),
        })
      ),
    }),
    t.union([
      t.type({
        status: t.literal('failed'),
        errorCode: t.union([
          t.literal('SIE_IMPORT_OVERLAPPING_FIN_YEAR'),
          t.literal('SIE_IMPORT_FAILED'),
          t.literal('SIE_IMPORT_FAILED_DUPLICATE_FINANCIAL_YEAR'),
          t.literal('SIE_IMPORT_ILLEGAL_ACCOUNT_NUMBER'),
          t.literal('SIE_IMPORT_WRONG_ORG_NUMBER'),
        ]),
        errorDetails: t.union([
          t.record(t.string, t.union([t.string, t.number])),
          t.array(t.record(t.string, t.union([t.string, t.number]))),
        ]),
      }),
      t.type({
        status: t.literal('failed'),
        errorCode: t.literal('SIE_IMPORT_CHANGE_IN_LOCKED_PERIOD'),
        errorDetails: t.array(
          t.union([
            t.type({
              type: t.literal('ACCOUNT_BALANCES_CHANGED'),
              account: t.string,
              periodId: t.number,
              changeIB: t.number,
            }),
            t.type({
              type: t.literal('NEW_VOUCHER'),
              voucher: t.string,
              periodId: t.number,
            }),
            t.type({
              type: t.literal('CHANGED_VOUCHER'),
              voucher: t.string,
              periodId: t.number,
            }),
            t.type({
              type: t.literal('REMOVED_VOUCHER'),
              voucher: t.string,
              periodId: t.number,
            }),
            t.type({
              type: t.literal('NEW_TRANSACTION'),
              voucher: t.string,
              account: t.string,
              periodId: t.number,
            }),
            t.type({
              type: t.literal('CHANGED_TRANSACTION'),
              voucher: t.string,
              account: t.string,
              periodId: t.number,
            }),
            t.type({
              type: t.literal('REMOVED_TRANSACTION'),
              voucher: t.string,
              account: t.string,
              periodId: t.number,
            }),
          ])
        ),
      }),
      t.type({
        status: t.literal('failed'),
        errorCode: t.literal('SIE_IMPORT_CONTENT_ERROR'),
        errorDetails: t.array(
          t.union([
            t.type({
              type: t.literal('SIE_IMPORT_ILLEGAL_VOUCHER_NUMBER'),
              voucher: t.string,
            }),
            t.type({
              type: t.literal('SIE_IMPORT_FAILED_DUPLICATE_VOUCHER'),
              voucher: t.string,
            }),
          ])
        ),
      }),
    ]),
  ]),
]);

export const parseSubscribeActionMessage = (json: string) =>
  parseMessage(SubscribeActionCodec, json);

/**
 * Progress update for SIE imports
 *
 * sie-import lambda will report when starting and ending a sie-import job.
 */
export type SieImportStateMessage = t.TypeOf<typeof SieImportStateMessageCodec>;

export const parseSieImportStateMessage = (json) =>
  parseMessage(SieImportStateMessageCodec, json);

/**
 * All possible messages from the websocket.
 */
const WSNotificationsCodec = t.union([
  SieImportStateMessageCodec,
  FortnoxInvoicesStateCodec,
  DocumentValuesUpdatedMessageCodec,
  AccountingBalancesChangedCodec,
  UserInputChangedCodec,
  ClientLampsChangedMessageCodec,
  ProgramStatusChangedCodec,
]);

export type WSNotification = t.TypeOf<typeof WSNotificationsCodec>;

export const parseWSNotification = (json: string) =>
  parseMessage(WSNotificationsCodec, json);
