import theme from 'theme';
import { LampValue } from '_clients/types/types';
import arrayBufferToBuffer from './ArrayBuffer';
// eslint-disable-next-line import/no-cycle
import isInputCorrect from './isInputCorrect';
import createPeriods from './createPeriods';
import useDebounce from './useDebounce';
import useLogChange from './useLogChange';
import mapDirectors from './mapDirectors';

export * from './numbers';
export * from './format';
export { useLogChange };
export { mapDirectors };

const between = (begin, middle, end) => middle >= begin && middle <= end;

export const totValue = (rowData, key) => {
  const filteredRowData = rowData.filter((val) => val[key]);
  return filteredRowData.length > 0
    ? filteredRowData[filteredRowData.length - 1][key]
    : 0;
};

/**
 * Joakims mysterious specification cleaner
 */
export const cleanSpecification = (specification: Client.Specification[]) => {
  const cleaned: Client.Specification[] = [];

  const isEmpty = (row: Client.Specification) => {
    return row.description === '' && row.amount === '0' && row.account === '';
  };

  const fill = (obj: Client.Specification, key: string) => {
    if (obj[key] === '' || obj[key] === 0) {
      // FIXME: dont re-assign function param
      // eslint-disable-next-line no-param-reassign
      obj[key] = key === 'amount' ? 0 : ' ';
    }
    return obj;
  };

  specification.forEach((row) => {
    if (!isEmpty(row)) {
      Object.keys(row).forEach((prop) => {
        fill(row, prop);
      });
      cleaned.push(row);
    }
  });

  return cleaned;
};

export const getPeriodHeaderStatusColor = (status: LampValue): string => {
  const colors = {
    done: theme.palette.success.main,
    unvisited: theme.palette.grey[400],
    wrong: theme.palette.error.main,
    started: theme.palette.warning.main,
  };
  return colors[status || 'unvisited'];
};

export const getStatusColor = (status: LampValue): string => {
  const colors: Record<Exclude<LampValue, null>, string> = {
    doesNotExist: theme.palette.accountingView.cell.incomingBalance,
    done: theme.palette.success.main,
    unvisited: theme.palette.grey[400],
    wrong: theme.palette.overviewView.accountStatusWrong,
    started: theme.palette.warning.main,
  };
  return colors[status || 'unvisited'];
};

export {
  arrayBufferToBuffer,
  between,
  isInputCorrect,
  createPeriods,
  useDebounce,
};
