import { AgoyDocumentStructure } from '../document';
import updateDocument from './helpers/updateDocument';
import { OperationResult, State } from './types';

/**
 * Operation to remove changes by id.
 *
 * Note that only the changes are affected, the document is untouched and
 * have to be recreated from the updated changes.
 *
 * Currently supporting  "part" and "table" types.
 *
 * @param structure
 * @param state
 * @param id
 * @returns
 */
const removeChanges = <T extends AgoyDocumentStructure>(
  structure: T,
  state: State<T>,
  id: string
): OperationResult<T> => {
  return updateDocument(structure, state, id, {
    table: (key, id, props) => {
      /**
       * Reset previous changes by provided rowId
       */
      if (key?.length && props.changes) {
        const revertedChanges = props.changes.rows?.filter(
          (row) => !key.find((keyId) => keyId === row.id)
        );

        return {
          ...props,
          changes: {
            ...props.changes,
            rows: revertedChanges,
          },
        };
      }
      if (props.changes) {
        return {
          ...props,
          changes: undefined,
        };
      }
      return props;
    },
    part: (key, id, props) => {
      if (props.changes) {
        return {
          ...props,
          changes: undefined,
        };
      }
      return props;
    },
    field: (key, id, props) => {
      if (props.changes) {
        return {
          ...props,
          changes: undefined,
        };
      }
      return props;
    },
  });
};

export default removeChanges;
