import {
  AgoyDocument,
  AgoyDocumentArrayChanges,
  AgoyDocumentChanges,
  AgoyDocumentPart,
  AgoyDocumentPartChanges,
  AgoyDocumentStructure,
  AgoyDocumentStructureArray,
  AgoyDocumentStructureBoolean,
  AgoyDocumentStructureField,
  AgoyDocumentStructurePart,
  AgoyDocumentStructureTable,
  AgoyDocumentStructureExternalDocument,
  AgoyDocumentStructureExternalDocuments,
  AgoySectionArray,
  AgoyTable,
  FieldUpdate,
  TableChange,
  AgoyDocumentStructureType,
  AgoyDocumentStructureTypeValue,
} from '../AgoyDocument';
import { BooleanCell, Field } from '..';
import { AgoyDocumentStructureRelatedClient, Errors } from '.';

type Unionize<T> = { [U in keyof T]: T[U] }[keyof T];

/**
 * Helper type to gather types that belongs together in the type `Mapping`
 */
type TraverseTuple<T, S, N, C> = {
  type: T;
  structure: S;
  node: N;
  changes: C;
  error: Errors | null;
};

/**
 * type Mapping
 *
 * The purpose of this type is to keep things together,
 * what type of change and node that is connected to
 * the types in the structure.
 *
 */
type Mapping = {
  document: TraverseTuple<
    'document',
    AgoyDocumentStructure,
    AgoyDocument<AgoyDocumentStructure>,
    AgoyDocumentChanges<AgoyDocumentStructure>
  >;
  field: TraverseTuple<
    'field',
    AgoyDocumentStructureField,
    Field,
    FieldUpdate | undefined
  >;
  table: TraverseTuple<
    'table',
    AgoyDocumentStructureTable,
    AgoyTable,
    TableChange | undefined
  >;
  boolean: TraverseTuple<
    'boolean',
    AgoyDocumentStructureBoolean,
    BooleanCell | boolean | undefined,
    boolean
  >;
  part: TraverseTuple<
    'part',
    AgoyDocumentStructurePart,
    AgoyDocumentPart<AgoyDocumentStructurePart>,
    AgoyDocumentPartChanges<AgoyDocumentStructurePart> | undefined
  >;
  array: TraverseTuple<
    'array',
    AgoyDocumentStructureArray,
    AgoySectionArray<AgoyDocumentStructureArray>,
    AgoyDocumentArrayChanges<AgoyDocumentStructureArray> | undefined
  >;
  externalDocument: TraverseTuple<
    'externalDocument',
    AgoyDocumentStructureExternalDocument,
    string | undefined,
    undefined
  >;
  externalDocuments: TraverseTuple<
    'externalDocuments',
    AgoyDocumentStructureExternalDocuments,
    string[],
    undefined
  >;
  relatedClient: TraverseTuple<
    'relatedClient',
    AgoyDocumentStructureRelatedClient,
    string | undefined,
    undefined
  >;
  type: TraverseTuple<
    'type',
    AgoyDocumentStructureType,
    AgoyDocumentStructureTypeValue,
    undefined
  >;
};

/**
 * An action that can update the node. Return a new Props object with the
 * changes or return the `props` if no change is made.
 */
type Action<T> = T extends TraverseTuple<
  keyof Mapping,
  infer S,
  infer N,
  infer C
>
  ? (
      key: string[] | null,
      id: string,
      props: {
        structure: S;
        node: N;
        changes: C;
        error: Errors | null;
      }
    ) => { node: N; changes: C; error: Errors | null }
  : never;

/**
 * Definitions of actions per type
 */
export type Actions = {
  [U in keyof Mapping]: Action<Mapping[U]>;
};

/**
 * Props that are passed through the traversal of a document.
 * Since TS can handle co-dependant arguments, we need to group
 * the arguments together as an object and pass that around.
 */
type Props = Mapping;

type UnverifiedProps = {
  type: Unionize<Props>['type'];
  structure: Unionize<Props>['structure'];
  node: Unionize<Props>['node'];
  changes: Unionize<Props>['changes'];
  error: Unionize<Props>['error'];
};

/**
 * Returns a Props object for a child in the structure.
 *
 * This is only applicable to node type that can have children, excluding document.
 *
 * The method of picking a child is different for arrays and sections and
 * this helper function takes care of that for the traversal functions.
 *
 * @param props Parent props
 * @param key key of child, name for parts and index (as string) for arrays.
 * @returns The child props.
 */
function getChild<T extends 'array' | 'part'>(
  props: Props[T],
  key: string
): Props[keyof Props] | undefined {
  if (props.type === 'part') {
    if (props.structure.children[key]) {
      const structure = props.structure.children[key];
      const { type } = structure;
      const child: UnverifiedProps = {
        type,
        structure,
        changes: props.changes?.[key],
        node: props.node?.[key],
        error: null,
      };
      // Validating the child props, a bit of type cheeting.
      if (isValid(child)) {
        return child;
      }
    }
  } else if (props.type === 'array') {
    const index = parseInt(key);
    if (typeof index === 'number' && !isNaN(index)) {
      const structure = props.structure.children;
      const { type } = structure;
      const childNode = props.node.sections[index];
      const childChanges = props.changes?.[key];
      if (type !== 'part') {
        console.error("Array type can only contain type 'part'");
      } else if (childChanges === null) {
        // Child was deleted
        return undefined;
      } else if (childNode !== null) {
        return {
          type,
          structure,
          changes: childChanges,
          node: childNode,
          error: null,
        };
      }
    } else {
      console.warn('Illegal key to array', key);
    }
  }
  return undefined;
}

/**
 * Updates the child of a node.
 *
 * @param props Parent props
 * @param key child key
 * @param value Child props
 * @returns new Parent props.
 */
function updateNode<T extends Props[keyof Props]>(
  props: T,
  key: string,
  value: Props[keyof Props]['node']
): T['node'] {
  if (props.type === 'part') {
    return { ...props.node, [key]: value };
  }
  if (props.type === 'array') {
    const index = parseInt(key, 10);
    const sections = props.node.sections;
    if (typeof value === 'object') {
      return {
        ...props.node,
        sections: [
          ...sections.slice(0, index),
          value as AgoyDocumentPart<AgoyDocumentStructurePart>,
          ...sections.slice(index + 1),
        ],
      };
    }
  }
  return props.node;
}

/**
 * Updates the child's changes of a node.
 *
 * @param props Parent props
 * @param key child key
 * @param value Child props
 * @returns new Parent props.
 */
function updateChanges<T extends keyof Props>(
  props: Props[T],
  key: string,
  value: Props[keyof Props]['changes']
): Props[T]['changes'] {
  if (props.type === 'part') {
    return { ...props.changes, [key]: value };
  }
  if (props.type === 'array') {
    if (typeof value === 'object') {
      return {
        ...props.changes,
        [key]: value,
      } as Props['array']['changes'];
    }
  }
  return props.changes;
}

/**
 * Checks if the `props` are a valid combination.
 * Currently only checks the structure, but can be improved for better runtime type checks.
 *
 * @param props
 * @returns
 */
export function isValid(props: UnverifiedProps): props is Props[keyof Props] {
  return props.type === props.structure.type;
}

/**
 * Default actions, returns unchanged props.
 */
const defaultActions: Actions = {
  array: (key, id, props) => props,
  field: (key, id, props) => props,
  table: (key, id, props) => props,
  part: (key, id, props) => props,
  boolean: (key, id, props) => props,
  document: (key, id, props) => props,
  externalDocument: (key, id, props) => props,
  externalDocuments: (key, id, props) => props,
  relatedClient: (key, id, props) => props,
  type: (key, id, props) => props,
};

const traverseDocumentChild = (
  subkeys: string[] | null,
  childKey: string,
  props: Props['document'],
  actions: Partial<Actions>
): Props['document'] => {
  const { node, changes, structure } = props;
  if (props.structure.children[childKey] === undefined) {
    return { ...props, error: 'INVALID_ID' };
  }
  const childProps: UnverifiedProps = {
    node: node[childKey],
    changes: changes?.[childKey],
    structure: structure.children[childKey],
    type: structure.children[childKey].type,
    error: props.error,
  };

  if (isValid(childProps)) {
    const update = traverseNode(subkeys, childKey, childProps, {
      ...defaultActions,
      ...actions,
    });
    if (
      childProps.node !== update.node ||
      childProps.changes !== update.changes ||
      update.error
    ) {
      return {
        ...props,
        node: { ...node, [childKey]: update.node },
        changes: { ...changes, [childKey]: update.changes },
        error: update.error,
      };
    }
  }
  return props;
};

/**
 * `traverseDocument`
 *
 * A method to either find a specific node by id or to go through
 * every element in the document and it's changes by following the
 * document's structure.
 *
 * To find and/or update a specific element in the document we pass
 * an array with the id splitted into an array.
 * `'incomeStatement.section.table' => ['incomeStatement', 'section', 'table']`
 * The function will then go down in the document to that specific child and
 * call the corresponding action for that type.
 *
 * To visit every element in the document/changes/structure combination
 * we call the function with a null key.
 *
 * @param key
 * @param structure
 * @param document
 * @param changes
 * @param actions
 * @returns The updated document/changes in a Props object. If no change then it
 * will contain the same object that was used as arguments.
 */
export const traverseDocument = <
  T extends AgoyDocumentStructure,
  D extends AgoyDocument<T>
>(
  key: string[] | null,
  structure: T,
  document: D,
  changes: AgoyDocumentChanges<T> | undefined,
  actions: Partial<Actions>
): {
  document: D;
  changes: AgoyDocumentChanges<T> | undefined;
  error: Errors | null;
} => {
  const props: Props['document'] = {
    type: 'document',
    node: document,
    changes: changes || {},
    structure,
    error: null,
  };

  if (key === null) {
    const result = Object.keys(structure.children).reduce(
      (props: Props['document'], childKey) => {
        return traverseDocumentChild(null, childKey, props, actions);
      },
      props
    );

    if (
      result.node !== document ||
      result.changes !== changes ||
      result.error !== null
    ) {
      return {
        changes: result.changes as AgoyDocumentChanges<T>,
        document: {
          ...document,
          ...result.node,
        },
        error: result.error,
      };
    }

    return {
      document,
      changes,
      error: null,
    };
  }

  const [childKey, ...subkeys] = key;
  const result = traverseDocumentChild(subkeys, childKey, props, actions);
  if (
    result.node !== document ||
    result.changes !== changes ||
    result.error !== null
  ) {
    return {
      document: {
        ...document,
        ...result.node,
      },
      changes: result.changes as AgoyDocumentChanges<T> | undefined,
      error: result.error,
    };
  }
  return {
    document,
    changes,
    error: null,
  };
};

const traversePartChild = (
  targetKey: string[] | null,
  id: string,
  props: Props['part'],
  actions: Actions,
  childKey: string
): Props['part'] => {
  const child = getChild(props, childKey);
  if (child) {
    const update = traverseNode(targetKey, `${id}.${childKey}`, child, actions);
    if (update !== child) {
      const nextNode =
        update.node !== child.node
          ? updateNode(props, childKey, update.node)
          : props.node;
      const nextChanges =
        update.changes !== child?.changes
          ? updateChanges(props, childKey, update.changes)
          : props.changes;
      const result: UnverifiedProps = {
        structure: props.structure,
        type: props.type,
        node: nextNode,
        changes: nextChanges,
        error: props.error || update.error,
      };

      if (isValid(result) && result.type === 'part') {
        return result;
      }
    }
  } else {
    return { ...props, error: 'INVALID_ID' };
  }
  return props;
};

const traverseArrayChild = (
  targetKey: string[] | null,
  id: string,
  props: Props['array'],
  actions: Actions,
  index: number
): Props['array'] => {
  const childKey = index.toString();
  const child = getChild(props, childKey);
  if (child) {
    const update = traverseNode(targetKey, `${id}-${childKey}`, child, actions);
    if (update !== props) {
      const nextNode =
        update.node !== child.node
          ? updateNode(props, childKey, update.node)
          : props.node;
      const nextChanges =
        update.changes !== child?.changes
          ? updateChanges(props, childKey, update.changes)
          : props.changes;
      const result: UnverifiedProps = {
        structure: props.structure,
        type: props.type,
        node: nextNode,
        changes: nextChanges,
        error: props.error || update.error,
      };

      if (isValid(result) && result.type === 'array') {
        return result;
      }
    }
  } else {
    return { ...props, error: 'INVALID_ID' };
  }
  return props;
};

const traverseChildren = <T extends Props[keyof Props]>(
  key: string[] | null,
  id: string,
  props: T,
  actions: Actions
): T => {
  if (key === null) {
    if (props.type === 'part') {
      return Object.keys(props.structure.children).reduce(
        (props: Props['part'], childKey: string) => {
          if (props.type === 'part') {
            return traversePartChild(null, id, props, actions, childKey);
          }
          return props;
        },
        props
      ) as T;
    } else if (props.type === 'array') {
      return props.node.sections.reduce(
        (props: Props['array'], part, index) => {
          return props.node === null
            ? props
            : traverseArrayChild(null, id, props, actions, index);
        },
        props
      ) as T;
    }
  } else {
    const [childKey, ...subkeys] = key;
    if (props.type === 'part') {
      return traversePartChild(subkeys, id, props, actions, childKey) as T;
    } else if (props.type === 'array') {
      const index = parseInt(childKey, 10);
      if (!isNaN(index)) {
        if (props.node.sections[index] !== null) {
          return traverseArrayChild(subkeys, id, props, actions, index) as T;
        }
      }
    }
  }
  return props;
};

const traverseNode = <T extends Props[keyof Props]>(
  targetKey: string[] | null,
  id: string,
  props: T,
  actions: Actions
): T => {
  let nodeProps = props;
  if (
    targetKey === null ||
    targetKey.length === 0 ||
    nodeProps.type === 'table'
  ) {
    switch (nodeProps.type) {
      case 'table': {
        const result = actions.table(targetKey, id, nodeProps);
        if (result !== nodeProps) {
          nodeProps = {
            ...nodeProps,
            node: result.node,
            changes: result.changes,
            error: nodeProps.error || result.error,
          };
        }
        break;
      }
      case 'field': {
        const result = actions.field(targetKey, id, nodeProps);
        if (result !== nodeProps) {
          nodeProps = {
            ...nodeProps,
            node: result.node,
            changes: result.changes,
            error: nodeProps.error || result.error,
          };
        }
        break;
      }
      case 'boolean': {
        const result = actions.boolean(targetKey, id, nodeProps);
        if (result !== nodeProps) {
          nodeProps = {
            ...nodeProps,
            node: result.node,
            changes: result.changes,
            error: nodeProps.error || result.error,
          };
        }
        break;
      }
      case 'part': {
        const result = actions.part(targetKey, id, nodeProps);
        if (result !== nodeProps) {
          nodeProps = {
            ...nodeProps,
            node: result.node,
            changes: result.changes,
            error: nodeProps.error || result.error,
          };
        }
        break;
      }
      case 'array': {
        const result = actions.array(targetKey, id, nodeProps);
        if (result !== nodeProps) {
          nodeProps = {
            ...nodeProps,
            node: result.node,
            changes: result.changes,
            error: nodeProps.error || result.error,
          };
        }
        break;
      }
      case 'externalDocument': {
        const result = actions.externalDocument(targetKey, id, nodeProps);
        if (result !== nodeProps) {
          nodeProps = {
            ...nodeProps,
            node: result.node,
            changes: result.changes,
            error: nodeProps.error || result.error,
          };
        }
        break;
      }
      case 'externalDocuments': {
        const result = actions.externalDocuments(targetKey, id, nodeProps);
        if (result !== nodeProps) {
          nodeProps = {
            ...nodeProps,
            node: result.node,
            changes: result.changes,
            error: nodeProps.error || result.error,
          };
        }
        break;
      }
      case 'relatedClient': {
        const result = actions.relatedClient(targetKey, id, nodeProps);
        if (result !== nodeProps) {
          nodeProps = {
            ...nodeProps,
            node: result.node,
            changes: result.changes,
            error: nodeProps.error || result.error,
          };
        }
        break;
      }
    }
  }
  if (nodeProps.type === 'array' || nodeProps.type === 'part') {
    if (targetKey === null || targetKey.length > 0) {
      nodeProps = traverseChildren(targetKey, id, nodeProps, actions);
    }
  }
  return nodeProps;
};
