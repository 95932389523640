import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSelector } from 'redux/reducers';
import { addGlobalErrorMessage, addGlobalMessage } from 'redux/actions';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import { useApiSdk } from 'api-sdk';
import PeriodSummary from '_shared/components/PeriodSummary';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { formatPeriodToMonthAndYear } from 'utils';
import { InputData, VacationDebt } from '_reconciliation/types';
import { setVacationDebt as setVacationDebtAction } from '_reconciliation/redux/accounting-view/actions';
import { PrintState } from '_annual-report/components/AnnualReportView/PrintStateContext';
import CommonPreviewModal from '_shared/components/CommonPreviewModal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useAccountDocuments } from 'utils/usePeriodDocuments';
import Button from '_shared/components/Buttons/Button';
import SummaryTable from './SummaryTable';
import { SemesterskuldlistaPreview } from './SemesterskuldlistaView';
import PeriodDataContext from '../PeriodDataContext';

type Props = {
  userData: InputData;
};

const StyledLoading = styled.div`
  position: absolute;
`;

const Wrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)}px;
  display: flex;
  gap: ${(props) => props.theme.spacing(3)}px;
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PreviewVisibilityIcon = styled(VisibilityIcon)`
  margin-right: 10px;
  color: ${(props) => props.theme.palette.common.white};
`;

const AttachIcon = styled(AttachFileIcon)`
  color: ${(props) => props.theme.palette.common.white};
`;

const PreviewTypography = styled(Typography)`
  color: ${(props) => props.theme.palette.common.white};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing(2)}px;
  margin-top: ${(props) => props.theme.spacing(2)}px;
`;

type PagePrintState = Pick<
  Required<PrintState>,
  'accountingView' | 'organisation' | 'customers' | 'periodData'
>;

const SemesterskuldView = ({ userData }: Props) => {
  const { ub, ib, psaldo, saldo: userDataSaldo } = userData || {};
  const saldo = userDataSaldo ? parseInt(userDataSaldo, 10) : 0;

  const sdk = useApiSdk();
  const dispatch = useDispatch();
  const { createAndAddDocument } = useAccountDocuments();
  const { formatMessage } = useIntl();
  const translate = (id) => formatMessage({ id });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [isPrintPreviewMode, setIsPrintPreviewMode] = useState(false);

  const periodData = useContext(PeriodDataContext);
  const { clientId, period } = periodData;
  const customer = useSelector((state) => state.customers[clientId]);
  const accountingView = useSelector((state) => state.accountingView);
  const organisation = useSelector((state) => state.organisation);

  const { vacationDebt } = useSelector((state) => state.accountingView);

  const getPrintState = (): PagePrintState | null => {
    if (!customer) {
      return null;
    }

    return {
      customers: {
        [customer.id]: customer,
      },
      organisation,
      accountingView,
      periodData,
    };
  };

  const handlePrint = () => {
    setTimeout(() => {
      setIsPrintPreviewMode(true);
      window.print();
    });

    window.onafterprint = () => setIsPrintPreviewMode(false);
  };

  const fetchVacationDebt = useCallback(async () => {
    setIsLoading(true);

    try {
      const newVacationDebt: VacationDebt = await sdk.getVacationDebt({
        periodId: period.id,
        clientId,
      });

      dispatch(setVacationDebtAction(newVacationDebt));
    } catch (e) {
      dispatch(setVacationDebtAction(undefined));
    } finally {
      setIsLoading(false);
    }
  }, [clientId, dispatch, period.id, sdk]);

  const handleShowPreview = (value: boolean) => {
    setOpenPreview(value);
  };

  const handleSavePdf = async () => {
    const printState = getPrintState();

    if (!customer || !printState) {
      return;
    }

    try {
      setIsSaving(true);
      const docName = `Semesterskuld ${customer.name} ${period.start}.pdf`;

      await createAndAddDocument({
        name: docName,
        docType: 'accounting',
        footer: '',
        header: '',
        parts: ['semesterskuld'],
        printState,
      });

      const messageKey = 'vacationDebt.table.saveToPeriodDocuments.success';
      dispatch(addGlobalMessage('success', messageKey));
    } catch (e) {
      dispatch(addGlobalErrorMessage('error'));
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    fetchVacationDebt();
  }, [fetchVacationDebt]);

  return (
    <Wrapper>
      <PeriodSummary
        date={formatPeriodToMonthAndYear(period)}
        periodIb={ib}
        periodSaldo={psaldo}
        periodUb={ub}
        saldo={saldo}
      />
      {openPreview && (
        <CommonPreviewModal
          handleShow={handleShowPreview}
          onPrint={handlePrint}
          isOpen={openPreview}
        >
          <SemesterskuldlistaPreview
            clientId={clientId}
            data={vacationDebt?.employeesData}
            periodEnd={period.end}
            isPrintPreviewMode={isPrintPreviewMode}
          />
        </CommonPreviewModal>
      )}
      {isLoading ? (
        <LoadingPlaceholder />
      ) : (
        <SummaryWrapper>
          <SummaryTable
            employeesData={vacationDebt?.employeesData}
            updatedAt={vacationDebt?.updatedAt}
          />
          <ActionButtons>
            <Button
              label={translate(
                'reconciliation.hidden_row.semesterskuld.preview.show'
              )}
              onClick={() => handleShowPreview(true)}
              color="primary"
              variant="contained"
              disabled={!vacationDebt}
              startIcon={<PreviewVisibilityIcon />}
            />
            <Button
              label={translate('vacationDebt.table.saveToPeriodDocuments')}
              onClick={() => handleSavePdf()}
              color="primary"
              variant="contained"
              disabled={!vacationDebt}
              loading={isSaving}
              startIcon={<AttachIcon />}
            />
          </ActionButtons>
        </SummaryWrapper>
      )}
    </Wrapper>
  );
};

export default SemesterskuldView;
