/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../../common/utils/util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2001K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2001K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Redovisningsprinciper'),
  data: {
    active: true,
    RedovisningsVarderingsprinciper: {
      type: 'group',
      active: true,
      title: field('Redovisningsprinciper'),
      value: ixbrlField(
        field(
          'Årsredovisningen är upprättad i enlighet med årsredovisningslagen och bokföringsnämndens allmänna råd (BFNAR2016:10) om årsredovisning i mindre företag (K2).'
        ),
        {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsVarderingsprinciper',
          standardRubrik: 'Redovisnings- och värderingsprinciper',
        }
      ),
    },
    RedovisningsprinciperImmateriellaAnlaggningstillgangar: {
      type: 'group',
      active: true,
      title: field('Immateriella anläggningstillgångar'),
      value: ixbrlField(
        field(
          'Immateriella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjande period. Nyttjandeperioden omprövas per varje balansdag. Pågående projekt skrivs inte av utan nedskrivningsprövas årligen. Följande nyttjande perioder tillämpas:'
        ),
        {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperImmateriellaAnlaggningstillgangar',
          standardRubrik:
            'Redovisningsprinciper för immateriella anläggningstillgångar',
        }
      ),
      RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar: {
        type: 'group',
        active: true,
        title: field('Avskrivning'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar',
          standardRubrik:
            'Redovisningsprinciper för avskrivningar immateriella anläggningstillgångar',
        }),
        RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar: {
          type: 'group',
          active: true,
          title: field('Avskrivningsprinciper'),
          table: table<IxbrlCell>(
            'notes.note1.data.RedovisningsprinciperImmateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar.table',
            {
              id: 'AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
              label: 'Typ av tillgång',
              dataType: 'text',
              active: true,
            },
            {
              id: 'AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
              label: 'Procent',
              dataType: 'numeric',
              active: true,
              canToggle: true,
            },
            {
              id: 'AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
              label: 'År',
              dataType: 'numeric',
              active: true,
              canToggle: true,
            }
          )
            .addRows((rows) => {
              rows
                .addRowWithType(
                  'RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar',
                  'hidden',
                  undefined,
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipImmateriellaAnlaggningstillgangarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterTuple',
                      'header',
                      noteLabel(
                        'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
                      ),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipImmateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipImmateriellaAnlaggningstillgangarHyresratterLiknandeRattigheterTuple',
                      'header',
                      noteLabel('Hyresrätter och liknande rättigheter'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarHyresratterLiknandeRattigheterTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipImmateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarHyresratterLiknandeRattigheterTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipImmateriellaAnlaggningstillgangarGoodwillTuple',
                      'header',
                      noteLabel('Goodwill'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarGoodwillTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipImmateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarGoodwillTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  return rows.build();
                });
              return rows.build();
            })
            .build(),
        },
        AvskrivningarImmateriellaAnlaggningstillgangarKommentar: {
          type: 'group',
          active: true,
          title: field('Kommentar'),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:AvskrivningarImmateriellaAnlaggningstillgangarKommentar',
            standardRubrik:
              'Kommentar till avskrivningsprinciper för immateriella anläggningstillgångar',
          }),
        },
      },
    },
    RedovisningsprinciperMateriellaAnlaggningstillgangar: {
      type: 'group',
      active: true,
      title: field('Materiella anläggningstillgångar'),
      value: ixbrlField(
        field(
          'Materiella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjandeperiod förutom mark som inte skrivs av. Nyttjandeperioden omprövas per varje balansdag. Följande nyttjandeperioder tillämpas:'
        ),
        {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperMateriellaAnlaggningstillgangar',
          standardRubrik:
            'Redovisningsprinciper materiella anläggningstillgångar',
        }
      ),
      RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar: {
        type: 'group',
        active: true,
        title: field('Avskrivning'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar',
          standardRubrik:
            'Redovisningsprinciper för avskrivningar materiella anläggningstillgångar',
        }),
        RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar: {
          type: 'group',
          active: true,
          title: field('Avskrivningsprinciper'),
          table: table<IxbrlCell>(
            'notes.note1.data.RedovisningsprinciperMateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar.table',
            {
              id: 'AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
              label: 'Typ av tillgång',
              dataType: 'text',
              active: true,
            },
            {
              id: 'AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
              label: 'Procent',
              dataType: 'numeric',
              active: true,
              canToggle: true,
            },
            {
              id: 'AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
              label: 'År',
              dataType: 'numeric',
              active: true,
              canToggle: true,
            }
          )
            .addRows((rows) => {
              rows
                .addRowWithType(
                  'RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar',
                  'hidden',
                  undefined,
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipMateriellaAnlaggningstillgangarByggnaderTuple',
                      'header',
                      noteLabel('Byggnader'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarByggnaderTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarByggnaderTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipMateriellaAnlaggningstillgangarMaskinerAndraTekniskaAnlaggningarTuple',
                      'header',
                      noteLabel('Maskiner och andra tekniska anläggningar'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarMaskinerAndraTekniskaAnlaggningarTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarMaskinerAndraTekniskaAnlaggningarTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipMateriellaAnlaggningstillgangarInventarierVerktygInstallationerTuple',
                      'header',
                      noteLabel('Inventarier, verktyg och installationer'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarInventarierVerktygInstallationerTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarInventarierVerktygInstallationerTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipMateriellaAnlaggningstillgangarForbattringsutgifterAnnansFastighetTuple',
                      'header',
                      noteLabel('Förbättringsutgifter på annans fastighet'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarForbattringsutgifterAnnansFastighetTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarForbattringsutgifterAnnansFastighetTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipMateriellaAnlaggningstillgangarOvrigaMateriellaAnlaggningstillgangarTuple',
                      'header',
                      noteLabel('Övriga materiella anläggningstillgångar'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarOvrigaMateriellaAnlaggningstillgangarTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarOvrigaMateriellaAnlaggningstillgangarTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  return rows.build();
                });
              return rows.build();
            })
            .build(),
        },
        AvskrivningarMateriellaAnlaggningstillgangarKommentar: {
          type: 'group',
          active: true,
          title: field('Kommentar'),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:AvskrivningarMateriellaAnlaggningstillgangarKommentar',
            standardRubrik:
              'Kommentar till avskrivningsprinciper för materiella anläggningstillgångar',
          }),
        },
      },
    },
    RedovisningsprinciperTjansteOchEntreprenaduppdrag: {
      type: 'group',
      active: true,
      title: field('Tjänste- och entreprenaduppdrag'),
      value: ixbrlField(
        field(
          'Intäkter från konsulttjänster intäktsredovisas när tjänsterna tillhandahålls.'
        ),
        {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperTjansteOchEntreprenaduppdrag',
          standardRubrik:
            'Redovisningsprinciper tjänste- och entreprenaduppdrag',
        }
      ),
    },
    RedovisningsprinciperAnskaffningsvardeEgentillverkadevaror: {
      type: 'group',
      active: true,
      title: field('Anskaffningsvärde för egentillverkade varor'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperAnskaffningsvardeEgentillverkadevaror',
        standardRubrik:
          'Redovisningsprinciper anskaffningsvärde för egentillverkade varor',
      }),
    },
    RedovisningsprinciperBristandeJamforbarhetAndradePrinciper: {
      type: 'group',
      active: true,
      title: field('Bristande jämförbarhet vid ändrade principer'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperBristandeJamforbarhetAndradePrinciper',
        standardRubrik:
          'Bristande jämförbarhet vid ändrade redovisningsprinciper',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2001K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        RedovisningsVarderingsprinciper: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperImmateriellaAnlaggningstillgangar: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,

            RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,

                RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar:
                  {
                    type: 'part' as const,
                    children: {
                      type: type,
                      active: boolean,

                      title: cell,

                      table: { type: 'table' as const },
                    },
                  },

                AvskrivningarImmateriellaAnlaggningstillgangarKommentar: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },
              },
            },
          },
        },

        RedovisningsprinciperMateriellaAnlaggningstillgangar: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,

            RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,

                RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar:
                  {
                    type: 'part' as const,
                    children: {
                      type: type,
                      active: boolean,

                      title: cell,

                      table: { type: 'table' as const },
                    },
                  },

                AvskrivningarMateriellaAnlaggningstillgangarKommentar: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },
              },
            },
          },
        },

        RedovisningsprinciperTjansteOchEntreprenaduppdrag: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperAnskaffningsvardeEgentillverkadevaror: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperBristandeJamforbarhetAndradePrinciper: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
