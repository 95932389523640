/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-6858 (former Note 98)
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2072K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2072K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Uppskjuten skatt'),
  data: {
    active: true,
    UppskjutenSkattSkillnaderSkattefordranTuple: {
      type: 'group',
      active: true,
      title: field(
        'Specifikation av skillnader hänförliga till uppskjuten skattefordran'
      ),
      table: table<IxbrlCell>(
        'notes.note72.data.UppskjutenSkattSkillnaderSkattefordranTuple.table',
        {
          id: 'UppskjutenSkattSkillnaderSkattefordranTyp',
          label: 'Typ av skillnad/avdrag',
          dataType: 'text',
          active: true,
        },
        {
          id: 'UppskjutenSkattSkillnaderSkattefordranTemporarSkillnad',
          label: 'Temporär skillnad/avdrag',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'UppskjutenSkattSkillnaderSkattefodran',
          label: 'Skattefordran',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'UppskjutenSkattSkillnaderSkattefordranTuple',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Typ av skillnad/avdrag'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:UppskjutenSkattSkillnaderSkattefordranTyp',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av temporär skillnad eller skattemässiga underskottsavdrag hänförlig till uppskjuten skattefordran',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:UppskjutenSkattSkillnaderSkattefordranTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UppskjutenSkattSkillnaderSkattefordranTemporarSkillnad',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Temporär skillnad eller avdrag hänförlig till uppskjuten skattefordran, per typ av skillnad eller avdrag',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UppskjutenSkattSkillnaderSkattefodran',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Uppskjuten skattefordran per typ av temporär skillnad eller avdrag',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              UppskjutenSkattSkillnaderSkattefordranTyp: ixbrlCell(
                value('Typ av skillnad/avdrag'),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:UppskjutenSkattSkillnaderSkattefordranTyp',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av temporär skillnad eller skattemässiga underskottsavdrag hänförlig till uppskjuten skattefordran',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:UppskjutenSkattSkillnaderSkattefordranTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              UppskjutenSkattSkillnaderSkattefordranTemporarSkillnad: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UppskjutenSkattSkillnaderSkattefordranTemporarSkillnad',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Temporär skillnad eller avdrag hänförlig till uppskjuten skattefordran, per typ av skillnad eller avdrag',
                  saldo: 'debit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              UppskjutenSkattSkillnaderSkattefodran: ixbrlCell(value(), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:UppskjutenSkattSkillnaderSkattefodran',
                contextRef: 'balans0',
                standardRubrik:
                  'Uppskjuten skattefordran per typ av temporär skillnad eller avdrag',
                saldo: 'debit',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
            },
          }));
          rows.addRowWithType(
            'UppskjutenSkattSkillnaderUppskjutenSkattefordran',
            'sum',
            noteLabel('Summa specifikation uppskjuten skattefordran'),
            undefined,
            undefined
          );
          return rows.build();
        })
        .build(),
    },
    UppskjutenSkattSkillnaderKvittasUppskjutenSkattefordran: {
      type: 'group',
      active: true,
      title: field('Belopp som kvittas mot uppskjutna skattefordringar'),
      table: table<IxbrlCell>(
        'notes.note72.data.UppskjutenSkattSkillnaderKvittasUppskjutenSkattefordran.table',
        {
          id: 'UppskjutenSkattSkillnaderKvittasUppskjutenSkattefordran',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows.addRowWithType(
            'UppskjutenSkattSkillnaderKvittasUppskjutenSkattefordran',
            'row',
            noteLabel('Belopp som kvittas mot uppskjutna skattefordringar'),
            ixbrlCell(value(undefined), {
              contextRef: 'balans0',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:UppskjutenSkattSkillnaderKvittasUppskjutenSkattefordran',
              standardRubrik:
                'Belopp som kvittas mot uppskjutna skattefordringar',
              saldo: 'credit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              contextRef: 'balans1',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:UppskjutenSkattSkillnaderKvittasUppskjutenSkattefordran',
              standardRubrik:
                'Belopp som kvittas mot uppskjutna skattefordringar',
              saldo: 'credit',
              negateValue: true,
            })
          );
          return rows.build();
        })
        .build(),
    },
    UppskjutenSkattSkillnaderSkatteskuldTuple: {
      type: 'group',
      active: true,
      title: field(
        'Specifikation av skillnader hänförliga till uppskjuten skatteskuld'
      ),
      table: table<IxbrlCell>(
        'notes.note72.data.UppskjutenSkattSkillnaderSkatteskuldTuple.table',
        {
          id: 'UppskjutenSkattSkillnaderSkatteskuldTyp',
          label: 'Typ av skillnad/avdrag',
          dataType: 'text',
          active: true,
        },
        {
          id: 'UppskjutenSkattSkillnaderSkatteskuldTemporarSkillnad',
          label: 'Temporär skillnad/avdrag',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'UppskjutenSkattSkillnaderSkatteskuld',
          label: 'Skatteskuld',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'UppskjutenSkattSkillnaderSkatteskuldTuple',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                '1',
                'row',
                ixbrlCell(value('Typ av skillnad/avdrag'), {
                  type: 'stringItemType',
                  name: 'se-gen-base:UppskjutenSkattSkillnaderSkatteskuldTyp',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av temporär skillnad eller avdrag hänförlig till uppskjuten skatteskuld',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:UppskjutenSkattSkillnaderSkatteskuldTuple',
                    id: `${rows.getBaseId()}.1.tuple`,
                    tupleID: `${rows.getBaseId()}.1.tuple`,
                  },
                  tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UppskjutenSkattSkillnaderSkatteskuldTemporarSkillnad',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Temporär skillnad eller avdrag hänförlig till uppskjuten skatteskuld, per typ av skillnad eller avdrag',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                }),
                ixbrlCell(value(), {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UppskjutenSkattSkillnaderSkatteskuld',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Uppskjuten skatteskuld per typ av temporär skillnad eller avdrag',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                  previousYearTuple: false,
                })
              );
              return rows.build();
            });
          rows.newRowTemplateGenerator((_id, baseId) => ({
            id: _id,
            active: true,
            type: 'row',
            cells: {
              UppskjutenSkattSkillnaderSkatteskuldTyp: ixbrlCell(
                value('Typ av skillnad/avdrag'),
                {
                  type: 'stringItemType',
                  name: 'se-gen-base:UppskjutenSkattSkillnaderSkatteskuldTyp',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Typ av temporär skillnad eller avdrag hänförlig till uppskjuten skatteskuld',
                  negateValue: false,
                  tuple: {
                    name: 'se-gaap-ext:UppskjutenSkattSkillnaderSkatteskuldTuple',
                    id: `${baseId}.${_id}.tuple`,
                    tupleID: `${baseId}.${_id}.tuple`,
                  },
                  tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              UppskjutenSkattSkillnaderSkatteskuldTemporarSkillnad: ixbrlCell(
                value(),
                {
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:UppskjutenSkattSkillnaderSkatteskuldTemporarSkillnad',
                  contextRef: 'balans0',
                  standardRubrik:
                    'Temporär skillnad eller avdrag hänförlig till uppskjuten skatteskuld, per typ av skillnad eller avdrag',
                  saldo: 'credit',
                  negateValue: false,
                  tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                  previousYearTuple: false,
                }
              ),
              UppskjutenSkattSkillnaderSkatteskuld: ixbrlCell(value(), {
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:UppskjutenSkattSkillnaderSkatteskuld',
                contextRef: 'balans0',
                standardRubrik:
                  'Uppskjuten skatteskuld per typ av temporär skillnad eller avdrag',
                saldo: 'credit',
                negateValue: false,
                tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                previousYearTuple: false,
              }),
            },
          }));
          rows.addRowWithType(
            'UppskjutenSkattSkillnaderUppskjutenSkatteskuld',
            'sum',
            noteLabel('Summa specifikation uppskjuten skatteskuld'),
            undefined,
            undefined
          );
          return rows.build();
        })
        .build(),
    },
    UppskjutenSkattSkillnaderKvittasUppskjutenSkatteskuld: {
      type: 'group',
      active: true,
      title: field('Belopp som kvittas mot uppskjutna skatteskulder'),
      table: table<IxbrlCell>(
        'notes.note72.data.UppskjutenSkattSkillnaderKvittasUppskjutenSkatteskuld.table',
        {
          id: 'UppskjutenSkattSkillnaderKvittasUppskjutenSkatteskuld',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows.addRowWithType(
            'UppskjutenSkattSkillnaderKvittasUppskjutenSkatteskuld',
            'row',
            noteLabel('Belopp som kvittas mot uppskjutna skatteskulder'),
            ixbrlCell(value(undefined), {
              contextRef: 'balans0',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:UppskjutenSkattSkillnaderKvittasUppskjutenSkatteskuld',
              standardRubrik: 'Belopp som kvittas mot uppskjutna skatteskulder',
              saldo: 'debit',
              negateValue: true,
            }),
            ixbrlCell(value(undefined), {
              contextRef: 'balans1',
              type: 'monetaryItemType',
              unitRef: 'SEK',
              name: 'se-gen-base:UppskjutenSkattSkillnaderKvittasUppskjutenSkatteskuld',
              standardRubrik: 'Belopp som kvittas mot uppskjutna skatteskulder',
              saldo: 'debit',
              negateValue: true,
            })
          );
          return rows.build();
        })
        .build(),
    },
    UppskjutenSkattefordran: {
      type: 'group',
      active: true,
      title: field('Uppskjuten skattefordran'),
      table: table<IxbrlCell>(
        'notes.note72.data.UppskjutenSkattefordran.table',
        {
          id: 'UppskjutenSkattefordran',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'UppskjutenSkattefordran',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'UppskjutenSkattefordran',
                'sum',
                noteLabel('Uppskjuten skattefordran'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note72.data.UppskjutenSkattSkillnaderSkattefordranTuple.table.UppskjutenSkattSkillnaderUppskjutenSkattefordran.year'
                      ),
                      id(
                        'notes.note72.data.UppskjutenSkattSkillnaderKvittasUppskjutenSkattefordran.table.UppskjutenSkattSkillnaderKvittasUppskjutenSkattefordran.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UppskjutenSkattefordran',
                    standardRubrik: 'Uppskjuten skattefordran',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note72.data.UppskjutenSkattSkillnaderSkattefordranTuple.table.UppskjutenSkattSkillnaderUppskjutenSkattefordran.previousYear'
                      ),
                      id(
                        'notes.note72.data.UppskjutenSkattSkillnaderKvittasUppskjutenSkattefordran.table.UppskjutenSkattSkillnaderKvittasUppskjutenSkattefordran.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UppskjutenSkattefordran',
                    standardRubrik: 'Uppskjuten skattefordran',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'UppskjutenSkatteskuld',
                'sum',
                noteLabel('Uppskjuten skatteskuld'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note72.data.UppskjutenSkattSkillnaderSkatteskuldTuple.table.UppskjutenSkattSkillnaderUppskjutenSkatteskuld.year'
                      ),
                      id(
                        'notes.note72.data.UppskjutenSkattSkillnaderKvittasUppskjutenSkatteskuld.table.UppskjutenSkattSkillnaderKvittasUppskjutenSkatteskuld.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UppskjutenSkatteskuld',
                    standardRubrik: 'Avsättningar för uppskjutna skatter',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note72.data.UppskjutenSkattSkillnaderSkatteskuldTuple.table.UppskjutenSkattSkillnaderUppskjutenSkatteskuld.previousYear'
                      ),
                      id(
                        'notes.note72.data.UppskjutenSkattSkillnaderKvittasUppskjutenSkatteskuld.table.UppskjutenSkattSkillnaderKvittasUppskjutenSkatteskuld.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:UppskjutenSkatteskuld',
                    standardRubrik: 'Avsättningar för uppskjutna skatter',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              );
              return rows.build();
            });
          return rows.build();
        })
        .build(),
    },
    NotUppskjutenSkattKommentar: {
      type: 'group',
      active: true,
      title: field('Kommentar till specifikation av uppskjuten skatt'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:NotUppskjutenSkattKommentar',
        standardRubrik: 'Kommentar till specifikation av uppskjuten skatt',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2072K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        UppskjutenSkattSkillnaderSkattefordranTuple: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        UppskjutenSkattSkillnaderKvittasUppskjutenSkattefordran: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        UppskjutenSkattSkillnaderSkatteskuldTuple: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        UppskjutenSkattSkillnaderKvittasUppskjutenSkatteskuld: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        UppskjutenSkattefordran: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },
          },
        },

        NotUppskjutenSkattKommentar: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
