import {
  endOfMonth,
  format,
  parse,
  startOfMonth,
  Interval,
  endOfDay,
  parseISO,
} from 'date-fns';

export interface ReferenceAccountInformation {
  account: string;
  accountName: string;
  yearIbs: {
    yearNo: string;
    saldo: number;
  }[];
  yearUbs: {
    yearNo: string;
    saldo: number;
  }[];
  yearCreditsWithoutInverse: number;
  yearDebitsWithoutInverse: number;
  periods: {
    period: string;
    saldo: number;
    ib: number;
    ub: number;
  }[];
  res: {
    yearNo: string;
    saldo: number;
  }[];
}

export type ReferenceErrorType =
  | 'missingAccount'
  | 'missingPeriod'
  | 'unknownReferenceType'
  | 'expandNotSupported'
  | 'tooManyValuesReturned'
  | 'missingId'
  | 'DivisionByZero'
  | 'notResolved'
  | 'incompatibleType'
  | 'internalError'
  | 'notEnoughArgumentsProvided';

export interface ResolveReferenceContext {
  resolveById: (
    id: string,
    context: ResolveReferenceContext
  ) => ResolvedReference;
  resolveConfig: (
    name: string,
    context: ResolveReferenceContext
  ) => ResolvedReference;
  expandIds?: (ids: string) => string[];
  input: ResolveReferenceInput;
  parent?: ResolveReferenceContext;
}

export type Values = Record<string, string | number | boolean | undefined>;

export type AccountValueType =
  | 'yearIb'
  | 'ib'
  | 'ub'
  | 'change'
  | 'creditWithoutInverse'
  | 'debitWithoutInverse';

const modifiers: AccountValueType[] = [
  'yearIb',
  'ib',
  'ub',
  'change',
  'creditWithoutInverse',
  'debitWithoutInverse',
];

export const isAccountValueType = (
  m: string | undefined
): m is AccountValueType =>
  m ? modifiers.includes(m as AccountValueType) : false;

/**
 * Period represents a time interval and can reflect a whole financial year, just a month
 * or start of the financial year until a monthly period.
 */
export class TimePeriod {
  _start: string;
  _end: string;
  _value: string;

  constructor(start: string | Date, end: string | Date) {
    if (typeof start === 'string' && start.length !== 8) {
      throw new Error(`Expected format yyyyMMdd (${start})`);
    }
    if (typeof end === 'string' && end.length !== 8) {
      throw new Error(`Expected format yyyyMMdd (${end})`);
    }
    this._start = typeof start === 'string' ? start : format(start, 'yyyyMMdd');
    this._end = typeof end === 'string' ? end : format(end, 'yyyyMMdd');
    this._value = `${this._start}-${this._end}`;
  }

  /**
   * Format yyyyMMdd
   */
  get start() {
    return this._start;
  }

  /**
   * Format yyyyMMdd
   */
  get end() {
    return this._end;
  }

  /**
   * "[start]-[end]", with the yyyyMMdd date format. Ex. "20200101-20201231"
   */
  get value() {
    return this._value;
  }

  /**
   * start date as Date object
   */
  get startDate(): Date {
    return parse(this._start, 'yyyyMMdd', Date.now());
  }

  /**
   * end date as Date object
   */
  get endDate(): Date {
    return parse(this._end, 'yyyyMMdd', Date.now());
  }

  /**
   * start date as yyyy-MM-dd
   */
  get startDateISO(): string {
    return format(this.startDate, 'yyyy-MM-dd');
  }

  /**
   * end date as yyyy-MM-dd
   */
  get endDateISO(): string {
    return format(this.endDate, 'yyyy-MM-dd');
  }

  /**
   * Convert to date-fns Interval object, useful
   * for interval operations.
   *
   * @returns An Interval object
   */
  toInterval(): Interval {
    return {
      start: this.startDate,
      end: endOfDay(this.endDate),
    };
  }

  toISODates(): { start: string; end: string } {
    return {
      start: this.startDateISO,
      end: this.endDateISO,
    };
  }

  static fromFinancialYear(year: string): TimePeriod {
    const [start, end] = year.split('-');
    return new TimePeriod(start, end);
  }

  /**
   * Creates a TimePeriod for a whole month by the given date.
   *
   * Ex.
   * 202110 => 2021-10-01 - 2020-10-31
   *
   * @param date formatted date as yyyyMM or yyyyMMdd
   */
  static monthOf(date: string): TimePeriod {
    const day = parse(
      date,
      date.length === 6 ? 'yyyyMM' : 'yyyyMMdd',
      new Date()
    );
    return new TimePeriod(
      format(startOfMonth(day), 'yyyyMMdd'),
      format(endOfMonth(day), 'yyyyMMdd')
    );
  }

  static fromDates(start: string, end: string, dateFormat: string) {
    return new TimePeriod(
      parse(start, dateFormat, Date.now()),
      parse(end, dateFormat, Date.now())
    );
  }

  static fromISODates(obj: { start: string; end: string }): TimePeriod;
  static fromISODates(start: string, end: string): TimePeriod;
  static fromISODates(
    start: string | { start: string; end: string },
    end?: string
  ) {
    if (typeof start === 'object') {
      return new TimePeriod(parseISO(start.start), parseISO(start.end));
    }
    return new TimePeriod(
      parse(start, 'yyyy-MM-dd', Date.now()),
      parse(end ?? '', 'yyyy-MM-dd', Date.now())
    );
  }
}

export interface AccountResolver {
  /**
   * Return the value for a single account
   *
   * @param field Type of value to get, 'ub' for outgoing balance...
   * @param period Time period to return the value for
   * @param account Account number
   */
  get(
    field: AccountValueType,
    period: TimePeriod,
    account: string
  ): ResolvedReference;

  /**
   * Returns the sum of account values for ranges of account numbers.
   * If an account exist in more than one range, it will only be counted once.
   *
   * @param field Type of value to get, 'ub' for outgoing balance...
   * @param period Time period to return the value for
   * @param ranges A list of account ranges, ex. [{ first: "1000", last: "1999"}]
   */
  sum(
    field: AccountValueType,
    period: TimePeriod,
    ranges: {
      first: string;
      last: string;
      nameFilter?: string;
    }[]
  ): ResolvedReference;

  /**
   * Returns the name of an account, or undefined if the account
   * doesn't exist.$
   *
   * @param accountNumber account number
   */
  getName(accountNumber: number): string | undefined;
}

export interface ResolveReferenceInput {
  accountResolver: AccountResolver;

  /**
   * If not period is targeted then the defaultPeriod will be used
   */
  defaultPeriod: TimePeriod;

  /**
   * Named periods that can be used by the account references
   */
  periods: Record<string, TimePeriod>;
}

export interface ResolveReferenceError {
  error: ReferenceErrorType;
  refChain?: string[];
}

export class ResolveError extends Error {
  error: ResolveReferenceError;

  constructor(code: ReferenceErrorType, cause?: Error) {
    super(code);
    this.error = { error: code, refChain: [] };
    if (cause) {
      this.stack = `${this.stack}\nCaused by ${cause.stack}`;
    }
  }

  toString(): string {
    return `${this.error.refChain?.join('\n')} ${this.stack}`;
  }
}

export function isResolveReferenceError(
  error: unknown
): error is ResolveReferenceError {
  return (
    typeof error === 'object' &&
    error !== null &&
    typeof (error as any)['error'] === 'string'
  );
}

export type ResolvedReference =
  | ResolveReferenceError
  | string
  | number
  | boolean
  | undefined;
