import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import { id, ref, value } from '@agoy/document';
import { mapFormId, toInitial, toStructure } from '../forms-util';

const configNEPersonHelper = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData
): TaxDeclarationFormDataConfig => {
  const FIELD_KEY = `NE_PERSON_HELPER.NE_PERSON_HELPER.fields`;
  const NE_PERSON_HELPER = mapFormId(form, 'NE_PERSON_HELPER');
  const initial = {
    NE_PERSON_HELPER: {
      partType: 'form' as const,
      id: NE_PERSON_HELPER,
      fields: {
        date: value(''),
        name: value(`${person.firstName} ${person.lastName}`),
        socSecNr: value(person.personNumber),
        scopeOfBusiness: value(''),
        passivNaringsverksamhet: value(false),
        sjalvstandingNaringsverksamhet: value(false),
        inteForenklatBokslut: value(false),
        socSecNrAccountant: value(''),
        A1_assets: ref(id(`${FIELD_KEY}.A1_assets`)),
        A2_unutilizedDeficit: ref(id(`${FIELD_KEY}.A2_unutilizedDeficit`)),
        A3_transitionPost: ref(id(`${FIELD_KEY}.A3_transitionPost`)),
        A4_specialPost: ref(id(`${FIELD_KEY}.A4_specialPost`)),
        A5_remainingSaved: ref(id(`${FIELD_KEY}.A5_remainingSaved`)),
        A6_nonPermanent: ref(id(`${FIELD_KEY}.A6_nonPermanent`)),
        A7_totalAccrualFunds: ref(id(`${FIELD_KEY}.A7_totalAccrualFunds`)),
        A8_expansionFundPercentage: ref(
          id(`${FIELD_KEY}.A8_expansionFundPercentage`)
        ),
        A8_sum: ref(id(`${FIELD_KEY}.A8_sum`)),
        A9a_bookedValue: ref(id(`${FIELD_KEY}.A9a_bookedValue`)),
        A9b_taxValue: ref(id(`${FIELD_KEY}.A9b_taxValue`)),
        A9_sum: ref(id(`${FIELD_KEY}.fields.A9_sum`)),
        A9_2nd_sum: ref(id(`${FIELD_KEY}.A9_2nd_sum`)),
        A10a_bookedValue: ref(id(`${FIELD_KEY}.A10a_bookedValue`)),
        A10b_halfOfMeans: ref(id(`${FIELD_KEY}.A10b_halfOfMeans`)),
        A10_sum: ref(id('NE_PERSON_HELPER.NE_PERSON_HELPER.fields.A10_sum')),
        A10_2nd_sum: ref(id(`${FIELD_KEY}.A10_2nd_sum`)),
        A11a_negativeAdjustment: ref(
          id(`${FIELD_KEY}.A11a_negativeAdjustment`)
        ),
        A11b_positiveAdjustment: ref(
          id(`${FIELD_KEY}.A11b_positiveAdjustment`)
        ),
        A12a_excess: ref(id(`${FIELD_KEY}.A12a_excess`)),
        A12b_deficit: ref(id(`${FIELD_KEY}.A12b_deficit`)),
        A13a_amountFrom12a: ref(id(`${FIELD_KEY}.A13a_amountFrom12a`)),
        A13b_amountFrom12b: ref(id(`${FIELD_KEY}.A13b_amountFrom12b`)),
        A14_savedDistributionAmount: ref(
          id(`${FIELD_KEY}.A14_savedDistributionAmount`)
        ),
        A15a_positiveDistributionAmount: ref(
          id(`${FIELD_KEY}.A15a_positiveDistributionAmount`)
        ),
        A15b_negativeDistributionAmount: ref(
          id(`${FIELD_KEY}.A15b_negativeDistributionAmount`)
        ),
        A16_positivelyUtilized: ref(id(`${FIELD_KEY}.A16_positivelyUtilized`)),
        A17_savedDistributionAmount: ref(
          id(`${FIELD_KEY}.A17_savedDistributionAmount`)
        ),
        B1_assetsMinusLiabilities: ref(
          id(`${FIELD_KEY}.B1_assetsMinusLiabilities`)
        ),
        B2_unutilizedDeficit: ref(id(`${FIELD_KEY}.B2_unutilizedDeficit`)),
        B3_transitionalItem: ref(id(`${FIELD_KEY}.B3_transitionalItem`)),
        B4_specialMail: ref(id(`${FIELD_KEY}.B4_specialMail`)),
        B5_nonPermanentCapital: ref(id(`${FIELD_KEY}.B5_nonPermanentCapital`)),
        B6_accrualFundsSum: ref(id(`${FIELD_KEY}.B6_accrualFundsSum`)),
        B6_subTotal: ref(id(`${FIELD_KEY}.B6_subTotal`)),
        B7a_valueOfProperties: ref(id(`${FIELD_KEY}.B7a_valueOfProperties`)),
        B7b_taxValueRealEstate: ref(id(`${FIELD_KEY}.B7b_taxValueRealEstate`)),
        B7_sum: ref(id(`${FIELD_KEY}.B7_sum`)),
        B7_2nd_sum: ref(id(`${FIELD_KEY}.B7_2nd_sum`)),
        B8a_valueAuthorAccount: ref(id(`${FIELD_KEY}.B8a_valueAuthorAccount`)),
        B8b_halfFundsAuthorAccount: ref(
          id(`${FIELD_KEY}.B8b_halfFundsAuthorAccount`)
        ),
        B8_sum: ref(id(`${FIELD_KEY}.B8_sum`)),
        B8_2nd_sum: ref(id(`${FIELD_KEY}.B8_2nd_sum`)),
        B9a_negativeAdjustments: ref(
          id(`${FIELD_KEY}.B9a_negativeAdjustments`)
        ),
        B9b_positiveAdjustments: ref(
          id(`${FIELD_KEY}.B9b_positiveAdjustments`)
        ),
        B10a_capitalBasis: ref(id(`${FIELD_KEY}.B10a_capitalBasis`)),
        B10b_maxExpansionFund: ref(id(`${FIELD_KEY}.B10b_maxExpansionFund`)),
        B_calculationMaxExpansionFondAmount: ref(
          id(`${FIELD_KEY}.B_calculationMaxExpansionFondAmount`)
        ),
        B_calculationExpansionFondYearStart: ref(
          id(`${FIELD_KEY}.B_calculationExpansionFondYearStart`)
        ),
        B_calculationPositiveAmount: ref(
          id(`${FIELD_KEY}.B_calculationPositiveAmount`)
        ),
        B_calculationNegativeAmount: ref(
          id(`${FIELD_KEY}.B_calculationNegativeAmount`)
        ),
        B_expansionFundYearStart: ref(
          id(`${FIELD_KEY}.B_expansionFundYearStart`)
        ),
        B_expansionFundIncrease: ref(
          id(`${FIELD_KEY}.B_expansionFundIncrease`)
        ),
        B_expansionFundReduction: ref(
          id(`${FIELD_KEY}.B_expansionFundReduction`)
        ),
        B_expansionFundYearEnd: ref(id(`${FIELD_KEY}.B_expansionFundYearEnd`)),
        C1_F1_acquisitionValue: ref(id(`${FIELD_KEY}.C1_F1_acquisitionValue`)),
        C1_F2_acquisitionValue: ref(id(`${FIELD_KEY}.C1_F2_acquisitionValue`)),
        C1_F3_acquisitionValue: ref(id(`${FIELD_KEY}.C1_F3_acquisitionValue`)),
        C2_F1_depreciationDeduction: ref(
          id(`${FIELD_KEY}.C2_F1_depreciationDeduction`)
        ),
        C2_F2_depreciationDeduction: ref(
          id(`${FIELD_KEY}.C2_F2_depreciationDeduction`)
        ),
        C2_F3_depreciationDeduction: ref(
          id(`${FIELD_KEY}.C2_F3_depreciationDeduction`)
        ),
        C3_F1_basis: ref(id(`${FIELD_KEY}.C3_F1_basis`)),
        C3_F2_basis: ref(id(`${FIELD_KEY}.C3_F2_basis`)),
        C3_F3_basis: ref(id(`${FIELD_KEY}.C3_F3_basis`)),
        C4_F1_acquisitions: ref(id(`${FIELD_KEY}.C4_F1_acquisitions`)),
        C4_F2_acquisitions: ref(id(`${FIELD_KEY}.C4_F2_acquisitions`)),
        C4_F3_acquisitions: ref(id(`${FIELD_KEY}.C4_F3_acquisitions`)),
        C5_F1_depreciation: ref(id(`${FIELD_KEY}.C5_F1_depreciation`)),
        C5_F2_depreciation: ref(id(`${FIELD_KEY}.C5_F2_depreciation`)),
        C5_F3_depreciation: ref(id(`${FIELD_KEY}.C5_F3_depreciation`)),
        C6_F1_correction: ref(id(`${FIELD_KEY}.C6_F1_correction`)),
        C6_F2_correction: ref(id(`${FIELD_KEY}.C6_F2_correction`)),
        C6_F3_correction: ref(id(`${FIELD_KEY}.C6_F3_correction`)),
        C7_F1_basis: ref(id(`${FIELD_KEY}.C7_F1_basis`)),
        C7_F2_basis: ref(id(`${FIELD_KEY}.C7_F2_basis`)),
        C7_F3_basis: ref(id(`${FIELD_KEY}.C7_F3_basis`)),
        C8_F1_appraisalValue: ref(id(`${FIELD_KEY}.C8_F1_appraisalValue`)),
        C8_F2_appraisalValue: ref(id(`${FIELD_KEY}.C8_F2_appraisalValue`)),
        C8_F3_appraisalValue: ref(id(`${FIELD_KEY}.C8_F3_appraisalValue`)),
        C9_F1_percentage: ref(id(`${FIELD_KEY}.C9_F1_percentage`)),
        C9_F2_percentage: ref(id(`${FIELD_KEY}.C9_F2_percentage`)),
        C9_F3_percentage: ref(id(`${FIELD_KEY}.C9_F3_percentage`)),
        C10_F1_partOfAssessedValue: ref(
          id(`${FIELD_KEY}.C10_F1_partOfAssessedValue`)
        ),
        C10_F2_partOfAssessedValue: ref(
          id(`${FIELD_KEY}.C10_F2_partOfAssessedValue`)
        ),
        C10_F3_partOfAssessedValue: ref(
          id(`${FIELD_KEY}.C10_F3_partOfAssessedValue`)
        ),
        C11_F1_depreciation: ref(id(`${FIELD_KEY}.C11_F1_depreciation`)),
        C11_F2_depreciation: ref(id(`${FIELD_KEY}.C11_F2_depreciation`)),
        C11_F3_depreciation: ref(id(`${FIELD_KEY}C11_F3_depreciation`)),
        C12_F1_acquisitionValue: ref(
          id(`${FIELD_KEY}.C12_F1_acquisitionValue`)
        ),
        C12_F2_acquisitionValue: ref(
          id(`${FIELD_KEY}.C12_F2_acquisitionValue`)
        ),
        C12_F3_acquisitionValue: ref(
          id(`${FIELD_KEY}.C12_F3_acquisitionValue`)
        ),
        C13_F1_depreciation: ref(id(`${FIELD_KEY}.C13_F1_depreciation`)),
        C13_F2_depreciation: ref(id(`${FIELD_KEY}.C13_F2_depreciation`)),
        C13_F3_depreciation: ref(id(`${FIELD_KEY}.C13_F3_depreciation`)),
        C14_F1_interestDistributionBasis: ref(
          id(`${FIELD_KEY}.C14_F1_interestDistributionBasis`)
        ),
        C14_F2_interestDistributionBasis: ref(
          id(`${FIELD_KEY}.C14_F2_interestDistributionBasis`)
        ),
        C14_F3_interestDistributionBasis: ref(
          id(`${FIELD_KEY}.C14_F3_interestDistributionBasis`)
        ),
        C15_F1_acquisitions: ref(id(`${FIELD_KEY}.C15_F1_acquisitions`)),
        C15_F2_acquisitions: ref(id(`${FIELD_KEY}.C15_F2_acquisitions`)),
        C15_F3_acquisitions: ref(id(`${FIELD_KEY}.C15_F3_acquisitions`)),
        C16_F1_depreciation: ref(id(`${FIELD_KEY}.C16_F1_depreciation`)),
        C16_F2_depreciation: ref(id(`${FIELD_KEY}.C16_F2_depreciation`)),
        C16_F3_depreciation: ref(id(`${FIELD_KEY}.C16_F3_depreciation`)),
        C17_F1_correction: ref(id(`${FIELD_KEY}.C17_F1_correction`)),
        C17_F2_correction: ref(id(`${FIELD_KEY}.C17_F2_correction`)),
        C17_F3_correction: ref(id(`${FIELD_KEY}.C17_F3_correction`)),
        C18_F1_expansionFundBasis: ref(
          id(`${FIELD_KEY}.C18_F1_expansionFundBasis`)
        ),
        C18_F2_expansionFundBasis: ref(
          id(`${FIELD_KEY}.C18_F2_expansionFundBasis`)
        ),
        C18_F3_expansionFundBasis: ref(
          id(`${FIELD_KEY}.C18_F3_expansionFundBasis`)
        ),
      },
      // Leaving SRU empty for now. I don't think we need it in this case
      sru: {},
    },
  };

  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configNEPersonHelper;
