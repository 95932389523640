import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'redux/reducers';
import { reformatDate } from '@agoy/common';
import { TaxSubSection, getTaxConfig } from '@agoy/tax-document';
import { TimePeriod } from '@agoy/document';

import { asResultClass, useApiSdk } from 'api-sdk';
import { last } from 'lodash';
import { AccountGroups, FinancialYear, Period } from '@agoy/api-sdk-core';
import useClientDataLayer from 'data/client/useClientDataLayer';
import {
  setAccountingBalances,
  setCurrentCustomer,
  setCurrentPeriod,
} from 'redux/actions';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';
import { selectFromCurrentTaxView } from '_tax/redux/tax-view/selectors';
import {
  clientYear,
  currentClient,
} from '_reconciliation/redux/accounting-view/selectors';
import { selectPeriod } from '_clients/redux/customer-view/selectors';
import { setTaxViewConfig } from '_tax/redux/tax-view/actions';
import TableServiceContext from '_shared/components/CommonTable/Context/TableServiceContext';
import { format, parseFinancialYear } from '@agoy/dates';
import taxViewService, { TaxViewService } from './TaxViewService';
import { TaxViewClientYearState } from '../redux/tax-view/reducer';
import TaxesExternalDocumentValues from './TaxesExternalDocumentValues';

interface TaxData {
  clientId: string;
  taxYearData: TaxViewClientYearState;
  financialYear: string;
  financialYearObj: FinancialYear;
  period: string;
  periodObj: Period;
  isLastPeriod: boolean;
  service: TaxViewService;
  accounts: string[] | undefined;
  groups: AccountGroups[] | undefined;
  internspecificationsSections: Record<string, TaxSubSection> | undefined;
  externspecificationsSections: Record<string, TaxSubSection> | undefined;
  overDeprecationSections: Record<string, TaxSubSection> | undefined;
  isDetailedSpecification: boolean;
  onToggleDetailedSpecification: (active: boolean) => void;
}

const TaxesDataContext = createContext<TaxData>({} as TaxData);

type ProviderProps = {
  clientId: string;
  financialYear: string;
  financialYearId: number;
  period: string;
  periods: Period[];
  children: JSX.Element;
};

export const TaxesDataContextProvider = ({
  clientId,
  financialYear,
  financialYearId,
  period,
  periods,
  children,
}: ProviderProps): JSX.Element | null => {
  const dispatch = useDispatch();

  const [taxState, setTaxState] = useState<TaxData | null>(null);
  const [groups, setGroups] = useState<AccountGroups[]>([]);

  const [isDetailedSpecification, setDetailedSpecification] = useState(true);
  const [
    currentAccountingBalancesUpdatedAt,
    setCurrentAccountingBalancesUpdatedAt,
  ] = useState<number | undefined>();

  const accountingBalancesUpdatedAt = useSelector(
    clientYear(
      clientId,
      financialYear,
      (state) => state.accountingBalancesUpdatedAt
    )
  );

  const companyType = useSelector((state) => state.customers[clientId].type);

  const clientDataLayer = useClientDataLayer(clientId);

  useEffect(() => {
    dispatch(setCurrentCustomer(clientId, financialYear));
    dispatch(setCurrentPeriod(period));
  }, [clientId, dispatch, financialYear, period]);

  useEffect(() => {
    if (accountingBalancesUpdatedAt === undefined) {
      const sub = clientDataLayer.accountingBalances
        .getAccountingBalances(financialYear)
        .subscribe((result) => {
          if (result.ok) {
            dispatch(
              setAccountingBalances(
                clientId,
                financialYear,
                result.val?.accountingBalances ?? null,
                result.val?.updatedAt ?? Date.now()
              )
            );
          }
        });
      return () => {
        sub.unsubscribe();
      };
    }
  }, [
    accountingBalancesUpdatedAt,
    financialYear,
    clientDataLayer,
    dispatch,
    clientId,
  ]);

  const taxesConfig = useSelector(
    selectFromCurrentTaxView((state) => state.config)
  );

  const taxYears = useSelector((state) => {
    return state.taxView.clients[clientId].years;
  });

  const financialYears = useSelector((state) => {
    return state.customers[clientId].rawFinancialYears;
  });

  const accountingBalances = useSelector(
    currentClient((state) => state.years[financialYear]?.accountingBalances)
  );

  const userInputData = useSelector(
    currentClient((state) => state.years[financialYear]?.userInput)
  );

  const currentPeriod = useSelector(selectPeriod(period));

  const internspecificationsSections = useSelector((state) => {
    return financialYear && clientId
      ? state.taxView.clients[clientId].years[financialYear]?.state
          ?.internspecifications
      : {};
  });

  const externspecificationsSections = useSelector((state) => {
    return financialYear && clientId
      ? state.taxView.clients[clientId].years[financialYear]?.state
          ?.externspecifications
      : {};
  });

  const overDeprecationSections = useSelector((state) => {
    return financialYear && clientId
      ? state.taxView.clients[clientId].years[financialYear]?.state
          ?.overdepreciation
      : {};
  });

  const sdk = useApiSdk();

  const fetchGroups = useCallback(
    async (period: Period): Promise<void> => {
      if (financialYearId && clientId) {
        const response = await asResultClass(
          sdk.getAccountsByGroups({
            clientid: clientId,
            periodId: period.id,
            financialYearId,
          })
        );
        if (response.ok) {
          const { groups: accountGroups } = response.val;
          setGroups(accountGroups);
        }
      }
    },
    [sdk, financialYearId, clientId]
  );

  const accounts = useMemo(() => {
    if (accountingBalances && currentPeriod) {
      return accountingBalances?.accounts
        .filter((account) => {
          const accountData = Object.entries(userInputData || {}).find(
            ([key, value]) => {
              return (
                key.startsWith('account') &&
                account.number === key.substring(7) &&
                value[currentPeriod.id] &&
                Object.keys(value[currentPeriod.id]).length > 0 &&
                (value[currentPeriod.id].visited ||
                  value[currentPeriod.id].saldo)
              );
            }
          );
          return !!accountData;
        })
        .map((account) => `${account.number} ${account.name}`);
    }
    return [];
  }, [accountingBalances, userInputData, currentPeriod]);

  useEffect(() => {
    if (currentPeriod) {
      fetchGroups(currentPeriod);
    }
  }, [currentPeriod, fetchGroups]);

  useEffect(() => {
    let yearState: TaxViewClientYearState | null = null;

    if (taxYears && financialYear) {
      yearState = taxYears[financialYear];
    }
    if (yearState === null || yearState.config === null) {
      getTaxConfig(
        (clientId, financialYear) =>
          sdk.getClientsTax({ clientid: clientId, financialYear }),
        clientId,
        financialYear,
        periods
          .filter((p) => p.type === 'month')
          .map((p) => TimePeriod.fromISODates(p).value),
        companyType
      ).then((config) =>
        dispatch(setTaxViewConfig(clientId, financialYear, config))
      );
    }

    const { start } = parseFinancialYear(financialYear);
    const financialYearObj = financialYears.find(
      (item) => item.start === format(start, 'yyyy-MM-dd')
    );

    if (yearState && financialYearObj && currentPeriod) {
      setTaxState({
        service: taxViewService(clientId, financialYear, period, dispatch),
        taxYearData: yearState,
        financialYear,
        financialYearObj,
        period,
        periodObj: currentPeriod,
        isLastPeriod:
          last(periods)?.start ===
          reformatDate(period, 'yyyyMMdd', 'yyyy-MM-dd'),
        clientId,
        accounts,
        groups,
        internspecificationsSections,
        externspecificationsSections,
        overDeprecationSections,
        isDetailedSpecification,
        onToggleDetailedSpecification: setDetailedSpecification,
      });
    }
  }, [
    clientId,
    dispatch,
    financialYear,
    financialYears,
    period,
    currentPeriod,
    periods,
    accounts,
    groups,
    internspecificationsSections,
    externspecificationsSections,
    overDeprecationSections,
    sdk,
    taxYears,
    isDetailedSpecification,
    companyType,
  ]);

  useEffect(() => {
    // Refetching the data when updating the values from FN
    if (
      !taxesConfig ||
      !accountingBalancesUpdatedAt ||
      currentAccountingBalancesUpdatedAt === accountingBalancesUpdatedAt
    ) {
      return;
    }
    dispatch(setTaxViewConfig(clientId, financialYear, taxesConfig));
    setCurrentAccountingBalancesUpdatedAt(accountingBalancesUpdatedAt);
  }, [
    accountingBalancesUpdatedAt,
    clientId,
    dispatch,
    taxesConfig,
    financialYear,
    currentAccountingBalancesUpdatedAt,
  ]);

  if (taxState === null) {
    return null;
  }

  if (taxState.taxYearData.state === null) {
    return <LoadingPlaceholder />;
  }

  return (
    <TaxesDataContext.Provider value={taxState}>
      <TableServiceContext.Provider value={taxState.service}>
        <TaxesExternalDocumentValues />
        {children}
      </TableServiceContext.Provider>
    </TaxesDataContext.Provider>
  );
};

export default TaxesDataContext;
