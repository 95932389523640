import React, { useContext } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { IndexPage } from '@agoy/annual-report-view';
import { booleanValue, stringValue } from '@agoy/document';
import {
  AnnualGeneralMeeting,
  SharesCompanyManagementReport,
  IndividualCompanyManagementReport,
  EconomicAssociationManagementReport,
  Signatures,
  LimitedCompaniesManagementReport,
  CashFlowStatementGeneral,
} from '@agoy/annual-report-document';
import { FormattingContextProvider } from '@agoy/formatting';
import useObservable from 'utils/useObservable';
import AnnualReportDataServiceContext from '_annual-report/service/AnnualReportDataServiceContext';
import CashFlowStatementPreview from './Parts/CashFlowStatement/CashFlowStatementPreview';
import SignaturesPreview from './Parts/Signatures/SignaturesPreview';
import ManagementReportPreview from './Parts/ManagementReport/ManagementReportPreview';
import AnnualGeneralMeetingPreview from './Parts/AnnualGeneralMeeting/AnnualGeneralMeetingPreview';
import NotesPreview from './Parts/Notes/NotesPreview';
import BalanceSheetPreview from './Parts/BalanceSheet/BalanceSheetPreview';
import IncomeStatementPreview from './Parts/IncomeStatement/IncomeStatementPreview';
import { SettingsContextProvider } from './Context/SettingsContext';
import useAnnualReport from './useAnnualReport';
import useDocumentConfiguration from './useDocumentConfiguration';
import Page from './UI/Print/Page';

interface AnnualReportPrintProps {
  pageNumbers: Record<string, number>;
  withCertificate?: boolean;
}

const AnnualReportPrint = ({
  pageNumbers,
  withCertificate,
}: AnnualReportPrintProps): JSX.Element | null => {
  const { path } = useRouteMatch();
  const data = useContext(AnnualReportDataServiceContext);
  const annualReport = useAnnualReport();
  const documentConfig = useDocumentConfiguration();

  const ccDocument = useObservable(data.confirmationCertificate?.report);

  const annualGeneralMeetingDocument = useObservable(
    data.annualGeneralMeeting?.report
  );

  const annualReportSignaturesDocument = useObservable(
    data.annualReportSignatures?.report
  );

  if (!annualReport || !documentConfig) {
    return null;
  }

  const confirmationCertificate =
    'confirmationCertificate' in annualReport
      ? annualReport.confirmationCertificate
      : ccDocument?.confirmationCertificate;

  const currentFinancialYear = stringValue(
    annualReport.settings.section.period
  );

  const { reportType, isDigitalSubmission, reportTransitionK3toK2, version } =
    documentConfig;

  const getManagementReport = ():
    | SharesCompanyManagementReport
    | IndividualCompanyManagementReport
    | EconomicAssociationManagementReport
    | LimitedCompaniesManagementReport
    | undefined =>
    'managementReport' in annualReport
      ? annualReport.managementReport
      : undefined;

  const getGeneralMeeting = (): AnnualGeneralMeeting | undefined =>
    'annualGeneralMeeting' in annualReport && annualReport.version === '1'
      ? annualReport.annualGeneralMeeting
      : annualGeneralMeetingDocument?.annualGeneralMeeting;

  const getCashflowStatement = (): CashFlowStatementGeneral | undefined => {
    if ('cashFlowStatement' in annualReport) {
      if (
        (annualReport.version === '1' &&
          annualReport.cashFlowStatement.section.active) ||
        (annualReport.version === '2' &&
          annualReport.settings.section.cashFlowStatementActive.value)
      ) {
        return annualReport.cashFlowStatement;
      }
    }
    return undefined;
  };

  const getSignatures = (): Signatures | undefined =>
    annualReport.version === '1'
      ? annualReport.signatures
      : annualReportSignaturesDocument?.signatures;

  return (
    <SettingsContextProvider settings={annualReport.settings}>
      <FormattingContextProvider
        displayInThousands={booleanValue(
          annualReport.settings.section.formatting.displayInThousands
        )}
      >
        <Switch>
          <Route path={`${path}/index`}>
            <Page>
              <IndexPage
                pageNumbers={pageNumbers}
                annualReport={annualReport}
                documentConfig={documentConfig}
                confirmationCertificate={confirmationCertificate ?? null}
                signatures={getSignatures()}
                injectFSI={withCertificate}
                isManualSubmission={!isDigitalSubmission}
                annualGeneralMeeting={getGeneralMeeting()}
                documentTypeVersion={version}
              />
            </Page>
          </Route>
          <Route path={`${path}/managementReport`}>
            <ManagementReportPreview
              annualGeneralMeeting={getGeneralMeeting()}
              managementReport={getManagementReport()}
              notes={annualReport.notes}
              transitionK3toK2={reportTransitionK3toK2}
              isDigitalSubmission={isDigitalSubmission}
              reportType={reportType}
              settings={annualReport.settings}
              version={version}
            />
          </Route>
          <Route path={`${path}/incomeStatement`}>
            <IncomeStatementPreview
              incomeStatement={annualReport.incomeStatement}
              settings={annualReport.settings}
              version={version}
            />
          </Route>
          <Route path={`${path}/balanceSheet`}>
            <BalanceSheetPreview
              balanceSheet={annualReport.balanceSheet}
              settings={annualReport.settings}
              version={version}
            />
          </Route>
          <Route path={`${path}/cashFlowStatement`}>
            <CashFlowStatementPreview
              cashFlowStatement={getCashflowStatement()}
              settings={annualReport.settings}
              version={version}
            />
          </Route>
          <Route path={`${path}/notes`}>
            <Page>
              <NotesPreview
                annualReport={annualReport}
                reportType={reportType}
                isDigitalSubmission={!!isDigitalSubmission}
                documentTypeVersion={version}
              />
            </Page>
          </Route>
          <Route path={`${path}/signatures`}>
            <SignaturesPreview
              currentFinancialYear={currentFinancialYear || ''}
              signatures={getSignatures()}
              isDigitalSubmission={isDigitalSubmission}
              settings={annualReport.settings}
              version={version}
            />
          </Route>
          <Route path={`${path}/annualGeneralMeeting`}>
            <AnnualGeneralMeetingPreview
              annualGeneralMeeting={getGeneralMeeting()}
              settings={annualReport.settings}
              signatures={getSignatures()}
            />
          </Route>
          <Route path={path} exact>
            {/* This is what you see when you open the PRINT Preview */}
            <Page>
              <IndexPage
                pageNumbers={pageNumbers}
                annualReport={annualReport}
                isManualSubmission={!isDigitalSubmission}
                documentConfig={documentConfig}
                confirmationCertificate={confirmationCertificate ?? null}
                signatures={getSignatures()}
                annualGeneralMeeting={getGeneralMeeting()}
                documentTypeVersion={version}
              />
            </Page>
            {'managementReport' in pageNumbers && (
              <ManagementReportPreview
                annualGeneralMeeting={getGeneralMeeting()}
                managementReport={getManagementReport()}
                notes={annualReport.notes}
                transitionK3toK2={reportTransitionK3toK2}
                isDigitalSubmission={isDigitalSubmission}
                reportType={reportType}
                settings={annualReport.settings}
                version={version}
              />
            )}
            <IncomeStatementPreview
              incomeStatement={annualReport.incomeStatement}
              settings={annualReport.settings}
              version={version}
            />
            <BalanceSheetPreview
              balanceSheet={annualReport.balanceSheet}
              settings={annualReport.settings}
              version={version}
            />
            <CashFlowStatementPreview
              cashFlowStatement={getCashflowStatement()}
              settings={annualReport.settings}
              version={version}
            />
            <NotesPreview
              annualReport={annualReport}
              reportType={reportType}
              isDigitalSubmission={isDigitalSubmission || false}
              documentTypeVersion={version}
            />
            <SignaturesPreview
              currentFinancialYear={currentFinancialYear || ''}
              signatures={getSignatures()}
              isDigitalSubmission={isDigitalSubmission}
              settings={annualReport.settings}
              version={version}
            />
          </Route>
        </Switch>
      </FormattingContextProvider>
    </SettingsContextProvider>
  );
};

export default AnnualReportPrint;
