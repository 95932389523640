/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-7165
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
  tupleRef,
} from '../../../../../common/utils/util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2001K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2001K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Redovisnings- och värderingsprinciper'),
  data: {
    active: true,
    RedovisningsVarderingsprinciper: {
      type: 'group',
      active: true,
      title: field('Redovisnings- och värderingsprinciper'),
      value: ixbrlField(
        field(
          'Årsredovisningen har upprättats i enlighet med årsredovisningslagen och Bokföringsnämndens allmänna råd BFNAR 2012:1 Årsredovisning och koncernredovisning (K3).'
        ),
        {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsVarderingsprinciper',
          standardRubrik: 'Redovisnings- och värderingsprinciper',
        }
      ),
    },
    RedovisningsprinciperAvvikelserGrundlaggandePrinciper: {
      type: 'group',
      active: true,
      title: field('Avvikelser från grundläggande principer'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperAvvikelserGrundlaggandePrinciper',
        standardRubrik:
          'Avvikelser från grundläggande principer (skäl och effekt, vid kvittning även bruttobelopp)',
      }),
    },
    RedovisningsprinciperByteRedovisningsprincip: {
      type: 'group',
      active: true,
      title: field('Byte av redovisningsprincip'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperByteRedovisningsprincip',
        standardRubrik: 'Byte av redovisningsprincip',
      }),
    },
    RedovisningsprinciperUpplysningSkalAvvikelseJamforelsesiffror: {
      type: 'group',
      active: true,
      title: field(
        'Skälen för avvikelse om jämförelsesiffror ej räknats om vid byte av principer'
      ),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperUpplysningSkalAvvikelseJamforelsesiffror',
        standardRubrik:
          'Upplysning om skälen för avvikelse om jämförelsesiffror ej räknats om vid byte av redovisningsprinciper',
      }),
    },
    RedovisningsprinciperBristandeJamforbarhetAndradePrinciper: {
      type: 'group',
      active: true,
      title: field('Bristande jämförbarhet vid ändrade principer'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperBristandeJamforbarhetAndradePrinciper',
        standardRubrik:
          'Bristande jämförbarhet vid ändrade redovisningsprinciper',
      }),
    },
    RedovisningsprinciperAvvikelseAllmannaRadRekommendationer: {
      type: 'group',
      active: true,
      title: field('Avvikelse från allmänna råd eller rekommendationer'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperAvvikelseAllmannaRadRekommendationer',
        standardRubrik: 'Avvikelse från allmänna råd eller rekommendationer',
      }),
    },
    RedovisningsprinciperByteUppstallningsformResultatRakning: {
      type: 'group',
      active: true,
      title: field('Byte av uppställningsform för RR'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperByteUppstallningsformResultatRakning',
        standardRubrik: 'Byte av uppställningsform för resultaträkning',
      }),
    },
    RedovisningsprinciperUpplysningForstaTillampningK3: {
      type: 'group',
      active: true,
      title: field('Upplysningar första gången K3 tillämpas'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperUpplysningForstaTillampningK3',
        standardRubrik:
          'Upplysningar första gången BFNAR 2012:1 (K3) tillämpas',
      }),
    },
    RedovisningsprinciperOmrakningUtlandskValutaAbstract: {
      type: 'group',
      active: true,
      title: field('Utländsk valuta'),
      RedovisningsprinciperOmrakningRedovisningsvaluta: {
        type: 'group',
        active: true,
        title: field('Omräkning till redovisningsvaluta'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperOmrakningRedovisningsvaluta',
          standardRubrik: 'Principer för omräkning till redovisningsvaluta',
        }),
      },
      RedovisningsprinciperOmrakningFilialerTillgangarSkulder: {
        type: 'group',
        active: true,
        title: field('Omräkning av filialers tillgångar och skulder'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperOmrakningFilialerTillgangarSkulder',
          standardRubrik:
            'Principer för omräkning av filialers tillgångar och skulder',
        }),
      },
    },
    RedovisningsprinciperIntakter: {
      type: 'group',
      active: true,
      title: field('Intäkter'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperIntakter',
        standardRubrik: 'Redovisningsprinciper för intäkter',
      }),
      RedovisningsprinciperForsaljningVaror: {
        type: 'group',
        active: true,
        title: field('Försäljning av varor'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperForsaljningVaror',
          standardRubrik:
            'Redovisningsprinciper för intäkter från försäljning av varor',
        }),
      },
      RedovisningsprinciperIntakterTjansteEntreprenaduppdrag: {
        type: 'group',
        active: true,
        title: field('Tjänste- och entreprenaduppdrag'),
        value: ixbrlField(
          field(
            '1. Vid försäljning av varor redovisas intäkten vid leverans.\n2. Redovisning av intäkt vid försäljning av varor sker när:\n- Sannolikheten att intäkten kommer att tillfalla företaget är hög.\n- Det är trovärdigt att inkomsten kan beräknas.\n- Överföring av betydande risker och fördelarna som är kopplade med varornas ägande till köparen.\n- Företaget har inte längre ett sådant engagemang i den löpande förvaltningen som vanligtvis förknippas med ägande och utövar inte heller någon reell kontroll över de sålda varorna.\n- Utgifter som finns eller väntas på grund av transaktionen kan räknas ut på ett säkert sätt.'
          ),
          {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:RedovisningsprinciperIntakterTjansteEntreprenaduppdrag',
            standardRubrik:
              'Redovisningsprinciper från intäkter för tjänste- och entreprenaduppdrag',
          }
        ),
      },
      RedovisningsprinciperIntakterKundlojalitetsprogram: {
        type: 'group',
        active: true,
        title: field('Kundlojalitetsprogram'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperIntakterKundlojalitetsprogram',
          standardRubrik:
            'Redovisningsprinciper för intäkter vid kundlojalitetsprogram',
        }),
      },
      RedovisningsprinciperIntakterRantaRoyaltyUtdelning: {
        type: 'group',
        active: true,
        title: field('Ränta, royalty och utdelning'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperIntakterRantaRoyaltyUtdelning',
          standardRubrik:
            'Redovisningsprinciper för intäkter avseende ränta, royalty och utdelning',
        }),
      },
      RedovisningsprinciperIntakterGarantiataganden: {
        type: 'group',
        active: true,
        title: field('Garantiåtaganden'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperIntakterGarantiataganden',
          standardRubrik:
            'Redovisningsprinciper för intäkter vid garantiåtaganden',
        }),
      },
    },
    RedovisningsprinciperOffentligaBidrag: {
      type: 'group',
      active: true,
      title: field('Offentliga bidrag'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperOffentligaBidrag',
        standardRubrik: 'Redovisningsprinciper för offentliga bidrag',
      }),
    },
    RedovisningsprinciperErsattningAnstallda: {
      type: 'group',
      active: true,
      title: field('Ersättningar till anställda'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperErsattningAnstallda',
        standardRubrik: 'Redovisningsprinciper för ersättningar till anställda',
      }),
    },
    RedovisningsprinciperLaneutgifter: {
      type: 'group',
      active: true,
      title: field('Låneutgifter'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperLaneutgifter',
        standardRubrik: 'Redovisningsprinciper för låneutgifter',
      }),
    },
    RedovisningsprinciperSkatter: {
      type: 'group',
      active: true,
      title: field('Skatter'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperSkatter',
        standardRubrik:
          'Redovisningsprinciper för skatter inklusive uppskjuten skatt',
      }),
    },
    RedovisningsprinciperLeasing: {
      type: 'group',
      active: true,
      title: field('Leasing'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperLeasing',
        standardRubrik: 'Redovisningsprinciper för leasing',
      }),
    },
    RedovisningsprinciperImmateriellaAnlaggningstillgangar: {
      type: 'group',
      active: true,
      title: field('Immateriella anläggningstillgångar'),
      value: ixbrlField(
        field(
          'Immateriella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjande period. Nyttjandeperioden omprövas per varje balansdag. Pågående projekt skrivs inte av utan nedskrivningsprövas årligen. Följande nyttjande perioder tillämpas:'
        ),
        {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperImmateriellaAnlaggningstillgangar',
          standardRubrik:
            'Redovisningsprinciper för immateriella anläggningstillgångar',
        }
      ),
      RedovisningsprinciperAktiveringInterntUpparbetadeImmateriellaAnlaggningstillgangar:
        {
          type: 'group',
          active: true,
          title: field(
            'Aktivering av internt upparbetade immateriella anläggningstillgångar'
          ),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:RedovisningsprinciperAktiveringInterntUpparbetadeImmateriellaAnlaggningstillgangar',
            standardRubrik:
              'Redovisningsprinciper för aktivering av internt upparbetade immateriella anläggningstillgångar',
          }),
        },
      RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar: {
        type: 'group',
        active: true,
        title: field('Avskrivning'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar',
          standardRubrik:
            'Redovisningsprinciper för avskrivningar immateriella anläggningstillgångar',
        }),
        RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar: {
          type: 'group',
          active: true,
          title: field('Avskrivningsprinciper'),
          table: table<IxbrlCell>(
            'notes.note1.data.RedovisningsprinciperImmateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar.table',
            {
              id: 'AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
              label: 'Typ av tillgång',
              dataType: 'text',
              active: true,
            },
            {
              id: 'AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
              label: 'Procent',
              dataType: 'numeric',
              active: true,
              canToggle: true,
            },
            {
              id: 'AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
              label: 'År',
              dataType: 'numeric',
              active: true,
              canToggle: true,
            }
          )
            .addRows((rows) => {
              rows
                .addRowWithType(
                  'RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar',
                  'hidden',
                  undefined,
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipImmateriellaAnlaggningstillgangarBalanseradeUtgifterUtvecklingsarbetenLiknandeArbetenTuple',
                      'header',
                      noteLabel(
                        'Balanserade utgifter för utvecklingsarbeten och liknande arbeten'
                      ),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarBalanseradeUtgifterUtvecklingsarbetenLiknandeArbetenTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipImmateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarBalanseradeUtgifterUtvecklingsarbetenLiknandeArbetenTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipImmateriellaAnlaggningstillgangarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterTuple',
                      'header',
                      noteLabel(
                        'Koncessioner, patent, licenser, varumärken samt liknande rättigheter'
                      ),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipImmateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarKoncessionerPatentLicenserVarumarkenLiknandeRattigheterTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipImmateriellaAnlaggningstillgangarHyresratterLiknandeRattigheterTuple',
                      'header',
                      noteLabel('Hyresrätter och liknande rättigheter'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarHyresratterLiknandeRattigheterTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipImmateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarHyresratterLiknandeRattigheterTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipImmateriellaAnlaggningstillgangarGoodwillTuple',
                      'header',
                      noteLabel('Goodwill'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarGoodwillTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipImmateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på immateriell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipImmateriellaAnlaggningstillgangarGoodwillTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipImmateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende immateriella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  return rows.build();
                });
              return rows.build();
            })
            .build(),
        },
        AvskrivningarImmateriellaAnlaggningstillgangarKommentar: {
          type: 'group',
          active: true,
          title: field('Kommentar'),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:AvskrivningarImmateriellaAnlaggningstillgangarKommentar',
            standardRubrik:
              'Kommentar till avskrivningsprinciper för immateriella anläggningstillgångar',
          }),
        },
      },
    },
    RedovisningsprinciperMateriellaAnlaggningstillgangar: {
      type: 'group',
      active: true,
      title: field('Materiella anläggningstillgångar'),
      value: ixbrlField(
        field(
          'Materiella anläggningstillgångar redovisas till anskaffningsvärde med avdrag för ackumulerade avskrivningar och eventuella nedskrivningar. Tillgångarna skrivs av linjärt över tillgångarnas bedömda nyttjandeperiod förutom mark som inte skrivs av. Nyttjandeperioden omprövas per varje balansdag. Följande nyttjandeperioder tillämpas:'
        ),
        {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperMateriellaAnlaggningstillgangar',
          standardRubrik:
            'Redovisningsprinciper materiella anläggningstillgångar',
        }
      ),
      RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar: {
        type: 'group',
        active: true,
        title: field('Avskrivning'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar',
          standardRubrik:
            'Redovisningsprinciper för avskrivningar materiella anläggningstillgångar',
        }),
        RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar: {
          type: 'group',
          active: true,
          title: field('Avskrivningsprinciper'),
          table: table<IxbrlCell>(
            'notes.note1.data.RedovisningsprinciperMateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar.RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar.table',
            {
              id: 'AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
              label: 'Typ av tillgång',
              dataType: 'text',
              active: true,
            },
            {
              id: 'AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
              label: 'Procent',
              dataType: 'numeric',
              active: true,
              canToggle: true,
            },
            {
              id: 'AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
              label: 'År',
              dataType: 'numeric',
              active: true,
              canToggle: true,
            }
          )
            .addRows((rows) => {
              rows
                .addRowWithType(
                  'RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar',
                  'hidden',
                  undefined,
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipMateriellaAnlaggningstillgangarByggnaderTuple',
                      'header',
                      noteLabel('Byggnader'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarByggnaderTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarByggnaderTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipMateriellaAnlaggningstillgangarMaskinerAndraTekniskaAnlaggningarTuple',
                      'header',
                      noteLabel('Maskiner och andra tekniska anläggningar'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarMaskinerAndraTekniskaAnlaggningarTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarMaskinerAndraTekniskaAnlaggningarTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipMateriellaAnlaggningstillgangarInventarierVerktygInstallationerTuple',
                      'header',
                      noteLabel('Inventarier, verktyg och installationer'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarInventarierVerktygInstallationerTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarInventarierVerktygInstallationerTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipMateriellaAnlaggningstillgangarForbattringsutgifterAnnansFastighetTuple',
                      'header',
                      noteLabel('Förbättringsutgifter på annans fastighet'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarForbattringsutgifterAnnansFastighetTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarForbattringsutgifterAnnansFastighetTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  rows
                    .addRowWithType(
                      'AvskrivningsprincipMateriellaAnlaggningstillgangarOvrigaMateriellaAnlaggningstillgangarTuple',
                      'header',
                      noteLabel('Övriga materiella anläggningstillgångar'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        '1',
                        'row',
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarOvrigaMateriellaAnlaggningstillgangarTuple',
                            id: `${rows.getBaseId()}.1.tuple`,
                            tupleID: `${rows.getBaseId()}.1.tuple`,
                          },
                          tupleRef: tupleRef(1, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        }),
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${rows.getBaseId()}.1.tuple`),
                          previousYearTuple: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.newRowTemplateGenerator((_id, baseId) => ({
                    id: _id,
                    active: true,
                    type: 'row',
                    cells: {
                      AvskrivningsprincipMateriellAnlaggningstillgangBenamning:
                        ixbrlCell(value('Typ av tillgång'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangBenamning',
                          contextRef: 'period0',
                          standardRubrik:
                            'Benämning på materiell anläggningstillgång',
                          negateValue: false,
                          tuple: {
                            name: 'se-gaap-ext:AvskrivningsprincipMateriellaAnlaggningstillgangarOvrigaMateriellaAnlaggningstillgangarTuple',
                            id: `${baseId}.${_id}.tuple`,
                            tupleID: `${baseId}.${_id}.tuple`,
                          },
                          tupleRef: tupleRef(1, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent:
                        ixbrlCell(value('Procent'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangAvskrivningsprocent',
                          contextRef: 'period0',
                          standardRubrik:
                            'Avskrivningsprocent avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(2, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                      AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod:
                        ixbrlCell(value('År'), {
                          type: 'stringItemType',
                          name: 'se-gen-base:AvskrivningsprincipMateriellAnlaggningstillgangNyttjandeperiod',
                          contextRef: 'period0',
                          standardRubrik:
                            'Nyttjandeperiod avseende materiella anläggningstillgångar',
                          negateValue: false,
                          tupleRef: tupleRef(3, `${baseId}.${_id}.tuple`),
                          previousYearTuple: false,
                        }),
                    },
                  }));
                  return rows.build();
                });
              return rows.build();
            })
            .build(),
        },
        AvskrivningarMateriellaAnlaggningstillgangarKommentar: {
          type: 'group',
          active: true,
          title: field('Kommentar'),
          value: ixbrlField(field(''), {
            contextRef: 'period0',
            type: 'stringItemType',
            name: 'se-gen-base:AvskrivningarMateriellaAnlaggningstillgangarKommentar',
            standardRubrik:
              'Kommentar till avskrivningsprinciper för materiella anläggningstillgångar',
          }),
        },
      },
    },
    RedovisningsprinciperNedskrivningarAnlaggningstillgangar: {
      type: 'group',
      active: true,
      title: field('Nedskrivningar av anläggningstillgångar'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperNedskrivningarAnlaggningstillgangar',
        standardRubrik:
          'Redovisningsprinciper för nedskrivningar av anläggningstillgångar',
      }),
    },
    RedovisningsprinciperAndelarKoncernforetagIntresseforetagGemensamtStyrdaForetagAgarintressen:
      {
        type: 'group',
        active: true,
        title: field(
          'Andelar i koncernföretag, intresseföretag, gemensamt styrda företag och företag som det finns ägarintresse i'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:RedovisningsprinciperAndelarKoncernforetagIntresseforetagGemensamtStyrdaForetagAgarintressen',
          standardRubrik:
            'Redovisningsprinciper för andelar i koncernföretag, intresseföretag, gemensamt styrda företag och företag som det finns ägarintresse i',
        }),
      },
    RedovisningsprinciperIntakterKoncernbidragAktieagartillskott: {
      type: 'group',
      active: true,
      title: field('Koncernbidrag och aktieägartillskott'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperIntakterKoncernbidragAktieagartillskott',
        standardRubrik:
          'Redovisningsprinciper för koncernbidrag och aktieägartillskott',
      }),
    },
    RedovisningsprinciperJointVenture: {
      type: 'group',
      active: true,
      title: field('Joint venture'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperJointVenture',
        standardRubrik: 'Redovisningsprinciper för joint venture',
      }),
    },
    RedovisningsprinciperFinansiellaInstrument: {
      type: 'group',
      active: true,
      title: field('Finansiella instrument'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperFinansiellaInstrument',
        standardRubrik: 'Redovisningsprinciper för finansiella instrument',
      }),
    },
    RedovisningsprinciperSakringsredovisning: {
      type: 'group',
      active: true,
      title: field('Säkringsredovisning'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperSakringsredovisning',
        standardRubrik: 'Redovisningsprinciper för säkringsredovisning',
      }),
    },
    RedovisningsprinciperVarulager: {
      type: 'group',
      active: true,
      title: field('Varulager'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperVarulager',
        standardRubrik: 'Redovisningsprinciper för varulager',
      }),
    },
    RedovisningsprinciperAvsattningar: {
      type: 'group',
      active: true,
      title: field('Avsättningar'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperAvsattningar',
        standardRubrik: 'Redovisningsprinciper för avsättningar',
      }),
    },
    RedovisningsprinciperEventualforpliktelser: {
      type: 'group',
      active: true,
      title: field('Eventualtillgångar och eventualförpliktelser'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperEventualforpliktelser',
        standardRubrik:
          'Redovisningsprinciper eventualtillgångar och eventualförpliktelser',
      }),
    },
    RedovisningsprinciperInkramsforvarv: {
      type: 'group',
      active: true,
      title: field('Inkråmsförvärv'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperInkramsforvarv',
        standardRubrik: 'Redovisningsprinciper inkråmsförvärv',
      }),
    },
    RedovisningsprinciperKassaflodesanalys: {
      type: 'group',
      active: true,
      title: field('Kassaflödesanalys'),
      value: ixbrlField(field(''), {
        contextRef: 'period0',
        type: 'stringItemType',
        name: 'se-gen-base:RedovisningsprinciperKassaflodesanalys',
        standardRubrik: 'Redovisningsprinciper för kassaflödesanalys',
      }),
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2001K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        RedovisningsVarderingsprinciper: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperAvvikelserGrundlaggandePrinciper: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperByteRedovisningsprincip: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperUpplysningSkalAvvikelseJamforelsesiffror: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperBristandeJamforbarhetAndradePrinciper: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperAvvikelseAllmannaRadRekommendationer: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperByteUppstallningsformResultatRakning: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperUpplysningForstaTillampningK3: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperOmrakningUtlandskValutaAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            RedovisningsprinciperOmrakningRedovisningsvaluta: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },

            RedovisningsprinciperOmrakningFilialerTillgangarSkulder: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },

        RedovisningsprinciperIntakter: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,

            RedovisningsprinciperForsaljningVaror: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },

            RedovisningsprinciperIntakterTjansteEntreprenaduppdrag: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },

            RedovisningsprinciperIntakterKundlojalitetsprogram: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },

            RedovisningsprinciperIntakterRantaRoyaltyUtdelning: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },

            RedovisningsprinciperIntakterGarantiataganden: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },

        RedovisningsprinciperOffentligaBidrag: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperErsattningAnstallda: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperLaneutgifter: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperSkatter: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperLeasing: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperImmateriellaAnlaggningstillgangar: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,

            RedovisningsprinciperAktiveringInterntUpparbetadeImmateriellaAnlaggningstillgangar:
              {
                type: 'part' as const,
                children: {
                  type: type,
                  active: boolean,

                  title: cell,

                  value: cell,
                },
              },

            RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,

                RedovisningsprinciperAvskrivningImmateriellaAnlaggningstillgangar:
                  {
                    type: 'part' as const,
                    children: {
                      type: type,
                      active: boolean,

                      title: cell,

                      table: { type: 'table' as const },
                    },
                  },

                AvskrivningarImmateriellaAnlaggningstillgangarKommentar: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },
              },
            },
          },
        },

        RedovisningsprinciperMateriellaAnlaggningstillgangar: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,

            RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,

                RedovisningsprinciperAvskrivningMateriellaAnlaggningstillgangar:
                  {
                    type: 'part' as const,
                    children: {
                      type: type,
                      active: boolean,

                      title: cell,

                      table: { type: 'table' as const },
                    },
                  },

                AvskrivningarMateriellaAnlaggningstillgangarKommentar: {
                  type: 'part' as const,
                  children: {
                    type: type,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },
              },
            },
          },
        },

        RedovisningsprinciperNedskrivningarAnlaggningstillgangar: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperAndelarKoncernforetagIntresseforetagGemensamtStyrdaForetagAgarintressen:
          {
            type: 'part' as const,
            children: {
              type: type,
              active: boolean,

              title: cell,

              value: cell,
            },
          },

        RedovisningsprinciperIntakterKoncernbidragAktieagartillskott: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperJointVenture: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperFinansiellaInstrument: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperSakringsredovisning: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperVarulager: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperAvsattningar: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperEventualforpliktelser: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperInkramsforvarv: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },

        RedovisningsprinciperKassaflodesanalys: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            value: cell,
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
