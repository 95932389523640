/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k2-arsredovisning-2021-10-31-rev20230131_sv - Noter.csv
 * - Comments: nan
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';
import {
  sumAccountsIBs,
} from '../../../../../common/utils/accountSumsUtil';

const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2053K2v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2053K2v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Andra långfristiga värdepappersinnehav'),
  data: {
    active: true,
    AndraLangfristigaVardepappersinnehavAbstract: {
      type: 'group',
      active: true,
      title: field('Andra långfristiga värdepappersinnehav'),
      table: table<IxbrlCell>(
        'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table',
        {
          id: 'AndraLangfristigaVardepappersinnehavAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'AndraLangfristigaVardepappersinnehavAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows.addRowWithType(
                'AndraLangfristigaVardepappersinnehavAnskaffningsvardenIngaende',
                'sum_accounts',
                noteLabel('Ingående anskaffningsvärden'),
                ixbrlCell(ref(sumAccountsIBs('1350:1357', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndraLangfristigaVardepappersinnehavAnskaffningsvarden',
                  standardRubrik:
                    'Anskaffningsvärden andra långfristiga värdepappersinnehav',
                  saldo: 'debit',
                  negateValue: false,
                }),
                ixbrlCell(
                  ref(sumAccountsIBs('1350:1357', false, 'previousYear')),
                  {
                    contextRef: 'balans2',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraLangfristigaVardepappersinnehavAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden andra långfristiga värdepappersinnehav',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAbstract',
                  'header',
                  noteLabel('Förändringar av anskaffningsvärden'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenInkop',
                    'row',
                    noteLabel('Inköp'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenInkop',
                      standardRubrik:
                        'Ökning av anskaffningsvärden i samband med inköp av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för andra långfristiga värdepappersinnehav genom fusion',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenFusion',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden för andra långfristiga värdepappersinnehav genom fusion',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenForsaljningar',
                    'row',
                    noteLabel('Försäljningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenForsaljningar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar av andra långfristiga värdepappersinnehav',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenForsaljningar',
                      standardRubrik:
                        'Minskning av anskaffningsvärden i samband med försäljningar av andra långfristiga värdepappersinnehav',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsResultatAndel',
                    'row',
                    noteLabel('Årets resultatandel'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsResultatAndel',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband periodens resultatandel i handelsbolag som är andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsResultatAndel',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband periodens resultatandel i handelsbolag som är andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsOmklassificeringar',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omklassificeringar av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAretsOmrakningsdifferenser',
                      standardRubrik:
                        'Ökning (minskning) av anskaffningsvärden i samband med omräkningsdifferenser av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AndraLangfristigaVardepappersinnehavAnskaffningsvardenUtgaende',
                'sum',
                noteLabel('Utgående anskaffningsvärden'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table.AndraLangfristigaVardepappersinnehavAbstract.AndraLangfristigaVardepappersinnehavAnskaffningsvardenIngaende.year'
                      ),
                      id(
                        'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table.AndraLangfristigaVardepappersinnehavAbstract.AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraLangfristigaVardepappersinnehavAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden andra långfristiga värdepappersinnehav',
                    saldo: 'debit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table.AndraLangfristigaVardepappersinnehavAbstract.AndraLangfristigaVardepappersinnehavAnskaffningsvardenIngaende.previousYear'
                      ),
                      id(
                        'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table.AndraLangfristigaVardepappersinnehavAbstract.AndraLangfristigaVardepappersinnehavForandringAnskaffningsvardenAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraLangfristigaVardepappersinnehavAnskaffningsvarden',
                    standardRubrik:
                      'Anskaffningsvärden andra långfristiga värdepappersinnehav',
                    saldo: 'debit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'AndraLangfristigaVardepappersinnehavNedskrivningarIngaende',
                'sum_accounts',
                noteLabel('Ingående nedskrivningar'),
                ixbrlCell(ref(sumAccountsIBs('1358', false)), {
                  contextRef: 'balans1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndraLangfristigaVardepappersinnehavNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar andra långfristiga värdepappersinnehav',
                  saldo: 'credit',
                  negateValue: true,
                }),
                ixbrlCell(ref(sumAccountsIBs('1358', false, 'previousYear')), {
                  contextRef: 'balans2',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:AndraLangfristigaVardepappersinnehavNedskrivningar',
                  standardRubrik:
                    'Ackumulerade nedskrivningar andra långfristiga värdepappersinnehav',
                  saldo: 'credit',
                  negateValue: true,
                })
              );
              rows
                .addRowWithType(
                  'AndraLangfristigaVardepappersinnehavForandringNedskrivningarAbstract',
                  'header',
                  noteLabel('Förändring av nedskrivningar'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'AndraLangfristigaVardepappersinnehavForandringNedskrivningarFusion',
                    'row',
                    noteLabel('Fusion'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för andra långfristiga värdepappersinnehav genom fusion',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarFusion',
                      standardRubrik:
                        'Ökning (minskning) av nedskrivningar för andra långfristiga värdepappersinnehav genom fusion',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaVardepappersinnehavForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                    'row',
                    noteLabel('Försäljningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarAterfordaNedskrivningarForsaljningar',
                      standardRubrik:
                        'Återförda nedskrivningar i samband med försäljningar eller utrangeringar av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaVardepappersinnehavForandringNedskrivningarAterfordaNedskrivningar',
                    'row',
                    noteLabel('Återförda nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarAterfordaNedskrivningar',
                      standardRubrik:
                        'Periodens återförda nedskrivningar av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaVardepappersinnehavForandringNedskrivningarOmklassificeringar',
                    'row',
                    noteLabel('Omklassificeringar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarOmklassificeringar',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omklassificeringar av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaVardepappersinnehavForandringNedskrivningarAretsNedskrivningar',
                    'row',
                    noteLabel('Årets nedskrivningar'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av andra långfristiga värdepappersinnehav',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarAretsNedskrivningar',
                      standardRubrik:
                        'Periodens nedskrivningar av andra långfristiga värdepappersinnehav',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'AndraLangfristigaVardepappersinnehavForandringNedskrivningarOmrakningsdifferenser',
                    'row',
                    noteLabel('Omräkningsdifferenser'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraLangfristigaVardepappersinnehavForandringNedskrivningarOmrakningsdifferenser',
                      standardRubrik:
                        'Minskning (ökning) av nedskrivningar i samband med omräkningsdifferenser av andra långfristiga värdepappersinnehav',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'AndraLangfristigaVardepappersinnehavNedskrivningarUtgaende',
                'sum',
                noteLabel('Utgående nedskrivningar'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table.AndraLangfristigaVardepappersinnehavAbstract.AndraLangfristigaVardepappersinnehavNedskrivningarIngaende.year'
                      ),
                      id(
                        'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table.AndraLangfristigaVardepappersinnehavAbstract.AndraLangfristigaVardepappersinnehavForandringNedskrivningarAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraLangfristigaVardepappersinnehavNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar andra långfristiga värdepappersinnehav',
                    saldo: 'credit',
                    negateValue: true,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table.AndraLangfristigaVardepappersinnehavAbstract.AndraLangfristigaVardepappersinnehavNedskrivningarIngaende.previousYear'
                      ),
                      id(
                        'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table.AndraLangfristigaVardepappersinnehavAbstract.AndraLangfristigaVardepappersinnehavForandringNedskrivningarAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'balans1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:AndraLangfristigaVardepappersinnehavNedskrivningar',
                    standardRubrik:
                      'Ackumulerade nedskrivningar andra långfristiga värdepappersinnehav',
                    saldo: 'credit',
                    negateValue: true,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'AndraLangfristigaVardepappersinnehav',
            'sum',
            noteLabel('Redovisat värde'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table.AndraLangfristigaVardepappersinnehavAbstract.AndraLangfristigaVardepappersinnehavAnskaffningsvardenUtgaende.year'
                  ),
                  id(
                    'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table.AndraLangfristigaVardepappersinnehavAbstract.AndraLangfristigaVardepappersinnehavNedskrivningarUtgaende.year'
                  )
                )
              ),
              {
                contextRef: 'balans0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AndraLangfristigaVardepappersinnehav',
                standardRubrik: 'Andra långfristiga värdepappersinnehav',
                saldo: 'debit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table.AndraLangfristigaVardepappersinnehavAbstract.AndraLangfristigaVardepappersinnehavAnskaffningsvardenUtgaende.previousYear'
                  ),
                  id(
                    'notes.note53.data.AndraLangfristigaVardepappersinnehavAbstract.table.AndraLangfristigaVardepappersinnehavAbstract.AndraLangfristigaVardepappersinnehavNedskrivningarUtgaende.previousYear'
                  )
                )
              ),
              {
                contextRef: 'balans1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:AndraLangfristigaVardepappersinnehav',
                standardRubrik: 'Andra långfristiga värdepappersinnehav',
                saldo: 'debit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotAndraLangfristigaVardepappersinnehavKommentar: {
        type: 'group',
        active: true,
        title: field(
          'Kommentar till specifikation av andra långfristiga värdepappersinnehav'
        ),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotAndraLangfristigaVardepappersinnehavKommentar',
          standardRubrik:
            'Kommentar till specifikation av andra långfristiga värdepappersinnehav',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2053K2v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        AndraLangfristigaVardepappersinnehavAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotAndraLangfristigaVardepappersinnehavKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
