import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';

import { Period } from '@agoy/api-sdk-core';
import { useSelector } from 'redux/reducers';
import { Voucher, VoucherTransaction, ReferencesTypes } from 'types/Voucher';
import { currentCustomer as currentCustomerSelector } from '_clients/redux/customer-view/selectors';
import { addGlobalMessage, insertSpecification } from 'redux/actions';
import VoucherForm from '_shared/components/VoucherView/CreateVoucherView';
import useVoucherData from '_shared/components/VoucherView/useVoucherData';
import useVoucherForm from '_shared/components/VoucherView/useVoucherForm';
import { isSameOrAfter, isSameOrBefore, parse, parseFormat } from '@agoy/dates';

import {
  VOUCHERS_SPECIFICATION_STOCK,
  VOUCHERS_TRANSACTION_DESCRIPTION,
  formatAmount,
} from './lagerjusteringUtil';
import { onUserInputCallbackFunction } from '../types';
import { InventoryObsolescenceDataType } from './types';

type LagerjusteringVoucherViewProps = {
  inventoryObsolescenceData: InventoryObsolescenceDataType;
  stock: number | undefined;
  accountNumber: string;
  period: Period;
  ub: number;
};

const emptyTransactions: VoucherTransaction[] = [
  {
    id: 1,
    sourceInfo: 'Lagerinkurans',
    kind: 'KS',
    typeAmount: 'credit',
    credit: 0,
    debit: 0,
    amount: 0,
    account: 0,
  },
  {
    id: 2,
    sourceInfo: 'Lagerinkurans',
    kind: 'KS',
    typeAmount: 'debit',
    credit: 0,
    debit: 0,
    amount: 0,
    account: 0,
  },
];

const LagerjusteringVoucherView = ({
  inventoryObsolescenceData,
  stock,
  accountNumber,
  period,
  ub,
}: LagerjusteringVoucherViewProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const [editing, setEditing] = useState(false);

  const { currentCustomer, currentFinancialYearId } = useSelector(
    (state) => state.customerView
  );
  const { integrations } = useSelector(currentCustomerSelector) || {};

  const customer = useSelector(currentCustomerSelector);
  const currentFinancialYear = customer?.rawFinancialYears.find(
    (y) => y.id === currentFinancialYearId
  );
  const formattedPeriodStart = parseFormat(period.start, 'yyyyMMdd');

  const { accountsList, seriesList } = useVoucherData({
    clientId: currentCustomer || '',
    period: formattedPeriodStart,
  });

  const findBookedVoucher = useCallback(
    (vouchers: Voucher[]) =>
      vouchers.find((voucher) => {
        if (
          isSameOrAfter(parse(voucher.transDate), parse(period?.start)) &&
          isSameOrBefore(parse(voucher.transDate), parse(period?.end))
        ) {
          return !!voucher.transactions.find(
            (item) => item.account === +accountNumber
          );
        }
        return false;
      }),
    [accountNumber, period?.end, period?.start]
  );

  const {
    voucherNumber,
    selectedSeries,
    transactions,
    description,
    date,
    documents,
    voucherStatus,
    nextVoucherNumber,
    actualTransactions,
    disabledDate,
    minPeriodDate,
    maxPeriodDate,
    setTransactions,
    onChangeSeries,
    onCreateVoucher,
    onAddTransaction,
    onChangeDate,
    onChangeDescription,
    onRemoveTransaction,
    onReset,
    onUpdateTransaction,
    onChangeDocuments,
    onAddDocument,
    onDeleteVoucher,
  } = useVoucherForm({
    clientId: currentCustomer || '',
    financialYear: currentFinancialYear,
    reference: ReferencesTypes.LAGERJUSTERING,
    period: formattedPeriodStart,
    checkBookedVoucher: findBookedVoucher,
  });

  const integrationActive = !!integrations?.fortnox;

  const isFinalVoucherEqualCalculations = useMemo(() => {
    const transaction = transactions.find(
      (item) => item.account === +accountNumber
    );

    if (actualTransactions.length && transaction) {
      const bookedAmount = transaction?.credit
        ? -transaction?.credit
        : transaction?.debit;

      const amount = +formatAmount(
        (ub - bookedAmount) * inventoryObsolescenceData.rate,
        2
      );

      return bookedAmount === -amount;
    }

    return false;
  }, [
    accountNumber,
    actualTransactions.length,
    inventoryObsolescenceData.rate,
    transactions,
    ub,
  ]);

  useEffect(() => {
    onChangeSeries(integrationActive ? 'A' : 'Agoy');
  }, [integrationActive, onChangeSeries]);

  useEffect(() => {
    onChangeDate(period.end);
  }, [onChangeDate, period.end]);

  useEffect(() => {
    onChangeDescription(formatMessage({ id: 'hidden.lagerjustering.title' }));
  }, [formatMessage, onChangeDescription]);

  useEffect(() => {
    setTransactions(
      emptyTransactions.map((transaction, index) => {
        const amount = +formatAmount(ub * inventoryObsolescenceData.rate, 2);
        if (index === 0) {
          return {
            ...transaction,
            credit: amount,
            amount,
            account: +accountNumber,
            editing: false,
          };
        }
        return {
          ...transaction,
          debit: amount,
          amount,
          account: 4910,
          editing: true,
        };
      })
    );
  }, [accountNumber, inventoryObsolescenceData.rate, setTransactions, ub]);

  const onUserInputChange: onUserInputCallbackFunction = (
    inputData,
    account,
    month,
    inputFinancialYear
  ) => {
    const financialYear = inputFinancialYear || currentFinancialYear;

    if (customer?.id && financialYear && inputData?.specification) {
      dispatch(
        insertSpecification(
          customer.id,
          financialYear,
          account,
          inputData.specification[0],
          month
        )
      );
    }
  };

  const createdVoucherSpecification = () => {
    const createdSpecification: Client.Specification[] = transactions.map(
      (transaction) => {
        return {
          id: +transaction.id,
          description: VOUCHERS_TRANSACTION_DESCRIPTION,
          amount: transaction.credit
            ? `-${transaction.amount}`
            : `${transaction.amount}`,
          account: `${transaction.account}`,
          reference: '',
        };
      }
    );
    onUserInputChange(
      {
        specification: [
          {
            id: 1,
            description: VOUCHERS_SPECIFICATION_STOCK,
            amount: `${stock}`,
            account: `${accountNumber}`,
            reference: '',
          },
          ...createdSpecification,
        ],
      },
      accountNumber,
      period
    );
  };

  const onSave = async (voucher: Voucher): Promise<void> => {
    if (currentCustomer) {
      const formattedTransactions = voucher?.transactions.map((transaction) => {
        return {
          sourceInfo: transaction.sourceInfo,
          kind: transaction.kind,
          typeAmount: transaction.typeAmount,
          amount: transaction.amount,
          account: transaction.account,
          pr: transaction.pr,
          ks: transaction.ks,
        };
      });
      const result = await onCreateVoucher({
        ...voucher,
        id: Date.now(),
        source: integrationActive ? 'fortnox' : 'agoy',
        period: formattedPeriodStart,
        serNumber: voucherNumber,
        transactions: formattedTransactions,
      });

      if (result.ok) {
        const message = integrationActive
          ? 'bokslutsVerification.table.saveToFortnox.success'
          : 'bokslutsVerification.table.saveToAgoy.success';
        dispatch(
          addGlobalMessage(
            'success',
            voucher.preliminary
              ? 'tax.voucher.fortnox.created.preliminary'
              : message
          )
        );
        createdVoucherSpecification();
      }
    }
  };

  const handleDeleteDocument = (id: number) => {
    const updatedDocuments = documents.filter((item) => item.id !== id);
    onChangeDocuments(updatedDocuments);
  };

  if (!currentCustomer || !currentFinancialYear) {
    return null;
  }

  return (
    <VoucherForm
      clientId={currentCustomer}
      financialYear={currentFinancialYear}
      isFinalVoucherEqualCalculations={isFinalVoucherEqualCalculations}
      titleText={formatMessage({
        id: 'hiddenRow.lagerjustering.voucherTitle',
      })}
      voucherStatus={voucherStatus}
      voucherNumber={voucherNumber}
      nextVoucherNumber={nextVoucherNumber}
      documents={documents}
      series={selectedSeries}
      description={description}
      date={date}
      seriesList={seriesList}
      accountsList={accountsList}
      transactions={transactions}
      source={ReferencesTypes.LAGERJUSTERING}
      fortnoxActive={integrationActive}
      editing={editing}
      disabledDate={disabledDate}
      minDate={minPeriodDate}
      maxDate={maxPeriodDate}
      onChangeSeries={onChangeSeries}
      onAddTransaction={onAddTransaction}
      onRemoveTransaction={onRemoveTransaction}
      onUpdateTransaction={onUpdateTransaction}
      onChangeDate={onChangeDate}
      onChangeDescription={onChangeDescription}
      onAddDocument={onAddDocument}
      onDeleteDocument={handleDeleteDocument}
      onChangeEditing={() => setEditing((currentValue) => !currentValue)}
      onReset={onReset}
      onSave={onSave}
      onDeleteVoucher={onDeleteVoucher}
    />
  );
};

export default memo(LagerjusteringVoucherView);
