import {
  TaxDeclarationFormDefinition,
  TaxDeclarationFormDataConfig,
  PersonMetaData,
} from '../../types';
import { id, sumAllowNull, ref, value } from '@agoy/document';
import { mapFormId, onlyPositive, toInitial, toStructure } from '../forms-util';

const configINK1 = (
  form: TaxDeclarationFormDefinition,
  person: PersonMetaData,
  financialYearEnd: string
): TaxDeclarationFormDataConfig => {
  const is2022 = financialYearEnd === '20221231';

  const INK1 = mapFormId(form, 'INK1');
  const name = `${person?.firstName || ''} ${person?.lastName || ''}`;
  const address1 = `${person?.street || ''} `;
  const address2 = `${person?.zipCode || ''} ${person?.city || ''}`;
  const initial = {
    INK1: {
      partType: 'form' as const,
      id: INK1,
      fields: {
        persOrgNumber: value(person.personNumber || ''),
        name: value(name),
        address1: value(address1),
        address2: value(address2),
        '1_1': ref(id('SRU.1000')),
        '1_2': ref(id('SRU.1001')),
        '1_3': ref(id('SRU.1002')),
        '1_4': ref(id('SRU.1003')),
        '1_5': ref(id('SRU.1004')),
        '1_6': ref(
          sumAllowNull(
            id('SRU.1005'),
            id('T2-*.T2.fields.D_6_resultat_overskott')
          )
        ),
        '1_7': ref(
          sumAllowNull(
            id('SRU.1006'),
            id('K13-*.K13.derivedFields.A_1a_ersattning'),
            id('K13-*.K13.fields.C_1_inkomst_av_tjanst'),
            id('K13-*.K13.fields.C_2_inkomst_tjanst'),
            id('K10-*.K10.derivedFields.simplifiedRule_1_8_toInk1'),
            id('K10-*.K10.derivedFields.mainRule_2_9_toInk1'),
            onlyPositive(id('K10-*.K10.fields.3_8'))
          )
        ),
        '2_1': ref(id('SRU.1070')),
        '2_2': ref(id('SRU.1071')),
        '2_3': ref(
          sumAllowNull(id('SRU.1072'), id('T2-*.T2.derivedFields.D_underskott'))
        ),
        '2_4': ref(id('SRU.1073')),
        '3_1': ref(id('SRU.1501')),
        '4_1': ref(id('SRU.1583')),
        '4_2': ref(id('SRU.1584')),
        '4_3': ref(id('SRU.1582'), undefined, 'kWh'),
        '4_4': ref(id('SRU.1581')),
        '4_5': ref(id('SRU.1585')),
        '5_1': ref(id('SRU.80')),
        '5_2': ref(id('SRU.82')), // TODO note absent in 0022
        '6_1': ref(id('SRU.84')),
        '7_1': ref(id('SRU.1106')),
        '7_2': ref(
          sumAllowNull(
            id('SRU.1100'),
            id('K4-*.K4.fields.C_summa_vinst'),
            id('K9-*.K9.fields.I_2_beloppet'),
            id('K12-*.K12.fields.A_2_utdelning_kapital'),
            id('K10-*.K10.derivedFields.simplifiedRule_1_17_toInk1'),
            id('K10-*.K10.derivedFields.mainRule_2_18_toInk1')
          )
        ),
        '7_3': ref(
          sumAllowNull(
            id('SRU.1101'),
            id('N3A-*.N3A.derivedFields.ovriga_uppgifter_overskott')
          )
        ),
        '7_4': ref(
          sumAllowNull(
            id('SRU.1102'),
            id('K4-*.K4.fields.A_summa_vinst'),
            id('K4-*.K4.fields.B_uppskovsbelopp_som_aterfors'),
            id('K9-*.K9.derivedFields.B_vinst_7_4'),
            id('K11-*.K11.fields.B_1_investeraravdrag'),
            id('K11-*.K11.fields.C_1_investeraravdrag'),
            id('K11-*.K11.fields.D_1_investeraravdrag'),
            id('K11-*.K11.fields.E_1_investeraravdrag'),
            id('K12-*.K12.fields.B_vinst_aktier_x_5_6'),
            id('K13-*.K13.derivedFields.C_1_vinst_to_7_4'),
            id('K13-*.K13.fields.C_2_vinst_kapital'),
            id('K13-*.K13.fields.A_1b_1_12_ersattning'),
            onlyPositive(id('K10-*.K10.fields.3_14'))
          )
        ),
        '7_5': ref(
          sumAllowNull(
            id('SRU.1103'),
            id('K4-*.K4.fields.D_summa_vinst'),
            id('K9-*.K9.derivedFields.B_vinst_7_5'),
            id('K12-*.K12.fields.C_vinst_andelar_x_5_6'),
            id('K15A-*.K15A.fields.summa_vinst'),
            id('K15B-*.K15B.fields.B_vinst_summa')
          )
        ),
        '7_6': ref(
          sumAllowNull(
            id('SRU.1104'),
            id('K2-*.K2.fields.F_preliminart_uppskovsbelopp'),
            id(
              'K2-*.K2.fields.G_3_uppskovsbelopp_som_ska_aterforas_till_beskattning'
            ),
            id('K2-*.K2.fields.H_2_uppskovsbelopp_till_beskattning'),
            id('K5-*.K5.derivedFields.B_vinst'),
            id('K6-*.K6.derivedFields.B_vinst')
          )
        ),
        '7_7': ref(
          sumAllowNull(
            id('SRU.1105'),
            id('K7-*.K7.fields.B_13_vinst'),
            id('K8-*.K8.fields.B_14_vinst_efter_avdrag')
          )
        ),
        '8_1': ref(
          sumAllowNull(id('SRU.1170'), id('K4-*.K4.fields.C_summa_forlust'))
        ),
        '8_3': ref(
          sumAllowNull(
            id('SRU.1172'),
            id('K4-*.K4.fields.A_summa_forlust'),
            id('K9-*.K9.derivedFields.B_forlust_8_3'),
            id('K10-*.K10.fields.3_4_b'),
            id('K12-*.K12.derivedFields.B_forlust'),
            id('K13-*.K13.derivedFields.C_1_forlust_to_8_3')
          )
        ),
        '8_4': ref(
          sumAllowNull(
            id('SRU.1173'),
            id('K4-*.K4.fields.D_summa_forlust'),
            id('K9-*.K9.derivedFields.B_forlust_8_4'),
            id('K12-*.K12.derivedFields.C_forlust'),
            id('K15A-*.K15A.fields.summa_forlust'),
            id('K15B-*.K15B.derivedFields.A_huvudregel_forlust'),
            id('K15B-*.K15B.fields.B_forlust_summa')
          )
        ),
        '8_5': ref(
          sumAllowNull(
            id('SRU.1174'),

            id('K5-*.K5.derivedFields.B_forlust'),
            id('K6-*.K6.derivedFields.B_forlust')
          )
        ),
        '8_6': ref(
          sumAllowNull(
            id('SRU.1175'),
            id('K7-*.K7.derivedFields.B_forlust'),
            id('K8-*.K8.derivedFields.B_forlust')
          )
        ),
        '8_7': ref(
          sumAllowNull(
            id('SRU.1176'),
            id('K11-*.K11.fields.A_3_begart_investeraravdrag')
          )
        ),
        '9_1': ref(id('SRU.1550')),
        '9_2': ref(id('SRU.1551')),
        '10_1_NE': ref(
          sumAllowNull(
            id('SRU.1200'),
            id('NE_PERSON.NE.derivedFields.R47_notPassive')
          )
        ),
        '10_1_N3A': ref(
          sumAllowNull(id('SRU.1201'), id('N3A-*.N3A.derivedFields.to_10_1'))
        ),
        '10_2_NE': ref(
          sumAllowNull(
            id('SRU.1202'),
            id('NE_PERSON.NE.derivedFields.R48_notPassive')
          )
        ),
        '10_2_N3A': ref(
          sumAllowNull(id('SRU.1203'), id('N3A-*.N3A.derivedFields.to_10_2'))
        ),
        '10_3_NE': ref(
          sumAllowNull(
            id('SRU.1250'),
            id('NE_PERSON.NE.derivedFields.R47_isPassive')
          )
        ),
        '10_3_N3A': ref(
          sumAllowNull(
            id('SRU.1251'),
            id('N3A-*.N3A.derivedFields.to_10_3_passiv_naringsverksamhet')
          )
        ),
        '10_4_NE': ref(
          sumAllowNull(
            id('SRU.1252'),
            id('NE_PERSON.NE.derivedFields.R48_isPassive')
          )
        ),
        '10_4_N3A': ref(
          sumAllowNull(
            id('SRU.1253'),
            id('N3A-*.N3A.derivedFields.to_10_4_passiv_naringsverksamhet')
          )
        ),
        '10_5_bruttoinkomst': ref(id('SRU.1400')),
        '10_5_kostnader': ref(id('SRU.1401')),
        '10_6_eget': ref(
          sumAllowNull(
            id('SRU.1301'),
            id('N3A-*.N3A.fields.23_neg'),
            id('NE_PERSON.NE.fields.R38_neg')
          )
        ),
        '10_6_anstalldas': ref(id('SRU.1300')),
        '10_7': ref(id('SRU.1305')),
        '11_1': ref(
          // TODO 11_1 (should be positive ?), 11_2 (negative ?), 12_1 (ökning), 12_2 (minskning) not exported as sru data ?
          sumAllowNull(
            id('SRU.1570'),
            id('N3A-*.N3A.fields.15_neg'),
            id('NE_PERSON.NE.fields.R30_neg')
          )
        ),
        '11_2': ref(
          sumAllowNull(
            id('SRU.1571'),
            id('N3A-*.N3A.fields.16_pos'),
            id('NE_PERSON.NE.fields.R31_pos')
          )
        ),
        '12_1': ref(
          sumAllowNull(
            id('SRU.1310'),
            id('N3A-*.N3A.fields.21_neg'),
            id('NE_PERSON.NE.fields.R36_neg')
          )
        ),
        '12_2': ref(
          sumAllowNull(
            id('SRU.1311'),
            id('N3A-*.N3A.fields.22_pos'),
            id('NE_PERSON.NE.fields.R37_pos')
          )
        ),
        '13_1': ref(id('SRU.1411')),
        '14_1': ref(
          sumAllowNull(
            id('SRU.1510'),

            id('N3A-*.N3A.fields.31_a_pos'),
            id('NE_PERSON.NE.fields.R45_pos')
          )
        ),
        '15_1': ref(id('SRU.93')),
        '15_2': ref(id('SRU.94')), // TODO note absent in 0022
        '16_1': ref(id('SRU.86')),
        '16_2': ref(id('SRU.95')),
        '16_3': ref(id('SRU.96')),
        '16_4': ref(id('SRU.97')),
        '16_5': ref(id('SRU.98')),
        '17_1': ref(id('SRU.1586')), // TODO note absent in 0021
        naringsverksamhet_upphort: value(false),
        omfordelning_av_skattereduktion_rot_rut: value(false),
        inkomstuppgift_fel_saknas: value(false),
        inkomst_fran_utlandet: value(false),
        avrakning_utlandsk_skatt: value(false),
        ovrigt: value(''),
        namnteckning: value(''),
        epost: value(''),
        telefonnummer: value(''),
      },
      sru: {
        '1000': ref(id('INK1.fields.1_1')),
        '1001': ref(id('INK1.fields.1_2')),
        '1002': ref(id('INK1.fields.1_3')),
        '1003': ref(id('INK1.fields.1_4')),
        '1004': ref(id('INK1.fields.1_5')),
        '1005': ref(id('INK1.fields.1_6')),
        '1006': ref(id('INK1.fields.1_7')),
        '1070': ref(id('INK1.fields.2_1')),
        '1071': ref(id('INK1.fields.2_2')),
        '1072': ref(id('INK1.fields.2_3')),
        '1073': ref(id('INK1.fields.2_4')),
        '1501': ref(id('INK1.fields.3_1')),
        '1583': ref(id('INK1.fields.4_1')),
        '1584': ref(id('INK1.fields.4_2')),
        '1582': ref(id('INK1.fields.4_3')),
        '1581': ref(id('INK1.fields.4_4')),
        '1585': ref(id('INK1.fields.4_5')),
        '80': ref(id('INK1.fields.5_1')),

        ...(is2022 ? undefined : { '82': ref(id('INK1.fields.5_2')) }),

        '84': ref(id('INK1.fields.6_1')),
        '1106': ref(id('INK1.fields.7_1')),
        '1100': ref(id('INK1.fields.7_2')),
        '1101': ref(id('INK1.fields.7_3')),
        '1102': ref(id('INK1.fields.7_4')),
        '1103': ref(id('INK1.fields.7_5')),
        '1104': ref(id('INK1.fields.7_6')),
        '1105': ref(id('INK1.fields.7_7')),
        '1170': ref(id('INK1.fields.8_1')),
        '1172': ref(id('INK1.fields.8_3')),
        '1173': ref(id('INK1.fields.8_4')),
        '1174': ref(id('INK1.fields.8_5')),
        '1175': ref(id('INK1.fields.8_6')),
        '1176': ref(id('INK1.fields.8_7')),
        '1550': ref(id('INK1.fields.9_1')),
        '1551': ref(id('INK1.fields.9_2')),
        '1200': ref(id('INK1.fields.10_1_NE')),
        '1201': ref(id('INK1.fields.10_1_N3A')),
        '1202': ref(id('INK1.fields.10_2_NE')),
        '1203': ref(id('INK1.fields.10_2_N3A')),
        '1250': ref(id('INK1.fields.10_3_NE')),
        '1251': ref(id('INK1.fields.10_3_N3A')),
        '1252': ref(id('INK1.fields.10_4_NE')),
        '1253': ref(id('INK1.fields.10_4_N3A')),
        '1400': ref(id('INK1.fields.10_5_bruttoinkomst')),
        '1401': ref(id('INK1.fields.10_5_kostnader')),
        '1301': ref(id('INK1.fields.10_6_eget')),
        '1300': ref(id('INK1.fields.10_6_anstalldas')),
        '1305': ref(id('INK1.fields.10_7')),
        '1411': ref(id('INK1.fields.13_1')),
        '1510': ref(id('INK1.fields.14_1')),
        '93': ref(id('INK1.fields.15_1')),
        '1570': ref(id('INK1.fields.11_1')),
        '1571': ref(id('INK1.fields.11_2')),
        '1310': ref(id('INK1.fields.12_1')),
        '1311': ref(id('INK1.fields.12_2')),

        ...(is2022 ? undefined : { '94': ref(id('INK1.fields.15_2')) }),

        '86': ref(id('INK1.fields.16_1')),
        '95': ref(id('INK1.fields.16_2')),
        '96': ref(id('INK1.fields.16_3')),
        '97': ref(id('INK1.fields.16_4')),
        '98': ref(id('INK1.fields.16_5')),

        ...(is2022 ? { '1586': ref(id('INK1.fields.17_1')) } : undefined),

        '92': ref(id('INK1.fields.naringsverksamhet_upphort')),
        '8052': ref(id('INK1.fields.omfordelning_av_skattereduktion_rot_rut')),
        '8051': ref(id('INK1.fields.inkomstuppgift_fel_saknas')),
        '8055': ref(id('INK1.fields.inkomst_fran_utlandet')),
        '8056': ref(id('INK1.fields.avrakning_utlandsk_skatt')),
        '8090': ref(id('INK1.fields.ovrigt')),
      },
    },
  };
  return {
    initial: toInitial(initial, form),
    definition: toStructure(initial, form),
  };
};

export default configINK1;
