import React, { useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  Typography,
  Checkbox,
  Tooltip,
  Grid,
  FormControlLabel,
} from '@material-ui/core';
import { CheckBoxOutlined } from '@material-ui/icons';
import styled from '@emotion/styled';
import ImageDropArea from '_shared/components/UploadDocument/ImageDropArea';
import usePeriodDocuments from 'utils/usePeriodDocuments/useAccountDocuments';

import { ccyFormat } from '@agoy/common';
import { formatPeriodToMonthAndYear, isInputCorrect } from 'utils';
import CurrencyField from '_shared/components/Inputs/CurrencyField';
import { InputData } from '_reconciliation/types';
import { FinancialYear, Period } from '@agoy/api-sdk-core';
import { useApiSdk } from 'api-sdk';

import { DocumentClippy } from '../DocumentClippy';
import SaldoHistory from './SaldoHistory';
import CheckedHistory from './CheckedHistory';
import Comments from './Comments';
import { SaldoHistoryContext } from '../../HiddenRowHistoryProvider';
import PeriodDataContext from '../PeriodDataContext';
import { onUserInputRoutineCallbackFunction } from '../types';

const EventTab = styled.div`
  display: flex;
  padding-top: ${(props) => props.theme.spacing(2)}px;
`;

const NumbersColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  padding-left: ${(props) => props.theme.spacing(3)}px;
  padding-right: ${(props) => props.theme.spacing(1)}px;
`;

const ImageColumn = styled.div`
  flex: 1;
  width: 50%;
  overflow-x: auto;
`;

const NumbersRow = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;

const CheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CheckboxLabel = styled(FormControlLabel)`
  margin-left: 0;
`;

const ActualSaldoLabel = styled(Typography)`
  font-weight: 500;
`;

const saldoInputProps = {
  size: 'small',
  'daya-cy': 'update-saldo',
  variant: 'outlined',
};

interface EventViewProps {
  account: string;
  accountName: string;
  groupId: string;
  userData: InputData;
  onUserInputRoutineCallback: onUserInputRoutineCallbackFunction;
}

const EventView = ({
  account,
  accountName,
  groupId,
  userData,
  onUserInputRoutineCallback,
}: EventViewProps): JSX.Element => {
  const sdk = useApiSdk();
  const { formatMessage } = useIntl();
  const {
    useSaldoHistory,
    onSaldoChange,
    toggleSaldoToCorrect,
    fetchSaldoHistory,
    fetchCheckedHistory,
    onChangeCheckedValue,
    useCheckedHistory,
    onUserInputChange,
  } = useContext(SaldoHistoryContext);
  const { addDocument } = usePeriodDocuments();

  const {
    period: selectedPeriod,
    periodLocked: isLocked,
    clientId,
    periodType,
  } = useContext(PeriodDataContext);
  const history = useSaldoHistory(selectedPeriod);
  const checkedHistory = useCheckedHistory(selectedPeriod);

  const {
    ib,
    ub,
    psaldo,
    saldo: userDataSaldo,
    specification = [],
    checked,
  } = userData || {};
  const saldo = userDataSaldo ? parseFloat(userDataSaldo) : undefined;

  const isCorrect =
    ub !== undefined && saldo !== undefined
      ? isInputCorrect(`${saldo}`, ub)
      : false;

  useEffect(() => {
    fetchSaldoHistory(selectedPeriod);
  }, [fetchSaldoHistory, selectedPeriod]);

  useEffect(() => {
    fetchCheckedHistory(selectedPeriod);
  }, [fetchCheckedHistory, selectedPeriod]);

  const onSaldoUpdate = (value: number | undefined) => {
    onSaldoChange(account, selectedPeriod, value);
  };

  const onSaldoCheckboxClick = () => {
    toggleSaldoToCorrect(account, selectedPeriod);
  };

  const onCheckedChange = () => {
    onChangeCheckedValue(account, selectedPeriod, !checked);
  };

  const handleChangeExternalComment = async (
    inputData: Partial<InputData>,
    accountNumber: string,
    period: Period,
    financialYear?: FinancialYear
  ) => {
    await sdk.putUserInput({
      clientid: clientId,
      periodId: selectedPeriod.id,
      accountNumber: parseInt(accountNumber, 10),
      requestBody: inputData,
    });
  };
  const handleSelectDocument = async (file: File, id: number) => {
    await addDocument(file.name, file, id);
  };

  return (
    <ImageDropArea onDrop={handleSelectDocument}>
      <EventTab>
        <Comments
          account={account}
          userData={userData}
          period={selectedPeriod}
          onUserInputCallback={onUserInputChange}
          onUserInputRoutineCallback={onUserInputRoutineCallback}
          onChangeExternalComment={handleChangeExternalComment}
          isLocked={isLocked}
        />
        <NumbersColumn>
          <NumbersRow>
            <ActualSaldoLabel variant="body1">
              {formatMessage({ id: 'actual.saldo' })}
            </ActualSaldoLabel>
            <CurrencyField
              InputProps={{
                ...saldoInputProps,
              }}
              value={saldo}
              disabled={!!specification.length || isLocked}
              onValueChange={onSaldoUpdate}
            />
            <Tooltip
              title={formatMessage({
                id: 'tooltips.accountingView.actualSaldoOk',
              })}
            >
              <Checkbox
                color="primary"
                checkedIcon={<CheckBoxOutlined />}
                checked={isCorrect}
                onClick={onSaldoCheckboxClick}
                disabled={!!specification.length || isLocked}
              />
            </Tooltip>
          </NumbersRow>
          {!!history.length && <SaldoHistory history={history} />}
          <NumbersRow>
            <Typography variant="body1" color="textSecondary">
              {formatMessage({ id: 'hidden.diff' })}
            </Typography>
            <Typography variant="body1">
              {saldo !== undefined && ub !== undefined
                ? ccyFormat(Math.abs(saldo - ub))
                : ''}
            </Typography>
          </NumbersRow>
          <CheckboxRow>
            <CheckboxLabel
              label={formatMessage({ id: 'hidden.event.checked' })}
              control={
                <Checkbox
                  color="primary"
                  checkedIcon={<CheckBoxOutlined />}
                  checked={checked}
                  onClick={onCheckedChange}
                />
              }
              labelPlacement="start"
              disabled={!isCorrect || isLocked}
            />
          </CheckboxRow>
          {!!checkedHistory.length && (
            <CheckedHistory history={checkedHistory} />
          )}
        </NumbersColumn>

        <ImageColumn>
          <DocumentClippy
            period={selectedPeriod}
            groupId={groupId}
            handleSelectDocument={handleSelectDocument}
            periodInformation={
              <>
                <Typography variant="body1" color="textSecondary">
                  {formatMessage({ id: 'account' })}
                </Typography>
                <Typography variant="body1">{`${account} ${accountName}`}</Typography>
                <Typography variant="body1" color="textSecondary">
                  {formatMessage({ id: 'month' })}
                </Typography>
                <Typography variant="body1">
                  {selectedPeriod
                    ? formatPeriodToMonthAndYear(selectedPeriod)
                    : ''}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  {formatMessage({ id: 'carousel.ib' })}
                </Typography>
                <Typography variant="body1">{ccyFormat(ib)}</Typography>
                <Typography variant="body1" color="textSecondary">
                  {formatMessage({ id: 'carousel.change' })}
                </Typography>
                <Typography variant="body1">{ccyFormat(psaldo)}</Typography>
                <Typography variant="body1" color="textSecondary">
                  {formatMessage({ id: 'carousel.ub' })}
                </Typography>
                <Typography variant="body1">{ccyFormat(ub)}</Typography>

                <Typography variant="body1" color="textSecondary">
                  {formatMessage({ id: 'carousel.actual.saldo' })}
                </Typography>
                <Grid container wrap="nowrap">
                  <CurrencyField
                    InputProps={{
                      size: 'small',
                      variant: 'outlined',
                      disabled: !!specification.length || isLocked,
                    }}
                    value={saldo}
                    onValueChange={onSaldoUpdate}
                  />
                  <Tooltip title="Genväg för att markera som korrekt">
                    <Checkbox
                      color="primary"
                      checkedIcon={<CheckBoxOutlined />}
                      checked={isCorrect}
                      onClick={onSaldoCheckboxClick}
                      disabled={!!specification.length || isLocked}
                    />
                  </Tooltip>
                </Grid>
              </>
            }
          />
        </ImageColumn>
      </EventTab>
    </ImageDropArea>
  );
};

export default EventView;
