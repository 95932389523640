import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { TableContainer, Table, Paper } from '@material-ui/core';
import styled from '@emotion/styled';
import {
  AgoyTableRow,
  AgoyTableColumn,
  Cell as CellType,
} from '@agoy/document';
import { CommonNotes } from '@agoy/annual-report-document';
import { NumberFormatType } from '@agoy/common';
import ServiceContext, {
  TableServiceType,
} from './Context/TableServiceContext';
import NotesContext from './Context/NotesContext';
import TableBody, { SubRowType } from './TableBody';
import TableHead from './TableHead';
import AccountListDrawer from '../AccountListDrawer';

export interface TableProps {
  className?: string;
  baseId: string;
  tableId: string;
  rows: AgoyTableRow[];
  columns: AgoyTableColumn[];
  service?: TableServiceType;
  isLocked?: boolean;
  notes?: CommonNotes;
  editing?: boolean;
  print?: boolean;
  canDragColumns?: boolean;
  canDeleteColumns?: boolean;
  columnsWithoutDrag?: string[];
  columnsWithoutDelete?: string[];
  columnsWithoutEditing?: string[];
  canDragRows?: boolean;
  rowsWithoutDrag?: string[];
  addNewColumnPosition?: number;
  addNewRowId?: string;
  addNewRowLabelId?: string;
  canToggleActive?: boolean;
  canAddRows?: boolean;
  canAddColumns?: boolean;
  canDeleteRows?: boolean;
  nonEditableRowColumns?: string[];
  canEditColumns?: boolean;
  hideHeader?: boolean;
  withPlaceholder?: boolean;
  widthFitContent?: boolean;
  selectedRowId?: string;
  columnsWithClicking?: string[];
  headerCellTitle?: string;
  accountRanges?: any;
  onHeaderColumnPress?: (column: AgoyTableColumn | undefined) => void;
  isRowSelectable?: (rowId: string) => boolean;
  onRowSelect?: (rowId: string) => void;
  numberFormat?: (rowId: string) => NumberFormatType;
  numberFormatColumn?: (col: string) => NumberFormatType;
  onAddColumn?: () => void;
  onDeleteRow?: (row: AgoyTableRow, id: string) => void;
  onColumnBlur?: (colId: string, newValue: string) => void;
  onToggleRow?: (row: AgoyTableRow, id: string) => void;
  onAddRow?: (baseId: string) => void;
  renderRow?: (subRow: SubRowType, index?: number) => React.ReactElement | null;
  renderCell?: (values: {
    cell: CellType;
    column: AgoyTableColumn;
    row: AgoyTableRow;
    baseId: string;
    isDragging: boolean;
  }) => React.ReactElement | null;
  renderHeadCell?: (values: {
    column: AgoyTableColumn;
    isDragging: boolean;
  }) => React.ReactElement | string | null;
  renderFooterRow?: () => React.ReactElement;
  handleShowRowInfo?: (
    cells: Record<string, CellType> | undefined
  ) => string | undefined;
  onValueChange?: (key: string, newValue: string) => void;
  additionalButtons?: { label: string; onClick: () => void }[];
}

const StyledTableContainer = styled(
  ({ print, editing, widthFitContent, ...props }) => (
    <TableContainer {...props} component={Paper} />
  )
)`
  overflow-x: visible;

  ${(props) => (props.widthFitContent ? 'width: fit-content;' : '')}

  ${(props) =>
    props.print
      ? `
  box-shadow: none;
  
  .MuiTableCell-root {
    border: none;
  }
  `
      : ''}
`;

const CommonTable = ({
  className,
  baseId,
  tableId,
  rows,
  columns,
  service,
  notes,
  editing = false,
  print = false,
  canDragColumns = false,
  columnsWithoutDrag,
  columnsWithoutDelete,
  columnsWithoutEditing,
  canDragRows = false,
  rowsWithoutDrag,
  canAddRows = false,
  canAddColumns = false,
  isLocked = false,
  canDeleteRows = false,
  nonEditableRowColumns,
  addNewRowId,
  addNewRowLabelId,
  canDeleteColumns = false,
  addNewColumnPosition,
  canToggleActive = false,
  canEditColumns = false,
  hideHeader = false,
  withPlaceholder = false,
  widthFitContent = false,
  selectedRowId,
  columnsWithClicking,
  headerCellTitle,
  accountRanges,
  onHeaderColumnPress,
  isRowSelectable,
  onRowSelect,
  numberFormat,
  numberFormatColumn,
  onAddColumn,
  renderRow,
  renderCell,
  renderHeadCell,
  onDeleteRow,
  onToggleRow,
  onAddRow,
  onColumnBlur,
  renderFooterRow,
  handleShowRowInfo,
  onValueChange,
  additionalButtons,
}: TableProps): JSX.Element => {
  const existingService = useContext(ServiceContext);

  const [currentColumns, setCurrentColumns] = useState<AgoyTableColumn[]>([]);
  const [hoverColumnId, setHoverColumnId] = useState('');

  const frameId = useRef<number | null>(null);

  useEffect(() => {
    const newColumns: AgoyTableColumn[] = [];
    const columnsWithSortKey: AgoyTableColumn[] = [];

    columns.forEach((item) => {
      if (typeof item.sortKey === 'number') {
        columnsWithSortKey.push(item);
      } else {
        newColumns.push(item);
      }
    });
    columnsWithSortKey.sort(
      (a: AgoyTableColumn, b: AgoyTableColumn) =>
        (a?.sortKey || 0) - (b?.sortKey || 0)
    );
    columnsWithSortKey.forEach((item) => {
      newColumns.splice(item.sortKey || 0, 0, item);
    });

    setCurrentColumns(newColumns);
  }, [columns]);

  const drawFrame = (newColumns: AgoyTableColumn[]) => {
    if (!frameId.current) {
      const updateFunction = () => {
        setCurrentColumns(newColumns);
        frameId.current = null;
      };

      frameId.current = requestAnimationFrame(updateFunction);
    }
  };

  const handleDragHover = useCallback(
    (draggedColumn: AgoyTableColumn, hoverColumn: AgoyTableColumn) => {
      if (draggedColumn.id !== hoverColumn.id) {
        const draggedIndex = currentColumns.indexOf(draggedColumn);
        const hoverIndex = currentColumns.indexOf(hoverColumn);

        const newColumns = [...currentColumns];
        newColumns.splice(draggedIndex, 1);
        newColumns.splice(hoverIndex, 0, draggedColumn);
        drawFrame(newColumns);
      } else if (draggedColumn.id !== hoverColumnId) {
        setHoverColumnId(hoverColumn.id);
      }
    },
    [currentColumns, hoverColumnId]
  );

  const handleEndDragColumn = useCallback(
    (column) => {
      const newColumns = [...currentColumns];
      const columnIndex = newColumns.indexOf(column);
      newColumns[columnIndex] = { ...column, sortKey: columnIndex };

      const columnsWithSortKey: { id: string; sortKey: number }[] = [];
      newColumns.forEach((item, index) => {
        if (typeof item.sortKey === 'number') {
          columnsWithSortKey.push({
            id: `${tableId}.${item.id}`,
            sortKey: index,
          });
        }
      });

      service?.updateColumnSortKey(columnsWithSortKey);
      setHoverColumnId('');
    },
    [currentColumns, service, tableId]
  );

  return (
    <ServiceContext.Provider value={service ?? existingService}>
      <NotesContext.Provider value={notes}>
        <StyledTableContainer
          className={className}
          editing={editing}
          print={print}
          widthFitContent={widthFitContent}
        >
          <Table>
            {!hideHeader && (
              <TableHead
                baseId={baseId}
                tableId={tableId}
                columns={currentColumns}
                editing={editing}
                print={print}
                canEdit={canEditColumns}
                canDrag={canDragColumns}
                canDragRows={canDragRows}
                canDeleteRows={canDeleteRows}
                canDelete={canDeleteColumns}
                addNewColumnPosition={addNewColumnPosition}
                columnsWithoutDrag={columnsWithoutDrag}
                columnsWithoutDelete={columnsWithoutDelete}
                columnsWithoutEditing={columnsWithoutEditing}
                canToggleActive={canToggleActive}
                onDragHoverColumn={handleDragHover}
                onEndDragColumn={handleEndDragColumn}
                onAddColumn={onAddColumn}
                renderCell={renderHeadCell}
                canAddColumns={canAddColumns}
                headerCellTitle={headerCellTitle}
                onHeaderColumnPress={onHeaderColumnPress}
                columnsWithClicking={columnsWithClicking}
              />
            )}
            <TableBody
              baseId={baseId}
              tableId={tableId}
              columns={currentColumns}
              rows={rows}
              editing={editing}
              print={print}
              hoverColumnId={hoverColumnId}
              addNewRowId={addNewRowId}
              addNewRowLabelId={addNewRowLabelId}
              addNewColumnPosition={addNewColumnPosition}
              rowsWithoutDrag={rowsWithoutDrag}
              canDrag={canDragRows}
              numberFormat={numberFormat}
              numberFormatColumn={numberFormatColumn}
              renderRow={renderRow}
              renderCell={renderCell}
              onDeleteRow={onDeleteRow}
              onToggleRow={onToggleRow}
              onAddRow={onAddRow}
              canToggleActive={canToggleActive}
              canAddRows={canAddRows}
              isLocked={isLocked}
              canDeleteRows={canDeleteRows}
              nonEditableRowColumns={nonEditableRowColumns}
              selectedRowId={selectedRowId}
              isRowSelectable={isRowSelectable}
              onRowSelect={onRowSelect}
              onColumnBlur={onColumnBlur}
              withPlaceholder={withPlaceholder}
              renderFooterRow={renderFooterRow}
              handleShowRowInfo={handleShowRowInfo}
              accountRanges={accountRanges}
              onValueChange={onValueChange}
              additionalButtons={additionalButtons}
            />
          </Table>
        </StyledTableContainer>
        <AccountListDrawer collapsedWidth={0} />
      </NotesContext.Provider>
    </ServiceContext.Provider>
  );
};

export default CommonTable;
