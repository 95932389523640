/* eslint-disable import/no-cycle */
import {
  TaxCalculationRow,
  TaxCalculationPart,
  TaxView,
  TaxCalculationConfig,
  TaxTableConfig,
  TaxDocument,
  yearEndPlanningConfig,
  taxCalculationTableConfig,
  nonTaxableIncomeCalculationTableConfig,
  nonDeductibleExpensesConfig,
  particularSalaryTaxConfig,
  taxDocumentsConfig,
  configBase,
  contentDefinition,
  createBaseTaxConfig,
} from '@agoy/tax-document';
import { mapOf } from '@agoy/common';
import {
  AgoyTableRow,
  numberValue,
  operations,
  isError,
  Cell,
  applyChanges,
} from '@agoy/document';
import { SetCurrentPeriodAction, GlobalActions } from '_shared/redux/actions';
import {
  SET_CURRENT_PERIOD,
  SET_PROGRAM_PERIODS_STATUS,
} from '_shared/redux/action-types';
import { ProgramStatusPeriodState } from '_shared/types';
import { updateProgramStatusPeriodState } from '_shared/redux/program-status/reducer';
import {
  SET_TAX_VIEW_CONFIG,
  SET_TAX_VIEW_STATE,
  DELETE_TAX_TABLE_ROW,
  UPDATE_TAX_TABLE_ROW_LABEL,
  UPDATE_TAX_TABLE_ROW_REFERENCE,
  ADD_EMPTY_TAX_TABLE_ROW,
  UPDATE_TRANSACTION_ROW_ACCOUNT,
  REMOVE_USER_TRANSACTIONS,
  RESET_YEAR_END_PLANNING,
  RESET_NONE_TAXABLE_INCOME,
  RESET_NONE_DEDUCTIBLE_EXPENSES,
  UPDATE_YEAR_END_PLANNING_CHECKSUM,
  RESET_TAX_CALCULATION_TABLE,
  RESET_TAX_CALCULATION_ADJUSTMENTS,
  RESET_TAX_PARTICULAR_SALARY,
  SET_TAX_DOCUMENTS,
  RESET_TAX_DOCUMENTS,
  SET_TAX_EXTERNAL_DOCUMENT_VALUES,
  ADD_EMPTY_TAX_SUMMARY_TABLE_ROW,
  UPDATE_TAX_SUMMARY_TABLE_ROW_VALUE,
  UPDATE_TAX_TABLE_ROW_VALUE,
  ADD_CONTRACTUAL_PENSION_TABLE_ROW,
  DELETE_CONTRACTUAL_PENSION_TABLE_ROW,
  UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_LABEL,
  UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_VALUE,
  UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_REFERENCE,
  UPDATE_FORA_FIELD,
  RESET_TAX_FORA,
  UPDATE_TAX_VIEW_FIELD,
  UPDATE_DOCUMENT_CELL,
  ADD_DOCUMENT_ROW,
  DELETE_DOCUMENT_ROW,
  RESET_DOCUMENT_PART,
  TOGGLE_TAX_SECTION_ACTIVE,
  ADD_TAX_SECTIONS,
} from './action-types';
import { TaxViewActionTypes } from './actions';

/**
 * Represents the tax calculation for a given client and year
 */
export interface TaxViewClientYearState {
  state: TaxView | null;
  config: TaxCalculationConfig | null;
  externalDocumentValues: Record<
    string,
    Record<string, string | boolean | number | undefined>
  >;
}

interface TaxViewClientState {
  years: Record<string, TaxViewClientYearState>;
  periodStatus?: Record<number, ProgramStatusPeriodState>;
}

interface TaxViewState {
  clients: Record<string, TaxViewClientState>;
}

const updateTaxTableRow = (
  state: TaxViewClientYearState,
  part: TaxCalculationPart | string,
  lastModified: string,
  mapper?: <T extends TaxCalculationRow>(row: T) => T,
  filter?: <T extends TaxCalculationRow>(row: T) => boolean
): TaxViewClientYearState => {
  if (!state.state || !state.config) {
    return state;
  }
  const rows = state.state[part]?.rows;
  if (!rows) {
    return state;
  }

  const mapRows = <T extends TaxCalculationRow>(rows: T[]): T[] => {
    let newRows = rows;
    if (filter) {
      newRows = newRows.filter(filter);
    }
    if (mapper) {
      newRows = newRows.map(mapper);
    }
    return newRows;
  };

  const statePart = state.state[part];
  if (statePart === null) {
    return state;
  }
  const configPart = state.config[part];

  const newStateRows = mapRows(statePart.rows);
  const newConfigRows = mapRows(configPart.rows);
  if (
    newStateRows.length === statePart.rows.length &&
    newConfigRows.length === configPart.rows.length &&
    newStateRows.every((row, index) => row === statePart.rows[index]) &&
    newConfigRows.every((row, index) => row === configPart.rows[index])
  ) {
    // No change
    return state;
  }
  return {
    ...state,
    state: {
      ...state.state,
      [part]: {
        ...state.state[part],
        rows: mapRows(statePart.rows),
      },
    },
    config: {
      ...state.config,
      [part]: {
        ...configPart,
        lastModified,
        rows: mapRows(configPart.rows),
      },
    },
  };
};

const initialTaxViewClientYearState: TaxViewClientYearState = {
  config: null,
  state: null,
  externalDocumentValues: {},
};

const initialTaxViewClientState: TaxViewClientState = {
  years: {},
};

const initialTaxViewState: TaxViewState = {
  clients: {},
};

const resetYearEndPlanningTaxReducerFunction = (
  state: TaxViewClientYearState,
  keep: string[] | undefined = []
): TaxViewClientYearState => {
  const defaultConfig = yearEndPlanningConfig;

  if (!state.config || !state.state) {
    return state;
  }
  const currentConfigToKeep = mapOf(
    state.config.yearEndPlanning.rows.filter((row) => keep.includes(row.id)),
    (row) => row.id,
    (row) => row
  );

  return {
    ...state,
    config: {
      ...state.config,
      yearEndPlanning: {
        ...defaultConfig,
        rows: defaultConfig.rows.map(
          (row) => currentConfigToKeep.get(row.id) ?? row
        ),
      },
    },
    state: {
      ...state.state,
      yearEndPlanning: null,
    },
  };
};

const resetDocumentPartReducerFunction = (
  state: TaxViewClientYearState,
  id: string
): TaxViewClientYearState => {
  if (!state.state?.document) {
    return state;
  }
  if (!state.config?.documentChanges) {
    return state;
  }

  const result = operations.removeChanges(
    contentDefinition,
    {
      document: state.state.document,
      changes: state.config.documentChanges,
    },
    id
  );

  if (!result || isError(result)) {
    console.warn(id, result);
    return state;
  }

  return {
    ...state,
    config: {
      ...state?.config,
      // Using the initial document here since removeChanges can't reproduce the original
      // The calculations afterwards will update the document with the changes.
      document: applyChanges(contentDefinition)(
        configBase.document,
        result.changes
      ),
      documentChanges: result.changes,
    },
    state: {
      ...state?.state,
      document: applyChanges(contentDefinition)(
        configBase.document,
        result.changes
      ),
    },
  };
};

const resetGenericTaxReducerFunction = (
  state: TaxViewClientYearState,
  keyValue: string,
  defaultConfig: TaxTableConfig<TaxCalculationRow> | TaxDocument[]
): TaxViewClientYearState => {
  if (!state.config || !state.state) {
    return state;
  }
  return {
    ...state,
    config: {
      ...state.config,
      [keyValue]: defaultConfig,
    },
    state: {
      ...state.state,
      [keyValue]: null,
    },
  };
};

const addDocumentRow = (
  state: TaxViewClientYearState,
  id: string
): TaxViewClientYearState => {
  if (!state.state?.document) {
    return state;
  }
  if (!state.config?.documentChanges) {
    return state;
  }

  const result = operations.addTableRow(
    contentDefinition,
    {
      document: state.state.document,
      changes: state.config.documentChanges,
    },
    id
  );

  if (!result || isError(result)) {
    console.warn(id, result);
    return state;
  }

  return {
    ...state,
    config: {
      ...state?.config,
      document: result.document,
      documentChanges: result.changes,
    },
    state: {
      ...state?.state,
      document: result.document,
    },
  };
};

const deleteDocumentRow = (
  state: TaxViewClientYearState,
  id: string
): TaxViewClientYearState => {
  if (!state.state?.document) {
    return state;
  }
  if (!state.config?.documentChanges) {
    return state;
  }

  const result = operations.deleteTableRow(
    contentDefinition,
    {
      document: state.state.document,
      changes: state.config.documentChanges,
    },
    id
  );

  if (!result || isError(result)) {
    console.warn(id, result);
    return state;
  }

  return {
    ...state,
    config: {
      ...state?.config,
      document: result.document,
      documentChanges: result.changes,
    },
    state: {
      ...state?.state,
      document: result.document,
    },
  };
};

const updateDocumentCell = (
  state: TaxViewClientYearState,
  id: string,
  value: string | number | boolean | undefined | Cell
): TaxViewClientYearState => {
  let newCell: Cell;

  switch (typeof value) {
    case 'string':
      newCell = { type: 'string', value };
      break;
    case 'boolean':
      newCell = { type: 'boolean', value };
      break;
    case 'number':
    case 'undefined':
      newCell = { type: 'number', value };
      break;
    default:
      newCell = value;
  }

  if (!state.state?.document) {
    return state;
  }

  if (!state.config?.documentChanges) {
    return state;
  }

  const result = operations.updateCellValue(
    contentDefinition,
    { document: state.state.document, changes: state.config.documentChanges },
    id,
    newCell
  );

  if (!result || isError(result)) {
    console.warn(id, value, result);
    return state;
  }

  return {
    ...state,
    config: {
      ...state?.config,
      document: result.document,
      documentChanges: result.changes,
    },
    state: {
      ...state?.state,
      document: result.document,
    },
  };
};

const updateTaxViewDocumentAccountCells = (
  state: TaxViewClientYearState,
  id: string,
  rowId: string,
  label: string,
  reference: string
): TaxViewClientYearState => {
  if (!state.state?.document) {
    return state;
  }

  if (!state.config?.documentChanges) {
    return state;
  }

  const result = operations.chain<typeof contentDefinition>(
    {
      document: state.state.document,
      changes: state.config.documentChanges,
    },
    (nextState) =>
      operations.updateCellValue(
        contentDefinition,
        nextState,
        `${id}.${rowId}.value`,
        {
          type: 'ref',
          value: 0,
          reference,
        }
      ),
    (nextState) =>
      operations.updateCellValue(
        contentDefinition,
        nextState,
        `${id}.${rowId}.label`,
        {
          type: 'ref',
          value: label,
          reference: `accountName(${label})`,
        }
      ),
    (nextState) =>
      operations.updateCellValue(
        contentDefinition,
        nextState,
        `${id}.${rowId}.accountNumber`,
        {
          type: 'number',
          // TODO pass account number as number so we don't have to parse it here
          value: parseInt(label, 10),
        }
      )
  );

  if (!result || isError(result)) {
    console.warn(id, rowId, label, reference, result);
    return state;
  }

  return {
    ...state,
    config: {
      ...state?.config,
      document: result.document,
      documentChanges: result.changes,
    },
    state: {
      ...state?.state,
      document: result.document,
    },
  };
};

const updateTaxViewDocumentField = (
  state: TaxViewClientYearState,
  id: string,
  value: string | number | boolean | undefined
): TaxViewClientYearState => {
  if (!state.state?.document) {
    return state;
  }

  if (!state.config?.documentChanges) {
    return state;
  }

  const result = operations.updateField(
    contentDefinition,
    { document: state.state.document, changes: state.config.documentChanges },
    id,
    value
  );

  if (!result || isError(result)) {
    console.warn(id, value, result);
    return state;
  }

  return {
    ...state,
    config: {
      ...state?.config,
      document: result.document,
      documentChanges: result.changes,
    },
    state: {
      ...state?.state,
      document: result.document,
    },
  };
};

const resetGenericFORAReducerFunction = (
  state: TaxViewClientYearState
): TaxViewClientYearState => {
  const defaultConfig = configBase.document.particularSalaryTax.fora;

  if (!state.config || !state.state) {
    return state;
  }
  return {
    ...state,
    state: {
      ...state?.state,
      document: {
        ...state?.state.document,
        particularSalaryTax: {
          ...state?.state.document.particularSalaryTax,
          fora: defaultConfig,
        },
      },
    },
    config: {
      ...state?.config,
      document: {
        ...state?.config.document,
        particularSalaryTax: {
          ...state?.config.document.particularSalaryTax,
          fora: defaultConfig,
        },
      },
    },
  };
};

const clientYearReducer = (
  state: TaxViewClientYearState = initialTaxViewClientYearState,
  action: TaxViewActionTypes | SetCurrentPeriodAction
): TaxViewClientYearState => {
  switch (action.type) {
    // Global actions
    case SET_CURRENT_PERIOD:
      return {
        ...state,
        state: null,
      };

    // Specific actions
    case SET_TAX_VIEW_CONFIG:
      if (state.config === action.config) {
        return state;
      }
      return {
        ...state,
        config: action.config,
      };
    case SET_TAX_VIEW_STATE:
      if (state.state === action.state) {
        return state;
      }
      return {
        ...state,
        state: action.state,
      };
    case ADD_EMPTY_TAX_TABLE_ROW: {
      if (!state.state || !state.config) {
        return state;
      }
      const { rowId, part } = action;
      const rows = state.state?.[part]?.rows || [];
      return {
        ...state,
        state: {
          ...state.state,
          [part]: {
            ...state.state[part],
            rows: [...rows, { id: rowId, label: '', value: 0 }],
          },
        },
        config: {
          ...state.config,
          [part]: {
            ...state.config[part],
            rows: [...rows, { id: rowId, label: '', value: 0 }],
          },
        },
      };
    }
    case ADD_EMPTY_TAX_SUMMARY_TABLE_ROW: {
      return addDocumentRow(
        state,
        `particularSalaryTax.summarize.${action.part}`
      );
    }

    case ADD_DOCUMENT_ROW: {
      return addDocumentRow(state, action.rowId);
    }

    case DELETE_DOCUMENT_ROW: {
      return deleteDocumentRow(state, action.rowId);
    }

    case ADD_CONTRACTUAL_PENSION_TABLE_ROW: {
      if (!state.state || !state.config) {
        return state;
      }

      const { rowId, part } = action;

      const newSalaryBaseRows =
        state?.state.document.particularSalaryTax.fora.helper.salaryBase.rows.map(
          (row) => {
            if (row.id === part) {
              const newRow: AgoyTableRow = {
                ...row.newRowTemplate,
                cells: {
                  accountNumber: {
                    type: 'number',
                    value: undefined,
                  },
                  label: {
                    type: 'string',
                    value: '',
                  },
                  value: {
                    type: 'number',
                    value: 0,
                  },
                },
                active: Boolean((row.newRowTemplate as AgoyTableRow).active),
                id: rowId,
              };

              row.rows?.push(newRow);
            }

            return row;
          }
        );

      return {
        ...state,
        state: {
          ...state?.state,
          document: {
            ...state?.state.document,
            particularSalaryTax: {
              ...state?.state.document.particularSalaryTax,
              fora: {
                ...state?.state.document.particularSalaryTax.fora,
                helper: {
                  ...state?.state.document.particularSalaryTax.fora.helper,
                  salaryBase: {
                    ...state?.state.document.particularSalaryTax.fora.helper
                      .salaryBase,
                    rows: newSalaryBaseRows,
                  },
                },
              },
            },
          },
        },
      };
    }

    case DELETE_CONTRACTUAL_PENSION_TABLE_ROW: {
      if (!state.state || !state.config) {
        return state;
      }
      const { part, rowId } = action;

      const newRows =
        state?.state.document.particularSalaryTax.fora.helper.salaryBase.rows.map(
          (row) => {
            if (row.id === part) {
              return {
                ...row,
                rows: row.rows!.filter((r) => r.id !== rowId),
              };
            }

            return row;
          }
        );

      return {
        ...state,
        state: {
          ...state?.state,
          document: {
            ...state?.state.document,
            particularSalaryTax: {
              ...state?.state.document.particularSalaryTax,
              fora: {
                ...state?.state.document.particularSalaryTax.fora,
                helper: {
                  ...state?.state.document.particularSalaryTax.fora.helper,
                  salaryBase: {
                    ...state?.state.document.particularSalaryTax.fora.helper
                      .salaryBase,
                    rows: newRows,
                  },
                },
              },
            },
          },
        },
      };
    }

    case UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_LABEL: {
      if (!state.state || !state.config) {
        return state;
      }
      const { part, rowId, label } = action;

      const newRows: AgoyTableRow[] =
        state?.state.document.particularSalaryTax.fora.helper.salaryBase.rows.map(
          (row) => {
            if (row.id === part) {
              return {
                ...row,
                rows: row.rows!.map((r) => {
                  if (r.id === rowId) {
                    return {
                      ...r,
                      cells: {
                        ...r.cells,
                        label: {
                          ...r.cells!.label,
                          value: label,
                        },
                      },
                    };
                  }

                  return r;
                }),
              } as AgoyTableRow;
            }

            return row;
          }
        );

      return {
        ...state,
        state: {
          ...state?.state,
          document: {
            ...state?.state.document,
            particularSalaryTax: {
              ...state?.state.document.particularSalaryTax,
              fora: {
                ...state?.state.document.particularSalaryTax.fora,
                helper: {
                  ...state?.state.document.particularSalaryTax.fora.helper,
                  salaryBase: {
                    ...state?.state.document.particularSalaryTax.fora.helper
                      .salaryBase,
                    rows: newRows,
                  },
                },
              },
            },
          },
        },
      };
    }

    case UPDATE_TAX_VIEW_FIELD: {
      return updateTaxViewDocumentField(state, action.id, action.value);
    }

    case UPDATE_DOCUMENT_CELL: {
      return updateDocumentCell(state, action.id, action.value);
    }

    case UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_VALUE: {
      if (!state.state || !state.config) {
        return state;
      }
      const { part, rowId, value } = action;

      const newRows: AgoyTableRow[] =
        state?.state.document.particularSalaryTax.fora.helper.salaryBase.rows.map(
          (row) => {
            if (row.id === part) {
              return {
                ...row,
                rows: row.rows!.map((r) => {
                  if (r.id === rowId) {
                    return {
                      ...r,
                      cells: {
                        ...r.cells,
                        value: {
                          ...r.cells!.label,
                          value,
                        },
                      },
                    };
                  }

                  return r;
                }),
              } as AgoyTableRow;
            }

            return row;
          }
        );

      return {
        ...state,
        state: {
          ...state?.state,
          document: {
            ...state?.state.document,
            particularSalaryTax: {
              ...state?.state.document.particularSalaryTax,
              fora: {
                ...state?.state.document.particularSalaryTax.fora,
                helper: {
                  ...state?.state.document.particularSalaryTax.fora.helper,
                  salaryBase: {
                    ...state?.state.document.particularSalaryTax.fora.helper
                      .salaryBase,
                    rows: newRows,
                  },
                },
              },
            },
          },
        },
      };
    }
    case UPDATE_CONTRACTUAL_PENSION_TABLE_ROW_REFERENCE: {
      if (!state.state || !state.config) {
        return state;
      }
      const { part, rowId, label, reference } = action;

      const newRows: AgoyTableRow[] =
        state?.state.document.particularSalaryTax.fora.helper.salaryBase.rows.map(
          (row) => {
            if (row.id === part) {
              return {
                ...row,
                rows: row.rows!.map((r) => {
                  if (r.id === rowId) {
                    return {
                      ...r,
                      cells: {
                        ...r.cells,
                        accountNumber: {
                          type: 'number',
                          value: label,
                        },
                        label: {
                          type: 'string',
                          value: label,
                          reference,
                        },
                        value: {
                          type: 'ref',
                          value: 0,
                          reference,
                        },
                      },
                    };
                  }

                  return r;
                }),
              } as AgoyTableRow;
            }

            return row;
          }
        );

      return {
        ...state,
        state: {
          ...state?.state,
          document: {
            ...state?.state.document,
            particularSalaryTax: {
              ...state?.state.document.particularSalaryTax,
              fora: {
                ...state?.state.document.particularSalaryTax.fora,
                helper: {
                  ...state?.state.document.particularSalaryTax.fora.helper,
                  salaryBase: {
                    ...state?.state.document.particularSalaryTax.fora.helper
                      .salaryBase,
                    rows: newRows,
                  },
                },
              },
            },
          },
        },
      };
    }

    case UPDATE_FORA_FIELD: {
      if (!state.state || !state.config) {
        return state;
      }
      const { part, field, value } = action;

      const newRows: AgoyTableRow[] =
        state?.state.document.particularSalaryTax.fora.helper.salaryBase.rows.map(
          (row) => {
            if (row.id === part) {
              const newRow = {
                ...row,
                cells: {
                  ...row.cells,
                  [field]: {
                    ...row.cells![field],
                    value,
                  },
                },
              } as AgoyTableRow;

              if (field === 'value' || field === 'percentage') {
                return {
                  ...newRow,
                  cells: {
                    ...newRow.cells,
                    sum: {
                      ...newRow.cells!.sum,
                      value:
                        numberValue(newRow.cells!.percentage!)! *
                        numberValue(newRow.cells!.value!)!,
                    },
                  },
                } as AgoyTableRow;
              }

              return newRow;
            }

            return row;
          }
        );

      return {
        ...state,
        state: {
          ...state?.state,
          document: {
            ...state?.state.document,
            particularSalaryTax: {
              ...state?.state.document.particularSalaryTax,
              fora: {
                ...state?.state.document.particularSalaryTax.fora,
                helper: {
                  ...state?.state.document.particularSalaryTax.fora.helper,
                  salaryBase: {
                    ...state?.state.document.particularSalaryTax.fora.helper
                      .salaryBase,
                    rows: newRows,
                  },
                },
              },
            },
          },
        },
      };
    }

    case DELETE_TAX_TABLE_ROW: {
      const { part, rowId, lastModified } = action;
      if (rowId.startsWith('@')) {
        return updateTaxTableRow(
          state,
          part,
          lastModified,
          undefined,
          (row) => row.id !== rowId
        );
      }
      return updateTaxTableRow(
        state,
        part,
        lastModified,
        (row) => (row.id === rowId ? { ...row, deleted: true } : row),
        undefined
      );
    }
    case UPDATE_TAX_TABLE_ROW_LABEL: {
      const { part, rowId, label, lastModified } = action;
      return updateTaxTableRow(state, part, lastModified, (row) =>
        row.id === rowId && row.label !== label ? { ...row, label } : row
      );
    }

    case UPDATE_TAX_TABLE_ROW_VALUE: {
      const { part, rowId, value, lastModified } = action;
      return updateTaxTableRow(state, part, lastModified, (row) =>
        row.id === rowId && row.value !== value ? { ...row, value } : row
      );
    }
    case UPDATE_TAX_SUMMARY_TABLE_ROW_VALUE: {
      if (!state.state || !state.config) {
        return state;
      }
      const { part, rowId, value } = action;

      const prevRows =
        state?.state?.document?.particularSalaryTax.summarize[part].rows;
      const newRows = prevRows?.map((row) => {
        if (rowId === row.id) {
          return {
            ...row,
            cells: {
              ...row.cells,
              value: { ...row.cells.value, value },
            },
          };
        }
        return row;
      });

      return {
        ...state,
        state: {
          ...state?.state,
          document: {
            ...state?.state.document,
            particularSalaryTax: {
              ...state?.state.document.particularSalaryTax,
              summarize: {
                ...state?.state.document.particularSalaryTax.summarize,
                [part]: {
                  ...state?.state.document.particularSalaryTax.summarize[part],
                  rows: newRows,
                },
              },
            },
          },
        },
        config: {
          ...state?.config,
          document: {
            ...state?.config.document,
            particularSalaryTax: {
              ...state?.config.document.particularSalaryTax,
              summarize: {
                ...state?.config.document.particularSalaryTax.summarize,
                [part]: {
                  ...state?.config.document.particularSalaryTax.summarize[part],
                  rows: newRows,
                },
              },
            },
          },
        },
      };
    }
    case UPDATE_TAX_TABLE_ROW_REFERENCE: {
      if (!state.state || !state.config) {
        return state;
      }
      const { part, rowId, label, reference } = action;
      const partRows = state.state?.[part]?.rows;
      if (!partRows) {
        return state;
      }
      const mapRows = <T extends TaxCalculationRow>(rows: T[]): T[] => {
        return rows.map((row) =>
          row.id === rowId ? { ...row, label, reference } : row
        );
      };

      return {
        ...state,
        state: {
          ...state.state,
          [part]: {
            ...state.state[part],
            rows: mapRows(partRows),
          },
        },
        config: {
          ...state.config,
          [part]: {
            ...state.config[part],
            rows: mapRows(partRows),
          },
        },
      };
    }

    case UPDATE_TRANSACTION_ROW_ACCOUNT: {
      const { rowId, account, lastModified } = action;
      const mapper = (row) =>
        row.id === rowId && row.account !== account ? { ...row, account } : row;
      return updateTaxTableRow(state, 'adjustments', lastModified, mapper);
    }
    case REMOVE_USER_TRANSACTIONS: {
      const { lastModified } = action;
      return updateTaxTableRow(
        state,
        'adjustments',
        lastModified,
        undefined,
        (row) => !row.id.startsWith('@')
      );
    }

    case RESET_TAX_PARTICULAR_SALARY:
      return resetGenericTaxReducerFunction(
        state,
        'particularSalaryTax',
        particularSalaryTaxConfig
      );

    case RESET_TAX_FORA:
      return resetGenericFORAReducerFunction(state);

    case RESET_YEAR_END_PLANNING:
      return resetYearEndPlanningTaxReducerFunction(state, action.keep);

    case RESET_NONE_TAXABLE_INCOME:
      return resetGenericTaxReducerFunction(
        state,
        'nonTaxableIncomes',
        nonTaxableIncomeCalculationTableConfig
      );

    case RESET_NONE_DEDUCTIBLE_EXPENSES:
      return resetGenericTaxReducerFunction(
        state,
        'nonDeductibleExpenses',
        nonDeductibleExpensesConfig
      );

    case RESET_TAX_CALCULATION_TABLE:
      return resetGenericTaxReducerFunction(
        state,
        'taxCalculation',
        taxCalculationTableConfig
      );

    case RESET_TAX_CALCULATION_ADJUSTMENTS:
      /**
       * TODO: totally re-write baseConfig to correctly use and reset adjustments
       */
      return resetGenericTaxReducerFunction(
        state,
        'adjustments',
        createBaseTaxConfig(
          action.financialYear,
          action.periods,
          action.companyType
        ).adjustments
      );

    case RESET_TAX_DOCUMENTS:
      return resetGenericTaxReducerFunction(
        state,
        'taxDocuments',
        taxDocumentsConfig
      );

    case RESET_DOCUMENT_PART:
      return resetDocumentPartReducerFunction(state, action.id);

    case UPDATE_YEAR_END_PLANNING_CHECKSUM: {
      if (!state.config) {
        return state;
      }

      const { checksum } = action;
      if (state.config.yearEndPlanning.checksum === checksum) {
        return state;
      }
      return {
        ...state,
        config: {
          ...state.config,
          yearEndPlanning: {
            ...state.config.yearEndPlanning,
            checksum,
          },
        },
      };
    }

    case SET_TAX_DOCUMENTS: {
      const { documents } = action;

      if (!state.state || !state.config) {
        return state;
      }

      return {
        ...state,
        state: {
          ...state.state,
          taxDocuments: documents,
        },
        config: {
          ...state.config,
          taxDocuments: documents,
        },
      };
    }
    case SET_TAX_EXTERNAL_DOCUMENT_VALUES: {
      return {
        ...state,
        externalDocumentValues: {
          ...state.externalDocumentValues,
          [action.name]: action.values,
        },
      };
    }
    case ADD_TAX_SECTIONS: {
      const { sections } = action;

      if (!state.state || !state.config) {
        return state;
      }

      return {
        ...state,
        state: {
          ...state?.state,
          ...sections,
        },
      };
    }
    case TOGGLE_TAX_SECTION_ACTIVE: {
      const { id, part } = action;

      if (!state.state || !state.config) {
        return state;
      }

      return {
        ...state,
        state: {
          ...state?.state,
          [part]: {
            ...state.state[part],
            [id]: {
              ...state.state[part][id],
              active: !state.state[part][id].active,
            },
          },
        },
      };
    }
    default:
      return state;
  }
};

const reducer = (
  state: TaxViewState = initialTaxViewState,
  action: TaxViewActionTypes | SetCurrentPeriodAction | GlobalActions
): TaxViewState => {
  if ('clientId' in action && 'financialYear' in action) {
    const { clientId, financialYear } = action;
    if (clientId && financialYear) {
      if (financialYear.length !== 17) {
        throw new Error(
          `financialYear had wrong length: ${financialYear.length}`
        );
      }
      const current = state.clients[clientId]?.years[financialYear];
      const newState = clientYearReducer(current, action);
      if (newState !== current) {
        return {
          ...state,
          clients: {
            ...state.clients,
            [clientId]: {
              ...state.clients[clientId],
              years: {
                ...state.clients[clientId]?.years,
                [financialYear]: newState,
              },
            },
          },
        };
      }
    }
  } else if ('clientId' in action) {
    const clientState =
      state.clients[action.clientId] || initialTaxViewClientState;
    switch (action.type) {
      case SET_PROGRAM_PERIODS_STATUS:
        return {
          ...state,
          clients: {
            ...state.clients,
            [action.clientId]: updateProgramStatusPeriodState(
              'FIN_STATEMENT',
              clientState,
              action
            ),
          },
        };
    }
  }
  return state;
};

export default reducer;
