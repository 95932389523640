import React, { useContext, useRef } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from '@emotion/styled';
import PrintStateContext from '_annual-report/components/AnnualReportView/PrintStateContext';
import AdditionalPage from '_tax/components/TaxDeclarationView/EditForm/AdditionalPages/AdditionalPage';
import {
  TableWithId,
  extractMetadataFromDocument,
  generateTaxCalculationPages,
  isTableLastAcrossPages,
} from '_tax/components/TaxDeclarationView/EditForm/AdditionalPages/utils';

const Page = styled(({ children, ...props }) =>
  React.Children.count(children) > 0 ? <div {...props}>{children}</div> : null
)`
  page-break-before: always;
  width: 842px;

  @media screen {
    border-bottom: 1px dashed ${(props) => props.theme.palette.grey['500']};
    padding: 32px 32px;
    width: 864px;
  }
`;

const TaxesFormPrint = (): JSX.Element | null => {
  const { path } = useRouteMatch();
  const { state } = useContext(PrintStateContext);
  const paper = useRef<HTMLDivElement | null>(null);

  const paperWidth = paper?.current?.clientWidth ?? 900;
  const maxAvailableHeight = (paperWidth - 200) * 1.41;
  const maxItems = Math.floor(maxAvailableHeight / 45);

  if (!state.additionalPages) {
    return null;
  }

  const { document } = state.additionalPages;

  if (!document.finalTaxCalculations) {
    return null;
  }

  const tables: TableWithId[] = [];

  Object.keys(document.finalTaxCalculations).forEach((key) => {
    if (
      typeof document.finalTaxCalculations[key] === 'object' &&
      'rows' in document.finalTaxCalculations[key] &&
      'columns' in document.finalTaxCalculations[key]
    ) {
      tables.push({
        id: `finalTaxCalculations.${key}`,
        table: document.finalTaxCalculations[key],
      });
    }
  });

  const pages = generateTaxCalculationPages(tables, 3, maxItems, true);
  const { startDate, endDate, companyType, orgNumber } =
    extractMetadataFromDocument(document);

  return (
    <Switch>
      <Route path={`${path}/index`}>
        <Page>
          {state.additionalPages &&
            pages.map((page, i) => {
              return (
                <Page
                  key={`tables_${i}_${page.map((table) => table.id).join('-')}`}
                  ref={paper}
                >
                  <AdditionalPage
                    page={page}
                    currentPageNumber={i + 1}
                    totalPageNumber={pages.length}
                    showSumRow={(table) =>
                      isTableLastAcrossPages(pages, table.id, i)
                    }
                    isPrint
                    orgNr={orgNumber}
                    companyType={companyType}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </Page>
              );
            })}
        </Page>
      </Route>
    </Switch>
  );
};

export default TaxesFormPrint;
