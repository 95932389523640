import React, { useContext } from 'react';
import { useSelector } from 'redux/reducers';

import SemesterskuldlistaPreview from './SemesterskuldlistaPreview';
import PeriodDataContext from '../../PeriodDataContext';

const SemesterskuldlistaPreviewPrint = (): JSX.Element | null => {
  const state = useSelector((state) => state.accountingView.vacationDebt);
  const { period, clientId } = useContext(PeriodDataContext);

  return (
    <SemesterskuldlistaPreview
      clientId={clientId}
      periodEnd={period.end}
      data={state?.employeesData}
      isPrintPreviewMode
    />
  );
};

export default SemesterskuldlistaPreviewPrint;
