/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: AGOY-7182
 */

import {
  value,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2025K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2025K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel(
    'Nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar'
  ),
  data: {
    active: true,
    NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringarAbstract:
      {
        type: 'group',
        active: true,
        title: field(
          'Periodens nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar'
        ),
        table: table<IxbrlCell>(
          'notes.note25.data.NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringarAbstract.table',
          {
            id: 'NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringarAbstract',
            label:
              'Nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar',
            dataType: 'text',
            active: true,
          },
          {
            id: 'year',
            label: year(financialYear, financialYears, period) ?? '',
            dataType: 'numeric',
            active: true,
          },
          {
            id: 'previousYear',
            label: previousYear(financialYear, financialYears) ?? '',
            dataType: 'numeric',
            active: true,
          }
        )
          .addRows((rows) => {
            rows
              .addRowWithType(
                'NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringarAbstract',
                'hidden',
                undefined,
                undefined,
                undefined
              )
              .addSubRows((rows) => {
                rows.addRowWithType(
                  'NedskrivningarFinansiellaAnlaggningstillgangarNedskrivningarAterforingarKortfristigaPlaceringar',
                  'row',
                  noteLabel(
                    'Periodens nedskrivningar och återföringar av nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar'
                  ),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:NedskrivningarFinansiellaAnlaggningstillgangarNedskrivningarAterforingarKortfristigaPlaceringar',
                    standardRubrik:
                      'Periodens nedskrivningar och återföringar av nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar',
                    saldo: 'debit',
                    negateValue: false,
                  }),
                  ixbrlCell(value(undefined), {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:NedskrivningarFinansiellaAnlaggningstillgangarNedskrivningarAterforingarKortfristigaPlaceringar',
                    standardRubrik:
                      'Periodens nedskrivningar och återföringar av nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar',
                    saldo: 'debit',
                    negateValue: false,
                  })
                );
                return rows.build();
              });
            return rows.build();
          })
          .build(),
        NotNedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringarKommentar:
          {
            type: 'group',
            active: true,
            title: field(
              'Kommentar till upplysning om nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar'
            ),
            value: ixbrlField(field(''), {
              contextRef: 'period0',
              type: 'stringItemType',
              name: 'se-gen-base:NotNedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringarKommentar',
              standardRubrik:
                'Kommentar till upplysning om nedskrivningar av finansiella anläggningstillgångar och kortfristiga placeringar',
            }),
          },
      },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2025K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        NedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringarAbstract:
          {
            type: 'part' as const,
            children: {
              type: type,
              active: boolean,

              title: cell,

              table: { type: 'table' as const },

              NotNedskrivningarFinansiellaAnlaggningstillgangarKortfristigaPlaceringarKommentar:
                {
                  type: 'part' as const,
                  children: {
                    type: type,
                    active: boolean,

                    title: cell,

                    value: cell,
                  },
                },
            },
          },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
