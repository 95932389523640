import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { printTheme } from '@agoy/theme';
import theme from 'theme';
import {
  withCssBaseline,
  withCssResets,
  withEmotionTheme,
  withFeatureFlags,
  withIntl,
  withMaterialTheme,
  withNotifications,
  withRedux,
  withSentryErrorBoundary,
  withSupportFallback,
  withDNDProvider,
} from '_shared/HOC';
import LoadingPlaceholder from '_shared/components/LoadingPlaceholder';

import { PrintContextProvider } from '_shared/HOC/withPrintContext';
import { SystemMessagesContextProvider } from '_shared/HOC/withSystemMessages';
import DrawerContainer from '_shared/components/DrawerContainer';
import AppHeader from '_shared/components/AppHeader';
import FallbackComponent from '_shared/components/ErrorBoundary';
import withClientDataLayer from 'data/client/withClientDataLayer';
import { AgoyAppClientProvider } from 'utils/AgoyAppClient/AgoyAppClientContext';
import withAuth from '_authentication/HOC/withAuth';
import useAuth from '_authentication/hooks/useAuth';
import { getUserOrganisation } from '_organization/redux/actions';
import Messages from '_messages/components/Messages/SnackbarMessages';
import { useSelector } from 'redux/reducers';
import withPeriod from '_shared/HOC/withPeriodContext';
import MaintenancePage from '_clients/components/MaintenancePage';
import { withPrintState } from '_annual-report/components/AnnualReportView/PrintStateContext';
import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';
import { isFortnoxCompanyTenantSelector } from './_shared/redux/selectors';
import { asResultClass, useApiSdk } from './api-sdk';

/**
 * Shared providers by all apps
 */
export const withSharedProviders = compose(
  withCssBaseline,
  withIntl,
  withFeatureFlags,
  withRedux,
  withDNDProvider,
  withPeriod
);

/**
 * PRINT specific providers
 *
 * HOC providers:
 * - sentryErrorBoundary
 * - EmotionTheme
 * - MaterialTheme
 * - PrintState
 */
export const withPrintProviders = compose(
  withSentryErrorBoundary(FallbackComponent),
  withEmotionTheme(printTheme),
  withMaterialTheme(printTheme),
  withPrintState
);

const DevTools = React.lazy(() => import('./dev/Tools/DevTools'));

const useDevTools =
  import.meta.env.VITE_RUNNING_ENVIRONMENT !== 'production' &&
  activeFeatureFlags.get('devTools');

const withDevTools = (Component) =>
  useDevTools
    ? (props) => {
        return (
          <>
            <Component {...props} />
            <Suspense fallback={null}>
              <DevTools />
            </Suspense>
          </>
        );
      }
    : Component;

export const withStyling = compose(
  withCssResets,
  withSentryErrorBoundary(FallbackComponent),
  withEmotionTheme(theme),
  withMaterialTheme(theme)
);

/**
 * APP specific providers
 */
export const withAppProviders = compose(
  withStyling,
  withSupportFallback,
  withAuth,
  withNotifications,
  withClientDataLayer,
  withDevTools
);

/**
 * Auth-protected routes with extra checks and AWS auth flow leftovers.
 * Initializes the client context, required by the API sdk to perform requests
 */
export const withAuthenticationRouteGuard =
  (Component: () => JSX.Element) =>
  (props: JSX.Element): JSX.Element => {
    const { isLoading, isAuthenticated, getCurrentSessionAWS } = useAuth();
    const history = useHistory();

    const [serverError, setServerError] = useState(false);

    const organisation = useSelector((state) => state.user.organisationId);

    useEffect(() => {
      getCurrentSessionAWS();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const location = history.location.pathname;
      if (
        isAuthenticated &&
        !organisation &&
        location !== '/create-organisation' &&
        location !== '/profile'
      ) {
        history.push('/create-organisation');
      }
    }, [history, history.location, isAuthenticated, organisation]);

    const handleError = useCallback(() => {
      setServerError(true);
    }, [setServerError]);

    if (isLoading) {
      return <LoadingPlaceholder />;
    }

    if (!isAuthenticated && !isLoading) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <AgoyAppClientProvider onError={handleError}>
          <SystemMessagesContextProvider>
            <PrintContextProvider>
              <DndProvider backend={HTML5Backend}>
                {serverError ? (
                  <MaintenancePage />
                ) : (
                  <>
                    <AppHeader />
                    <Component {...props} />
                  </>
                )}
              </DndProvider>
            </PrintContextProvider>
          </SystemMessagesContextProvider>
        </AgoyAppClientProvider>
        <Messages />
        <DrawerContainer />
        <div id="portals" />
      </>
    );
  };

/**
 * HOC that fetches a user's organisation
 */
export const withInitialFetch =
  (Component: () => JSX.Element) =>
  (props: () => JSX.Element): JSX.Element => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getUserOrganisation());
    }, [dispatch]);

    return <Component {...props} />;
  };

/**
 * Redirects user to the client page when the organisation is a company tenant.
 * It is used when a certain view should not be accessible for company tenants.
 */
export const withFortnoxCompanyTenantRouteGuard = (Component) => (props) => {
  const sdk = useApiSdk();
  const history = useHistory();

  const isFortnoxCompanyTenant = useSelector(isFortnoxCompanyTenantSelector);

  (async () => {
    if (isFortnoxCompanyTenant) {
      const result = await asResultClass(sdk.getClients({}));

      if (result.ok) {
        const client = result.val[0];
        history.replace(`/clients/${client.id}`);
      }
    }
  })();

  return <Component {...props} />;
};
