import { AgoyTable } from '@agoy/document';
import { AnnualReportPart, AnnualReportSection } from '../../document';

export interface BalanceSheetSection extends AnnualReportSection {
  assets: AgoyTable;
  equityAndLiabilities: AgoyTable;
}

// Balansräkning
export interface BalanceSheet extends AnnualReportPart {
  section: BalanceSheetSection;
}

const boolean = { type: 'boolean' as const };

export const balanceSheet = {
  type: 'part' as const,
  children: {
    active: boolean,
    section: {
      type: 'part' as const,
      children: {
        active: boolean,
        assets: { type: 'table' as const },
        equityAndLiabilities: { type: 'table' as const },
      },
    },
  },
};
