/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { IconButton, Typography } from '@material-ui/core';
import { Print as PrintIcon, Close as CloseIcon } from '@material-ui/icons';
import { format } from 'date-fns';
import { SkatteverketTransaction } from '_clients/redux/customer-view/types';
import { useSelector } from 'redux/reducers';
import { reformatDate } from 'utils';
import Table, { TableFilter } from './organisms/Table';
import PeriodDataContext from '../PeriodDataContext';

const DocumentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Content = styled.div`
  width: 95%;
  margin: 3mm;
`;

const Text = styled(Typography)`
  font-weight: bold;
`;

const Margin = styled.div`
  margin: 40px;
`;

const TopbarActions = styled.div`
  display: flex;
  justify-content: space-between;
  @media print {
    display: none;
  }
`;

const PrintButton = styled(IconButton)`
  @media print {
    display: none;
  }
  background-color: white;
`;

const CloseButton = styled(IconButton)`
  @media print {
    display: none;
  }
  background-color: white;
`;

const SkatteverketLogo = styled.div`
  display: flex;
  flex: 1;
  margin-top: 40px;
  margin-bottom: 40px;
`;

interface Props {
  accountNumber: string;
  isLoading?: boolean;
  isPrintPreviewMode?: boolean;
  transactions: SkatteverketTransaction[];
  onClose?: () => void;
}

const SkatteverketPrintView: React.FC<Props> = (props) => {
  const {
    accountNumber,
    isLoading,
    isPrintPreviewMode,
    transactions,
    onClose,
  } = props;

  const timestamp = format(new Date(), 'yyyy-MM-dd, HH:mm');
  const totalResults = transactions?.length || 0;

  const { clientId, period, groupedPeriods } = useContext(PeriodDataContext);
  const { name, orgNumber } = useSelector((state) => state.customers[clientId]);

  const filterBy: TableFilter = {
    pageNumber: 1,
    periods: groupedPeriods.reduce(
      (result, p) => ({
        ...result,
        [reformatDate(p.start, 'yyyy-MM-dd', 'yyyyMM')]: true,
      }),
      {}
    ),
  };

  return (
    <Content>
      <TopbarActions>
        <PrintButton aria-label="print" onClick={() => window.print()}>
          <PrintIcon />
        </PrintButton>
        <CloseButton aria-label="close" onClick={onClose || undefined}>
          <CloseIcon />
        </CloseButton>
      </TopbarActions>
      <DocumentHeader>
        <Text variant="body2">Skattekonto - Skatteverket</Text>
        <Text variant="body2">{timestamp}</Text>
      </DocumentHeader>
      <SkatteverketLogo>
        <img
          src="../../../skatteverket_logo.png"
          alt="Skateverket logo"
          width={100}
          height={100}
        />
        <Text variant="h1">Skattekonto</Text>
      </SkatteverketLogo>
      <DocumentHeader>
        <Text variant="body2" />
        <Text variant="body1">
          <b>{name}</b> {orgNumber}
        </Text>
      </DocumentHeader>
      <Text variant="h3">Bokförda transaktioner</Text>
      <Margin />

      <Table
        isPrintPreviewMode={isPrintPreviewMode}
        isLoading={isLoading || false}
        transactions={transactions}
        currentPeriod={period}
        filterBy={filterBy}
        accountNumber={accountNumber}
        totalResults={totalResults}
      />

      <Margin />
    </Content>
  );
};

export default SkatteverketPrintView;
