import * as t from 'io-ts';
import { parseMessage } from './common';

export const AccountingBalancesChangedCodec = t.intersection([
  t.type({
    clientId: t.string,
    topic: t.literal('accounting-balances-changed'),
    financialYearId: t.number,
    userId: t.string,
  }),
  t.partial({
    periodsIds: t.array(t.number),
  }),
]);

/**
 * Message used in AWS SNS Topic accounting-balances-changed messages.
 * Conforms to ws-notification messages and can be used for FE notifications.
 */
export type AccountingBalancesChanged = t.TypeOf<
  typeof AccountingBalancesChangedCodec
>;

/**
 * parseAccountingBalancesChanged
 *
 * @param json message in string
 * @returns A result object with a valid message.
 */
export const parseAccountingBalancesChanged = (json: string) =>
  parseMessage(AccountingBalancesChangedCodec, json);
