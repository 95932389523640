import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePickerProps,
} from '@material-ui/pickers';
import { Typography } from '@material-ui/core';
import AdapterDateFns from '@date-io/date-fns';

import { GetComponentProps } from '_shared/services/type-helpers';
import GenericSimplified from './GenericSimplefied';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Title = styled(Typography)`
  position: absolute;
  top: 8px;
  left: 16px;
  font-size: 10px;
  color: #656d6a;
`;

const DatePicker = styled(KeyboardDatePicker)`
  width: 100%;

  .MuiFormControl-marginDense {
    margin: 0;
  }
  .MuiOutlinedInput-root {
    padding-top: 12px;
    border-radius: 8px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  }

  .MuiOutlinedInput-input {
    padding-left: 16px;
  }

  .MuiIconButton-root {
    margin-bottom: 8px;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 4px;
  }
`;

type SimplifiedDatePickerProps = {
  onChange?: (date: string | null | undefined) => void;
  dataCy?: string;
  title?: string;
};

const SimplifiedDatePicker = React.forwardRef(
  (
    props: SimplifiedDatePickerProps &
      GetComponentProps<typeof GenericSimplified> &
      KeyboardDatePickerProps,
    ref
  ): JSX.Element => {
    const { width, title, format = 'yyyy-MM-dd', onChange } = props;
    const {
      value,
      editing,
      label,
      labelPlacement,
      size = 'small',
      dataCy,
      ...rest
    } = props;
    const inputProps = dataCy ? { 'data-cy': dataCy } : {};

    const handleChange = useCallback((_, dateValue) => {
      onChange(dateValue);
    }, []);

    return (
      <MuiPickersUtilsProvider utils={AdapterDateFns}>
        {title ? (
          <Wrapper>
            <DatePicker
              inputValue={value}
              inputProps={inputProps}
              size={size}
              fullWidth={!!width}
              inputVariant="outlined"
              variant="inline"
              disableToolbar
              autoOk
              inputRef={ref}
              {...rest}
              format={format}
              onChange={handleChange}
              value={null}
            />
            <Title>{title}</Title>
          </Wrapper>
        ) : (
          <GenericSimplified {...props}>
            <KeyboardDatePicker
              inputValue={value}
              inputProps={inputProps}
              size={size}
              fullWidth={!!width}
              inputVariant="outlined"
              variant="inline"
              disableToolbar
              autoOk
              inputRef={ref}
              {...rest}
              format={format}
              onChange={handleChange}
              value={null}
            />
          </GenericSimplified>
        )}
      </MuiPickersUtilsProvider>
    );
  }
);

export default SimplifiedDatePicker;
