import React from 'react';
import {
  AnnualReportVersion,
  Settings,
  Signatures,
} from '@agoy/annual-report-document';
import { SignaturesPage } from '@agoy/annual-report-view';
import PreviewSection from '../../UI/PreviewSection';
import Page from '../../UI/Print/Page';

interface SignaturesPreviewProps {
  signatures: Signatures | undefined;
  isDigitalSubmission: boolean | null;
  currentFinancialYear: string;
  settings: Settings;
  version: AnnualReportVersion;
}

const SignaturesPreview = ({
  signatures,
  isDigitalSubmission,
  currentFinancialYear,
  settings,
  version,
}: SignaturesPreviewProps): JSX.Element | null => {
  if (signatures == null) {
    return null;
  }

  return (
    <Page>
      <PreviewSection id="signatures">
        <SignaturesPage
          currentFinancialYear={currentFinancialYear}
          signatures={signatures}
          isDigitalSubmission={isDigitalSubmission}
          settings={settings}
          version={version}
        />
      </PreviewSection>
    </Page>
  );
};

export default SignaturesPreview;
