// helpers for config
// see also resolveReference.ts and its tests
import { ConfigType } from '@agoy/common';
import { AccountValueType } from './types';

const fn =
  (operator: string) =>
  (...args: (string | number)[]): string =>
    `${operator}(${args.join(',')})`;

export const multiply = fn('multiply');
export const div = fn('div');
// sum is to add references, to subtract we usually do a sum too but with
// but with multiply(-1, ref()) on the references we want negative values
export const sum = fn('sum');

export const formatDate = fn('formatDate');

// sumAllowNull is used for tax declarations where we want to sum up expanded
// ids with null values, i ex sum(id('K10-*.K10.fields.X))
export const sumAllowNull = fn('sumAllowNull');
export const or = fn('or');
export const max = fn('max');
export const min = fn('min');
export const id = (id: string) => `id(${id})`;
// We use multiplyPercentage to multiply a percentage with a reference * (value / 100)
export const multiplyPercentage = (...args: (string | number)[]) =>
  multiply(...args, 0.01);

// sumAllowEmpty is used for tax declarations where we want to sum up multiple values
// where some ids can be undefined
export const sumAllowEmpty = fn('sumAllowEmpty');

/**
 * You can use account() to sum one account or multiple account ranges
 * ex: (1000:1002+1004)
 * @name: The account range you want to use as a string
 * @modifier if not specified, it will return the outgoing balance for the account
 * @periodname e.g 'year' or 'previousYear'
 */
export const account = (
  name: string,
  modifier?: AccountValueType,
  periodName?: string
) =>
  `account(${name}${modifier ? `,${modifier}` : ''}${
    periodName ? `,period(${periodName})` : ''
  })`;
/**
 * accountName() gives you the name of an account.
 * Can be used with a reference to another cell/field with
 * the account number.
 *
 * Examples:
 * * accountName(id(myAccountNumber))
 * * accountName(1010)
 */
export const accountName = (accountNumber: string): string =>
  `accountName(${accountNumber})`;
export const round = (arg: string) => `round(${arg})`;
export const floor = (arg: string) => `round(sum(-0.5,${arg}))`;
export const abs = (arg: string) => `abs(${arg})`;
export const not = (arg: string) => `not(${arg})`;
export const ifOrElse = (
  ifStatement: string,
  thenArg: string,
  elseArg: string | undefined
) => `if(${ifStatement},${thenArg},${elseArg})`;

/**
 * config(name) resolves a value from the ResolveReferenceContext.resolveConfig.
 *
 * Bokslut has its own set of names. See `@agoy/tax-document`
 * Skatt uses the annualConfig from `@agoy/common`.
 *
 * @param name
 * @returns
 */
export const config = (name: keyof ConfigType): string => `config(${name})`;
