import React, {
  useRef,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react';
import styled from '@emotion/styled';
import { useDragDropManager } from 'react-dnd';
import { DraggableItemTypes } from 'contants';
import { activeFeatureFlags } from '_shared/HOC/withFeatureFlags';

type ScrollableTableProps = {
  className?: string;
  /**
   * Disclaimer: Typing React is nearly impossible.
   *
   * The children must be elements of Column type exported
   * from here.
   */
  children: any[];
};

export type ScrollableTableRef = {
  scrollToFocus: (type?: 'smooth' | 'auto') => void;
};

const ScrollableTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  min-width: 0px;
  overflow-y: auto;
  width: calc(100vw - 64px);
`;

/**
 * Element wrapping the sticky header
 */
const StickyHeader = styled.div`
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 5;
`;

/**
 * Element wrapping the sticky columns
 */
const Sticky = styled.div`
  position: sticky;
  left: 0px;
  top: 130px;
  z-index: 3;
  display: flex;
  flex-grow: 1;
  height: max-content;
`;

/**
 * Props that a column can/should have.
 */
export type ColumnProps = {
  className?: string;
  sticky?: boolean;
  focus?: boolean;
};

/**
 * A column in the table
 *
 * Overflow in horizontal direction must be visible to render the hidden row.
 *
 * @prop sticky  Makes the column sticky to the left
 * @prop focus   Requests focus for this column.
 *               The last column with true will be used to
 *               scroll the table to place that right after the sticky elements.
 */
export const Column = styled('div', {
  shouldForwardProp: (name) => name !== 'focus' && name !== 'sticky',
})<ColumnProps>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  .row {
    overflow: visible;
  }
`;

/**
 * Element wrapping both the sticky and scrollable elements
 */
const Content = styled.div`
  display: grid;
  grid-template-columns: 430px max-content;
  grid-template-rows: 130px 1fr;
  overflow-x: auto;
`;

/**
 * Element wrapping the scrollable columns
 */
const ScrollableArea = styled.div`
  display: flex;
  flex-direction: row;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 2;
  position: relative;
`;

/**
 * ScrollableTable
 *
 * A horizontally scrollable table with support for initial focus and sticky columns.
 *
 * The layout is table -> column -> rows, i.e. not the regular table -> row -> column.
 *
 * The responsibility of aligning the rows vertically is not part of this component.
 * This will instead be handled by RowContext that provides the information about each
 * row that is rendered included className that should give a fixed height per row.
 */
const ScrollableTable = forwardRef<ScrollableTableRef, ScrollableTableProps>(
  ({ children, className }: ScrollableTableProps, ref) => {
    const focusRef = useRef<HTMLDivElement | null>(null);
    const stickyRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const timerRef = useRef<NodeJS.Timer>();

    const dragDropManager = useDragDropManager();
    const monitor = dragDropManager.getMonitor();

    const startScrolling = (speed: number, container: HTMLElement) => {
      timerRef.current = setInterval(() => {
        container.scrollBy(0, speed);
      }, 1);
    };

    const cancelScroll = () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };

    useEffect(() => {
      if (!activeFeatureFlags.get('feature_moveAccountsBetweenGroups')) {
        return () => {};
      }

      const unsubscribe = monitor.subscribeToOffsetChange(() => {
        const offset = monitor.getClientOffset();
        const type = monitor.getItemType();

        if (type !== DraggableItemTypes.RECONCILIATION_ACCOUNT_ROW) {
          return;
        }

        cancelScroll();

        if (!offset || !contentRef.current) {
          return;
        }

        if (offset.y < 300) {
          startScrolling(-5, contentRef.current);
        } else if (window.innerHeight - offset.y < 200) {
          startScrolling(5, contentRef.current);
        }
      });

      return () => {
        unsubscribe();
      };
    }, [monitor]);

    // to control scrolling in the parent component
    useImperativeHandle(ref, () => ({
      scrollToFocus(type = 'auto') {
        if (focusRef.current && stickyRef.current) {
          const focus = focusRef.current.getBoundingClientRect();
          const { width } = stickyRef.current.getBoundingClientRect();
          const sideBarWidth = 60;
          if (contentRef.current) {
            contentRef.current.scrollBy({
              left: focus.left - width - sideBarWidth,
              behavior: type,
            });
          }
        }
      },
    }));

    return (
      <ScrollableTableContainer className={className}>
        <Content ref={contentRef}>
          <StickyHeader>
            {React.Children.map(children, (child) =>
              child?.props.stickyHeader ? child : null
            )}
          </StickyHeader>

          <ScrollableArea>
            {React.Children.map(children, (child) => {
              if (child?.props.sticky || child?.props.stickyHeader) {
                return null;
              }
              if (child?.props.focus) {
                return React.cloneElement(child, { ref: focusRef });
              }
              return child;
            })}
          </ScrollableArea>

          <Sticky ref={stickyRef}>
            {React.Children.map(children, (child) =>
              child?.props.sticky ? child : null
            )}
          </Sticky>
        </Content>
      </ScrollableTableContainer>
    );
  }
);

export default ScrollableTable;
