/*
 * This is a GENERATED file, do not change manually!
 * Generated:
 * - from file k3-arsredovisning-2021-10-31-rev20230131 - Noter.csv
 * - Comments: Former Note 90
 */

import {
  value,
  ref,
  sumAllowNull,
  id,
  AgoyDocumentPart,
} from '@agoy/document';
import { IxbrlCell } from '../../../../../common';
import {
  field,
  ixbrlCell,
  ixbrlField,
  table,
} from '../../../../../common/utils/util';
import { previousYear, year } from '../../../../../common/utils/date-util';



const cell = { type: 'field' as const };
const boolean = { type: 'boolean' as const };
const type = { type: 'type' as const };

export const getNote2027K3v2 = (
  noteLabel: any,
  financialYear: string,
  financialYears: string[],
  period: string | null
): AgoyDocumentPart<typeof note2027K3v2ContentDefinition> => ({
  active: false,
  number: value(undefined),
  name: noteLabel('Bokslutsdispositioner'),
  data: {
    active: true,
    BokslutsdispositionerAbstract: {
      type: 'group',
      active: true,
      title: field('Bokslutsdispositioner'),
      table: table<IxbrlCell>(
        'notes.note27.data.BokslutsdispositionerAbstract.table',
        {
          id: 'BokslutsdispositionerAbstract',
          label: '',
          dataType: 'text',
          active: true,
        },
        {
          id: 'year',
          label: year(financialYear, financialYears, period) ?? '',
          dataType: 'numeric',
          active: true,
        },
        {
          id: 'previousYear',
          label: previousYear(financialYear, financialYears) ?? '',
          dataType: 'numeric',
          active: true,
        }
      )
        .addRows((rows) => {
          rows
            .addRowWithType(
              'BokslutsdispositionerAbstract',
              'hidden',
              undefined,
              undefined,
              undefined
            )
            .addSubRows((rows) => {
              rows
                .addRowWithType(
                  'SpecifikationKoncernbidragAbstract',
                  'header',
                  noteLabel('Specifikation av koncernbidrag'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'ErhallnaKoncernbidrag',
                    'row',
                    noteLabel('Erhållna koncernbidrag'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ErhallnaKoncernbidrag',
                      standardRubrik: 'Erhållna koncernbidrag',
                      saldo: 'credit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ErhallnaKoncernbidrag',
                      standardRubrik: 'Erhållna koncernbidrag',
                      saldo: 'credit',
                      negateValue: false,
                    })
                  );
                  rows.addRowWithType(
                    'LamnadeKoncernbidrag',
                    'row',
                    noteLabel('Lämnade koncernbidrag'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LamnadeKoncernbidrag',
                      standardRubrik: 'Lämnade koncernbidrag',
                      saldo: 'debit',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:LamnadeKoncernbidrag',
                      standardRubrik: 'Lämnade koncernbidrag',
                      saldo: 'debit',
                      negateValue: true,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'NettoErhallnaLamnadeKoncernbidrag',
                'sum',
                noteLabel('Summa'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note27.data.BokslutsdispositionerAbstract.table.BokslutsdispositionerAbstract.SpecifikationKoncernbidragAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:NettoErhallnaLamnadeKoncernbidrag',
                    standardRubrik:
                      'Nettot av erhållna och lämnade koncernbidrag',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note27.data.BokslutsdispositionerAbstract.table.BokslutsdispositionerAbstract.SpecifikationKoncernbidragAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:NettoErhallnaLamnadeKoncernbidrag',
                    standardRubrik:
                      'Nettot av erhållna och lämnade koncernbidrag',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              );
              rows
                .addRowWithType(
                  'ForandringPeriodiseringsfondAbstract',
                  'header',
                  noteLabel('Förändring av periodiseringsfonder'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows.addRowWithType(
                    'ForandringPeriodiseringsfondAvsattning',
                    'row',
                    noteLabel('Avsättning till periodiseringsfonder'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringPeriodiseringsfondAvsattning',
                      standardRubrik: 'Avsättning till periodiseringsfonder',
                      saldo: 'debit',
                      negateValue: true,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringPeriodiseringsfondAvsattning',
                      standardRubrik: 'Avsättning till periodiseringsfonder',
                      saldo: 'debit',
                      negateValue: true,
                    })
                  );
                  rows.addRowWithType(
                    'ForandringPeriodiseringsfondAterforing',
                    'row',
                    noteLabel('Återföring av periodiseringsfonder'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringPeriodiseringsfondAterforing',
                      standardRubrik: 'Återföring av periodiseringsfonder',
                      saldo: 'credit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:ForandringPeriodiseringsfondAterforing',
                      standardRubrik: 'Återföring av periodiseringsfonder',
                      saldo: 'credit',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'ForandringPeriodiseringsfond',
                'sum',
                noteLabel('Summa'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note27.data.BokslutsdispositionerAbstract.table.BokslutsdispositionerAbstract.ForandringPeriodiseringsfondAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringPeriodiseringsfond',
                    standardRubrik: 'Förändring av periodiseringsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note27.data.BokslutsdispositionerAbstract.table.BokslutsdispositionerAbstract.ForandringPeriodiseringsfondAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:ForandringPeriodiseringsfond',
                    standardRubrik: 'Förändring av periodiseringsfonder',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              );
              rows.addRowWithType(
                'ForandringOveravskrivningar',
                'row',
                noteLabel('Förändring av överavskrivningar'),
                ixbrlCell(value(undefined), {
                  contextRef: 'period0',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForandringOveravskrivningar',
                  standardRubrik: 'Förändring av överavskrivningar',
                  saldo: 'credit',
                  negateValue: false,
                }),
                ixbrlCell(value(undefined), {
                  contextRef: 'period1',
                  type: 'monetaryItemType',
                  unitRef: 'SEK',
                  name: 'se-gen-base:ForandringOveravskrivningar',
                  standardRubrik: 'Förändring av överavskrivningar',
                  saldo: 'credit',
                  negateValue: false,
                })
              );
              rows
                .addRowWithType(
                  'OvrigaBokslutsdispositionerAbstract',
                  'header',
                  noteLabel('Övriga bokslutsdispositioner'),
                  undefined,
                  undefined
                )
                .addSubRows((rows) => {
                  rows
                    .addRowWithType(
                      'ForandringErsattningsfondAbstract',
                      'header',
                      noteLabel('Förändring av ersättningsfond'),
                      undefined,
                      undefined
                    )
                    .addSubRows((rows) => {
                      rows.addRowWithType(
                        'AvsattningErsattningsfond',
                        'row',
                        noteLabel('Avsättning till ersättningsfond'),
                        ixbrlCell(value(undefined), {
                          contextRef: 'period0',
                          type: 'monetaryItemType',
                          unitRef: 'SEK',
                          name: 'se-gen-base:AvsattningErsattningsfond',
                          standardRubrik: 'Avsättning till ersättningsfond',
                          saldo: 'debit',
                          negateValue: true,
                        }),
                        ixbrlCell(value(undefined), {
                          contextRef: 'period1',
                          type: 'monetaryItemType',
                          unitRef: 'SEK',
                          name: 'se-gen-base:AvsattningErsattningsfond',
                          standardRubrik: 'Avsättning till ersättningsfond',
                          saldo: 'debit',
                          negateValue: true,
                        })
                      );
                      rows.addRowWithType(
                        'IanspraktagetBeloppErsattningsfondAvskrivningar',
                        'row',
                        noteLabel(
                          'Ianspråktaget belopp från ersättningsfond för avskrivningar'
                        ),
                        ixbrlCell(value(undefined), {
                          contextRef: 'period0',
                          type: 'monetaryItemType',
                          unitRef: 'SEK',
                          name: 'se-gen-base:IanspraktagetBeloppErsattningsfondAvskrivningar',
                          standardRubrik:
                            'Ianspråktaget belopp från ersättningsfond för avskrivningar',
                          saldo: 'credit',
                          negateValue: false,
                        }),
                        ixbrlCell(value(undefined), {
                          contextRef: 'period1',
                          type: 'monetaryItemType',
                          unitRef: 'SEK',
                          name: 'se-gen-base:IanspraktagetBeloppErsattningsfondAvskrivningar',
                          standardRubrik:
                            'Ianspråktaget belopp från ersättningsfond för avskrivningar',
                          saldo: 'credit',
                          negateValue: false,
                        })
                      );
                      rows.addRowWithType(
                        'IanspraktagetBeloppErsattningsfondAnnatAvskrivningar',
                        'row',
                        noteLabel(
                          'Ianspråktaget belopp från ersättningsfond för annat än avskrivningar'
                        ),
                        ixbrlCell(value(undefined), {
                          contextRef: 'period0',
                          type: 'monetaryItemType',
                          unitRef: 'SEK',
                          name: 'se-gen-base:IanspraktagetBeloppErsattningsfondAnnatAvskrivningar',
                          standardRubrik:
                            'Ianspråktaget belopp från ersättningsfond för annat än avskrivningar',
                          saldo: 'credit',
                          negateValue: false,
                        }),
                        ixbrlCell(value(undefined), {
                          contextRef: 'period1',
                          type: 'monetaryItemType',
                          unitRef: 'SEK',
                          name: 'se-gen-base:IanspraktagetBeloppErsattningsfondAnnatAvskrivningar',
                          standardRubrik:
                            'Ianspråktaget belopp från ersättningsfond för annat än avskrivningar',
                          saldo: 'credit',
                          negateValue: false,
                        })
                      );
                      rows.addRowWithType(
                        'AterforingErsattningsfond',
                        'row',
                        noteLabel('Återföring från ersättningsfond'),
                        ixbrlCell(value(undefined), {
                          contextRef: 'period0',
                          type: 'monetaryItemType',
                          unitRef: 'SEK',
                          name: 'se-gen-base:AterforingErsattningsfond',
                          standardRubrik: 'Återföring från ersättningsfond',
                          saldo: 'credit',
                          negateValue: false,
                        }),
                        ixbrlCell(value(undefined), {
                          contextRef: 'period1',
                          type: 'monetaryItemType',
                          unitRef: 'SEK',
                          name: 'se-gen-base:AterforingErsattningsfond',
                          standardRubrik: 'Återföring från ersättningsfond',
                          saldo: 'credit',
                          negateValue: false,
                        })
                      );
                      return rows.build();
                    });
                  rows.addRowWithType(
                    'ForandringErsattningsfond',
                    'sum',
                    noteLabel('Summa'),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'notes.note27.data.BokslutsdispositionerAbstract.table.BokslutsdispositionerAbstract.OvrigaBokslutsdispositionerAbstract.ForandringErsattningsfondAbstract.*.year'
                          )
                        )
                      ),
                      {
                        contextRef: 'period0',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringErsattningsfond',
                        standardRubrik: 'Förändring av ersättningsfonder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    ),
                    ixbrlCell(
                      ref(
                        sumAllowNull(
                          id(
                            'notes.note27.data.BokslutsdispositionerAbstract.table.BokslutsdispositionerAbstract.OvrigaBokslutsdispositionerAbstract.ForandringErsattningsfondAbstract.*.previousYear'
                          )
                        )
                      ),
                      {
                        contextRef: 'period1',
                        type: 'monetaryItemType',
                        unitRef: 'SEK',
                        name: 'se-gen-base:ForandringErsattningsfond',
                        standardRubrik: 'Förändring av ersättningsfonder',
                        saldo: 'credit',
                        negateValue: false,
                      }
                    )
                  );
                  rows.addRowWithType(
                    'AndraBokslutsdispositioner',
                    'row',
                    noteLabel('Andra bokslutsdispositioner'),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period0',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraBokslutsdispositioner',
                      standardRubrik:
                        'Andra bokslutsdispositioner än koncernbidrag, förändring av periodiseringsfonder, förändring av överavskrivningar och förändring av ersättningsfonder',
                      saldo: 'credit',
                      negateValue: false,
                    }),
                    ixbrlCell(value(undefined), {
                      contextRef: 'period1',
                      type: 'monetaryItemType',
                      unitRef: 'SEK',
                      name: 'se-gen-base:AndraBokslutsdispositioner',
                      standardRubrik:
                        'Andra bokslutsdispositioner än koncernbidrag, förändring av periodiseringsfonder, förändring av överavskrivningar och förändring av ersättningsfonder',
                      saldo: 'credit',
                      negateValue: false,
                    })
                  );
                  return rows.build();
                });
              rows.addRowWithType(
                'OvrigaBokslutsdispositioner',
                'sum',
                noteLabel('Summa'),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note27.data.BokslutsdispositionerAbstract.table.BokslutsdispositionerAbstract.OvrigaBokslutsdispositionerAbstract.*.year'
                      )
                    )
                  ),
                  {
                    contextRef: 'period0',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:OvrigaBokslutsdispositioner',
                    standardRubrik:
                      'Övriga bokslutsdispositioner utöver koncernbidrag, förändring av periodiseringsfonder och förändring av överavskrivningar',
                    saldo: 'credit',
                    negateValue: false,
                  }
                ),
                ixbrlCell(
                  ref(
                    sumAllowNull(
                      id(
                        'notes.note27.data.BokslutsdispositionerAbstract.table.BokslutsdispositionerAbstract.OvrigaBokslutsdispositionerAbstract.*.previousYear'
                      )
                    )
                  ),
                  {
                    contextRef: 'period1',
                    type: 'monetaryItemType',
                    unitRef: 'SEK',
                    name: 'se-gen-base:OvrigaBokslutsdispositioner',
                    standardRubrik:
                      'Övriga bokslutsdispositioner utöver koncernbidrag, förändring av periodiseringsfonder och förändring av överavskrivningar',
                    saldo: 'credit',
                    negateValue: false,
                  }
                )
              );
              return rows.build();
            });
          rows.addRowWithType(
            'Bokslutsdispositioner',
            'sum',
            noteLabel('Summa'),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note27.data.BokslutsdispositionerAbstract.table.BokslutsdispositionerAbstract.*.year'
                  )
                )
              ),
              {
                contextRef: 'period0',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:Bokslutsdispositioner',
                standardRubrik: 'Bokslutsdispositioner',
                saldo: 'credit',
                negateValue: false,
              }
            ),
            ixbrlCell(
              ref(
                sumAllowNull(
                  id(
                    'notes.note27.data.BokslutsdispositionerAbstract.table.BokslutsdispositionerAbstract.*.previousYear'
                  )
                )
              ),
              {
                contextRef: 'period1',
                type: 'monetaryItemType',
                unitRef: 'SEK',
                name: 'se-gen-base:Bokslutsdispositioner',
                standardRubrik: 'Bokslutsdispositioner',
                saldo: 'credit',
                negateValue: false,
              }
            )
          );
          return rows.build();
        })
        .build(),
      NotBokslutsdispositionerKommentar: {
        type: 'group',
        active: true,
        title: field('Kommentar till specifikation av bokslutsdispositioner'),
        value: ixbrlField(field(''), {
          contextRef: 'period0',
          type: 'stringItemType',
          name: 'se-gen-base:NotBokslutsdispositionerKommentar',
          standardRubrik:
            'Kommentar till specifikation av bokslutsdispositioner',
        }),
      },
    },
  },
  group: value(undefined),
  section: value(undefined),
  validForReportType: {
    k2: true,
    k3: true,
  },
});
export const note2027K3v2ContentDefinition = {
  type: 'part' as const,
  children: {
    active: boolean,
    number: cell,
    name: cell,
    data: {
      type: 'part' as const,
      children: {
        active: boolean,

        BokslutsdispositionerAbstract: {
          type: 'part' as const,
          children: {
            type: type,
            active: boolean,

            title: cell,

            table: { type: 'table' as const },

            NotBokslutsdispositionerKommentar: {
              type: 'part' as const,
              children: {
                type: type,
                active: boolean,

                title: cell,

                value: cell,
              },
            },
          },
        },
      },
    },
    group: cell,
    section: cell,
    validForReportType: {
      type: 'part' as const,
      children: {
        k2: boolean,
        k3: boolean,
      },
    },
  },
};
